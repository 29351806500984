import type {ConnectorSpec} from '@cohort/shared/apps/app';
import type {BaseProfileProperties, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const TalonOneCredentialsSchema = z.object({
  deploymentUrl: z.string().url(),
  applicationId: z.number().int().positive(),
  integrationApiKey: z.string(),
  managementApiKey: z.string(),
});
export type TalonOneCredentials = z.infer<typeof TalonOneCredentialsSchema>;

export type TalonOneConnectorStruct = ConnectorStruct<
  'talon-one',
  null,
  TalonOneCredentials,
  BaseProfileProperties,
  null
>;

export const TalonOneConnectorSpec = {
  id: 'talon-one',
  type: 'custom',
  appCredentialsSchema: null,
  credentialsSchema: TalonOneCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<TalonOneConnectorStruct>;
