import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortChallengeLeftEventStruct} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeLeft';
import {CohortChallengeLeftEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeLeft';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const campaignId = (userEvent.properties as CohortChallengeLeftEventStruct['Properties'])
    .campaignId;
  const campaignInternalName = userEvent.campaignInternalName;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.challenge.left.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCampaignRoute(campaignId).path)
        ),
      }}
    />
  );
};

export const CohortChallengeLeftUserEvent: UserEvent<CohortChallengeLeftEventStruct> = {
  spec: CohortChallengeLeftEventSpec,
  detailComponent: DetailComponent,
};
