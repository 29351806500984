import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';

type TitleProps = {
  children: React.ReactNode;
  className?: string;
  testId?: string;
};

const Title: React.FC<TitleProps> = ({children, testId, className}) => (
  <h1 data-testid={testId} className={cn('w-full', className)}>
    {children}
  </h1>
);

export default Title;
