import type {
  ActionSpec,
  AppSpec,
  AppStruct,
  BaseProfileProperties,
  ConnectorSpec,
  ConnectorStruct,
  PerkIntegrationSpec,
} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {ListRolesActionSpec} from '@cohort/shared/apps/discord/actions/listRoles';
import {DiscordPrivateAccessPerkIntegrationSpec} from '@cohort/shared/apps/discord/perks/privateAccess';
import {DiscordRoleResourceSpec} from '@cohort/shared/apps/discord/resources/discordRole';
import {DiscordHasRoleTriggerIntegrationSpec} from '@cohort/shared/apps/discord/triggers/hasRole';
import {DiscordRolesUserPropertySpec} from '@cohort/shared/apps/discord/userProperties/roles';
import type {OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {OAuth2AppCredentialsSchema, OAuth2CredentialsSchema} from '@cohort/shared/apps/oauth';
import type {ResourceSpec} from '@cohort/shared/apps/resource';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import {createConnectAccountTriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {
  createAppUsernameUserPropertySpec,
  createBaseAppUserPropertySpec,
} from '@cohort/shared/apps/userProperty';
import {z} from 'zod';

export const DISCORD_APP_ID = 'discord' as const;
export const DISCORD_APP_NAME = 'Discord' as const;

export const DiscordAppCredentialsSchema = OAuth2AppCredentialsSchema.extend({
  botToken: z.string(),
});
export type DiscordAppCredentials = z.infer<typeof DiscordAppCredentialsSchema>;

export const DiscordUserProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  username: z.string(),
  discriminator: z.string(),
});
export type DiscordUserProfileProperties = z.infer<typeof DiscordUserProfilePropertiesSchema>;

export type DiscordMerchantConnectorStruct = ConnectorStruct<
  'discord-merchant',
  DiscordAppCredentials,
  DiscordMerchantCredentials,
  BaseProfileProperties,
  null
>;

export type DiscordUserConnectorStruct = ConnectorStruct<
  'discord',
  DiscordAppCredentials,
  OAuth2Credentials,
  DiscordUserProfileProperties,
  null
>;

export const DiscordUserConnectorSpec = {
  id: 'discord',
  type: 'oauth2',
  appCredentialsSchema: DiscordAppCredentialsSchema,
  credentialsSchema: OAuth2CredentialsSchema,
  profilePropertiesSchema: DiscordUserProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<DiscordUserConnectorStruct>;

export const DiscordMerchantCredentialsSchema = OAuth2CredentialsSchema.extend({
  guildId: z.string(),
});
export type DiscordMerchantCredentials = z.infer<typeof DiscordMerchantCredentialsSchema>;

export const DiscordMerchantConnectorSpec = {
  id: 'discord-merchant',
  type: 'oauth2',
  appCredentialsSchema: DiscordAppCredentialsSchema,
  credentialsSchema: DiscordMerchantCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<DiscordMerchantConnectorStruct>;

export const DiscordOauth2CallbackParamsSchema = z.object({
  guild_id: z.string(),
});
export type DiscordOauth2CallbackParams = z.infer<typeof DiscordOauth2CallbackParamsSchema>;

export const DiscordPerkIntegrationSpecs = [
  DiscordPrivateAccessPerkIntegrationSpec,
] as const satisfies ReadonlyArray<PerkIntegrationSpec>;
type DiscordPerkIntegrationIds = (typeof DiscordPerkIntegrationSpecs)[number]['id'];

export const DiscordTriggerIntegrationSpecs = [
  createConnectAccountTriggerIntegrationSpec(DISCORD_APP_ID),
  DiscordHasRoleTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type DiscordTriggerIntegrationIds = (typeof DiscordTriggerIntegrationSpecs)[number]['id'];

export const DiscordActionSpecs = [
  ListRolesActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type DiscordActionIds = (typeof DiscordActionSpecs)[number]['id'];

export const DiscordUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(DISCORD_APP_ID, DISCORD_APP_NAME),
  createAppUsernameUserPropertySpec(DISCORD_APP_ID, DISCORD_APP_NAME),
  {
    id: 'discord.discriminator',
    dataType: 'string',
    name: 'Discord Discriminator',
    scope: 'profile',
    profileKey: 'discriminator',
  },
  DiscordRolesUserPropertySpec,
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type DiscordUserPropertyIds = (typeof DiscordUserPropertiesSpecs)[number]['id'];

export const DiscordResourceSpecs = [
  DiscordRoleResourceSpec,
] as const satisfies ReadonlyArray<ResourceSpec>;
type DiscordResourceTypes = (typeof DiscordResourceSpecs)[number]['id'];

export type DiscordAppStruct = AppStruct<
  'discord',
  DiscordMerchantConnectorStruct,
  DiscordUserConnectorStruct,
  null,
  DiscordActionIds,
  never,
  DiscordPerkIntegrationIds,
  DiscordTriggerIntegrationIds,
  never,
  DiscordUserPropertyIds,
  DiscordResourceTypes,
  never
>;
export const DiscordAppSpec: AppSpec<DiscordAppStruct> = {
  id: DISCORD_APP_ID,
  name: DISCORD_APP_NAME,
  merchantConnector: DiscordMerchantConnectorSpec,
  userConnector: DiscordUserConnectorSpec,
  actionSpecs: DiscordActionSpecs,
  syncSpec: null,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: DiscordPerkIntegrationSpecs,
  triggerIntegrationSpecs: DiscordTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: DiscordUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
};
