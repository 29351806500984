import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortDigitalAssetDeletedPropertiesSchema = z.object({
  collectionId: z.string().uuid(),
  digitalAssetId: z.string().uuid(),
  ownershipId: z.string().uuid(),
});
export type CohortDigitalAssetDeletedProperties = z.infer<
  typeof CohortDigitalAssetDeletedPropertiesSchema
>;

export type CohortDigitalAssetDeletedEventStruct = UserEventStruct<
  'cohort.digital-asset.deleted',
  keyof CohortDigitalAssetDeletedProperties,
  CohortDigitalAssetDeletedProperties,
  CohortDigitalAssetDeletedProperties
>;

export const CohortDigitalAssetDeletedEventSpec: UserEventSpec<CohortDigitalAssetDeletedEventStruct> =
  {
    id: 'cohort.digital-asset.deleted',
    name: 'Deleted a Digital Asset',
    description: 'Event triggerred when a Digital Asset is deleted',
    propertiesSchema: CohortDigitalAssetDeletedPropertiesSchema,
    exposedPropertiesSchema: CohortDigitalAssetDeletedPropertiesSchema,
    resources: {
      collectionId: 'cohort.digital-asset-collection',
      digitalAssetId: 'cohort.digital-asset',
    },
    rulesEngineConfig: {
      isVisible: false,
      exposedFields: [],
    },
  };
