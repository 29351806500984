import {
  Breadcrumb,
  BreadcrumbBadges,
  BreadcrumbContent,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
} from '@cohort/merchants/components/Breadcrumb';
import {badgesKeys} from '@cohort/merchants/hooks/api/Badges';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {updateBadge} from '@cohort/merchants/lib/api/Badges';
import {getBadgesRoute} from '@cohort/merchants/lib/Pages';
import DeleteBadge from '@cohort/merchants/pages/users/badges/badge/DeleteBadge';
import BadgeStatusOrCohortLabel from '@cohort/merchants/pages/users/badges/BadgeStatusOrCohortLabel';
import {BadgeInternalNameSchema} from '@cohort/shared/schema/common/badges';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const PatchBadgeInternalNameSchema = z.object({
  internalName: BadgeInternalNameSchema,
});
type PatchBadgeInternalNameValues = z.infer<typeof PatchBadgeInternalNameSchema>;

const BadgePageBreadcrumb: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const badge = useCurrentBadge();

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.badges.badgePageBreadcrumb',
  });

  const {mutate: updateBadgeMutation} = useCohortMutation({
    mutationFn: async (badgeData: PatchBadgeInternalNameValues) =>
      updateBadge(merchantId, badge.id, badgeData),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationUpdateSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(badgesKeys.getById(merchantId, badge.id));
      queryClient.invalidateQueries(badgesKeys.list(merchantId));
    },
  });

  return (
    <Breadcrumb>
      <BreadcrumbContent>
        <BreadcrumbLink name={t('name')} path={getBadgesRoute().path} />
        <BreadcrumbEditableTitle
          value={badge.internalName}
          onChange={(internalName: string) => updateBadgeMutation({internalName})}
          validator={(internalName: string) =>
            PatchBadgeInternalNameSchema.safeParse({internalName}).success
          }
        />
        <BreadcrumbBadges>
          <BadgeStatusOrCohortLabel badge={badge} />
        </BreadcrumbBadges>
      </BreadcrumbContent>
      <DeleteBadge badge={badge} />
    </Breadcrumb>
  );
};

export default BadgePageBreadcrumb;
