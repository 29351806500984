import CohortFormQuestionTypeLabel from '@cohort/merchants/components/form/cohortForm/CohortFormQuestionTypeLabel';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import {ValueNotTranslatedLabel} from '@cohort/merchants/components/ValueNotTranslatedLabel';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {Language} from '@cohort/shared/schema/common';
import type {CohortFormQuestion} from '@cohort/shared/schema/common/cohortForm';

const CohortFormQuestionItem: React.FC<{
  question: CohortFormQuestion;
  handleEdit: () => void;
  handleRemove?: () => void;
  selectedLanguage: Language;
}> = ({question, handleEdit, handleRemove, selectedLanguage}) => {
  const merchant = useCurrentMerchant();

  return (
    <DraggableListItem onEdit={handleEdit} onRemove={handleRemove}>
      <div className="flex items-center justify-between gap-2">
        {question.name[selectedLanguage] !== undefined ? (
          <span className="flex items-center text-sm">{question.name[selectedLanguage]}</span>
        ) : (
          <ValueNotTranslatedLabel
            defaultValue={question.name[merchant.defaultLanguage]}
            language={selectedLanguage}
          />
        )}
        <span className="font-regular whitespace-nowrap rounded-md bg-slate-100 px-2 py-1 text-sm leading-4 text-slate-600">
          <CohortFormQuestionTypeLabel type={question.type} />
        </span>
      </div>
    </DraggableListItem>
  );
};
export default CohortFormQuestionItem;
