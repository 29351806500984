import {Card} from '@cohort/components-xps/components/cards/Card';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentInfos from '@cohort/components-xps/components/contents/MediaContentInfos';
import type {CohortImageMediaStruct} from '@cohort/shared/apps/cohort/medias/image';
import Spinner from '@cohort/shared-frontend/components/Spinner';
import {useState} from 'react';
import {isDefined} from 'remeda';

const MediaContentCohortImage: React.FC<MediaContentProps<CohortImageMediaStruct>> = ({
  description,
  config,
  title,
}) => {
  const [isImageLoading, setIsImageLoading] = useState(isDefined(config?.imageFileKey));

  return (
    <Card className="space-y-6">
      {config?.imageFileKey && (
        <div className="relative aspect-video w-full">
          <img
            src={config.imageFileKey}
            className="aspect-video w-full object-fill"
            onLoad={() => setIsImageLoading(false)}
          />
          {isImageLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Spinner size={28} />
            </div>
          )}
        </div>
      )}

      <MediaContentInfos title={title} description={description} />
    </Card>
  );
};

export default MediaContentCohortImage;
