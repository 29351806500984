import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {z} from 'zod';

export const CohortAppResourceSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type CohortAppResourceType = z.infer<typeof CohortAppResourceSchema>;

export type CohortAppResourceStruct = ResourceStruct<'cohort.app', CohortAppResourceType>;

export const CohortAppResourceSpec: ResourceSpec<CohortAppResourceStruct> = {
  id: 'cohort.app',
  schema: CohortAppResourceSchema,
};
