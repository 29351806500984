import Loader from '@cohort/merchants/components/Loader';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Slot} from '@radix-ui/react-slot';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';
import React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-60',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-primary bg-background text-primary hover:underline',
        secondary: 'bg-white border border-border text-secondary-foreground hover:bg-secondary/80 ',
        ghost: 'text-primary hover:bg-primary/5 hover:transition-colors',
        ghostGray: 'text-slate-500 hover:bg-slate-100 hover:transition-colors',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'h-6 text-xs rounded-md px-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {className, variant, size, asChild = false, loading, disabled, type, children, ...props},
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    const isDisabled = disabled || loading;
    const btnType = type || 'button';

    return (
      <Comp
        className={cn(buttonVariants({variant, size, className}))}
        ref={ref}
        disabled={isDisabled}
        type={btnType}
        {...props}
      >
        {loading && (
          <Loader
            size={25}
            color={!variant || ['primary', 'destructive'].includes(variant) ? 'white' : 'gray'}
          />
        )}
        {children}
      </Comp>
    );
  }
);

export default Button;
