// i18nOwl-ignore [app-discord.perkIntegrations.private-access.title, app-discord.perkIntegrations.private-access.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import DiscordPrivateAccessPerkIntegrationConfigComponent from '@cohort/merchants/apps/discord/perkIntegrations/privateAccess/ConfigComponent';
import DiscordPrivateAccessPerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/discord/perkIntegrations/privateAccess/UsageViewComponent';
import type {DiscordPrivateAccessPerkIntegrationStruct} from '@cohort/shared/apps/discord/perks/privateAccess';
import {DiscordPrivateAccessPerkIntegrationSpec} from '@cohort/shared/apps/discord/perks/privateAccess';

export const DiscordPrivateAccessPerkIntegration: PerkIntegration<DiscordPrivateAccessPerkIntegrationStruct> =
  {
    spec: DiscordPrivateAccessPerkIntegrationSpec,
    configComponent: DiscordPrivateAccessPerkIntegrationConfigComponent,
    usageViewComponent: DiscordPrivateAccessPerkIntegrationsUsageViewComponent,
    iconColor: 'text-purple-500',
    backgroundColor: 'bg-purple-100',
  };
