import {formatTypeformAnswer} from '@cohort/merchants/apps/typeform/utils/format';
import type {TypeformQuestionWithAnswer} from '@cohort/shared/apps/typeform/common';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

type TypeformQuestionWithAnswerListProps = {
  questionsWithAnswers: TypeformQuestionWithAnswer[] | undefined;
};

const TypeformQuestionWithAnswerList: React.FC<TypeformQuestionWithAnswerListProps> = ({
  questionsWithAnswers,
}) => {
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'triggerIntegrations.typeformQuestionWithAnswerList',
  });

  if (!questionsWithAnswers || questionsWithAnswers.length === 0) {
    return <Fragment />;
  }

  return (
    <div className="space-y-4">
      {questionsWithAnswers.map(questionWithAnswer => {
        const {question, answer} = questionWithAnswer;
        return (
          <div key={question.id} className="space-y-1">
            <div className="text-base font-medium">{question.title}</div>
            <div className="rounded border border-slate-200 bg-slate-100 px-2 py-1.5 text-slate-500">
              {formatTypeformAnswer(answer) ?? t('noAnswer')}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TypeformQuestionWithAnswerList;
