import type FeatureFlags from '@cohort/merchants/featureFlags';
import {
  getBadgesRoute,
  getCampaignsRoute,
  getCohortsRoute,
  getContentCategoriesRoute,
  getContentsRoute,
  getDigitalAssetsRoute,
  getHomeRoute,
  getPerksRoute,
  getUsersRoute,
} from '@cohort/merchants/lib/Pages';
import {trackNavbarLinkButtonClicked} from '@cohort/merchants/lib/Tracking';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {useFlags} from 'launchdarkly-react-client-sdk';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';

const NavbarPagesLinks: React.FC = () => {
  const location = useLocation();
  const {t} = useTranslation('components', {keyPrefix: 'navBar.navbarPagesLinks'});
  const {featureContent, featureAnalytics} = useFlags<FeatureFlags>();

  const pages = [
    ...(featureAnalytics
      ? [
          {
            name: t('linkHome'),
            href: getHomeRoute().path,
            pageName: getHomeRoute().name,
          },
        ]
      : []),
    {
      name: t('linkUsers'),
      href: getUsersRoute().path,
      additionalHrefs: [getCohortsRoute().path, getBadgesRoute().path],
      pageName: getUsersRoute().name,
    },

    {
      name: t('linkCampaigns'),
      href: getCampaignsRoute().path,
      pageName: getCampaignsRoute().name,
    },
    {
      name: t('linkRewards'),
      href: getPerksRoute().path,
      additionalHrefs: [getDigitalAssetsRoute().path],
      pageName: getPerksRoute().name,
    },
    ...(featureContent
      ? [
          {
            name: t('linkContents'),
            href: getContentsRoute().path,
            additionalHrefs: [getContentCategoriesRoute().path],
            pageName: getContentsRoute().name,
          },
        ]
      : []),
  ];

  function isCurrentPath(hrefs: string | string[]): boolean {
    hrefs = Array.isArray(hrefs) ? hrefs : [hrefs];
    return hrefs.some(href => location.pathname.startsWith(href.split('?')[0] ?? href));
  }

  return (
    <div className="-mb-px flex flex-col gap-6 text-center text-base font-extrabold md:h-16 md:flex-row">
      {pages.map(item => {
        const isActive =
          isCurrentPath(item.href.split('?')[0] ?? '') ||
          (item.additionalHrefs !== undefined && isCurrentPath(item.additionalHrefs));

        return (
          <Link
            className={cn(
              'flex cursor-pointer items-center border-b-2 text-sm font-medium',
              isActive
                ? 'border-b-primary'
                : 'border-b-transparent text-slate-500 hover:text-slate-700'
            )}
            onClick={() => trackNavbarLinkButtonClicked(item.pageName)}
            key={item.name}
            to={item.href}
            replace
          >
            {item.name}
          </Link>
        );
      })}
    </div>
  );
};

export default NavbarPagesLinks;
