import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import type {ResourceListDataType} from '@cohort/shared/schema/common/userProperty';

export type CohortPerksUserPropertyStruct = UserPropertyStruct<
  'cohort.perks',
  ResourceListDataType
>;

export const CohortPerksUserPropertySpec: UserPropertySpec<CohortPerksUserPropertyStruct> = {
  id: 'cohort.perks',
  name: 'Perks',
  dataType: 'resource_list',
  scope: 'global',
  resource: 'cohort.perk',
  defaultValue: [],
};
