/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {GetPerkAccessesParamsADto} from '@cohort/admin-schemas/perkAccesses';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getPerkAccess, getPerkAccesses} from '@cohort/merchants/lib/api/PerksAccesses';

export const perkAccessesKeys = {
  perkAccesses: ['perk-accesses'] as const,
  get: (merchantId: string, perkAccessId: string) =>
    [...perkAccessesKeys.perkAccesses, merchantId, perkAccessId, 'get'] as const,
  list: (merchantId: string, params: GetPerkAccessesParamsADto) =>
    [...perkAccessesKeys.perkAccesses, merchantId, params, 'list'] as const,
};

export const usePerkAccess = (merchantId: string, perkAccessId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: perkAccessesKeys.get(merchantId, perkAccessId),
    queryFn: async () => getPerkAccess(merchantId, perkAccessId),
    ...options,
  });

export const usePerkAccesses = (
  merchantId: string,
  params: GetPerkAccessesParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: perkAccessesKeys.list(merchantId, params),
    queryFn: async () => getPerkAccesses(merchantId, params),
    ...options,
  });
