import type {UserAnalyticsADto} from '@cohort/admin-schemas/userAnalytics';
import {CurrentUserContext} from '@cohort/merchants/pages/users/user/CurrentUserContext';
import {useContext} from 'react';

export const useCurrentUser = (): UserAnalyticsADto => {
  const context = useContext(CurrentUserContext);

  if (context === null) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider');
  }
  return context;
};
