import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {INSTAGRAM_URL} from '@cohort/shared/apps/instagram/common';
import type {InstagramMerchantConnectorStruct} from '@cohort/shared/apps/instagram/merchantConnector';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const InstagramFollowTriggerConfigComponent: React.FC<TriggerIntegrationConfigComponentProps> = ({
  formReturn,
  connectionId,
}) => {
  const merchant = useCurrentMerchant();

  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.follow.configComponent',
  });

  const {data: profile} = useConnectionProfile<InstagramMerchantConnectorStruct>(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {enabled: !!connectionId}
  );

  const {setValue} = formReturn;

  useEffect(() => {
    if (!profile) {
      return;
    }

    setValue('triggerIntegrationConfig.url', `${INSTAGRAM_URL}/${profile.properties.username}`);
  }, [setValue, profile]);

  return <HighlightText type="info" text={t('unverifiedInfo')} />;
};

export default InstagramFollowTriggerConfigComponent;
