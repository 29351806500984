import type {SvgIconProps} from '@cohort/shared-frontend/components/BaseSvgIcon';
import BaseSvgIcon from '@cohort/shared-frontend/components/BaseSvgIcon';

const ICONS = {
  CohortLogo: {
    viewBox: '0 0 313 66',
    element: (
      <svg
        width="313"
        height="66"
        viewBox="0 0 313 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M287.55 31.2028V50.52H278.997V28.7416C278.997 24.6229 282.337 21.2832 286.456 21.2832H295.854V29.3382H289.414C288.385 29.3382 287.55 30.1736 287.55 31.2028Z"
          fill="#131A22"
        />
        <path
          d="M312.383 42.9165V50.524H309.105C303.138 50.524 298.414 47.9882 298.414 39.9828V18.2044L306.917 15.7183V21.2872H312.383V29.3422H306.917V39.9331C306.917 42.2701 308.061 42.9165 310.349 42.9165H312.383Z"
          fill="#131A22"
        />
        <path
          d="M180.541 35.9011C180.541 27.15 187.204 20.686 195.956 20.686C204.708 20.686 211.371 27.15 211.371 35.9011C211.371 44.6523 204.708 51.1162 195.956 51.1162C187.204 51.1162 180.541 44.702 180.541 35.9011ZM202.719 35.9011C202.719 31.5753 199.785 28.6914 195.956 28.6914C192.127 28.6914 189.144 31.6747 189.144 35.9011C189.144 40.1275 192.078 43.0114 195.956 43.0114C199.835 43.0114 202.719 40.0778 202.719 35.9011Z"
          fill="#131A22"
        />
        <path
          d="M240.957 31.7786V50.524H232.205V33.32C232.205 30.4858 230.663 28.845 228.127 28.845C225.591 28.845 223.851 30.4858 223.851 33.32V50.524H215.099V15.7183H223.851V22.7291C225.392 21.3866 227.431 20.6905 229.818 20.6905C236.133 20.6905 240.957 24.9169 240.957 31.7786Z"
          fill="#131A22"
        />
        <path
          d="M244.437 35.9011C244.437 27.15 251.1 20.686 259.852 20.686C268.604 20.686 275.267 27.15 275.267 35.9011C275.267 44.6523 268.604 51.1162 259.852 51.1162C251.1 51.1162 244.437 44.702 244.437 35.9011ZM266.615 35.9011C266.615 31.5753 263.681 28.6914 259.852 28.6914C256.023 28.6914 253.04 31.6747 253.04 35.9011C253.04 40.1275 255.974 43.0114 259.852 43.0114C263.731 43.0114 266.615 40.0778 266.615 35.9011Z"
          fill="#131A22"
        />
        <path
          d="M144.387 33.1167C144.387 23.222 152.741 15.1172 162.785 15.1172C170.145 15.1172 175.764 19.095 178.748 24.9623L170.941 28.9401C169.548 25.8573 166.664 23.5203 162.835 23.5203C157.664 23.5203 153.885 27.7467 153.885 33.0173C153.885 38.2879 157.664 42.564 162.835 42.564C166.664 42.564 169.499 40.2271 170.891 37.1443L178.847 41.1718C175.864 47.1385 170.244 51.1163 162.785 51.1163C152.691 51.1163 144.387 43.0115 144.387 33.1167Z"
          fill="#131A22"
        />
        <path
          d="M87.8805 65.9984C105.833 65.9984 120.387 51.2241 120.387 32.9992C120.387 14.7742 105.833 0 87.8805 0L32.8959 0C14.9432 0 0.389648 14.7742 0.389648 32.9992C0.389648 51.2241 14.9432 65.9984 32.8959 65.9984H87.8805Z"
          fill="#8428FF"
        />
        <path
          d="M63.748 32.9994C63.748 19.8064 74.8605 9 88.222 9C98.0115 9 105.486 14.3037 109.455 22.1267L99.0698 27.4305C97.2177 23.3201 93.3812 20.2042 88.288 20.2042C81.4089 20.2042 76.3818 25.8394 76.3818 32.8668C76.3818 39.8943 81.4089 45.5958 88.288 45.5958C93.3812 45.5958 97.1515 42.4798 99.0036 38.3694L109.587 43.7395C105.618 51.6951 98.1438 56.9988 88.222 56.9988C74.7944 56.9988 63.748 46.1925 63.748 32.9994Z"
          fill="white"
        />
        <path
          d="M46.2285 32.9994C46.2285 46.1925 54.9042 56.9988 68.3332 56.9988H70.2279C62.3617 55.0364 54.7483 46.1925 54.7483 32.9994C54.7483 19.8064 62.3617 10.9624 70.2279 9H68.3332C54.9042 9 46.2285 19.8064 46.2285 32.9994Z"
          fill="white"
        />
        <path
          d="M28.709 32.9994C28.709 46.1925 37.3847 56.9988 50.8138 56.9988H52.7084C44.8421 55.0364 37.2288 46.1925 37.2288 32.9994C37.2288 19.8064 44.8421 10.9624 52.7084 9H50.8138C37.3847 9 28.709 19.8064 28.709 32.9994Z"
          fill="white"
          fillOpacity="0.66"
        />
        <path
          d="M11.1895 32.9994C11.1895 46.1925 19.8651 56.9988 33.2941 56.9988H35.1889C27.3226 55.0364 19.7092 46.1925 19.7092 32.9994C19.7092 19.8064 27.3226 10.9624 35.1889 9H33.2941C19.8651 9 11.1895 19.8064 11.1895 32.9994Z"
          fill="white"
          fillOpacity="0.33"
        />
      </svg>
    ),
  },
  SignInBg: {
    viewBox: '',
    element: (
      <svg
        preserveAspectRatio="xMinYMin slice"
        viewBox="0 0 864 1024"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1222_664)">
          <rect width="1747" height="1024" transform="translate(-480)" fill="white" />
          <rect
            width="1747"
            height="1024"
            transform="translate(-480)"
            fill="url(#paint0_radial_1222_664)"
            fillOpacity="0.3"
          />
          <rect
            width="1747"
            height="1024"
            transform="translate(-480)"
            fill="url(#paint1_radial_1222_664)"
            fillOpacity="0.3"
          />
          <rect
            width="1747"
            height="1024"
            transform="translate(-480)"
            fill="url(#paint2_radial_1222_664)"
            fillOpacity="0.3"
          />
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_1222_664"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(860.155 744.948) rotate(-32.0272) scale(729.809 729.828)"
          >
            <stop stopColor="#5CCDF1" />
            <stop offset="1" stopColor="#5CCDF1" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_1222_664"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1437.64 441.63) rotate(142.61) scale(576.426 576.435)"
          >
            <stop stopColor="#FFB746" />
            <stop offset="1" stopColor="#FFB746" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_1222_664"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(578.694 304.531) rotate(45.0018) scale(556.767 556.767)"
          >
            <stop stopColor="#F693D6" />
            <stop offset="1" stopColor="#F693D6" stopOpacity="0" />
          </radialGradient>
          <clipPath id="clip0_1222_664">
            <rect width="864" height="1024" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
};

type SvgIconName = keyof typeof ICONS;

const SvgIcon: React.FC<SvgIconProps<SvgIconName>> = ({name, ...rest}) => (
  <BaseSvgIcon icon={ICONS[name]} name={name} {...rest} />
);
export default SvgIcon;
