import Navbar from '@cohort/merchants/components/navbar/NavBar';
import TestModeBanner from '@cohort/merchants/components/test-mode/TestModeBanner';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import useCheckBrokenConnections from '@cohort/merchants/hooks/useCheckBrokenConnections';
import {useOrganizationSlug} from '@cohort/merchants/hooks/useOrganizationSlug';
import useSyncBackNavigation from '@cohort/merchants/hooks/useSyncBackNavigation';
import {CurrentMerchantProvider} from '@cohort/merchants/layouts/CurrentMerchantContext';
import {getSignInRoute} from '@cohort/merchants/lib/Pages';
import {getMerchantCustomStyleSheetUrl} from '@cohort/shared-frontend/utils/merchant';
import {Fragment, useEffect} from 'react';
import {Navigate, Outlet, useLocation, useMatch} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

let wasLoggedIn = false;

const AuthLayoutContent: React.FC = () => {
  const merchant = useCurrentMerchant();
  const testModeEnabled = useTestModeStore(store => store.testModeEnabled);

  useSyncBackNavigation();
  useCheckBrokenConnections();

  // Load the merchant's custom fonts for previews
  useEffect(() => {
    merchant.fonts.forEach(font => {
      const link = document.createElement('link');

      link.rel = 'stylesheet';
      link.href = font.src;
      document.head.appendChild(link);
    });
  }, [merchant.fonts]);

  // Load the merchant's custom stylesheet for previews
  useEffect(() => {
    const stylesheet = getMerchantCustomStyleSheetUrl(
      merchant.id,
      import.meta.env.COHORT_FIREBASE_STORAGE_BUCKET
    );
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = stylesheet;
    document.head.appendChild(link);
  }, [merchant.id]);

  return (
    <div
      key={`authLayout-${testModeEnabled ? 'test' : 'live'}`}
      className="relative grid h-screen max-h-screen overflow-hidden [grid-template-rows:max-content_min-content_1fr]"
      style={{
        gridTemplateAreas: `
        "header"
        "testModeBanner"
        "main"
      `,
      }}
    >
      <Navbar />
      <TestModeBanner />
      <div className="h-full overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

const AuthLayout: React.FC = () => {
  const location = useLocation();
  const organizationSlug = useOrganizationSlug();
  const {isLoggedIn, merchantId, toggleMerchantId} = useUserSessionStore(
    store => ({
      isLoggedIn: store.isLoggedIn,
      organizationMember: store.organizationMember,
      merchantId: store.merchantId,
      toggleMerchantId: store.toggleMerchantId,
    }),
    shallow
  );
  const {testModeEnabled, toggleTestMode} = useTestModeStore(
    store => ({
      testModeEnabled: store.testModeEnabled,
      toggleTestMode: store.toggleTestMode,
    }),
    shallow
  );
  const match = useMatch('/test/*');

  // Takes care of toggling test mode on and off
  useEffect(() => {
    if (isLoggedIn) {
      if (match && !testModeEnabled) {
        toggleTestMode(true);
        toggleMerchantId('test');
      } else if ((!match && testModeEnabled) || merchantId === undefined) {
        toggleTestMode(false);
        toggleMerchantId('live');
      }
    }
  }, [match, testModeEnabled, toggleTestMode, toggleMerchantId, isLoggedIn, merchantId]);

  if (organizationSlug === null) {
    return <Navigate to={getSignInRoute().path} replace />;
  }

  // If the user is logged in and the merchantId is undefined, we need to wait for the toggleMerchantId to finish
  if (isLoggedIn && merchantId === undefined) {
    return <Fragment />;
  }

  if (!isLoggedIn) {
    // If the user was logged in before, redirect to sign in with no destination
    if (wasLoggedIn) {
      return <Navigate to={getSignInRoute().path} replace />;
    }
    const destination = encodeURIComponent(`${location.pathname}${location.search}`);

    return <Navigate to={`${getSignInRoute().path}?destination=${destination}`} replace />;
  }

  wasLoggedIn = true;
  // Need to use a key to force a remount of the Outlet
  return (
    <CurrentMerchantProvider>
      <AuthLayoutContent />
    </CurrentMerchantProvider>
  );
};

export default AuthLayout;
