import {Badge} from '@cohort/merchants/components/Badge';
import type {ContentStatus} from '@cohort/shared/schema/common/content';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type ContentStatusBadgeProps = {
  status: ContentStatus;
  size?: 'large' | 'small';
};

export const ContentStatusBadge: React.FC<ContentStatusBadgeProps> = ({status, size}) => {
  const {t} = useTranslation('components', {keyPrefix: 'contents.contentStatusBadge'});
  const badgeData = match(status)
    .with('draft', () => ({
      text: t('statusDraft'),
      backgroundColor: 'bg-yellow-100',
      textColor: 'text-yellow-800',
      dotColor: 'text-yellow-400',
    }))
    .with('published', () => ({
      text: t('statusPublished'),
      backgroundColor: 'bg-green-100',
      textColor: 'text-green-800',
      dotColor: 'text-green-400',
    }))
    .exhaustive();

  return (
    <Badge
      text={badgeData.text}
      backgroundColor={badgeData.backgroundColor}
      textColor={badgeData.textColor}
      dotColor={badgeData.dotColor}
      size={size ?? 'large'}
    />
  );
};
