import type {PerkAnalyticsADto} from '@cohort/admin-schemas/perk';
import {CurrentPerkContext} from '@cohort/merchants/pages/perks/perk/CurrentPerkContext';
import {useContext} from 'react';

export const useCurrentPerk = (): PerkAnalyticsADto => {
  const context = useContext(CurrentPerkContext);

  if (context === null) {
    throw new Error('useCurrentPerk must be used within a CurrentPerkProvider');
  }
  return context;
};
