import type {TriggerIntegration} from '@cohort/merchants/apps';
import GarminCompleteActivityTriggerIntegrationConfigComponent from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/completeActivity/ConfigComponent';
import GarminConnectCompleteActivityTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/completeActivity/StepCompletionTitleComponent';
import {GarminConnectTriggerIntegrationAvailableActivityGroupsSchema} from '@cohort/shared/apps/garmin-connect/activity';
import type {
  GarminConnectCompleteActivityTriggerConfig,
  GarminConnectCompleteActivityTriggerStruct,
} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';
import {GarminConnectCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {ensureMetricsIsSetRefinment} from '@cohort/shared/schema/common/metrics';
import {
  toKilometers,
  toKilometersPerHour,
  toMeters,
  toMetersPerSecond,
  toMinutes,
  toSeconds,
} from '@cohort/shared/utils/convert';
import {z} from 'zod';

export const GarminCompleteActivityTriggerFormConfigSchema = z
  .object({
    activityGroups: z
      .array(GarminConnectTriggerIntegrationAvailableActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .optional(),
    activityEndDate: z.date({coerce: true}),
    activityStartDate: z.date({coerce: true}),
    hasActivityTypes: z.boolean().optional().default(false),
    hasDistance: z.boolean().optional().default(false),
    hasDuration: z.boolean().optional().default(false),
    hasNumberOfSteps: z.boolean().optional().default(false),
    hasAverageElevationGain: z.boolean().optional().default(false),
    hasAverageSpeed: z.boolean().optional().default(false),
    minAverageElevationGainMeters: z.number().min(0).optional().nullable(),
    minAverageSpeedKilometersPerHour: z.number().min(0).optional().nullable(),
    minDistanceKilometers: z.number().min(0).optional().nullable(),
    minDurationMinutes: z.number().min(0).optional().nullable(),
  })
  .transform(data => ({
    ...data,
    minAverageElevationGainMeters: data.hasAverageElevationGain
      ? data.minAverageElevationGainMeters
      : null,
    minAverageSpeedKilometersPerHour: data.hasAverageSpeed
      ? data.minAverageSpeedKilometersPerHour
      : null,
    minDistanceKilometers: data.hasDistance ? data.minDistanceKilometers : null,
    minDurationMinutes: data.hasDuration ? data.minDurationMinutes : null,
  }))
  .superRefine(ensureMetricsIsSetRefinment)
  .superRefine(
    startDateBeforeEndDateRefinement({
      endDatePropertyName: 'activityEndDate',
      startDatePropertyName: 'activityStartDate',
    })
  );

export type GarminCompleteActivityTriggerFormConfig = z.infer<
  typeof GarminCompleteActivityTriggerFormConfigSchema
>;

function fromForm(
  formValues: GarminCompleteActivityTriggerFormConfig
): GarminConnectCompleteActivityTriggerConfig {
  return {
    activityEndDateTimestamp: new Date(formValues.activityEndDate).getTime(),
    activityStartDateTimestamp: new Date(formValues.activityStartDate).getTime(),
    activityGroups: formValues.activityGroups,
    minAverageElevationGainMeters: formValues.minAverageElevationGainMeters ?? null,
    minAverageSpeedMetersPerSecond: formValues.minAverageSpeedKilometersPerHour
      ? toMetersPerSecond(formValues.minAverageSpeedKilometersPerHour)
      : null,
    minDistanceMeters: formValues.minDistanceKilometers
      ? toMeters(formValues.minDistanceKilometers)
      : null,
    minDurationSeconds: formValues.minDurationMinutes
      ? toSeconds(formValues.minDurationMinutes)
      : null,
  };
}

function toForm(
  config: GarminConnectCompleteActivityTriggerConfig
): GarminCompleteActivityTriggerFormConfig {
  return {
    activityEndDate: new Date(config.activityEndDateTimestamp),
    activityStartDate: new Date(config.activityStartDateTimestamp),
    activityGroups: config.activityGroups,
    minAverageElevationGainMeters: config.minAverageElevationGainMeters,
    minAverageSpeedKilometersPerHour: config.minAverageSpeedMetersPerSecond
      ? toKilometersPerHour(config.minAverageSpeedMetersPerSecond)
      : null,
    minDistanceKilometers: config.minDistanceMeters ? toKilometers(config.minDistanceMeters) : null,
    minDurationMinutes: config.minDurationSeconds ? toMinutes(config.minDurationSeconds) : null,
    hasDistance: config.minDistanceMeters !== null,
    hasDuration: config.minDurationSeconds !== null,
    hasAverageElevationGain: config.minAverageElevationGainMeters !== null,
    hasAverageSpeed: config.minAverageSpeedMetersPerSecond !== null,
    hasActivityTypes: !!config.activityGroups,
    hasNumberOfSteps: false,
  };
}

export const GarminConnectCompleteActivityTriggerIntegration: TriggerIntegration<GarminConnectCompleteActivityTriggerStruct> =
  {
    spec: GarminConnectCompleteActivityTriggerIntegrationSpec,
    configComponent: GarminCompleteActivityTriggerIntegrationConfigComponent,
    configForm: {
      schema: GarminCompleteActivityTriggerFormConfigSchema,
      toForm,
      fromForm,
    },
    stepCompletionTitleComponent:
      GarminConnectCompleteActivityTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.complete-activity.title', {ns: 'app-garmin-connect'}),
  };
