import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import {typeformAppKeys} from '@cohort/merchants/apps/typeform/api';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import type {ListVideosActionStruct} from '@cohort/shared/apps/youtube/actions/listVideos';
import type {QueryOptions, UseQueryResult} from '@tanstack/react-query';

export const youtubeAppKeys = {
  youtubeApp: ['youtubeApp'] as const,
  listVideos: (merchantId: string, merchantConnectionId: string) =>
    [...typeformAppKeys.typeformApp, merchantId, merchantConnectionId, 'list-videos'] as const,
};

export const useYoutubeVideos = (
  merchantId: string,
  merchantConnectionId: string,
  search: string | undefined,
  options?: QueryOptions
): UseQueryResult<ExecuteActionResponseADto<ListVideosActionStruct>, unknown> => {
  return useCohortQuery({
    queryKey: youtubeAppKeys.listVideos(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<ListVideosActionStruct>(merchantId, {
        appId: 'youtube',
        actionId: 'list-videos',
        merchantConnectionId,
        input: {search},
      });
    },
    ...options,
  });
};
