import type {MediaCardProps} from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import TriggerMediaCardWrapper from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {ChatCircleDots, FacebookLogo, ThumbsUp} from '@phosphor-icons/react';
import dayjs from 'dayjs';

type FacebookPostCardProps = MediaCardProps<FacebookPost>;

const FacebookPostCard: React.FC<FacebookPostCardProps> = ({
  media: post,
  isSelected = false,
  onClick,
}) => (
  <TriggerMediaCardWrapper hasMedia={post !== undefined} onClick={onClick} isSelected={isSelected}>
    {!post ? (
      <div className="flex h-[120px] w-[120px] items-center justify-center">
        <FacebookLogo size={24} weight="fill" className="text-slate-200" />
      </div>
    ) : (
      <div className="flex gap-4">
        <div
          className={cn(
            'flex aspect-square h-[136px] w-[136px] flex-shrink-0 items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-slate-50 shadow-sm',
            isSelected && 'opacity-50'
          )}
        >
          {post.thumbnailUrl !== undefined ? (
            <img src={post.thumbnailUrl} alt="Post thumbnail" />
          ) : (
            <FacebookLogo size={40} weight="fill" className="text-slate-200" />
          )}
        </div>
        <div className="flex flex-grow flex-col justify-between gap-3">
          <div className="line-clamp-5 whitespace-pre-wrap text-sm font-normal">
            {post.message !== undefined ? post.message : ''}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex-grow text-xs font-medium text-slate-500">
              {dayjs(post.createdTime).format('ll')}
            </div>
            {post.likeCount !== undefined && (
              <div className="flex items-center gap-1 text-xs font-medium text-slate-500">
                <ThumbsUp size={16} />
                {post.likeCount}
              </div>
            )}
            {post.commentCount !== undefined && (
              <div className="flex items-center gap-1 text-xs font-medium text-slate-500">
                <ChatCircleDots size={16} />
                {post.commentCount}
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  </TriggerMediaCardWrapper>
);

export default FacebookPostCard;
