/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {GetContentsParamsADto} from '@cohort/admin-schemas/content';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getContent, getContents} from '@cohort/merchants/lib/api/Contents';

export const contentsKeys = {
  contents: ['contents'] as const,
  list: (merchantId: string, params: GetContentsParamsADto) =>
    [...contentsKeys.contents, merchantId, 'list', params] as const,
  getById: (merchantId: string, contentId: string) =>
    [...contentsKeys.contents, merchantId, contentId] as const,
};

export const useContents = (
  merchantId: string,
  params: GetContentsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: contentsKeys.list(merchantId, params),
    queryFn: async () => getContents(merchantId, params),
    ...options,
  });

export const useContent = (merchantId: string, contentId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: contentsKeys.getById(merchantId, contentId),
    queryFn: async () => getContent(merchantId, contentId),
    ...options,
  });
