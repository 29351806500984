import {create} from 'zustand';

export interface ContentPageStore {
  contentPreview?: React.ReactNode;
  footer?: React.ReactNode;
  menu?: React.ReactNode;
  setFooter: (footer: React.ReactNode) => void;
  setContentPreview: (contentPreview: React.ReactNode) => void;
  setMenu: (menu: React.ReactNode) => void;
}

export const useContentPageStore = create<ContentPageStore>(set => ({
  contentPreview: undefined,
  footer: undefined,
  menu: undefined,
  setFooter: footer => set({footer}),
  setContentPreview: contentPreview => set({contentPreview}),
  setMenu: menu => set({menu}),
}));
