import {
  FileInput,
  FileInputImageCropper,
  FileInputUploader,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {useTranslation} from 'react-i18next';

const CohortImageMediaConfigComponent: React.FC = () => {
  const {control, register} = useCohortForm<ContentSettingsStepValues>();

  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'medias.image.configComponent',
  });

  return (
    <FileInput
      acceptHint={t('instructionFileInput')}
      assetKind="contentImage"
      name="media.config.imageFileKey"
      control={control}
      register={register}
    >
      <FileInputUploader />
      <FileInputImageCropper />
    </FileInput>
  );
};

export default CohortImageMediaConfigComponent;
