import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useCurrentCampaign} from '@cohort/merchants/hooks/contexts/currentCampaign';
import {useCampaignPageStore} from '@cohort/merchants/hooks/stores/campaignPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import PageOutlet from '@cohort/merchants/layouts/PageOutlet';
import CampaignPageBreadcrumb from '@cohort/merchants/pages/campaigns/campaign/CampaignPageBreadcrumb';
import CampaignPageMenu from '@cohort/merchants/pages/campaigns/campaign/CampaignPageMenu';
import {CurrentCampaignProvider} from '@cohort/merchants/pages/campaigns/campaign/CurrentCampaignContext';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

const CampaignPageSkeleton: React.FC = () => {
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.campaign.campaignPageLayout'});
  const campaign = useCurrentCampaign();
  const campaignPreview = useCampaignPageStore(store => store.campaignPreview);
  const location = useLocation();

  if (
    campaign.status === 'draft' &&
    (location.pathname.includes('activity') ||
      location.pathname.includes('audience') ||
      location.pathname.includes('settings'))
  ) {
    return <NotFoundSection />;
  }

  if (
    campaign.status !== 'draft' &&
    location.pathname.includes('edit') &&
    !location.pathname.includes('audience/edit')
  ) {
    return <NotFoundSection />;
  }

  return (
    <PageLayout
      title={campaign.internalName || t('title')}
      breadcrumb={<CampaignPageBreadcrumb />}
      menu={<CampaignPageMenu />}
      rightAside={campaignPreview}
    >
      <PageOutlet />
    </PageLayout>
  );
};

const CampaignPageLayout = (): JSX.Element => (
  <CurrentCampaignProvider>
    <CampaignPageSkeleton />
  </CurrentCampaignProvider>
);

export default CampaignPageLayout;
