import type {NotificationConfigurationType} from '@cohort/merchants/apps';
import type {NotificationIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import JsonEditor from '@cohort/merchants/components/editor/JsonEditor';
import LiquidEditorInput from '@cohort/merchants/components/editor/LiquidEditorInput';
import DynamicKeyValueInput from '@cohort/merchants/components/form/DynamicKeyValueInput';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import type {ResourceType} from '@cohort/shared/apps';
import {CohortCustomRequestNotificationHttpMethodSchema} from '@cohort/shared/apps/cohort/notifications/customRequest';
import liquid from '@cohort/shared/lib/liquid';
import {UserPSchema} from '@cohort/shared/schema/public/users';
import {generateMockObjectFromSchema} from '@cohort/shared/utils/zod';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@cohort/shared-frontend/components/Accordion';
import {Editor} from '@monaco-editor/react';
import {Fragment, useRef, useState} from 'react';
import {useController, useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import type {JSONEditor} from 'vanilla-jsoneditor';
import {ValidationSeverity} from 'vanilla-jsoneditor';
import {z} from 'zod';

const HTTP_METHODS_WITH_BODY = ['POST', 'PUT', 'PATCH'];

function formatEventResources(resources: Record<ResourceType, z.ZodSchema>): z.AnyZodObject {
  const formattedResources: Record<string, z.ZodSchema> = {};

  for (const [resourceType, resource] of Object.entries(resources)) {
    const [, resourceName] = resourceType.split('.');

    if (resourceName) {
      formattedResources[resourceName] = resource;
    }
  }

  return z.object(formattedResources);
}

const CohortCustomRequestNotificationIntegrationConfigComponent: React.FC<
  NotificationIntegrationConfigComponentProps
> = ({event}) => {
  const {control, register, setValue, setError, clearErrors} =
    useFormContext<NotificationConfigurationType>();
  const jsonEditorRef = useRef<JSONEditor>();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'notificationIntegrations.custom-request.configComponent',
  });
  const [preview, setPreview] = useState<string>();

  // Setup Liquid context
  const resources = formatEventResources(event.resources);
  const liquidContextSchema = z.object({
    user: UserPSchema,
    event: z.object({
      properties: event.properties,
      resources,
    }),
  });
  type LiquidContext = z.infer<typeof liquidContextSchema>;
  const [liquidContext, setLiquidContext] = useState<LiquidContext>(
    generateMockObjectFromSchema<LiquidContext>(liquidContextSchema)
  );

  const body = useWatch({
    control,
    name: 'notificationIntegrationConfig.body',
  });
  const {field: httpMethod} = useController({
    control,
    name: 'notificationIntegrationConfig.httpMethod',
  });
  const httpMethodsOptions: Array<SelectOption> =
    CohortCustomRequestNotificationHttpMethodSchema.options.map(option => ({
      value: option,
      label: option,
    }));

  const handleHttpMethodChange = (option: SelectOption | null): void => {
    const value = option?.value;
    if (!value) {
      return;
    }

    if (!HTTP_METHODS_WITH_BODY.includes(option.value as string)) {
      setValue('notificationIntegrationConfig.body', null);
    }
  };

  return (
    <div className="space-y-6">
      <div className="w-2/5">
        <SelectInput
          name="notificationIntegrationConfig.httpMethod"
          label={t('labelHttpMethod')}
          register={register}
          control={control}
          options={httpMethodsOptions}
          onChange={e => handleHttpMethodChange(e)}
        />
      </div>
      <UrlInput
        name="notificationIntegrationConfig.url"
        label={t('labelUrl')}
        register={register}
        control={control}
      />
      {HTTP_METHODS_WITH_BODY.includes(httpMethod.value) && (
        <Fragment>
          <LiquidEditorInput
            height={200}
            name="notificationIntegrationConfig.body"
            placeholder={t('placeholderBody')}
            label={t('labelBody')}
            control={control}
            register={register}
            onValidate={markers => {
              if (markers.length === 0) {
                return clearErrors('notificationIntegrationConfig.body');
              }
              const error = markers[0];

              setError('notificationIntegrationConfig.body', {message: error?.message});
            }}
            liquidConfig={{
              schema: liquidContextSchema,
              context: liquidContext,
            }}
            onRenderedChange={setPreview}
          />
          <Accordion
            type="single"
            collapsible
            onValueChange={val => {
              if (val !== 'test-payload') {
                jsonEditorRef.current?.destroy();
                jsonEditorRef.current = undefined;
              }
            }}
          >
            <AccordionItem value="test-payload">
              <AccordionTrigger>{t('previewTitle')}</AccordionTrigger>
              <AccordionContent>
                <p className="mb-2 text-sm text-gray-500">{t('previewHint')}</p>
                <div className="grid grid-cols-2 gap-4">
                  <JsonEditor
                    value={liquidContext}
                    ref={jsonEditorRef}
                    validator={json => {
                      const newLiquidContext = json as LiquidContext;
                      setLiquidContext(newLiquidContext);
                      const template = liquid.parseAndRenderSync(body, newLiquidContext);

                      setPreview(template);
                      const res = liquidContextSchema.safeParse(json);

                      if (!res.success) {
                        return res.error.errors.map(error => ({
                          path: error.path as Array<string>,
                          message: error.message,
                          severity: ValidationSeverity.error,
                        }));
                      }
                      return [];
                    }}
                  />
                  <div>
                    <Editor
                      height={300}
                      theme="vs-dark"
                      defaultLanguage="json"
                      value={preview}
                      options={{
                        readOnly: true,
                        minimap: {
                          enabled: false,
                        },
                        scrollbar: {
                          vertical: 'hidden',
                          horizontal: 'hidden',
                        },
                      }}
                    />
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </Fragment>
      )}
      <div>
        <DynamicKeyValueInput
          name="notificationIntegrationConfig.headers"
          control={control}
          register={register}
          labels={{
            title: t('headersTitle'),
            addBtn: t('addAnotherItem'),
            key: t('headersKey'),
            value: t('headersValue'),
          }}
        />
      </div>
    </div>
  );
};

export default CohortCustomRequestNotificationIntegrationConfigComponent;
