import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortXpsVisitedPropertiesSchema = z.object({
  sessionId: z.string(),
});
export type CohortXpsVisitedProperties = z.infer<typeof CohortXpsVisitedPropertiesSchema>;

export type CohortXpsVisitedEventStruct = UserEventStruct<
  'cohort.user.xps-visited',
  keyof CohortXpsVisitedProperties,
  CohortXpsVisitedProperties,
  CohortXpsVisitedProperties
>;

export const CohortXpsVisitedEventSpec: UserEventSpec<CohortXpsVisitedEventStruct> = {
  id: 'cohort.user.xps-visited',
  name: 'Visited their Experience Space',
  description: 'Event Triggerred when a user visits their Experience Space',
  propertiesSchema: CohortXpsVisitedPropertiesSchema,
  exposedPropertiesSchema: CohortXpsVisitedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: [],
  },
};
