import DeletedBadge from '@cohort/merchants/components/badges/DeletedBadge';
import {
  Breadcrumb,
  BreadcrumbContent,
  BreadcrumbLink,
  BreadcrumbTitle,
} from '@cohort/merchants/components/Breadcrumb';
import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import {getUsersRoute} from '@cohort/merchants/lib/Pages';
import {useTranslation} from 'react-i18next';

const UserPageBreadcrumb = (): JSX.Element => {
  const user = useCurrentUser();
  const {t} = useTranslation('pages', {keyPrefix: 'users.user.userPageBreadcrumb'});

  return (
    <Breadcrumb>
      <BreadcrumbContent>
        <BreadcrumbLink name={t('name')} path={getUsersRoute().path} />
        <BreadcrumbTitle className="flex items-center space-x-4">
          <span>{user.email}</span>
          {user.isDeleted && <DeletedBadge />}
        </BreadcrumbTitle>
      </BreadcrumbContent>
    </Breadcrumb>
  );
};

export default UserPageBreadcrumb;
