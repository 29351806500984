import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

const DiscordPrivateAccessConfigSchema = z.object({});
export type DiscordPrivateAccessConfig = z.infer<typeof DiscordPrivateAccessConfigSchema>;

const DiscordPrivateAccessStateSchema = z.object({
  roleId: z.string(),
});
export type DiscordPrivateAccessState = z.infer<typeof DiscordPrivateAccessStateSchema>;

const DiscordPrivateAccessAccessDataSchema = z.object({});
export type DiscordPrivateAccessAccessData = z.infer<typeof DiscordPrivateAccessAccessDataSchema>;

const DiscordPrivateAccessUsageInputSchema = z.object({});
export type DiscordPrivateAccessUsageInput = z.infer<typeof DiscordPrivateAccessUsageInputSchema>;

const DiscordPrivateAccessUsageDataSchema = z.object({
  discordUserId: z.string(),
  discordUserName: z.string(),
  discordDiscriminator: z.string(),
  guildName: z.string(),
  url: z.string().url(),
});
export type DiscordPrivateAccessUsageData = z.infer<typeof DiscordPrivateAccessUsageDataSchema>;

const DiscordPrivateAccessExposedUsageDataSchema = z.object({
  discordUserId: z.string(),
  discordUsername: z.string(),
  discordDiscriminator: z.string(),
});
export type DiscordPrivateAccessExposedUsageData = z.infer<
  typeof DiscordPrivateAccessExposedUsageDataSchema
>;

export type DiscordPrivateAccessPerkIntegrationStruct = PerkIntegrationStruct<
  'discord.private-access',
  DiscordPrivateAccessConfig,
  DiscordPrivateAccessState,
  DiscordPrivateAccessAccessData,
  DiscordPrivateAccessUsageInput,
  DiscordPrivateAccessUsageData,
  DiscordPrivateAccessExposedUsageData
>;

export const DiscordPrivateAccessPerkIntegrationSpec: PerkIntegrationSpec<DiscordPrivateAccessPerkIntegrationStruct> =
  {
    id: 'discord.private-access' as const,
    configSchema: DiscordPrivateAccessConfigSchema,
    stateSchema: DiscordPrivateAccessStateSchema,
    accessDataSchema: DiscordPrivateAccessAccessDataSchema,
    usageInputDataSchema: DiscordPrivateAccessUsageInputSchema,
    internalUsageDataSchema: DiscordPrivateAccessUsageDataSchema,
    exposedUsageDataSchema: DiscordPrivateAccessExposedUsageDataSchema,
    defaultMaxUsagesPerToken: null,
    requiresMerchantConnection: true,
  };
