import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import type {CohortQrCodeUsageData} from '@cohort/shared/apps/cohort/perks/qrCode';
import {useTranslation} from 'react-i18next';

const CohortQrCodePerkIntegrationsUsageViewComponent: React.FC<PerkUsageViewComponentProps> = ({
  usage,
}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'perkIntegrations.qr-code.usageViewComponent',
  });
  const data = usage.data as CohortQrCodeUsageData;
  return (
    <PerkUsageLayout.Grid>
      <PerkUsageLayout.Row name={t('scannedBy')} value={data.qrReaderName} />
    </PerkUsageLayout.Grid>
  );
};

export default CohortQrCodePerkIntegrationsUsageViewComponent;
