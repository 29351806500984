import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import type {
  MappedUserProperty,
  SalesforceUserObject,
  SalesforceUserObjectField,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import {
  getDataTypeFromSalesforceFieldType,
  getSalesforceObjectByName,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

interface ExportUsersFieldSelectProps {
  index: number;
  salesforceUserObjects: SalesforceUserObject[];
  userProperties: UserPropertyADto[] | undefined;
  mappedUserProperty: MappedUserProperty;
}

const ExportUsersFieldSelect: React.FC<ExportUsersFieldSelectProps> = ({
  index,
  salesforceUserObjects,
  mappedUserProperty,
}) => {
  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.exportUsersFieldSelect',
  });

  const {control, watch} = useCohortForm<Extract<SyncConfigFormValues, {appId: 'salesforce'}>>();

  const isExportEnabled = watch('userExportEnabled');
  const salesforceObjectName = watch('userSyncConfig.salesforceObjectName');
  const mappedUserProperties = watch('userExportConfig.userProperties');

  const selectedObject = getSalesforceObjectByName(salesforceUserObjects, salesforceObjectName);

  const {replace: replaceUserProperties} = useFieldArray({
    control,
    name: 'userExportConfig.userProperties',
  });

  const selectedSalesforceFieldNames = mappedUserProperties.map(
    mappedUserProperty => mappedUserProperty.salesforceFieldName
  );
  const salesforceFieldOptions =
    selectedObject !== undefined
      ? selectedObject.fields
          .filter(
            field =>
              getDataTypeFromSalesforceFieldType(field.type) ===
                mappedUserProperty.userProperty?.dataType && field.updateable
          )
          .map(field => ({
            label: field.label,
            value: field.name,
            isDisabled: selectedSalesforceFieldNames.includes(field.name),
          }))
      : [];
  const salesforceFieldValue =
    mappedUserProperty.salesforceField !== null
      ? {
          value: mappedUserProperty.salesforceField.name,
          label: mappedUserProperty.salesforceField.label,
        }
      : null;

  const handleChange = (index: number, field: SalesforceUserObjectField | null): void => {
    const updatedMappedUserProperties = [...mappedUserProperties];

    if (updatedMappedUserProperties[index] !== undefined && field !== null) {
      updatedMappedUserProperties[index] = {
        ...updatedMappedUserProperties[index],
        salesforceFieldName: field.name,
      };
      replaceUserProperties(updatedMappedUserProperties);
    }
  };

  return (
    <SelectPicker
      name={`field-${index}`}
      placeholder={t('placeholder')}
      formatOptionLabel={data => {
        const fieldType = selectedObject?.fields.find(field => field.name === data.value)?.type;
        return (
          <div className="flex items-center gap-2">
            {fieldType && <DataTypeIcon dataType={getDataTypeFromSalesforceFieldType(fieldType)} />}
            <span>{data.label}</span>
          </div>
        );
      }}
      options={salesforceFieldOptions}
      value={salesforceFieldValue}
      isDisabled={
        !isExportEnabled || selectedObject === undefined || mappedUserProperty.userProperty === null
      }
      onChange={option => {
        handleChange(
          index,
          selectedObject?.fields.find(field => field.name === option?.value) ?? null
        );
      }}
    />
  );
};

export default ExportUsersFieldSelect;
