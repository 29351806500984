import Button from '@cohort/merchants/components/buttons/Button';
import {
  EmptyState,
  EmptyStateContainer,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@cohort/merchants/components/EmptyState';
import {XCircle} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const ErrorState: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'errorState'});

  return (
    <EmptyStateContainer>
      <EmptyState>
        <XCircle size={40} className="text-slate-400" />
        <EmptyStateTitle>{t('title')}</EmptyStateTitle>
        <EmptyStateDescription>{t('description')}</EmptyStateDescription>
        <Button onClick={() => window.location.reload()}>{t('btnRefresh')}</Button>
      </EmptyState>
    </EmptyStateContainer>
  );
};

export default ErrorState;
