import type {SpotifyResourceType} from '@cohort/shared-frontend/common/apps/spotify';
import Spinner from '@cohort/shared-frontend/components/Spinner';
import {useSyncExternalStore} from 'react';
import React, {useCallback, useEffect, useRef, useState} from 'react';

type EmbedController = {
  loadUri: (spotifyUri: string, preferVideo?: boolean, startAt?: number) => void;
  play: () => void;
  pause: () => void;
  resume: () => void;
  togglePlay: () => void;
  restart: () => void;
  seek: (seconds: number) => void;
  destroy: () => void;
  addListener: (
    event: 'ready' | 'playback_update',
    callback: (eventData: PlaybackUpdateEvent) => void
  ) => void;
};

type IFrameAPI = {
  createController: (
    element: HTMLElement,
    options: {uri: string; width: string; height: string},
    callback: (controller: EmbedController) => void
  ) => void;
};

type PlaybackUpdateEvent = {
  data: {
    isPaused: boolean;
    position: number;
    duration: number;
  };
};

// Spotify native player heights in pixels.
const SPOTIFY_IFRAME_HEIGHTS = {
  sm: '152',
  lg: '352',
};

declare global {
  interface Window {
    onSpotifyIframeApiReady: ((iframeApi: IFrameAPI) => void) | null;
    Spotify?: {
      Embed?: {
        API?: IFrameAPI;
      };
    };
  }
}

type SpotifyPlayerProps = {
  onError: () => void;
  resourceType: SpotifyResourceType;
  resourceId: string;
  size?: keyof typeof SPOTIFY_IFRAME_HEIGHTS;
};

// Global variables to manage API readiness
let spotifyApiReadyPromise: Promise<void> | null = null;
let isSpotifyApiReady = false;
let listeners: Array<() => void> = [];

// Ensures Spotify API is loaded and ready
const ensureSpotifyApiReady = (): Promise<void> => {
  if (!spotifyApiReadyPromise) {
    spotifyApiReadyPromise = new Promise(resolve => {
      window.onSpotifyIframeApiReady = (iframeApi: IFrameAPI) => {
        window.Spotify = window.Spotify || {};
        window.Spotify.Embed = window.Spotify.Embed || {};
        window.Spotify.Embed.API = iframeApi;
        isSpotifyApiReady = true;
        listeners.forEach(listener => listener()); // Notify all listeners
        resolve();
      };
    });
  }
  return spotifyApiReadyPromise;
};

// Subscribe function for useSyncExternalStore
const subscribe = (callback: () => void): (() => void) => {
  listeners.push(callback);
  return () => {
    listeners = listeners.filter(listener => listener !== callback);
  };
};

// Snapshot functions for useSyncExternalStore
const getSnapshot = (): boolean => isSpotifyApiReady;
const getServerSnapshot = (): boolean => false;

const SpotifyPlayer: React.FC<SpotifyPlayerProps> = ({
  resourceType,
  resourceId,
  onError,
  size = 'sm',
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const controllerRef = useRef<EmbedController | null>(null);
  const isApiReady = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);

  useEffect(() => {
    ensureSpotifyApiReady().catch(error => {
      // eslint-disable-next-line no-console
      console.error('Error waiting for Spotify API readiness', error);
    });

    return () => {
      controllerRef.current?.destroy();
    };
  }, []);

  const renderSpotifyPlayer = useCallback(
    (node: HTMLDivElement | null) => {
      if (node && isApiReady) {
        const options = {
          width: '100%',
          height: SPOTIFY_IFRAME_HEIGHTS[size],
          uri: `spotify:${resourceType}:${resourceId}`,
        };

        try {
          window.Spotify?.Embed?.API?.createController(node, options, (ctrl: EmbedController) => {
            ctrl.addListener('ready', () => {
              controllerRef.current = ctrl;
              setIsLoading(false); // Mark loading as complete once ready
            });

            // @Devs - Uncomment this to log playback updates.
            // const handlePlaybackUpdate = (e: PlaybackUpdateEvent): void => {
            //   const {isPaused, position, duration} = e.data;
            //   console.log(
            //     `Playback - Is Paused: ${isPaused}, Position: ${position} ms, Duration: ${duration} ms`
            //   );
            // };
            //
            // ctrl.addListener('playback_update', handlePlaybackUpdate);
          });
        } catch {
          onError();
        }
      }
    },
    [isApiReady, size, resourceType, resourceId, onError]
  );

  useEffect(() => {
    if (controllerRef.current) {
      controllerRef.current.loadUri(`spotify:${resourceType}:${resourceId}`);
    }
  }, [resourceType, resourceId]);

  return (
    <div className="relative">
      <div ref={renderSpotifyPlayer} className="relative z-10" />
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner size={24} color="gray" />
        </div>
      )}
    </div>
  );
};

export default SpotifyPlayer;
