import {EventIdSchema} from '@cohort/shared/apps';
import {asArray, DateSchema, DatetimeSchema} from '@cohort/shared/schema/common';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {UserEventsOrderBySchema} from '@cohort/shared/schema/common/userEvent';
import {z} from 'zod';

export const UserEventASchema = z.object({
  id: z.string().uuid(),
  eventId: EventIdSchema,
  properties: z.unknown(),
  createdAt: DatetimeSchema,
  campaignInternalName: z.string().nullable(),
  badgeInternalName: z.string().nullable(),
  perkInternalName: z.string().nullable(),
  digitalAssetCollectionInternalName: z.string().nullable(),
  cohortName: z.string().nullable(),
  isResourceDeleted: z.boolean(),
  eventDate: DatetimeSchema,
});
export type UserEventADto = z.infer<typeof UserEventASchema>;

export const ListUserEventsParamsASchema = PaginationParamsSchema.extend({
  userId: z.string().uuid(),
  eventIds: z.preprocess(asArray, z.array(EventIdSchema).optional()),
  search: z.string().optional(),
  orderBy: UserEventsOrderBySchema.default('eventDate'),
});
export type ListUserEventsParamsADto = z.infer<typeof ListUserEventsParamsASchema>;

export const ExportUserEventsParamsASchema = z
  .object({
    from: DateSchema.optional(),
    to: DateSchema.optional(),
    eventKinds: z.preprocess(asArray, z.array(EventIdSchema)).optional(),
  })
  .superRefine(({from, to}, ctx) => {
    if ((from !== undefined && to === undefined) || (from === undefined && to !== undefined)) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorValue',
        path: ['from', 'to'],
      });
    }
  })
  .transform(data => {
    const dateRange =
      data.from !== undefined && data.to !== undefined ? {from: data.from, to: data.to} : undefined;
    return {
      dateRange,
      eventKinds: data.eventKinds,
    };
  });
export type ExportUserEventsParamsADto = z.infer<typeof ExportUserEventsParamsASchema>;
export type ExportUserEventsParamsInput = z.input<typeof ExportUserEventsParamsASchema>;
