import {
  getBasePath,
  parseAndReportLiquidTemplate,
  validateTemplate,
} from '@cohort/merchants/components/editor/utils';
import liquid from '@cohort/shared/lib/liquid';
import {getPropertiesPathFromZodSchema} from '@cohort/shared/utils/zod';
import type {Monaco} from '@monaco-editor/react';
import {Editor} from '@monaco-editor/react';
import type {IDisposable} from 'monaco-editor';
import type {editor} from 'monaco-editor';
import {useEffect, useRef} from 'react';
import type {z} from 'zod';

type OnChangePayload = {
  template: string;
  parsedTemplate: string;
};

export type LiquidEditorProps = {
  height: number;
  defaultValue?: string;
  liquidConfig?: {
    schema: z.ZodSchema;
    context: Record<string, unknown>;
  };
  placeholder?: string;
  suggestionsSchema?: z.ZodSchema | false;
  onValidate?: (isValid: editor.IMarker[]) => void;
  onError?: (error: unknown) => void;
  onChange: (onChangePayload: OnChangePayload) => void;
};

const LiquidEditor: React.FC<LiquidEditorProps> = ({
  height,
  defaultValue,
  liquidConfig,
  placeholder,
  suggestionsSchema,
  onValidate,
  onError,
  onChange,
}) => {
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const monacoRef = useRef<Monaco>();
  const providerRef = useRef<IDisposable>();

  function handleEditorDidMount(editor: editor.IStandaloneCodeEditor, monaco: Monaco): void {
    editorRef.current = editor;
    monacoRef.current = monaco;

    if (providerRef.current) {
      providerRef.current.dispose();
    }

    if (!liquidConfig || suggestionsSchema === false) {
      return;
    }
    providerRef.current = monaco.languages.registerCompletionItemProvider('liquid', {
      provideCompletionItems: (model, position) => {
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        };
        const lineContent = model
          .getLineContent(position.lineNumber)
          .substring(0, position.column - 1);
        const basePath = getBasePath(lineContent);
        // Suggestions are shared between all editor instances for a same language.
        // Since for context we have the same default schema, shared properties are duplicated in suggestions.
        // To avoid this, instead of taking the schema from the liquidConfig, we take it from the suggestionsSchema
        // that will only define the properties that are specific to the current editor instance.
        // It's a bit hacky but I couldn't find a better way to do it.
        const suggestions = getPropertiesPathFromZodSchema(suggestionsSchema ?? liquidConfig.schema)
          .filter(label => label.startsWith(basePath))
          .map(label => {
            const suggestionLabel = basePath ? label.replace(`${basePath}.`, '') : label;

            return {
              label: suggestionLabel,
              kind: monaco.languages.CompletionItemKind.Property,
              insertText: suggestionLabel,
              range,
            };
          });

        return {suggestions};
      },
    });
  }

  useEffect(() => {
    return () => {
      providerRef.current?.dispose();
    };
  }, []);

  return (
    <Editor
      theme="vs-dark"
      defaultLanguage="liquid"
      defaultValue={!defaultValue || defaultValue === '' ? placeholder : defaultValue}
      height={height}
      onValidate={markers => onValidate?.(markers)}
      onChange={tpl => {
        if (tpl) {
          try {
            const model = editorRef.current?.getModel();

            if (!model) {
              return;
            }
            const errors = liquidConfig?.schema ? validateTemplate(model, liquidConfig.schema) : [];
            const result = parseAndReportLiquidTemplate(liquid, tpl, liquidConfig?.context);

            if (result.success) {
              onChange({
                template: tpl,
                parsedTemplate: result.template,
              });
            }
            monacoRef.current?.editor.setModelMarkers(model, 'liquid', [
              ...errors,
              ...(result.success ? [] : [result.report]),
            ]);
          } catch (e) {
            onError?.(e);
          }
        }
      }}
      onMount={handleEditorDidMount}
      options={{
        minimap: {
          enabled: false,
        },
        wordWrap: 'on',
        wordWrapColumn: 120,
        scrollbar: {
          vertical: 'hidden',
        },
      }}
    />
  );
};

export default LiquidEditor;
