import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import useTranslateLanguage from '@cohort/merchants/hooks/useTranslateLanguage';
import type {Language} from '@cohort/shared/schema/common';
import {LANGUAGE_FLAGS} from '@cohort/shared/schema/common';
import {useTranslation} from 'react-i18next';

type Props = {
  defaultValue: string | null | undefined;
  language: Language;
};
export const ValueNotTranslatedLabel = ({defaultValue, language}: Props): JSX.Element => {
  const {t} = useTranslation('components', {keyPrefix: 'valueNotTranslatedLabel'});
  const useLanguageTranslations = useTranslateLanguage();
  const merchant = useCurrentMerchant();

  return (
    <div className="flex flex-col">
      <div className="flex items-center space-x-2 text-sm font-medium">
        <p>{LANGUAGE_FLAGS[merchant.defaultLanguage]}</p>
        <p>{defaultValue ?? t('labelNoTitle')}</p>
      </div>
      <p className="flex items-center text-sm font-normal italic text-primary">
        {t(`labelValueNotTranslated`, {language: useLanguageTranslations(language)})}
      </p>
    </div>
  );
};
