import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {BaseResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {MetadataFormattedSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortPerkResourceSchema = BaseResourcePropertiesSchema.extend({
  id: z.string().uuid(),
  metadata: MetadataFormattedSchema,
});

export type CohortPerkResourceType = z.infer<typeof CohortPerkResourceSchema>;

export type CohortPerkResourceStruct = ResourceStruct<'cohort.perk', CohortPerkResourceType>;

export const CohortPerkResourceSpec: ResourceSpec<CohortPerkResourceStruct> = {
  id: 'cohort.perk',
  schema: CohortPerkResourceSchema,
};
