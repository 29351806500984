import type {CohortFormContextType} from '@cohort/merchants/components/form/CohortFormContext';
import {CohortFormContext} from '@cohort/merchants/components/form/CohortFormContext';
import {useContext} from 'react';
import type {FieldValues} from 'react-hook-form';

export function useCohortForm<
  TFieldValues extends FieldValues = FieldValues,
>(): CohortFormContextType<TFieldValues> {
  const context = useContext(CohortFormContext);

  if (!context) {
    throw new Error('useCohortForm must be used within a CohortFormProvider');
  }
  return context as CohortFormContextType<TFieldValues>;
}
