import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {useConnectionProfile} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {FacebookMerchantConnectorStruct} from '@cohort/shared/apps/facebook/merchantConnector';
import {Fragment, useEffect} from 'react';

const FacebookPageInfoConfigComponent: React.FC<TriggerIntegrationConfigComponentProps> = ({
  formReturn,
  connectionId,
}) => {
  const merchant = useCurrentMerchant();

  const {data: profile} = useConnectionProfile<FacebookMerchantConnectorStruct>(
    merchant.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {enabled: !!connectionId}
  );

  const {setValue} = formReturn;

  useEffect(() => {
    if (!profile) {
      return;
    }

    setValue('triggerIntegrationConfig.pageId', profile.properties.id);
    setValue('triggerIntegrationConfig.pageName', profile.properties.username);
  }, [setValue, profile]);

  return <Fragment />;
};

export default FacebookPageInfoConfigComponent;
