/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {ChallengeParticipationsParamsADto} from '@cohort/admin-schemas/challengeParticipation';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {
  getChallengeParticipation,
  getChallengeParticipations,
  getParticipationAnalytics,
} from '@cohort/merchants/lib/api/ChallengeParticipations';

export const ChallengeParticipationKeys = {
  participations: ['challenge-participations'] as const,
  getById: (merchantId: string, challengeParticipationId: string) =>
    [...ChallengeParticipationKeys.participations, merchantId, challengeParticipationId] as const,
  listWithParams: (merchantId: string, params: ChallengeParticipationsParamsADto) =>
    [...ChallengeParticipationKeys.participations, merchantId, params] as const,
  getStatistics: (merchantId: string, campaignId: string) =>
    [...ChallengeParticipationKeys.participations, merchantId, campaignId, 'statistics'] as const,
};

export const useChallengeParticipations = (
  merchantId: string,
  params: ChallengeParticipationsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: ChallengeParticipationKeys.listWithParams(merchantId, params),
    queryFn: async () => getChallengeParticipations(merchantId, params),
    ...options,
  });

export const useChallengeParticipation = (
  merchantId: string,
  challengeParticipationId: string,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: ChallengeParticipationKeys.getById(merchantId, challengeParticipationId),
    queryFn: async () => getChallengeParticipation(merchantId, challengeParticipationId),
    ...options,
  });

export const useParticipationAnalytics = (
  merchantId: string,
  campaignId: string,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: ChallengeParticipationKeys.getStatistics(merchantId, campaignId),
    queryFn: async () => getParticipationAnalytics(merchantId, campaignId),
    ...options,
  });
