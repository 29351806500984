import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {TypeformTakeQuizVerificationAttemptData} from '@cohort/shared/apps/typeform/triggers/takeQuiz';

const TypeformTakeQuizTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: TypeformTakeQuizVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {formTitle, minScore, userScore} = data;

  if (minScore === undefined || userScore === undefined) {
    throw new Error('Never happens: minScore or userScore is undefined');
  }

  return (
    <StepCompletionCard
      translationConfig={{
        // i18nOwl-ignore [app-typeform.triggerIntegrations.take-quiz.stepCompletionTitleComponent.success]
        // i18nOwl-ignore [app-typeform.triggerIntegrations.take-quiz.stepCompletionTitleComponent.error]
        key: `triggerIntegrations.take-quiz.stepCompletionTitleComponent.${
          completionSuccess ? 'success' : 'error'
        }`,
        ns: 'app-typeform',
        value: formTitle ?? null,
      }}
    />
  );
};

export default TypeformTakeQuizTriggerIntegrationStepCompletionTitleComponent;
