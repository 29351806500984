import {z} from 'zod';

export const YoutubeVideoSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  publishedAt: z.date(),
  thumbnailUrl: z.string(),
});
export type YoutubeVideo = z.infer<typeof YoutubeVideoSchema>;

export function getYoutubeProfileUrl(channelId: string): string {
  return `https://www.youtube.com/channel/${channelId}`;
}

export function getYoutubeVideoUrl(videoId: string): string {
  return `https://www.youtube.com/watch?v=${videoId}`;
}

export function getYoutubeCommentUrl(videoId: string, commentId: string): string {
  return `https://www.youtube.com/watch?v=${videoId}&lc=${commentId}`;
}
