import type {ActionSpec, AppSpec, AppStruct, PerkIntegrationSpec} from '@cohort/shared/apps/app';
import {ShopifyGetShopActionSpec} from '@cohort/shared/apps/shopify/actions/getShop';
import type {ShopifyMerchantConnectorStruct} from '@cohort/shared/apps/shopify/merchantConnector';
import {ShopifyMerchantConnectorSpec} from '@cohort/shared/apps/shopify/merchantConnector';
import {ShopifyDiscountPerkIntegrationSpec} from '@cohort/shared/apps/shopify/perks/discount';
import type {ShopifySyncStruct} from '@cohort/shared/apps/shopify/sync';
import {ShopifySyncIntegrationSpec} from '@cohort/shared/apps/shopify/sync';

export const ShopifyActionSpecs = [
  ShopifyGetShopActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type ShopifyActionIds = (typeof ShopifyActionSpecs)[number]['id'];

export const ShopifyPerkIntegrationSpecs = [
  ShopifyDiscountPerkIntegrationSpec,
] as const satisfies ReadonlyArray<PerkIntegrationSpec>;
type ShopifyPerkIntegrationIds = (typeof ShopifyPerkIntegrationSpecs)[number]['id'];

export const SHOPIFY_APP_ID = 'shopify' as const;

export type ShopifyAppStruct = AppStruct<
  'shopify',
  ShopifyMerchantConnectorStruct,
  null,
  ShopifySyncStruct,
  ShopifyActionIds,
  never,
  ShopifyPerkIntegrationIds,
  never,
  never,
  never,
  never,
  never
>;

export const ShopifyAppSpec: AppSpec<ShopifyAppStruct> = {
  id: SHOPIFY_APP_ID,
  name: 'Shopify',
  merchantConnector: ShopifyMerchantConnectorSpec,
  userConnector: null,
  syncSpec: ShopifySyncIntegrationSpec,
  actionSpecs: ShopifyActionSpecs,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: ShopifyPerkIntegrationSpecs,
  triggerIntegrationSpecs: [],
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
