import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {z} from 'zod';

export const CohortVideoMediaConfigSchema = z.object({
  videoFileKey: z.string(),
  thumbnailFileKey: z.string().nullable(),
});
export type CohortVideoMediaConfig = z.infer<typeof CohortVideoMediaConfigSchema>;

export type CohortVideoMediaStruct = MediaStruct<'cohort.video', CohortVideoMediaConfig>;

export const CohortVideoMediaSpec: MediaSpec<CohortVideoMediaStruct> = {
  kind: 'cohort.video',
  configSchema: CohortVideoMediaConfigSchema,
};
