import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {INSTAGRAM_URL} from '@cohort/shared/apps/instagram/common';
import type {InstagramFollowVerificationAttemptData} from '@cohort/shared/apps/instagram/triggers/follow';
import {Trans} from 'react-i18next';

const InstagramFollowTriggerStepCompletionTitleComponent: React.FC<{
  data: InstagramFollowVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data}) => {
  const {username, url} = data;
  const userLink = `${INSTAGRAM_URL}/${username}`;

  return (
    <Trans
      i18nKey="triggerIntegrations.follow.stepCompletionTitleComponent.success"
      ns="app-instagram"
      values={{username}}
      components={{
        userLink: <StepCompletionLink url={userLink} />,
        merchantLink: <StepCompletionLink url={url} />,
      }}
    />
  );
};

export default InstagramFollowTriggerStepCompletionTitleComponent;
