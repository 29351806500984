import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {StravaBeActiveVerificationAttemptData} from '@cohort/shared/apps/strava/triggers/beActive';

const StravaBeActiveTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: StravaBeActiveVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => (
  <StepCompletionCard
    translationConfig={{
      // i18nOwl-ignore [app-strava.triggerIntegrations.be-active.stepCompletionTitleComponent.success]
      // i18nOwl-ignore [app-strava.triggerIntegrations.be-active.stepCompletionTitleComponent.error]
      key: `triggerIntegrations.be-active.stepCompletionTitleComponent.${
        completionSuccess ? 'success' : 'error'
      }`,
      ns: 'app-strava',
      value: data.stravaUsername,
    }}
  />
);

export default StravaBeActiveTriggerIntegrationStepCompletionTitleComponent;
