import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {FACEBOOK_URL} from '@cohort/shared/apps/facebook/common';
import type {FacebookCommentPostVerificationAttemptData} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {Trans} from 'react-i18next';

const FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: FacebookCommentPostVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {username, comment} = data;

  if (!completionSuccess || comment === null) {
    return (
      <Trans
        i18nKey="triggers.comment-post.stepCompletionTitleComponent.error"
        ns="app-facebook"
        values={{username}}
        components={{
          userLink: <StepCompletionLink url={data.userLink ?? FACEBOOK_URL} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggers.comment-post.stepCompletionTitleComponent.success"
      ns="app-facebook"
      values={{username}}
      components={{
        userLink: <StepCompletionLink url={data.userLink ?? FACEBOOK_URL} />,
        commentLink: <StepCompletionLink url={comment.permalinkUrl} />,
      }}
    />
  );
};

export default FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent;
