import Loader from '@cohort/merchants/components/Loader';
import {cn} from '@cohort/shared-frontend/utils/classNames';

type LoadingScreenProps = {
  isFullScreen?: boolean;
};

const LoadingScreen: React.FC<LoadingScreenProps> = ({isFullScreen = false}) => (
  <div
    className={cn(
      'my-auto flex w-full items-center justify-center',
      isFullScreen ? 'h-screen' : 'h-full'
    )}
  >
    <Loader size={50} color="gray" />
  </div>
);

export default LoadingScreen;
