import type {TriggerIntegration} from '@cohort/merchants/apps';
import GarminBeActiveTriggerIntegrationConfigComponent from '@cohort/merchants/apps/google-fit/triggerIntegratons/beActive/ConfigComponent';
import GoogleFitBeActiveTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/google-fit/triggerIntegratons/beActive/StepCompletionTitleComponent';
import {GoogleFitActivityGroupsSchema} from '@cohort/shared/apps/google-fit/activity';
import type {
  GoogleFitBeActiveTriggerConfig,
  GoogleFitBeActiveTriggerStruct,
} from '@cohort/shared/apps/google-fit/triggers/beActive';
import {
  GoogleFitBeActiveTriggerConfigMetricSchema,
  GoogleFitBeActiveTriggerIntegrationSpec,
} from '@cohort/shared/apps/google-fit/triggers/beActive';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {toKilometers, toMeters} from '@cohort/shared/utils/convert';
import {z} from 'zod';

export const GoogleFitConnectBeActiveTriggerFormConfigSchema = z
  .object({
    activityGroups: z
      .array(GoogleFitActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .nullable()
      .optional(),
    metric: GoogleFitBeActiveTriggerConfigMetricSchema,
    hasActivityTypes: z.boolean().optional().default(false),
    endDate: z.date({coerce: true}),
    startDate: z.date({coerce: true}),
    value: z.number().min(0),
  })
  .superRefine(
    startDateBeforeEndDateRefinement({
      startDatePropertyName: 'startDate',
      endDatePropertyName: 'endDate',
    })
  );

export type GoogleFitBeActiveTriggerFormConfig = z.infer<
  typeof GoogleFitConnectBeActiveTriggerFormConfigSchema
>;

function fromForm(formValues: GoogleFitBeActiveTriggerFormConfig): GoogleFitBeActiveTriggerConfig {
  const result: GoogleFitBeActiveTriggerConfig = {
    ...formValues,
    endDateTimestamp: new Date(formValues.endDate).getTime(),
    startDateTimestamp: new Date(formValues.startDate).getTime(),
    activityGroups: formValues.hasActivityTypes ? (formValues.activityGroups ?? null) : null,
  };

  switch (formValues.metric) {
    case 'distance':
      result.value = toMeters(formValues.value);
      break;
    case 'active minutes':
    case 'heart points':
    case 'steps':
      result.value = formValues.value;
      break;
  }

  return result;
}

function toForm(config: GoogleFitBeActiveTriggerConfig): GoogleFitBeActiveTriggerFormConfig {
  const result: GoogleFitBeActiveTriggerFormConfig = {
    ...config,
    endDate: new Date(config.endDateTimestamp),
    startDate: new Date(config.startDateTimestamp),
    hasActivityTypes: config.activityGroups !== null,
    activityGroups: config.activityGroups,
  };

  switch (config.metric) {
    case 'distance':
      result.value = toKilometers(config.value);
      break;
    case 'active minutes':
    case 'heart points':
    case 'steps':
      result.value = config.value;
      break;
  }

  return result;
}

export const GoogleFitConnectBeActiveTriggerIntegration: TriggerIntegration<GoogleFitBeActiveTriggerStruct> =
  {
    spec: GoogleFitBeActiveTriggerIntegrationSpec,
    configComponent: GarminBeActiveTriggerIntegrationConfigComponent,
    configForm: {
      schema: GoogleFitConnectBeActiveTriggerFormConfigSchema,
      toForm,
      fromForm,
    },
    stepCompletionTitleComponent: GoogleFitBeActiveTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.be-active.title', {ns: 'app-google-fit'}),
  };
