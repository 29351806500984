import type {CohortWithBadgeADto} from '@cohort/admin-schemas/cohort';
import {CurrentCohortContext} from '@cohort/merchants/pages/users/cohort/CurrentCohortContext';
import {useContext} from 'react';

export const useCurrentCohort = (): CohortWithBadgeADto => {
  const context = useContext(CurrentCohortContext);

  if (context === null) {
    throw new Error('useCurrentCohort must be used within a CurrentCohortProvider');
  }
  return context;
};
