import {notify} from '@cohort/merchants/hooks/toast';
import {CheckCircle, Clipboard, Copy} from '@phosphor-icons/react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type CopyToClipboardProps = {
  target: string;
  variant?: 'primary' | 'secondary';
  disableNotification?: boolean;
  size?: number;
};

const CopyToClipboard: React.FC<CopyToClipboardProps> = props => {
  const {t} = useTranslation('components', {keyPrefix: 'buttons.copyToClipboard'});
  const [copied, setCopied] = useState(false);

  const size = props.size ?? 20;

  const icon = match(props.variant)
    .with(undefined, 'primary', () => <Clipboard size={size} className="text-slate-500" />)
    .with('secondary', () => <Copy size={size} className="text-slate-500" />)
    .exhaustive();

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return copied ? (
    <CheckCircle size={size} className="inline-block align-sub text-green-600" />
  ) : (
    <button
      className="align-sub"
      onClick={async () => {
        await navigator.clipboard.writeText(props.target);
        if (!props.disableNotification) {
          notify('success', t('copied'));
        }
        setCopied(true);
      }}
    >
      {icon}
    </button>
  );
};

export default CopyToClipboard;
