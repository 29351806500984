import useBackNavigationStore from '@cohort/merchants/hooks/stores/backNavigation';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const useSyncBackNavigation = (): void => {
  const location = useLocation();
  const setCurrentPath = useBackNavigationStore(state => state.setCurrentPath);

  useEffect(() => {
    setCurrentPath(location.pathname, location.search);
  }, [location.pathname, location.search, setCurrentPath]);
};

export default useSyncBackNavigation;
