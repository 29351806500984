import {z} from 'zod';

export const SpotifyResourceTypesSchema = z.enum([
  'album',
  'artist',
  'episode',
  'playlist',
  'show',
  'track',
]);
export type SpotifyResourceType = z.infer<typeof SpotifyResourceTypesSchema>;

const SPOTIFY_BASE_URL = 'https://open.spotify.com';

export const SPOTIFY_RESOURCE_URL_SCHEMAS: Record<SpotifyResourceType, string> = {
  album: `${SPOTIFY_BASE_URL}/album/`,
  artist: `${SPOTIFY_BASE_URL}/artist/`,
  episode: `${SPOTIFY_BASE_URL}/episode/`,
  playlist: `${SPOTIFY_BASE_URL}/playlist/`,
  show: `${SPOTIFY_BASE_URL}/show/`,
  track: `${SPOTIFY_BASE_URL}/track/`,
};

export function buildSpotifyUrl(resourceType: SpotifyResourceType, resourceId: string): string {
  return `${SPOTIFY_RESOURCE_URL_SCHEMAS[resourceType]}${resourceId}`;
}
