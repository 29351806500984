import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortUserCreatedPropertiesSchema = z.object({
  memberHandle: z.string(),
});
export type CohortUserCreatedProperties = z.infer<typeof CohortUserCreatedPropertiesSchema>;

export type CohortUserCreatedEventStruct = UserEventStruct<
  'cohort.user.created',
  keyof CohortUserCreatedProperties,
  CohortUserCreatedProperties,
  CohortUserCreatedProperties
>;

export const CohortUserCreatedEventSpec: UserEventSpec<CohortUserCreatedEventStruct> = {
  id: 'cohort.user.created',
  name: 'Created their Experience Space',
  description: 'Event Triggerred when a user is created',
  propertiesSchema: CohortUserCreatedPropertiesSchema,
  exposedPropertiesSchema: CohortUserCreatedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
