import {z} from 'zod';

export const FontTypeSchema = z.enum(['embedded', 'manual']);
export const FontStyleSchema = z.enum(['normal', 'italic', 'oblique']);
export const FontWeightSchema = z.enum([
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
]);

export type FontType = z.infer<typeof FontTypeSchema>;
export type FontStyle = z.infer<typeof FontStyleSchema>;
export type FontWeight = z.infer<typeof FontWeightSchema>;
