import {z} from 'zod';

export const INSTAGRAM_URL = 'https://instagram.com';

export const InstagramMediaTypeSchema = z.enum(['CAROUSEL_ALBUM', 'IMAGE', 'VIDEO']);
export type InstagramMediaType = z.infer<typeof InstagramMediaTypeSchema>;

export const InstagramMediaProductTypeSchema = z.enum(['FEED', 'REELS']);
export type InstagramMediaProductType = z.infer<typeof InstagramMediaProductTypeSchema>;

export const InstagramMediaSchema = z.object({
  id: z.string(),
  authorUsername: z.string(),
  caption: z.string().nullable(),
  createdAt: z.date(), // Using DatetimeSchema fails here because the type thinks it's a string
  isCommentEnabled: z.boolean().optional(),
  mediaType: InstagramMediaTypeSchema,
  mediaProductType: InstagramMediaProductTypeSchema,
  permalink: z.string().url(),
  thumbnailUrl: z.string().url(),
});
export type InstagramMedia = z.infer<typeof InstagramMediaSchema>;

export const InstagramCommentSchema = z.object({
  id: z.string(),
  createdAt: z.date(), // Using DatetimeSchema fails here because the type thinks it's a string
  mediaId: z.string(),
  text: z.string(),
  userId: z.string(),
  username: z.string(),
});
export type InstagramComment = z.infer<typeof InstagramCommentSchema>;

export const FacebookPageSchema = z.object({
  id: z.string(),
  instagramBusinessAccountId: z.string().nullable(),
  name: z.string(),
});
export type FacebookPage = z.infer<typeof FacebookPageSchema>;
