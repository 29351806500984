import type {
  ConnectionADto,
  ConnectionInUseErrorContextADto,
  ConnectionInUseErrorEntityADto,
} from '@cohort/admin-schemas/connection';
import {ConnectionInUseErrorContextASchema} from '@cohort/admin-schemas/connection';
import Dropdown, {
  DropdownButton,
  DropdownEntry,
  DropdownPanel,
} from '@cohort/merchants/components/buttons/Dropdown';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {deleteConnection} from '@cohort/merchants/lib/api/Connections';
import {pauseSync, resumeSync} from '@cohort/merchants/lib/api/Sync';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {DotsThreeVertical, PlayCircle, Trash} from '@phosphor-icons/react';
import {PauseCircle} from '@phosphor-icons/react/dist/ssr';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';

type ConnectionInUseEntityErrorProps = {
  errors: ConnectionInUseErrorEntityADto;
  entity: keyof ConnectionInUseErrorContextADto;
};

const ConnectionInUseEntityError: React.FC<ConnectionInUseEntityErrorProps> = ({
  errors,
  entity,
}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'connections.connectionDropdown',
  });

  if (errors.length === 0) {
    return null;
  }

  // i18nOwl-ignore [inUseErrorToast.perks, inUseErrorToast.campaigns, inUseErrorToast.cohorts, inUseErrorToast.notifications]
  return (
    <li className="flex space-x-1">
      <p>{t(`inUseErrorToast.${entity}`)}</p>
      <p className="font-medium">{errors.map(error => error.name).join(', ')}</p>
    </li>
  );
};

type ConnectionInUseErrorProps = {
  errorContext: ConnectionInUseErrorContextADto;
};

const ConnectionInUseError: React.FC<ConnectionInUseErrorProps> = ({errorContext}) => {
  const connectionUsedInEntities = [
    'perks',
    'campaigns',
    'cohorts',
    'notifications',
  ] satisfies (keyof ConnectionInUseErrorContextADto)[];

  return (
    <ul className="flex flex-col">
      {connectionUsedInEntities.map(entity => (
        <ConnectionInUseEntityError
          key={entity}
          errors={errorContext[entity] as {id: string; name: string}[]}
          entity={entity}
        />
      ))}
    </ul>
  );
};

type ConnectionDropdownProps = {
  connection: ConnectionADto;
  onConnectionDeleted?: () => void;
};

const ConnectionDropdown: React.FC<ConnectionDropdownProps> = ({
  connection,
  onConnectionDeleted,
}) => {
  const sync = connection.sync;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {keyPrefix: 'connections.connectionDropdown'});
  const queryClient = useQueryClient();

  const onSyncUpdated = (): void => {
    queryClient.invalidateQueries(connectionsKeys.list(merchant.id));
    queryClient.invalidateQueries(connectionsKeys.getById(merchant.id, connection.id));
  };

  const {mutate: pauseSyncMutation} = useCohortMutation({
    mutationFn: async (syncId: string) => pauseSync(merchant.id, syncId),
    onSuccess: onSyncUpdated,
  });

  const {mutate: resumeSyncMutation} = useCohortMutation({
    mutationFn: async (syncId: string) => resumeSync(merchant.id, syncId),
    onSuccess: onSyncUpdated,
  });

  const {mutate: deleteMerchantConnection} = useCohortMutation({
    mutationFn: async (connectionId: string) => deleteConnection(merchant.id, connectionId),
    notifySuccessMessage: t('deleteNotificationSuccess'),
    onSuccess: async () => {
      queryClient.invalidateQueries(connectionsKeys.list(merchant.id));
      onConnectionDeleted?.();
      setDeleteModalOpen(false);
    },
    onError: error => {
      setDeleteModalOpen(false);
      if (isCohortError(error, 'connection.in-use')) {
        const parsedError = ConnectionInUseErrorContextASchema.parse(error.context);
        notify('error', t('inUseErrorToast.inUseError'), {
          description: <ConnectionInUseError errorContext={parsedError} />,
        });
      } else {
        notify('error', t('deleteErrorNotification'));
      }
    },
  });

  return (
    <Fragment>
      <Dropdown>
        <DropdownButton className="p-0 [height:36px] [width:36px]">
          <DotsThreeVertical size={20} color="gray" />
        </DropdownButton>
        <DropdownPanel>
          {sync?.status === 'started' && (
            <DropdownEntry
              label={t('labelPauseSync')}
              icon={<PauseCircle className="h-4 w-4 text-slate-400" />}
              handleClick={() => pauseSyncMutation(sync.id)}
            />
          )}
          {sync?.status === 'paused' && (
            <DropdownEntry
              disabled={connection.status !== 'ready'}
              label={t('labelStartSync')}
              icon={<PlayCircle className="h-4 w-4 text-slate-400" />}
              handleClick={() => resumeSyncMutation(sync.id)}
            />
          )}
          <DropdownEntry
            label={<span className="text-red-500">{t('labelDeleteConnection')}</span>}
            icon={<Trash className="h-4 w-4 text-red-500" />}
            handleClick={() => setDeleteModalOpen(true)}
          />
        </DropdownPanel>
      </Dropdown>
      {deleteModalOpen && (
        <DeletionModal
          show
          title={t('deleteModalTitle')}
          subtitle={t('deleteModalText', {connectionName: connection.name})}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={() => {
            deleteMerchantConnection(connection.id);
          }}
        />
      )}
    </Fragment>
  );
};

export default ConnectionDropdown;
