import {useCurrentUser} from '@cohort/merchants/hooks/contexts/currentUser';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import PageOutlet from '@cohort/merchants/layouts/PageOutlet';
import {CurrentUserProvider} from '@cohort/merchants/pages/users/user/CurrentUserContext';
import UserPageBreadcrumb from '@cohort/merchants/pages/users/user/UserPageBreadcrumb';
import UserPageMenu from '@cohort/merchants/pages/users/user/UserPageMenu';

const UserPageSkeleton: React.FC = () => {
  const user = useCurrentUser();

  return (
    <PageLayout title={user.email} breadcrumb={<UserPageBreadcrumb />} menu={<UserPageMenu />}>
      <PageOutlet />
    </PageLayout>
  );
};

const UserPageLayout = (): JSX.Element => (
  <CurrentUserProvider>
    <UserPageSkeleton />
  </CurrentUserProvider>
);

export default UserPageLayout;
