import {asMapOrThrow, asStringOrThrow} from '@cohort/shared/typeUtils';

export function parseJwtToken(data: unknown): string {
  const anyMap = asMapOrThrow(data);
  return asStringOrThrow(anyMap.token);
}

export function parseSingInLink(data: unknown): string | undefined {
  if (import.meta.env.COHORT_ENV !== 'development') {
    return undefined;
  }
  const anyMap = asMapOrThrow(data);
  return asStringOrThrow(anyMap.link);
}
