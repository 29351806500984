import {z} from 'zod';

const RewardRefinement = (
  request: {
    perkIds?: string[];
    digitalAssetCollectionIds?: string[];
    cohortId: string;
  },
  ctx: z.RefinementCtx
): {message: string; path: string[]} | boolean => {
  const rewardsLength =
    (request.perkIds ?? []).length + (request.digitalAssetCollectionIds ?? []).length;

  if (rewardsLength < 1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'At least one reward must be provided',
      path: ['perkIds', 'digitalAssetCollectionIds'],
    });
    return false;
  }

  return true;
};

export const CreateAirdropDataASchema = z
  .object({
    perkIds: z.array(z.string()).optional(),
    digitalAssetCollectionIds: z.array(z.string()).optional(),
    cohortId: z.string().uuid(),
  })
  .superRefine(RewardRefinement);
export type CreateAirdropDataADto = z.infer<typeof CreateAirdropDataASchema>;

export const GetCurrentAirdropASchema = z.object({
  status: z.enum(['in-progress', 'completed']),
});
export type GetCurrentAirdropADto = z.infer<typeof GetCurrentAirdropASchema>;
