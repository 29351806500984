import SelectMediaContext from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContext';
import {useState} from 'react';

type SelectMediaContextProviderProps = {
  children: React.ReactNode;
  defaultMediaId: string | null;
};

export const SelectMediaContextProvider = ({
  children,
  defaultMediaId,
}: SelectMediaContextProviderProps): JSX.Element => {
  const [selectedMediaId, setSelectedMediaId] = useState<string | null>(defaultMediaId);
  const [selectMediaSheetOpened, setSelectMediaSheetOpened] = useState<boolean>(false);

  const context = {
    selectedMediaId,
    setSelectedMediaId,
    selectMediaSheetOpened,
    setSelectMediaSheetOpened,
  };

  return <SelectMediaContext.Provider value={context}>{children}</SelectMediaContext.Provider>;
};
