import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import type {InstagramCloseFriendsListUsageData} from '@cohort/shared/apps/instagram/perks/closeFriendsList';
import {useTranslation} from 'react-i18next';

const InstagramCloseFriendsListPerkIntegrationsUsageViewComponent: React.FC<
  PerkUsageViewComponentProps
> = ({usage}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'perkIntegrations.close-friends-list.usageViewComponent',
  });
  const data = usage.data as InstagramCloseFriendsListUsageData;
  const username = `@${data.instagramUsername}`;

  return (
    <PerkUsageLayout.Grid>
      <PerkUsageLayout.Row name={t('labelUsername')} value={username} />
    </PerkUsageLayout.Grid>
  );
};

export default InstagramCloseFriendsListPerkIntegrationsUsageViewComponent;
