import {ToastAction} from '@cohort/merchants/components/toasts/Toast';
import {useConnections} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useToast} from '@cohort/merchants/hooks/toast';
import {getAppsRoute} from '@cohort/merchants/lib/Pages';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

/**
 * Hook that checks if there are any broken connections and shows an error toast if there are.
 */
export default function useCheckBrokenConnections(): void {
  const {toast} = useToast();
  const merchant = useCurrentMerchant();
  const connections = useConnections(merchant.id);
  const {t} = useTranslation('hooks', {keyPrefix: 'useCheckBrokenConnections'});
  const navigate = useNavigate();

  useEffect(() => {
    if (connections.data?.some(connection => connection.status === 'broken')) {
      toast({
        title: t('title'),
        description: t('description'),
        duration: Infinity,
        hideToastClose: true,
        variant: 'error',
        action: (
          <ToastAction
            onClick={() => navigate(getAppsRoute().path)}
            altText={t('altText')}
            variant="error"
          >
            {t('actionBtn')}
          </ToastAction>
        ),
      });
    }
  }, [connections.data, navigate, t, toast]);
}
