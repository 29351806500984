import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortNewChallengeStepAvailableEventStruct} from '@cohort/shared/apps/cohort/userEvents/challenge/newChallengeStepAvailable';
import {CohortNewChallengeStepAvailableEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/newChallengeStepAvailable';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const campaignId = (
    userEvent.properties as CohortNewChallengeStepAvailableEventStruct['Properties']
  ).campaignId;
  const campaignInternalName = userEvent.campaignInternalName;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.challenge.new-step-available.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCampaignRoute(campaignId).path)
        ),
      }}
    />
  );
};

export const CohortNewChallengeStepAvailableUserEvent: UserEvent<CohortNewChallengeStepAvailableEventStruct> =
  {
    spec: CohortNewChallengeStepAvailableEventSpec,
    detailComponent: DetailComponent,
  };
