import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {BaseResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {z} from 'zod';

const CohortBadgeResourceSchema = BaseResourcePropertiesSchema.extend({
  id: z.string().uuid(),
});

export type CohortBadgeResourceType = z.infer<typeof CohortBadgeResourceSchema>;

export type CohortBadgeResourceStruct = ResourceStruct<'cohort.badge', CohortBadgeResourceType>;

export const CohortBadgeResourceSpec: ResourceSpec<CohortBadgeResourceStruct> = {
  id: 'cohort.badge',
  schema: CohortBadgeResourceSchema,
};
