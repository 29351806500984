import {fit} from '@cloudinary/url-gen/actions/resize';
import type {CloudinaryImage} from '@cloudinary/url-gen/assets/CloudinaryImage';
import {Cloudinary} from '@cloudinary/url-gen/instance/Cloudinary';
import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {getConfigVar} from '@cohort/shared/utils/config';

export const cld = new Cloudinary({
  cloud: {
    cloudName: 'cohort-xyz',
  },
});

export const Sizes = {
  XL: 1440,
  L: 1080,
  M: 720,
  S: 360,
  XS: 64,
} as const;

export function getCloudinaryPath(env: string, fileKey: string): string {
  return `${getConfigVar(env, 'cloudinaryFolder')}/${fileKey}`;
}

export function getCloudinaryImage(env: string, fileKey: string): CloudinaryImage {
  return cld.image(getCloudinaryPath(env, fileKey));
}

export function getCloudinaryVideo(env: string, fileKey: string): CloudinaryImage {
  return cld.video(getCloudinaryPath(env, fileKey));
}

interface MediaOptions {
  w?: number;
  h?: number;
  format?: string;
  quality?: string;
}

export function srcSet(...srcs: (string | undefined)[]): string {
  return srcs.filter(Boolean).join(', ');
}

export function getImageUrl(env: string, fileKey: string, options?: MediaOptions): string {
  const image = getCloudinaryImage(env, fileKey)
    .resize(fit(options?.w, options?.h))
    .format(options?.format ?? 'auto')
    .quality(options?.quality ?? 'auto:best');

  return image.toURL();
}

export function getVideoUrl(env: string, fileKey: string, options?: MediaOptions): string {
  const video = getCloudinaryVideo(env, fileKey)
    .resize(fit(options?.w, options?.h))
    .format(options?.format ?? 'auto')
    .quality(options?.quality ?? 'auto:best');

  return video.toURL();
}

export const isRoundedVisual = (assetKind: AssetKind): boolean => {
  return assetKind === 'badgeVisual';
};
