import type {TriggerIntegrationSpec, UnverifiedTriggerStruct} from '@cohort/shared/apps/trigger';
import {UnverifiedTriggerBaseConfigSchema} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const TikTokFollowTriggerConfigSchema = UnverifiedTriggerBaseConfigSchema;
type TikTokFollowTriggerConfig = z.infer<typeof TikTokFollowTriggerConfigSchema>;

const TikTokFollowVerificationAttemptDataSchema = z.object({
  username: z.string(),
  url: z.string().url(),
});
export type TikTokFollowVerificationAttemptData = z.infer<
  typeof TikTokFollowVerificationAttemptDataSchema
>;

export type TikTokFollowTriggerStruct = UnverifiedTriggerStruct<
  'tiktok.follow',
  TikTokFollowTriggerConfig,
  TikTokFollowVerificationAttemptData
>;
export const TikTokFollowTriggerSpec: TriggerIntegrationSpec<TikTokFollowTriggerStruct> = {
  id: 'tiktok.follow' as const,
  type: 'action',
  configurableMaxAttempts: false,
  configSchema: TikTokFollowTriggerConfigSchema,
  verificationAttemptDataSchema: TikTokFollowVerificationAttemptDataSchema,
  verificationAttemptInputSchema: z.object({}),
  requiresMerchantConnection: true,
};
