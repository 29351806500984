import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {INSTAGRAM_URL} from '@cohort/shared/apps/instagram/common';
import type {InstagramLikeMediaVerificationAttemptData} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import {Trans} from 'react-i18next';

const InstagramLikeMediaTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: InstagramLikeMediaVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data}) => {
  const {username, url} = data;
  const userLink = `${INSTAGRAM_URL}/${username}`;

  return (
    <Trans
      i18nKey="triggerIntegrations.like-media.stepCompletionTitleComponent.success"
      ns="app-instagram"
      values={{username}}
      components={{
        userLink: <StepCompletionLink url={userLink} />,
        mediaLink: <StepCompletionLink url={url} />,
      }}
    />
  );
};

export default InstagramLikeMediaTriggerIntegrationStepCompletionTitleComponent;
