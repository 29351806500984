import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {
  SelectMediaConfig,
  SelectMediaConfigButton,
  SelectMediaConfigEmptyState,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaConfig';
import {SelectMediaContextProvider} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContextProvider';
import {useSpotifyPlaylists} from '@cohort/merchants/apps/spotify/actions';
import SelectPlaylistSheet from '@cohort/merchants/apps/spotify/triggers/SelectPlaylistSheet';
import SpotifyPlaylistCard from '@cohort/merchants/apps/spotify/triggers/SpotifyPlaylistCard';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {Fragment, useEffect} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const SpotifyFollowPlaylistTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {setValue, control} = formReturn;

  const {field: playlistIdField} = useController({
    name: 'triggerIntegrationConfig.playlistId',
    control,
  });
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'triggerIntegrations.follow-playlist.configComponent',
  });

  const {
    data: playlistsResponse,
    isLoading: isPlaylistsLoading,
    isFetched: isPlaylistsFetched,
  } = useSpotifyPlaylists(merchant.id, connectionId, undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const playlists = playlistsResponse?.output.playlists ?? [];

  // Set the value of the title to match the playlistId
  useEffect(() => {
    const playlist = playlists.find(playlist => playlist.id === playlistIdField.value);
    if (playlist === undefined) {
      return;
    }
    setValue('triggerIntegrationConfig.playlistName', playlist.name);
    setValue('triggerIntegrationConfig.playlistThumbnailUrl', playlist.thumbnailUrl);
    setValue('triggerIntegrationConfig.playlistUrl', playlist.url);
    setValue('triggerIntegrationConfig.playlistTracksCount', playlist.tracksCount);
  }, [playlistIdField, playlists, setValue]);

  return match({
    playlists,
    isPlaylistsLoading,
    isPlaylistsFetched,
    formIdFieldValue: playlistIdField.value,
  })
    .with({isPlaylistsLoading: true}, () => <Loader size={30} color="gray" />)
    .with({playlists: []}, () => <p>{t('noPlaylistsMessage')}</p>)
    .with({isPlaylistsFetched: true}, () => {
      const selectedPlaylist = playlists.find(playlist => playlist.id === playlistIdField.value);

      return (
        <SelectMediaContextProvider defaultMediaId={playlistIdField.value}>
          <SelectMediaConfig
            title={t('titlePlaylistSelect')}
            description={t('descriptionPlaylistSelect')}
          >
            {selectedPlaylist === undefined ? (
              <Fragment>
                <SelectMediaConfigEmptyState />
                <SelectMediaConfigButton mode="add" />
              </Fragment>
            ) : (
              <Fragment>
                <SpotifyPlaylistCard media={selectedPlaylist} />
                <SelectMediaConfigButton mode="change" />
              </Fragment>
            )}
            <SelectPlaylistSheet setValue={setValue} playlists={playlists} />
          </SelectMediaConfig>
        </SelectMediaContextProvider>
      );
    })
    .otherwise(() => <ErrorState />);
};

export default SpotifyFollowPlaylistTriggerConfigComponent;
