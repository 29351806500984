import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import GoogleFitActivitySelectPicker from '@cohort/merchants/apps/google-fit/triggerIntegratons/ActivitySelectPicker';
import GoogleFitCompleteActivityMetricsForm from '@cohort/merchants/apps/google-fit/triggerIntegratons/completeActivity/ActivityMetricsForm';
import TriggerIntegrationDateRangeInput from '@cohort/merchants/components/trigger-integration/TriggerIntegrationDateRangeInput';

const GoogleFitCompleteActivityTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = props => {
  return (
    <div className="flex flex-col gap-4">
      <TriggerIntegrationDateRangeInput
        {...props}
        startDateName="triggerIntegrationConfig.activityStartDate"
        endDateName="triggerIntegrationConfig.activityEndDate"
      />

      <GoogleFitActivitySelectPicker
        {...props}
        fieldName="triggerIntegrationConfig.activityGroups"
        hasFieldName="triggerIntegrationConfig.hasActivityTypes"
      />

      <GoogleFitCompleteActivityMetricsForm {...props} />
    </div>
  );
};

export default GoogleFitCompleteActivityTriggerIntegrationConfigComponent;
