import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const TwitterProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  username: z.string(),
  followersCount: z.number(),
  followingCount: z.number(),
  tweetsCount: z.number(),
  listedCount: z.number(),
  likesCount: z.number(),
  isVerified: z.boolean(),
});
export type TwitterProfileProperties = z.infer<typeof TwitterProfilePropertiesSchema>;
