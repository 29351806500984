import type {SelectMediaContextType} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContext';
import SelectMediaContext from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContext';
import React from 'react';

const useSelectMediaContext = (): SelectMediaContextType => {
  const context = React.useContext(SelectMediaContext);
  if (context === undefined) {
    throw new Error('useSelectMediaContext must be used within a ThemeContextProvider');
  }
  return context;
};

export default useSelectMediaContext;
