import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useCurrentContent} from '@cohort/merchants/hooks/contexts/currentContent';
import {useContentPageStore} from '@cohort/merchants/hooks/stores/contentPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import ContentPageBreadcrumb from '@cohort/merchants/pages/contents/content/ContentPageBreadcrumb';
import ContentPageMenu from '@cohort/merchants/pages/contents/content/ContentPageMenu';
import {CurrentContentProvider} from '@cohort/merchants/pages/contents/CurrentContentContext';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

const ContentPageSkeleton: React.FC = () => {
  const {t} = useTranslation('pages', {keyPrefix: 'contents.content.contentPageLayout'});
  const content = useCurrentContent();
  const {footer, contentPreview} = useContentPageStore(
    store => ({
      footer: store.footer,
      contentPreview: store.contentPreview,
    }),
    shallow
  );
  const location = useLocation();

  // Ensure people won't access some locked pages if the content is in draft
  if (
    content.status === 'draft' &&
    (location.pathname.includes('activity') ||
      location.pathname.includes('audience') ||
      location.pathname.includes('notifications'))
  ) {
    return <NotFoundSection />;
  }

  if (
    content.status !== 'draft' &&
    location.pathname.includes('edit') &&
    !location.pathname.includes('audience/edit')
  ) {
    return <NotFoundSection />;
  }

  return (
    <PageLayout
      title={content.internalName || t('title')}
      breadcrumb={<ContentPageBreadcrumb />}
      menu={<ContentPageMenu />}
      rightAside={contentPreview}
      footer={footer}
    >
      <Outlet />
    </PageLayout>
  );
};

const ContentPageLayout = (): JSX.Element => (
  <CurrentContentProvider>
    <ContentPageSkeleton />
  </CurrentContentProvider>
);

export default ContentPageLayout;
