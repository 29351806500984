import {create} from 'zustand';

export interface PerkPageStore {
  perkPreview?: React.ReactNode;
  footer?: React.ReactNode;
  setFooter: (footer: React.ReactNode) => void;
  setPerkPreview: (perkPreview: React.ReactNode) => void;
}

export const usePerkPageStore = create<PerkPageStore>(set => ({
  perkPreview: undefined,
  footer: undefined,
  setFooter: footer => set({footer}),
  setPerkPreview: perkPreview => set({perkPreview}),
}));
