import {create} from 'zustand';

export interface CohortPageStore {
  footer?: React.ReactNode;
  setFooter: (footer: React.ReactNode) => void;
}

export const useCohortPageStore = create<CohortPageStore>(set => ({
  footer: undefined,
  setFooter: footer => set({footer}),
}));
