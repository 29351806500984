import {useCurrentOrganization} from '@cohort/merchants/hooks/api/Organizations';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {
  getAuthForTenant,
  ORG_PICKER_TENANT_ID,
  signinOrganizationMember,
} from '@cohort/merchants/lib/Firebase';
import {setAmplitudeMerchantUser, trackError} from '@cohort/merchants/lib/Tracking';
import {getOrganizationSlugFromSubdomain, getSigninSubdomainUrl} from '@cohort/merchants/lib/Utils';
import storageAvailable from '@cohort/shared-frontend/utils/storageAvailable';
import {browserLocalPersistence, getAuth, inMemoryPersistence} from 'firebase/auth';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

export const useAuthentication = (): void => {
  const [enableAuthStateChange, setEnableAuthStateChange] = useState(false);
  const organizationSlug = getOrganizationSlugFromSubdomain();
  const {data: organization, error: organizationFetchError} = useCurrentOrganization({
    enabled: !!organizationSlug,
  });
  const location = useLocation();
  const organizationReady =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    organizationSlug === null || (organizationSlug !== null && !!organization);

  const {login, logout, partialLogin} = useUserSessionStore(
    store => ({
      login: store.login,
      logout: store.logout,
      partialLogin: store.partialLogin,
    }),
    shallow
  );

  // Enable auth state change listener
  useEffect(() => {
    if (!organizationReady) {
      return;
    }
    setEnableAuthStateChange(true);
  }, [organizationReady, organization]);

  useEffect(() => {
    // prevent redirection to default signin page in development mode when backend is restarting
    if (import.meta.env.COHORT_ENV === 'development') {
      return;
    }
    const getDestinationPath = (): string | null => {
      if (location.pathname === '/' && location.search === '') {
        return null;
      }
      return encodeURIComponent(`${location.pathname}${location.search}`);
    };

    if (organizationFetchError) {
      const destinationPath = getDestinationPath();

      window.location.assign(getSigninSubdomainUrl(destinationPath));
    }
  }, [organizationFetchError, location.pathname, location.search]);

  // Set auth persistence
  useEffect(() => {
    async function setAuthPersistence(): Promise<void> {
      const auth = getAuth();

      if (storageAvailable() && organizationSlug) {
        await auth.setPersistence(browserLocalPersistence);
      } else {
        await auth.setPersistence(inMemoryPersistence);
      }
    }

    setAuthPersistence();
  }, [organizationSlug]);

  // Initialize Firebase auth listener
  useEffect(() => {
    if (!enableAuthStateChange) {
      return;
    }
    const tenantId = organization ? organization.adminAuthTenantId : ORG_PICKER_TENANT_ID;
    const auth = getAuthForTenant(tenantId);

    auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        // In case we don't have an organization slug, we only user firebase auth for Organization picker
        if (!organization) {
          return partialLogin();
        }

        try {
          const member = await signinOrganizationMember(firebaseUser);

          login(member);
          setAmplitudeMerchantUser(member);
        } catch (e) {
          trackError(e);
        }
      } else {
        logout();
        setAmplitudeMerchantUser(undefined);
      }
    });
  }, [login, logout, enableAuthStateChange, partialLogin, organization]);
};
