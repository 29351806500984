import type {FacebookCommentPostVerificationAttemptData} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {Quotes} from '@phosphor-icons/react';
import {Fragment} from 'react';

const FacebookCommentPostTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: FacebookCommentPostVerificationAttemptData;
}> = ({data}) => {
  const {comment} = data;

  if (!comment) {
    return <Fragment />;
  }

  const {message} = comment;
  return (
    <div className="flex flex-col gap-y-2 rounded-lg border border-slate-200 bg-slate-50 p-4">
      <Quotes size={16} className="text-slate-400" />
      <p className="text-sm text-slate-700">{message}</p>
    </div>
  );
};

export default FacebookCommentPostTriggerIntegrationStepCompletionContextComponent;
