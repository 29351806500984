import type {
  ContentADto,
  CreateContentADto,
  GetContentsParamsADto,
  PatchContentADto,
} from '@cohort/admin-schemas/content';
import {ContentASchema} from '@cohort/admin-schemas/content';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getContents(
  merchantId: string,
  params: GetContentsParamsADto
): Promise<[PaginationDto, Array<ContentADto>]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/contents`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(ContentASchema),
    params,
  });
}

export async function getContent(merchantId: string, contentId: string): Promise<ContentADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/contents/${contentId}`, {
    expect: HttpCodes.SUCCESS,
    parser: ContentASchema.parse,
  });
}

export async function createContent(
  merchantId: string,
  data: CreateContentADto
): Promise<ContentADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/contents`, {
    expect: HttpCodes.CREATED,
    parser: ContentASchema.parse,
    body: data,
  });
}

export async function updateContent(
  merchantId: string,
  contentId: string,
  data: PatchContentADto
): Promise<ContentADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/contents/${contentId}`, {
    expect: HttpCodes.SUCCESS,
    parser: ContentASchema.parse,
    body: data,
  });
}

export async function deleteContent(merchantId: string, contentId: string): Promise<object> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/contents/${contentId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}

export async function publishContent(merchantId: string, contentId: string): Promise<ContentADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/contents/${contentId}/publish`, {
    expect: HttpCodes.SUCCESS,
    parser: ContentASchema.parse,
  });
}
