import type {BadgeADto} from '@cohort/admin-schemas/badge';
import {Badge} from '@cohort/merchants/components/Badge';
import {useCohort} from '@cohort/merchants/hooks/api/Cohorts';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useTranslation} from 'react-i18next';

type BadgeStatusOrCohortLabelProps = {
  badge: BadgeADto;
};

const BadgeStatusOrCohortLabel: React.FC<BadgeStatusOrCohortLabelProps> = ({badge}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.badges.badgeStatusOrCohortLabel',
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: badgeCohort} = useCohort(merchant.id, badge.cohortId!, {
    enabled: badge.cohortId !== null,
  });

  const getCohortLabel = (): JSX.Element => {
    return (
      <div className="flex space-x-1 text-xs">
        <span>{t('assignedTo')}</span>
        <span className="text-green-800">{badgeCohort?.name}</span>
      </div>
    );
  };

  if (badge.cohortId === null && badge.status === 'published') {
    throw new Error("A Badge can't be published without a cohort");
  }

  const badgeText = badge.status === 'published' ? getCohortLabel() : t('badgeStatusDraft');
  const badgeTextColor = badge.status === 'published' ? 'text-green-600' : 'text-yellow-800';
  const badgeBackgroundColor = badge.status === 'published' ? 'bg-green-100' : 'bg-yellow-100';
  const badgeDotColor = badge.status === 'published' ? 'text-green-400' : 'text-yellow-400';

  return (
    <Badge
      size="small"
      text={badgeText}
      textColor={badgeTextColor}
      backgroundColor={badgeBackgroundColor}
      dotColor={badgeDotColor}
    />
  );
};

export default BadgeStatusOrCohortLabel;
