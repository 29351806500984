import {
  GarminConnectTriggerIntegrationAvailableActivityGroupsSchema,
  GarminConnectTriggerIntegrationAvailableActivityTypesSchema,
} from '@cohort/shared/apps/garmin-connect/activity';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {z} from 'zod';

export const GarminConnectCompleteActivityTriggerConfigSchema = z
  .object({
    activityGroups: z
      .array(GarminConnectTriggerIntegrationAvailableActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .optional(),
    minAverageElevationGainMeters: z.number().min(0).nullable(),
    minAverageSpeedMetersPerSecond: z.number().min(0).nullable(),
    minDistanceMeters: z.number().min(0).nullable(),
    minDurationSeconds: z.number().min(0).nullable(),
    activityEndDateTimestamp: z.number(),
    activityStartDateTimestamp: z.number(),
  })
  .superRefine(
    startDateBeforeEndDateRefinement({
      startDatePropertyName: 'activityStartDateTimestamp',
      endDatePropertyName: 'activityEndDateTimestamp',
    })
  );

export type GarminConnectCompleteActivityTriggerConfig = z.infer<
  typeof GarminConnectCompleteActivityTriggerConfigSchema
>;

export const GarminConnectCompleteActivityVerificationAttemptDataSchema = z
  .object({
    activityType: GarminConnectTriggerIntegrationAvailableActivityTypesSchema,
    activityId: z.number().nullable(),
    activityStartDate: z.date().nullable(),
    averageSpeedMetersPerSecond: z.number().min(0).nullable(),
    distanceMeters: z.number().min(0).nullable(),
    durationSeconds: z.number().min(0).nullable(),
    totalElevationGainMeters: z.number().min(0).nullable(),
    garminConnectUserId: z.string(),
    garminConnectUsername: z.string(),
  })
  .nullable();

export type GarminConnectCompleteActivityVerificationAttemptData = z.infer<
  typeof GarminConnectCompleteActivityVerificationAttemptDataSchema
>;

export type GarminConnectCompleteActivityTriggerStruct = TriggerIntegrationStruct<
  'garmin-connect.complete-activity',
  GarminConnectCompleteActivityTriggerConfig,
  GarminConnectCompleteActivityVerificationAttemptData
>;

export const GarminConnectCompleteActivityTriggerIntegrationSpec: TriggerIntegrationSpec<GarminConnectCompleteActivityTriggerStruct> =
  {
    id: 'garmin-connect.complete-activity' as const,
    type: 'verification',
    configurableMaxAttempts: false,
    configSchema: GarminConnectCompleteActivityTriggerConfigSchema,
    verificationAttemptDataSchema: GarminConnectCompleteActivityVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
  };
