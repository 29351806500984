import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import {useSalesforceUserObjects} from '@cohort/merchants/apps/salesforce/actions';
import ImportUsersEmailFieldSelect from '@cohort/merchants/apps/salesforce/sync/ImportUsersEmailFieldSelect';
import ImportUsersFields from '@cohort/merchants/apps/salesforce/sync/ImportUsersFields';
import ImportUsersObjectNameSelect from '@cohort/merchants/apps/salesforce/sync/ImportUsersObjectNameSelect';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import LoadingState from '@cohort/merchants/components/LoadingState';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useTranslation} from 'react-i18next';

type ImportUsersConfigProps = SyncConfigComponentProps;
const ImportUsersConfig: React.FC<ImportUsersConfigProps> = ({connection}) => {
  const merchant = useCurrentMerchant();

  const {setValue, register, control} =
    useCohortForm<Extract<SyncConfigFormValues, {appId: 'salesforce'}>>();

  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.importUsersConfig',
  });

  const {data: getUserObjectsResponse, isFetched: isSalesforceUserObjectsFetched} =
    useSalesforceUserObjects(merchant.id, connection.id);
  const salesforceUserObjects = getUserObjectsResponse?.output.objects ?? [];

  if (!isSalesforceUserObjectsFetched) {
    return <LoadingState className="bg-white" />;
  }

  return (
    <div className="flex flex-col space-y-4">
      <ImportUsersObjectNameSelect salesforceUserObjects={salesforceUserObjects} />
      <ImportUsersEmailFieldSelect salesforceUserObjects={salesforceUserObjects} />
      <RadioCards
        name="userSyncConfig.createUsers"
        label={t('labelUserGroup')}
        register={register}
        control={control}
        options={[
          {label: t('labelAllUsers'), value: 'true'},
          {label: t('labelExistingUsers'), value: 'false'},
        ]}
        onChange={e => {
          setValue('userSyncConfig.createUsers', e.target.value === 'true');
        }}
        preventFieldOnChange
      />
      <ImportUsersFields salesforceUserObjects={salesforceUserObjects} />
    </div>
  );
};

export default ImportUsersConfig;
