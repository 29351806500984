import type {
  CreateSyncDataADto,
  ListSyncsParamsADto,
  SyncADto,
  UpdateSyncDataADto,
} from '@cohort/admin-schemas/sync';
import {ListSyncsResponseASchema, SyncASchema} from '@cohort/admin-schemas/sync';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function getSyncById(merchantId: string, syncId: string): Promise<SyncADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/syncs/${syncId}`, {
    expect: HttpCodes.SUCCESS,
    parser: SyncASchema.parse,
  });
}

export async function listSyncs(
  merchantId: string,
  params?: ListSyncsParamsADto
): Promise<SyncADto[]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/syncs`, {
    expect: HttpCodes.SUCCESS,
    parser: ListSyncsResponseASchema.parse,
    params,
  });
}

export async function createSync(merchantId: string, data: CreateSyncDataADto): Promise<SyncADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/syncs`, {
    expect: HttpCodes.CREATED,
    parser: SyncASchema.parse,
    body: data,
  });
}

export async function patchSync(
  merchantId: string,
  syncId: string,
  data: UpdateSyncDataADto
): Promise<SyncADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/syncs/${syncId}`, {
    expect: HttpCodes.SUCCESS,
    parser: SyncASchema.parse,
    body: data,
  });
}

export async function pauseSync(merchantId: string, syncId: string): Promise<object> {
  return apiCall('POST', `/v1/merchants/${merchantId}/syncs/${syncId}/pause`, {
    expect: HttpCodes.CREATED,
    parser: () => ({}),
  });
}

export async function resumeSync(merchantId: string, syncId: string): Promise<object> {
  return apiCall('POST', `/v1/merchants/${merchantId}/syncs/${syncId}/resume`, {
    expect: HttpCodes.CREATED,
    parser: () => ({}),
  });
}

export async function deleteSync(merchantId: string, syncId: string): Promise<object> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/syncs/${syncId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}
