import {useApps} from '@cohort/merchants/apps/useApps';
import Header from '@cohort/merchants/components/Header';
import type {AppId} from '@cohort/shared/apps';
import React from 'react';

type ConnectionHeaderProps = {
  appId: AppId;
  title: React.ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
};

const ConnectionHeader: React.FC<ConnectionHeaderProps> = ({appId, title, actions}) => {
  const {getApp} = useApps();
  const app = getApp(appId);

  const titleWithLogo = (
    <div className="flex items-center gap-4">
      {app && (
        <div className="flex h-12 w-12 items-center justify-center rounded-md bg-slate-100">
          {React.cloneElement(app.logo, {height: 32, width: 32})}
        </div>
      )}
      <div className="text-base font-medium leading-5">{title}</div>
    </div>
  );
  return <Header title={titleWithLogo} actions={actions} />;
};
export default ConnectionHeader;
