import Button from '@cohort/merchants/components/buttons/Button';
import {Dialog, DialogContent, DialogTitle} from '@cohort/merchants/components/modals/Dialog';
import {Info} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

interface Props {
  show: boolean;
  onClose: () => void;
}

const TestModeModal: React.FC<Props> = ({show, onClose}) => {
  const {t} = useTranslation('components', {keyPrefix: 'testMode.testModeModal'});

  return (
    <Dialog open={show} onOpenChange={() => onClose()}>
      <DialogContent>
        <div className="max-w-[40rem] p-8">
          <DialogTitle className="flex flex-col items-center gap-4 text-center text-2xl font-bold">
            <div className="rounded-full bg-slate-100 p-2 text-slate-500">
              <Info className="h-8 w-8" />
            </div>
            {t('modalTitle')}
          </DialogTitle>
          <div className="mt-4">
            <p className="text-center text-sm text-slate-500">{t('modalDescription')}</p>
            <Button type="button" className="mt-4 w-full" onClick={() => onClose()}>
              OK
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TestModeModal;
