import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {
  getPerkCampaignsRoute,
  getPerkEditRoute,
  getPerkNotificationsRoute,
  getPerkOwersRoute,
} from '@cohort/merchants/lib/Pages';
import {Bell, Gear, ListMagnifyingGlass, Megaphone} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const PerkPageMenu = (): JSX.Element => {
  const perk = useCurrentPerk();
  const {t} = useTranslation('pages', {keyPrefix: 'perks.perk.perkPageMenu'});

  return (
    <AsidePageMenu
      entries={[
        ...(perk.status !== 'draft'
          ? [
              {
                name: t('labelOwners'),
                path: getPerkOwersRoute(perk.id).path,
                icon: <ListMagnifyingGlass size={20} />,
                testId: 'perk-owners-link',
              },
              {
                name: t('labelCampaigns'),
                path: getPerkCampaignsRoute(perk.id).path,
                icon: <Megaphone size={20} />,
                testId: 'perk-campaigns-link',
              },
              {
                name: t('labelNotifications'),
                path: getPerkNotificationsRoute(perk.id).path,
                icon: <Bell size={20} />,
                testId: 'perk-notifications-link',
              },
            ]
          : []),
        {
          name: t('labelSettings'),
          path: getPerkEditRoute(perk.id).path,
          icon: <Gear size={20} />,
          testId: 'settings-link',
          anchors: [
            {
              name: t('titleAppearance'),
              value: 'public_appearance',
            },
            {
              name: t('titleAccess'),
              value: 'access',
            },
            {
              name: t('titlePrivateContent'),
              value: 'private_content',
            },
            {
              name: t('titleIntegration'),
              value: 'integration',
            },
          ],
        },
      ]}
    />
  );
};

export default PerkPageMenu;
