import type {App} from '@cohort/merchants/apps';
import {useApps} from '@cohort/merchants/apps/useApps';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import type {AppId} from '@cohort/shared/apps';
import {AppIdSchema} from '@cohort/shared/apps';
import type {AppSpecWithMerchantConnector, AppStruct} from '@cohort/shared/apps/app';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentAppProviderProps = {
  children: React.ReactNode;
};

export type CurrentAppContextValue = {
  appId: AppId;
  app: App<AppStruct>;
  appSpec: AppSpecWithMerchantConnector;
};

export const CurrentAppContext = createContext<CurrentAppContextValue | null>(null);

export const CurrentAppProvider: React.FC<CurrentAppProviderProps> = ({children}) => {
  const {appId} = useParams();
  const {getApp} = useApps();
  const app = getApp(appId);

  if (
    appId === undefined ||
    AppIdSchema.safeParse(appId).success === false ||
    app === null ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    app.spec.merchantConnector === null
  ) {
    return <NotFoundSection />;
  }

  const appSpec = app.spec as unknown as AppSpecWithMerchantConnector;

  return (
    <CurrentAppContext.Provider value={{appId: appSpec.id as AppId, app, appSpec}}>
      {children}
    </CurrentAppContext.Provider>
  );
};
