import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useBadgePageStore} from '@cohort/merchants/hooks/stores/badgePage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import PageOutlet from '@cohort/merchants/layouts/PageOutlet';
import BadgePageMenu from '@cohort/merchants/pages/users/badges/badge/BadgePageMenu';
import BadgePageBreadcrumb from '@cohort/merchants/pages/users/badges/BadgePageBreadcrumb';
import {CurrentBadgeProvider} from '@cohort/merchants/pages/users/cohort/CurrentBadgeContext';

const BadgePageSkeleton: React.FC = () => {
  const badge = useCurrentBadge();
  const footer = useBadgePageStore(store => store.footer);

  return (
    <PageLayout
      title={badge.internalName}
      breadcrumb={<BadgePageBreadcrumb />}
      menu={<BadgePageMenu />}
      footer={footer}
    >
      <PageOutlet />
    </PageLayout>
  );
};

const BadgePageLayout = (): JSX.Element => (
  <CurrentBadgeProvider>
    <BadgePageSkeleton />
  </CurrentBadgeProvider>
);

export default BadgePageLayout;
