/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {
  GetUserAnalyticsParamsADto,
  GetUserBadgesParamsADto,
  GetUserCohortsParamsADto,
} from '@cohort/admin-schemas/userAnalytics';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getUser, getUserBadges, getUserCohorts, getUsers} from '@cohort/merchants/lib/api/Users';

export const usersKeys = {
  users: ['users'] as const,
  getById: (merchantId: string, userId: string) =>
    [...usersKeys.users, merchantId, userId] as const,
  list: (merchantId: string, params: GetUserAnalyticsParamsADto) =>
    [...usersKeys.users, merchantId, 'listUsers', params] as const,
  getUserCohorts: (merchantId: string, userId: string, params?: GetUserCohortsParamsADto) =>
    [...usersKeys.getById(merchantId, userId), 'listUserCohorts', params] as const,
  getUserBadges: (merchantId: string, userId: string, params?: GetUserBadgesParamsADto) =>
    [...usersKeys.getById(merchantId, userId), 'listUserBadges', params] as const,
};

export const useUser = (userId: string, merchantId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: usersKeys.getById(merchantId, userId),
    queryFn: async () => getUser(merchantId, userId),
    ...options,
    refetchInterval: options?.autoRefresh === true ? 5000 : false,
  });

export const useUsers = (
  merchantId: string,
  params: GetUserAnalyticsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: usersKeys.list(merchantId, params),
    queryFn: async () => getUsers(merchantId, params),
    ...options,
    refetchInterval: options?.autoRefresh === true ? 5000 : false,
  });

export const useUserCohorts = (
  merchantId: string,
  userId: string,
  params: GetUserCohortsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: usersKeys.getUserCohorts(merchantId, userId, params),
    queryFn: async () => getUserCohorts(merchantId, userId, params),
    ...options,
  });

export const useUserBadges = (
  merchantId: string,
  userId: string,
  params: GetUserBadgesParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: usersKeys.getUserBadges(merchantId, userId, params),
    queryFn: async () => getUserBadges(merchantId, userId, params),
    ...options,
  });
