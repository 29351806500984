import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import {StravaBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/strava/triggers/beActive';
import {StravaCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/strava/triggers/completeActivity';
import type {StravaConnectorStruct} from '@cohort/shared/apps/strava/userConnector';
import {StravaConnectorSpec} from '@cohort/shared/apps/strava/userConnector';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import {createConnectAccountTriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {
  createAppUsernameUserPropertySpec,
  createBaseAppUserPropertySpec,
} from '@cohort/shared/apps/userProperty';

export const STRAVA_APP_ID = 'strava' as const;
export const STRAVA_APP_NAME = 'Strava' as const;

export const StravaTriggerIntegrationSpecs = [
  createConnectAccountTriggerIntegrationSpec(STRAVA_APP_ID),
  StravaBeActiveTriggerIntegrationSpec,
  StravaCompleteActivityTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;

type StravaTriggerIntegrationIds = (typeof StravaTriggerIntegrationSpecs)[number]['id'];

export const StravaUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(STRAVA_APP_ID, STRAVA_APP_NAME),
  createAppUsernameUserPropertySpec(STRAVA_APP_ID, STRAVA_APP_NAME),
  {
    id: 'strava.firstname',
    dataType: 'string',
    name: 'Strava First Name',
    scope: 'profile',
    profileKey: 'firstName',
  },
  {
    id: 'strava.lastname',
    dataType: 'string',
    name: 'Strava Last Name',
    scope: 'profile',
    profileKey: 'lastName',
  },
  {
    id: 'strava.gender',
    dataType: 'string',
    name: 'Strava Gender',
    scope: 'profile',
    profileKey: 'gender',
  },
  {
    id: 'strava.member-since',
    dataType: 'date',
    name: 'Strava Member Since',
    scope: 'profile',
    profileKey: 'memberSince',
  },
  {
    id: 'strava.is-premium',
    dataType: 'boolean',
    name: 'Strava Is Premium Subscriber',
    scope: 'profile',
    profileKey: 'isPremium',
  },
  {
    id: 'strava.city',
    dataType: 'string',
    name: 'Strava City',
    scope: 'profile',
    profileKey: 'city',
  },
  {
    id: 'strava.state',
    dataType: 'string',
    name: 'Strava State',
    scope: 'profile',
    profileKey: 'state',
  },
  {
    id: 'strava.country',
    dataType: 'string',
    name: 'Strava Country',
    scope: 'profile',
    profileKey: 'country',
  },
  {
    id: 'strava.followers-count',
    dataType: 'number',
    name: 'Strava Number of Followers',
    scope: 'profile',
    profileKey: 'followersCount',
  },
  {
    id: 'strava.friends-count',
    dataType: 'number',
    name: 'Strava Number of Friends',
    scope: 'profile',
    profileKey: 'friendsCount',
  },
  {
    id: 'strava.total-rides-count',
    dataType: 'number',
    name: 'Strava Total number of Rides',
    scope: 'profile',
    profileKey: 'totalRidesCount',
  },
  {
    id: 'strava.total-rides-distance',
    dataType: 'number',
    name: 'Strava Total Rides Distance',
    scope: 'profile',
    profileKey: 'totalRidesDistance',
  },
  {
    id: 'strava.total-runs-count',
    dataType: 'number',
    name: 'Strava Total number of Runs',
    scope: 'profile',
    profileKey: 'totalRunsCount',
  },
  {
    id: 'strava.total-runs-distance',
    dataType: 'number',
    name: 'Strava Total Runs Distance',
    scope: 'profile',
    profileKey: 'totalRunsDistance',
  },
  {
    id: 'strava.total-swims-count',
    dataType: 'number',
    name: 'Strava Total number of Swims',
    scope: 'profile',
    profileKey: 'totalSwimsCount',
  },
  {
    id: 'strava.total-swims-distance',
    dataType: 'number',
    name: 'Strava Total Swims Distance',
    scope: 'profile',
    profileKey: 'totalSwimsDistance',
  },
  {
    id: 'strava.ytd-rides-count',
    dataType: 'number',
    name: 'Strava Year to Date number of Rides',
    scope: 'profile',
    profileKey: 'ytdRidesCount',
  },
  {
    id: 'strava.ytd-rides-distance',
    dataType: 'number',
    name: 'Strava Year to Date Rides Distance',
    scope: 'profile',
    profileKey: 'ytdRidesDistance',
  },
  {
    id: 'strava.ytd-runs-count',
    dataType: 'number',
    name: 'Strava Year to Date number of Runs',
    scope: 'profile',
    profileKey: 'ytdRunsCount',
  },
  {
    id: 'strava.ytd-runs-distance',
    dataType: 'number',
    name: 'Strava Year to Date Runs Distance',
    scope: 'profile',
    profileKey: 'ytdRunsDistance',
  },
  {
    id: 'strava.ytd-swims-count',
    dataType: 'number',
    name: 'Strava Year to Date number of Swims',
    scope: 'profile',
    profileKey: 'ytdSwimsCount',
  },
  {
    id: 'strava.ytd-swims-distance',
    dataType: 'number',
    name: 'Strava Year to Date Swims Distance',
    scope: 'profile',
    profileKey: 'ytdSwimsDistance',
  },
  {
    id: 'strava.recent-rides-count',
    dataType: 'number',
    name: 'Strava Recent number of Rides',
    scope: 'profile',
    profileKey: 'recentRideCount',
  },
  {
    id: 'strava.recent-rides-distance',
    dataType: 'number',
    name: 'Strava Recent Rides Distance',
    scope: 'profile',
    profileKey: 'recentRideDistance',
  },
  {
    id: 'strava.recent-runs-count',
    dataType: 'number',
    name: 'Strava Recent number of Runs',
    scope: 'profile',
    profileKey: 'recentRunCount',
  },
  {
    id: 'strava.recent-runs-distance',
    dataType: 'number',
    name: 'Strava Recent Runs Distance',
    scope: 'profile',
    profileKey: 'recentRunDistance',
  },
  {
    id: 'strava.recent-swims-count',
    dataType: 'number',
    name: 'Strava Recent number of Swims',
    scope: 'profile',
    profileKey: 'recentSwimCount',
  },
  {
    id: 'strava.recent-swims-distance',
    dataType: 'number',
    name: 'Strava Recent Swims Distance',
    scope: 'profile',
    profileKey: 'recentSwimDistance',
  },
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type StravaUserPropertyIds = (typeof StravaUserPropertiesSpecs)[number]['id'];

export type StravaAppStruct = AppStruct<
  'strava',
  null,
  StravaConnectorStruct,
  null,
  never,
  never,
  never,
  StravaTriggerIntegrationIds,
  never,
  StravaUserPropertyIds,
  never,
  never
>;

// TODO: try to get rid of this
export const StravaAppSpec: AppSpec<StravaAppStruct> = {
  id: STRAVA_APP_ID,
  name: STRAVA_APP_NAME,
  category: 'health-fitness',
  merchantConnector: null,
  userConnector: StravaConnectorSpec,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: StravaTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: StravaUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
};
