import {z} from 'zod';

export const BaseUserEventExportConfigSchema = z.object({
  allUserEventIds: z.boolean(), // Whether to export all user events
  userEventIds: z.array(z.string()), // The list of all user event ids that should be exported. We cannot use UserEventIdSchema type because of circular dependencies
});
export type BaseUserEventExportConfig = z.infer<typeof BaseUserEventExportConfigSchema>;

export type SyncIntegrationStruct<
  UsersSyncConfig = unknown | never,
  UsersSyncState = unknown | never,
  ContentSyncConfig = unknown | never,
  UserEventExportConfig = unknown | never,
  UserExportConfig = unknown | never,
  UserExportState = unknown | never,
> = {
  UserSyncConfig: UsersSyncConfig;
  UserSyncState: UsersSyncState;
  ContentSyncConfig: ContentSyncConfig;
  UserEventExportConfig: UserEventExportConfig;
  UserExportConfig: UserExportConfig;
  UserExportState: UserExportState;
};

export type SyncIntegrationSpec<T extends SyncIntegrationStruct = SyncIntegrationStruct> = {
  userSyncsConfigSchema: z.ZodSchema<T['UserSyncConfig']> | null;
  userSyncsStateSchema: z.ZodSchema<T['UserSyncState']> | null;
  contentSyncConfigSchema: z.ZodSchema<T['ContentSyncConfig']> | null;
  userEventExportConfigSchema: z.ZodSchema<T['UserEventExportConfig']> | null;
  userExportConfigSchema: z.ZodSchema<T['UserExportConfig']> | null;
  userExportStateSchema: z.ZodSchema<T['UserExportState']> | null;
};
