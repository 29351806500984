import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const TestQueryInputSchema = z.object({query: z.string()});
export type TestQueryInput = z.infer<typeof TestQueryInputSchema>;

export const TestQueryOutputSchema = z.object({
  error: z.string().optional(),
  rows: z.array(z.record(z.string(), z.unknown())),
});
export type TestQueryOutput = z.infer<typeof TestQueryOutputSchema>;

export type TestQueryActionStruct = ActionStruct<'test-query', TestQueryInput, TestQueryOutput>;
export const TestQueryActionSpec: ActionSpec<TestQueryActionStruct> = {
  id: 'test-query',
  inputSchema: TestQueryInputSchema,
  outputSchema: TestQueryOutputSchema,
};
