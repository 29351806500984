import type {CohortWithBadgeADto} from '@cohort/admin-schemas/cohort';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useCohort} from '@cohort/merchants/hooks/api/Cohorts';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import SkeletonLoader from '@cohort/merchants/layouts/SkeletonLoader';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentCohortProviderProps = {
  children: React.ReactNode;
};

export const CurrentCohortContext = createContext<CohortWithBadgeADto | null>(null);

export const CurrentCohortProvider: React.FC<CurrentCohortProviderProps> = ({children}) => {
  const merchant = useCurrentMerchant();
  const {cohortId} = useParams();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: cohort, isFetched} = useCohort(merchant.id, cohortId!);

  if (cohortId === undefined) {
    return <NotFoundSection />;
  }

  if (isFetched && !cohort) {
    return <NotFoundSection />;
  }

  if (cohort === undefined) {
    return <SkeletonLoader />;
  }

  return <CurrentCohortContext.Provider value={cohort}>{children}</CurrentCohortContext.Provider>;
};
