/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {CampaignADto, RedeemCodeADto} from '@cohort/admin-schemas/campaign';
import type {DigitalAssetCollectionADto} from '@cohort/admin-schemas/digitalAssetCollection';
import type {OrganizationADto} from '@cohort/admin-schemas/organization';
import type {OrganizationMemberADto} from '@cohort/admin-schemas/organizationMember';
import type {PerkADto} from '@cohort/admin-schemas/perk';
import {ampli} from '@cohort/merchants/ampli';
import type {FilterOption} from '@cohort/merchants/components/filters/Filters';
import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import type {PageName} from '@cohort/merchants/lib/Pages';
import type {BackgroundColorOptionKey} from '@cohort/merchants/pages/settings/membership-pass-configuration/page';
import type {PerkIntegrationId} from '@cohort/shared/apps';
import {uuidv4} from '@firebase/util';
import {
  captureException,
  getCurrentScope,
  init,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import {useEffect} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {z} from 'zod';

const UntrackedErrorSchema = z.object({
  code: z.enum([
    'auth/cancelled-popup-request',
    'auth/internal-error',
    'auth/invalid-login-credentials',
    'auth/popup-closed-by-user',
    'auth/too-many-requests',
    'auth/user-not-found',
    'auth/wrong-password',
  ]),
});

const COHORT_ENV = import.meta.env.COHORT_ENV;

let TRACE_ID: string | undefined;

export const getTraceId = (): string => {
  if (TRACE_ID === undefined) {
    TRACE_ID = uuidv4();
  }
  return TRACE_ID;
};

export const getAmplitudeSessionId = (): number | null | undefined => {
  return ampli.client.getSessionId();
};

const initAmplitude = (userId?: string): void => {
  ampli.load({
    client: {
      apiKey: import.meta.env.COHORT_AMPLITUDE_API_KEY_EU,
      configuration: {
        defaultTracking: false,
        serverUrl: 'https://amp-eu.getcohort.com/2/httpapi',
        optOut: COHORT_ENV === 'development',
      },
    },
  });
  ampli.identify(userId, {
    traceId: getTraceId(),
  });
};

const initSentry = (): void => {
  init({
    dsn: import.meta.env.COHORT_SENTRY_DSN,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      // uncomment me one day if we pay for Session Replay
      // new Replay(),
    ],
    environment: COHORT_ENV,
    autoSessionTracking: true,
    release: GIT_COMMIT_SHA,
    debug: false,
    tracesSampleRate: COHORT_ENV === 'prod' ? 0.1 : 1,
    // uncomment me one day if we pay for Session Replay
    // replaysOnErrorSampleRate: 1,
    // replaysSessionSampleRate: 0.1,
    enabled: COHORT_ENV !== 'development',
    // uncomment me to test Sentry in development
    // enabled: true,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (UntrackedErrorSchema.safeParse(error).success) {
        return null;
      }
      return event;
    },
  });
  getCurrentScope().setTag('trace_id', getTraceId());
};

export const trackError = (err: unknown): void => {
  if (COHORT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(err);
  } else {
    captureException(err);
  }
};

export const initTracking = (userId?: string): void => {
  initAmplitude(userId);
  initSentry();
};

export const setAmplitudeMerchantUser = (
  organizationMember: OrganizationMemberADto | undefined
): void => {
  ampli.identify(organizationMember?.id, {
    traceId: getTraceId(),
  });
};

const getCampaignProperties = (campaign: CampaignADto) => {
  return {
    campaignId: campaign.id,
    campaignSlug: campaign.store?.slug ?? undefined,
    campaignStatus: campaign.status,
  };
};

const getDigitalAssetCollectionProperties = (
  digitalAssetCollection: DigitalAssetCollectionADto
) => {
  return {
    digitalAssetCollectionId: digitalAssetCollection.id,
    digitalAssetCollectionTitle: digitalAssetCollection.internalName,
  };
};

const getDefaultProperties = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const organization = useUserSessionStore.getState().organization!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore.getState().merchantId!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantSlug = useUserSessionStore.getState().merchantSlug!;
  const testModeEnabled = useTestModeStore.getState().testModeEnabled;

  return {
    organizationId: organization.id,
    organizationName: organization.name,
    merchantId,
    merchantSlug,
    mode: testModeEnabled ? 'test' : 'live',
  };
};

const getPerkProperties = (perk: PerkADto) => {
  return {
    perkId: perk.id,
    perkName: perk.internalName,
    perkStatus: perk.status,
    perkIntegrationId: perk.integration?.perkIntegrationId,
    perkType: perk.type,
  };
};

const getRedeemCodeProperties = (redeemCode: RedeemCodeADto) => {
  return {
    redeemCodeId: redeemCode.id,
    campaignId: redeemCode.campaignId,
    redeemCodeCode: redeemCode.code,
    redeemCodeStatus: redeemCode.status,
  };
};

interface SignInProperties {
  signinMethod: 'google' | 'emailLink' | 'saml' | 'emailPassword';
}

interface ErrorProperties {
  errorReason?: string;
  errorCode?: string;
}

export const trackBrandSettingsPageViewed = (): void => {
  ampli.brandSettingsPageViewed(getDefaultProperties());
};

export const trackApiSettingsPageViewed = (): void => {
  ampli.apiSettingsPageViewed(getDefaultProperties());
};

export const trackPaymentSettingsPageViewed = (): void => {
  ampli.paymentSettingsPageViewed(getDefaultProperties());
};

export const trackBrandSettingsSaveButtonClicked = (): void => {
  ampli.brandSettingsSaveButtonClicked(getDefaultProperties());
};

export const trackBreadcrumbBackButtonClicked = (
  pageName: string,
  campaign?: CampaignADto,
  perk?: PerkADto,
  digitalAssetCollection?: DigitalAssetCollectionADto
): void => {
  ampli.breadcrumbBackButtonClicked({
    pageName,
    ...(campaign && getCampaignProperties(campaign)),
    ...(perk && getPerkProperties(perk)),
    ...(digitalAssetCollection && getDigitalAssetCollectionProperties(digitalAssetCollection)),
  });
};

export const trackCampaignCreateButtonClicked = (): void => {
  ampli.campaignCreateButtonClicked(getDefaultProperties());
};

export const trackCampaignCreateSuccess = (campaign: CampaignADto): void => {
  ampli.campaignCreateSuccess({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignDropdownClicked = (campaign: CampaignADto): void => {
  ampli.campaignDropdownClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignDropdownArchiveClicked = (campaign: CampaignADto): void => {
  ampli.campaignDropdownArchiveClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignDropdownVisitPageClicked = (campaign: CampaignADto): void => {
  ampli.campaignDropdownVisitPageClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignDropdownUnarchiveClicked = (campaign: CampaignADto): void => {
  ampli.campaignDropdownUnarchiveClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignEditDominantBgColorButtonClicked = (campaign: CampaignADto): void => {
  ampli.campaignEditDominantBgColorButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignEditPageViewed = (campaign: CampaignADto): void => {
  ampli.campaignEditPageViewed({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignEditImportBrandSpaceColorsBtnClicked = (campaign: CampaignADto): void => {
  ampli.campaignEditImportBrandSpaceColorsButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignOrdersPageViewed = (campaign: CampaignADto): void => {
  ampli.campaignOrdersPageViewed({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPerksAssociationsModalOpened = (campaign: CampaignADto): void => {
  ampli.campaignPerksAssociationsModalOpened({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPerksAssociationsModalClosed = (campaign: CampaignADto): void => {
  ampli.campaignPerksAssociationsModalClosed({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPerksAssociationsModalSubmit = (campaign: CampaignADto): void => {
  ampli.campaignPerksAssociationsModalSubmit({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPerksAssociationsModalSortingChanged = (
  campaign: CampaignADto,
  orderBy: string
): void => {
  ampli.campaignPerksAssociationsModalSortingChanged({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
    orderBy,
  });
};

export const trackCampaignPerksAssociationsModalFilteringChanged = (
  campaign: CampaignADto,
  filters: string[]
): void => {
  ampli.campaignPerksAssociationsModalFilteringChanged({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
    filters,
  });
};

export const trackCampaignPerksPageViewed = (campaign: CampaignADto): void => {
  ampli.campaignPerksPageViewed({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPerksReordered = (campaign: CampaignADto): void => {
  ampli.campaignPerksReordered({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPerksSaveButtonClicked = (campaign: CampaignADto): void => {
  ampli.campaignPerksSaveButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPreviewModeButtonClicked = (
  campaign: CampaignADto,
  previewMode: 'web' | 'mobile'
): void => {
  ampli.campaignPreviewModeButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
    previewMode,
  });
};

export const trackCampaignPreviewModalClosed = (campaign: CampaignADto): void => {
  ampli.campaignPreviewModalClosed({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPreviewModalOpened = (campaign: CampaignADto): void => {
  ampli.campaignPreviewModalOpened({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignPublishButtonClicked = (campaign: CampaignADto): void => {
  ampli.campaignPublishButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignRedeemLinksPageViewed = (campaign: CampaignADto): void => {
  ampli.campaignRedeemLinksPageViewed({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignReviewAndPublishButtonClicked = (campaign: CampaignADto): void => {
  ampli.campaignReviewAndPublishButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignReviewAndPublishModalOpened = (campaign: CampaignADto): void => {
  ampli.campaignReviewAndPublishModalOpened({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignReviewAndPublishModalClosed = (campaign: CampaignADto): void => {
  ampli.campaignReviewAndPublishModalClosed({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignReviewAndPublishPreviewModeButtonClicked = (
  campaign: CampaignADto,
  previewMode: 'web' | 'mobile'
): void => {
  ampli.campaignReviewAndPublishPreviewModeButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
    previewMode,
  });
};

export const trackCampaignSaveButtonClicked = (campaign: CampaignADto): void => {
  ampli.campaignSaveButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignsOverviewPageViewed = (): void => {
  ampli.campaignsOverviewPageViewed({
    ...getDefaultProperties(),
  });
};

export const trackCampaignsSortingChanged = (orderBy: string): void => {
  ampli.campaignsSortingChanged({
    ...getDefaultProperties(),
    orderBy,
  });
};

export const trackCampaignsStatusFilterChanged = (filters: FilterOption[]): void => {
  ampli.campaignsStatusFilterChanged({
    ...getDefaultProperties(),
    filters: filters.map(filter => filter.value),
  });
};

export const trackCampaignsTypeFilterChanged = (filters: FilterOption[]): void => {
  ampli.campaignsTypeFilterChanged({
    ...getDefaultProperties(),
    filters: filters.map(filter => filter.value),
  });
};

export const trackChangeOrganizationButtonClicked = (organization: OrganizationADto): void => {
  ampli.changeOrganizationButtonClicked({
    ...getDefaultProperties(),
    organizationId: organization.id,
    organizationName: organization.name,
  });
};

export const trackDigitalAssetCollectionCreateSuccess = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionCreateSuccess({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionDuplicateSuccess = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionDuplicateSuccess({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionDeleteSuccess = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionDeleteSuccess({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionDropdownClicked = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionDropdownClicked({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionDropdownDeleteClicked = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionDropdownDeleteClicked({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionDropdownDuplicateClicked = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionDropdownDuplicateClicked({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionsOverviewPageViewed = (): void => {
  ampli.digitalAssetCollectionsOverviewPageViewed({
    ...getDefaultProperties(),
  });
};

export const trackDigitalAssetCollectionCreateButtonClicked = (): void => {
  ampli.digitalAssetCollectionCreateButtonClicked({
    ...getDefaultProperties(),
  });
};

export const trackDigitalAssetCollectionsStatusFilterChanged = (
  selectedStatuses: string[]
): void => {
  ampli.digitalAssetCollectionsStatusFilterChanged({
    ...getDefaultProperties(),
    selectedStatuses: selectedStatuses.join(','),
  });
};

export const trackDigitalAssetCollectionsSortingChanged = (orderBy: string): void => {
  ampli.digitalAssetCollectionsSortingChanged({
    ...getDefaultProperties(),
    orderBy,
  });
};

export const trackDigitalAssetCollectionsDisplayChanged = (display: string): void => {
  ampli.digitalAssetCollectionsDisplayChanged({
    ...getDefaultProperties(),
    display,
  });
};

export const trackDigitalAssetCollectionPerkAdded = (
  digitalAssetCollection: DigitalAssetCollectionADto,
  perk: PerkADto
): void => {
  ampli.digitalAssetCollectionPerkAdded({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
    ...getPerkProperties(perk),
  });
};

export const trackDigitalAssetCollectionPerkRemoved = (
  digitalAssetCollection: DigitalAssetCollectionADto,
  perk: PerkADto
): void => {
  ampli.digitalAssetCollectionPerkRemoved({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
    ...getPerkProperties(perk),
  });
};

export const trackDigitalAssetCollectionPerksReordered = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionPerksReordered({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionPerksAssociationsModalOpened = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionPerksAssociationsModalOpened({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionPerksAssociationsModalClosed = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionPerksAssociationsModalClosed({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionPerksAssociationsModalSortingChanged = (
  digitalAssetCollection: DigitalAssetCollectionADto,
  orderBy: string
): void => {
  ampli.digitalAssetCollectionPerksAssociationsModalSortingChanged({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
    orderBy,
  });
};

export const trackDigitalAssetCollectionPerksAssociationsModalAppFilterChanged = (
  digitalAssetCollection: DigitalAssetCollectionADto,
  filters: Array<string>
): void => {
  ampli.digitalAssetCollectionPerksAssociationsModalAppFilterChanged({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
    filters,
  });
};

export const trackDigitalAssetCollectionPerksAssociationsModalTypeFilterChanged = (
  digitalAssetCollection: DigitalAssetCollectionADto,
  filters: Array<string>
): void => {
  ampli.digitalAssetCollectionPerksAssociationsModalTypeFilterChanged({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
    filters,
  });
};

export const trackDigitalAssetCollectionPerksAssociationsModalSubmit = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionPerksAssociationsModalSubmit({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionPerksPageViewed = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionPerksPageViewed({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionPerksSaveButtonClicked = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionPerksSaveButtonClicked({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionDetailsPageViewed = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionDetailsPageViewed({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackDigitalAssetCollectionDetailsSaveButtonClicked = (
  digitalAssetCollection: DigitalAssetCollectionADto
): void => {
  ampli.digitalAssetCollectionDetailsSaveButtonClicked({
    ...getDefaultProperties(),
    ...getDigitalAssetCollectionProperties(digitalAssetCollection),
  });
};

export const trackExportOrdersError = (campaign: CampaignADto, error?: ErrorProperties): void => {
  ampli.exportOrdersError({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
    ...error,
  });
};

export const trackExportOrdersSuccess = (campaign: CampaignADto): void => {
  ampli.exportOrdersSuccess({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackExportPerkAnalyticsSuccess = (perk: PerkADto): void => {
  ampli.exportPerkAnalyticsSuccess({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackExportRedeemCodesSuccess = (campaign: CampaignADto): void => {
  ampli.exportRedeemCodesSuccess({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackExportRedeemCodesError = (campaign: CampaignADto): void => {
  ampli.exportRedeemCodesError({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackInvitationActivationError = (error?: ErrorProperties): void => {
  ampli.invitationActivationError({
    ...error,
  });
};

export const trackInvitationActivationSuccess = (): void => {
  ampli.invitationActivationSuccess();
};

export const trackInvitationPageViewed = (): void => {
  ampli.invitationPageViewed();
};

export const trackMembersSettingsPageViewed = (): void => {
  ampli.membersSettingsPageViewed({
    ...getDefaultProperties(),
  });
};

export const trackNavbarLinkButtonClicked = (pageName: PageName): void => {
  ampli.navbarLinkButtonClicked({
    pageName,
  });
};

export const trackOnboardingCreateOrganizationSuccess = (organization: OrganizationADto): void => {
  ampli.onboardingCreateOrganizationSuccess({
    ...getDefaultProperties(),
    organizationId: organization.id,
    organizationName: organization.name,
  });
};

export const trackOnboardingPageViewed = (): void => {
  ampli.onboardingPageViewed();
};

export const trackOrganizationMembershipInviteButtonClicked = (): void => {
  ampli.organizationMembershipInviteButtonClicked({
    ...getDefaultProperties(),
  });
};

export const trackOrganizationMembershipReSendInvitationButtonClicked = (): void => {
  ampli.organizationMembershipReSendInvitationButtonClicked(getDefaultProperties());
};

export const trackPerkAnalyticsCampaignFilterChanged = (
  selectedCampaigns: FilterOption[]
): void => {
  const campaignsLabels = selectedCampaigns.map(campaign => campaign.label);

  ampli.perkAnalyticsCampaignFilterChanged({
    ...getDefaultProperties(),
    ...{selectedCampaignsLabels: campaignsLabels},
  });
};

export const trackPerkAnalyticsUsageFilterChanged = (selectedUsage: FilterOption): void => {
  ampli.perkAnalyticsUsageFilterChanged({
    ...getDefaultProperties(),
    ...{selectedUsage: selectedUsage.value},
  });
};

export const trackPerkCampaignsAssociationsModalOpened = (perk: PerkADto): void => {
  ampli.perkCampaignsAssociationsModalOpened({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkCampaignsAssociationsModalClosed = (perk: PerkADto): void => {
  ampli.perkCampaignsAssociationsModalClosed({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkCampaignsAssociationsModalSubmit = (perk: PerkADto): void => {
  ampli.perkCampaignsAssociationsModalSubmit({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkCampaignsAssociationsModalSortingChanged = (
  perk: PerkADto,
  orderBy: string
): void => {
  ampli.perkCampaignsAssociationsModalSortingChanged({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
    orderBy,
  });
};

export const trackPerkCampaignsAssociationsModalFilteringChanged = (
  perk: PerkADto,
  filters: FilterOption[]
): void => {
  ampli.perkCampaignsAssociationsModalFilteringChanged({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
    filters: filters.map(filter => filter.value),
  });
};

export const trackPerkCampaignsPageViewed = (perk: PerkADto): void => {
  ampli.perkCampaignsPageViewed({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkCampaignsSaveButtonClicked = (perk: PerkADto): void => {
  ampli.perkCampaignsSaveButtonClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkCreateAppFilterChanged = (perkIntegrationId?: PerkIntegrationId): void => {
  ampli.perkCreateAppFilterChanged({
    ...getDefaultProperties(),
  });
};

export const trackPerkCreateButtonClicked = (): void => {
  ampli.perkCreateButtonClicked(getDefaultProperties());
};

export const trackPerkCreateSuccess = (perk: PerkADto): void => {
  ampli.perkCreateSuccess({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkDropdownClicked = (perk: PerkADto): void => {
  ampli.perkDropdownClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkDropdownArchiveClicked = (perk: PerkADto): void => {
  ampli.perkDropdownArchiveClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkDropdownDeleteClicked = (perk: PerkADto): void => {
  ampli.perkDropdownDeleteClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkDropdownDuplicateClicked = (perk: PerkADto): void => {
  ampli.perkDropdownDuplicateClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkDropdownUnarchiveClicked = (perk: PerkADto): void => {
  ampli.perkDropdownUnarchiveClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkEditPageViewed = (perk: PerkADto): void => {
  ampli.perkEditPageViewed({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkCreatePageViewed = (): void => {
  ampli.perkCreatePageViewed(getDefaultProperties());
};

export const trackPerkPreviewButtonClicked = (perk?: PerkADto): void => {
  ampli.perkPreviewButtonClicked({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkSaveButtonClicked = (perk?: PerkADto): void => {
  ampli.perkSaveButtonClicked({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkUsagesPageViewed = (perk: PerkADto): void => {
  ampli.perkUsagesPageViewed({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkPreviewModalOpened = (perk?: PerkADto): void => {
  ampli.perkPreviewModalOpened({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkPreviewModalClosed = (perk?: PerkADto): void => {
  ampli.perkPreviewModalClosed({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkReviewAndPublishButtonClicked = (perk?: PerkADto): void => {
  ampli.perkReviewAndPublishButtonClicked({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkReviewAndPublishModalOpened = (perk?: PerkADto): void => {
  ampli.perkReviewAndPublishModalOpened({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkReviewAndPublishModalClosed = (perk?: PerkADto): void => {
  ampli.perkReviewAndPublishModalClosed({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkPublishButtonClicked = (perk: PerkADto): void => {
  ampli.perkPublishButtonClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackQrReaderDeleted = (qrReaderId: string): void => {
  ampli.qrReaderDeleted({
    ...getDefaultProperties(),
    qrReaderId,
  });
};

export const trackQrReadersCreated = (count: number, fromCsv: boolean): void => {
  ampli.qrReadersCreated({
    ...getDefaultProperties(),
    count,
    fromCsv,
  });
};

export const trackQrReadersExported = (): void => {
  ampli.qrReaderExportSuccess(getDefaultProperties());
};

export const trackQrReadersSettingsPageViewed = (): void => {
  ampli.qrReadersSettingsPageViewed(getDefaultProperties());
};

export const trackPerksListPageViewed = (): void => {
  ampli.perksListPageViewed(getDefaultProperties());
};

export const trackPerksListTablePerkClicked = (perk: PerkADto): void => {
  ampli.perksListTablePerkClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerksListTableStatusFilterChanged = (selectedStatuses: string[]): void => {
  ampli.perksListTableStatusFilterChanged({
    ...getDefaultProperties(),
    ...{selectedPerkStatuses: selectedStatuses},
  });
};

export const trackPerksListTableAppsFilterChanged = (selectedApps: string[]): void => {
  ampli.perksListTableAppsFilterChanged({
    ...getDefaultProperties(),
    selectedApps,
  });
};

export const trackPerksListTableTypesFilterChanged = (selectedTypes: string[]): void => {
  ampli.perksListTableTypesFilterChanged({
    ...getDefaultProperties(),
    selectedTypes,
  });
};

export const trackSigninError = (signin: SignInProperties, error?: ErrorProperties): void => {
  ampli.signinError({
    ...signin,
    ...error,
  });
};

export const trackSigninFormRedirect = (signin: SignInProperties): void => {
  ampli.signinFormRedirect({
    ...signin,
  });
};

export const trackSigninPageViewed = (): void => {
  ampli.signinPageViewed();
};

export const trackSigninSendLinkSuccess = (): void => {
  ampli.signinSendLinkSuccess();
};

export const trackSigninSuccess = (signin: SignInProperties): void => {
  ampli.signinSuccess({
    ...signin,
  });
};

export const trackSignoutSuccess = (): void => {
  ampli.signoutSuccess();
};

export const trackCampaignAirdropModalOpenButtonClicked = (campaign: CampaignADto): void => {
  ampli.campaignAirdropModalOpenButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignAirdropModalCloseButtonClicked = (campaign: CampaignADto): void => {
  ampli.campaignAirdropModalCloseButtonClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignAirdropSubmitted = (
  campaign: CampaignADto,
  fromCsv: boolean,
  emailsCount: number
): void => {
  ampli.campaignAirdropSubmitted({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
    fromCsv,
    emailsCount,
  });
};

export const trackCampaignAirdropModalCsvImported = (campaign: CampaignADto): void => {
  ampli.campaignAirdropModalCsvImported({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackTestModeToggleClicked = (): void => {
  ampli.testModeToggleClicked(getDefaultProperties());
};

export const trackTestModeToggleInfoButtonClicked = (): void => {
  ampli.testModeToggleInfoButtonClicked(getDefaultProperties());
};

export const trackTestModeBannerModalButtonClicked = (): void => {
  ampli.testModeBannerModalButtonClicked(getDefaultProperties());
};

export const trackPerkMetadataSettingsSeeDocLinkClicked = (perk?: PerkADto): void => {
  ampli.perkMetadataSettingsSeeDocLinkClicked({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkMetadataSettingsAddFieldBtnClicked = (perk?: PerkADto): void => {
  ampli.perkMetadataSettingsAddFieldBtnClicked({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackPerkMetadataSettingsRemoveFieldBtnClicked = (perk?: PerkADto): void => {
  ampli.perkMetadataSettingsRemoveFieldBtnClicked({
    ...getDefaultProperties(),
    ...(perk && getPerkProperties(perk)),
  });
};

export const trackAccordionClicked = (accordionName: string): void => {
  ampli.accordionClicked({
    ...getDefaultProperties(),
    accordionName,
  });
};

export const trackCampaignCardClicked = (campaign: CampaignADto): void => {
  ampli.campaignCardClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackCampaignListItemClicked = (campaign: CampaignADto): void => {
  ampli.campaignListItemClicked({
    ...getDefaultProperties(),
    ...getCampaignProperties(campaign),
  });
};

export const trackPerkCardClicked = (perk: PerkADto): void => {
  ampli.perkCardClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackPerkListItemClicked = (perk: PerkADto): void => {
  ampli.perkListItemClicked({
    ...getDefaultProperties(),
    ...getPerkProperties(perk),
  });
};

export const trackCampaignsDisplayChanged = (display: 'card' | 'list'): void => {
  ampli.campaignsDisplayChanged({
    ...getDefaultProperties(),
    display,
  });
};

export const trackPerksDisplayChanged = (display: 'card' | 'list'): void => {
  ampli.perksDisplayChanged({
    ...getDefaultProperties(),
    display,
  });
};

export const trackDeleteRedeemCodeBtnClicked = (redeemCode: RedeemCodeADto): void => {
  ampli.deleteRedeemCodeBtnClicked({
    ...getDefaultProperties(),
    ...getRedeemCodeProperties(redeemCode),
  });
};

export const trackCopyRedeemCodeBtnClicked = (redeemCode: RedeemCodeADto): void => {
  ampli.copyRedeemCodeBtnClicked({
    ...getDefaultProperties(),
    ...getRedeemCodeProperties(redeemCode),
  });
};

export const trackDeleteRedeemCodeSuccess = (redeemCode: RedeemCodeADto): void => {
  ampli.deleteRedeemCodeSuccess({
    ...getDefaultProperties(),
    ...getRedeemCodeProperties(redeemCode),
  });
};

export const trackMembershipPassSettingsBgColorBtnClicked = (
  selectedBackgroundColorOption: BackgroundColorOptionKey
): void => {
  ampli.membershipPassSettingsBgColorBtnClicked({
    ...getDefaultProperties(),
    selectedBackgroundColorOption,
  });
};

export const trackMembershipPassPreviewModalOpened = (): void => {
  ampli.membershipPassPreviewModalOpened({
    ...getDefaultProperties(),
  });
};

export const trackMembershipPassPreviewModalClosed = (): void => {
  ampli.membershipPassPreviewModalClosed({
    ...getDefaultProperties(),
  });
};

export const trackMembershipPassConfigurationSettingsSaveButtonClicked = (): void => {
  ampli.membershipPassConfigurationSettingsSaveButtonClicked({
    ...getDefaultProperties(),
  });
};
