import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {z} from 'zod';

export const YoutubeVideoMediaConfigSchema = z.object({
  videoId: z.string(),
});
export type YoutubeVideoMediaConfig = z.infer<typeof YoutubeVideoMediaConfigSchema>;

export type YoutubeVideoMediaStruct = MediaStruct<'youtube.video', YoutubeVideoMediaConfig>;
export const YoutubeVideoMediaSpec: MediaSpec<YoutubeVideoMediaStruct> = {
  kind: 'youtube.video',
  configSchema: YoutubeVideoMediaConfigSchema,
};
