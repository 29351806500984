import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {FACEBOOK_URL} from '@cohort/shared/apps/facebook/common';
import type {FacebookLikePageVerificationAttemptData} from '@cohort/shared/apps/facebook/triggers/likePage';
import {Trans} from 'react-i18next';

const FacebookLikePageTriggerStepCompletionTitleComponent: React.FC<{
  data: FacebookLikePageVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggers.like-page.stepCompletionTitleComponent.error"
        ns="app-facebook"
        values={{username: data.username}}
        components={{
          a: <StepCompletionLink url={data.link ?? FACEBOOK_URL} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggers.like-page.stepCompletionTitleComponent.success"
      ns="app-facebook"
      values={{username: data.username}}
      components={{
        a: <StepCompletionLink url={data.link ?? FACEBOOK_URL} />,
      }}
    />
  );
};

export default FacebookLikePageTriggerStepCompletionTitleComponent;
