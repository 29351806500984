import type {ActionStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

// Execute Action
export const ExecuteActionDataASchema = z.object({
  appId: z.string(),
  actionId: z.string(),
  merchantConnectionId: z.string(),
  input: z.unknown(),
});
export type ExecuteActionDataADto<T extends ActionStruct = ActionStruct> = z.infer<
  typeof ExecuteActionDataASchema
> & {
  actionId: T['Id'];
  input: T['Input'];
};

export const ExecuteActionResponseASchema = z.object({
  output: z.unknown(),
});
export type ExecuteActionResponseADto<T extends ActionStruct = ActionStruct> = z.infer<
  typeof ExecuteActionResponseASchema
> & {
  output: T['Output'];
};

// Execute Action async
export const ExecuteAsyncActionDataASchema = ExecuteActionDataASchema.extend({
  retryOptions: z.object({
    maxAttempts: z.number().int(),
    delayMs: z.number().int(),
  }),
});
export type ExecuteAsyncActionDataADto<T extends ActionStruct = ActionStruct> = z.infer<
  typeof ExecuteAsyncActionDataASchema
> & {
  actionId: T['Id'];
  input: T['Input'];
};
