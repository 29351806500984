import type {LiquidEditorProps} from '@cohort/merchants/components/editor/LiquidEditor';
import LiquidEditor from '@cohort/merchants/components/editor/LiquidEditor';
import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type LiquidEditorInputProps<T extends FieldValues> = WrappedField &
  FormField<T> &
  Omit<LiquidEditorProps, 'defaultValue' | 'onChange'> & {
    onRenderedChange?: (rendered: string) => void;
  };

export default function LiquidEditorInput<T extends FieldValues>({
  label,
  description,
  name,
  control,
  liquidConfig,
  placeholder,
  height,
  onValidate,
  onRenderedChange,
  onError,
}: LiquidEditorInputProps<T>): JSX.Element {
  const {field, fieldState} = useController({
    control,
    name,
  });

  return (
    <FieldWrapper
      label={label}
      name={name}
      description={description}
      error={fieldState.error?.message}
    >
      <LiquidEditor
        height={height}
        defaultValue={field.value}
        liquidConfig={liquidConfig}
        placeholder={placeholder}
        onValidate={onValidate}
        onChange={({template, parsedTemplate}) => {
          onRenderedChange?.(parsedTemplate);
          field.onChange(template);
        }}
        onError={onError}
      />
    </FieldWrapper>
  );
}
