import {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import type {ValidationError} from 'vanilla-jsoneditor';
import {JSONEditor, Mode} from 'vanilla-jsoneditor';

type JsonEditorProps = {
  value: unknown;
  validator?: (json: unknown) => ValidationError[];
};

const JsonEditor = forwardRef<JSONEditor | undefined, JsonEditorProps>(
  ({value, validator}, ref) => {
    const jsonEditorRef = useRef<JSONEditor>();

    const renderJsonEditor = useCallback(
      (node: HTMLElement | null) => {
        if (node && !jsonEditorRef.current) {
          jsonEditorRef.current = new JSONEditor({
            target: node,
            props: {
              content: {
                json: value,
              },
              mode: Mode.text,
              mainMenuBar: false,
              navigationBar: false,
              validator,
            },
          });
        }
      },
      [value, validator]
    );

    useImperativeHandle(ref, () => jsonEditorRef.current, []);

    return <div ref={renderJsonEditor} />;
  }
);

export default JsonEditor;
