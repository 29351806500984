import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import type {CohortXpsVisitedEventStruct} from '@cohort/shared/apps/cohort/userEvents/xpsVisited';
import {CohortXpsVisitedEventSpec} from '@cohort/shared/apps/cohort/userEvents/xpsVisited';
import {Trans} from 'react-i18next';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => (
  <Trans i18nKey="userEvents.user.xps-visited.detail" ns="app-cohort" />
);

export const CohortXpsVisitedUserEvent: UserEvent<CohortXpsVisitedEventStruct> = {
  spec: CohortXpsVisitedEventSpec,
  detailComponent: DetailComponent,
};
