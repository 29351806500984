import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import {usePerkAccess} from '@cohort/merchants/hooks/api/PerkAccesses';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortPerkUsedVerificationAttemptData} from '@cohort/shared/apps/cohort/triggers/perkUsed';
import {Trans} from 'react-i18next';

const CohortPerkUsedTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortPerkUsedVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const merchant = useCurrentMerchant();
  const firstPerkUsage = data.perkUsages[0];
  const {data: perkAccess, isLoading} = usePerkAccess(merchant.id, firstPerkUsage?.accessId ?? '', {
    enabled: firstPerkUsage !== undefined,
  });

  return completionSuccess ? (
    <StepCompletionCard
      isLoading={isLoading}
      translationConfig={
        perkAccess !== undefined
          ? {
              // i18nOwl-ignore [app-cohort.triggerIntegrations.perk-used.stepCompletionTitleComponent.success]
              key: 'triggerIntegrations.perk-used.stepCompletionTitleComponent.success',
              ns: 'app-cohort',
              value: perkAccess.perk.internalName,
            }
          : null
      }
    />
  ) : (
    <Trans
      i18nKey="triggerIntegrations.perk-used.stepCompletionTitleComponent.error"
      ns="app-cohort"
    />
  );
};

export default CohortPerkUsedTriggerIntegrationStepCompletionTitleComponent;
