import type {
  ActionSpec,
  AppSpec,
  AppStruct,
  BaseProfileProperties,
  ConnectorSpec,
  ConnectorStruct,
} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {TestQueryActionSpec} from '@cohort/shared/apps/postgresql/actions/testQuery';
import type {PostgresqlSyncStruct} from '@cohort/shared/apps/postgresql/sync';
import {PostgresqlSyncIntegrationSpec} from '@cohort/shared/apps/postgresql/sync';
import {z} from 'zod';

export const UserIdOrEmailColumnRefinement = (
  request: {
    idColumn?: string | null;
    emailColumn?: string | null;
  },
  ctx: z.RefinementCtx
): {message: string; path: string[]} | boolean => {
  const emailSet = request.emailColumn !== undefined && request.emailColumn !== null;
  const userIdSet = request.idColumn !== undefined && request.idColumn !== null;

  if (!emailSet && !userIdSet) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'One of the following 2 options must be provided for identifying the user: email, userId',
      path: ['idColumn', 'emailColumn'],
    });
    return false;
  }

  return true;
};

export const PostgresqlCredentialsSslSchema = z.object({
  rejectUnauthorized: z.boolean(),
  ca: z.string().optional(),
  key: z.string().optional(),
  cert: z.string().optional(),
});
export type PostgresqlMerchantConnectionSsl = z.infer<typeof PostgresqlCredentialsSslSchema>;

// Use ssl: null to disable SSL
// Use ssl: {} to enable SSL without providing certificates (not sure it can work)
// Use ssl: {ca: server Certificate} to enable SSL with server certificate
// Use ssl: {ca: server Certificate, key: client private key, cert: client certificate} to enable SSL with client certificate (this is the only one that works with Cloud SQL)
// Note: Don't provide ssl options in the connection string (e.g. ?ssl=true) as it will override the ssl options provided in the config
export const PostgresqlCredentialsSchema = z.object({
  url: z.string().nonempty({message: 'errorRequired'}),
  schema: z.string().nonempty({message: 'errorRequired'}),
  ssl: PostgresqlCredentialsSslSchema.nullable(),
});
export type PostgresqlCredentials = z.infer<typeof PostgresqlCredentialsSchema>;

export type PostgresqlConnectorStruct = ConnectorStruct<
  'postgresql',
  null,
  PostgresqlCredentials,
  BaseProfileProperties,
  null
>;

export const PostgresqlConnectorSpec = {
  id: 'postgresql',
  type: 'custom',
  appCredentialsSchema: null,
  credentialsSchema: PostgresqlCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<PostgresqlConnectorStruct>;

export const PostgresqlActionSpecs = [
  TestQueryActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type PostgresqlActionIds = (typeof PostgresqlActionSpecs)[number]['id'];

export type PostgresqlAppStruct = AppStruct<
  'postgresql',
  PostgresqlConnectorStruct,
  null,
  PostgresqlSyncStruct,
  PostgresqlActionIds,
  never,
  never,
  never,
  never,
  never,
  never,
  never
>;

export const PostgresqlAppSpec: AppSpec<PostgresqlAppStruct> = {
  id: 'postgresql',
  name: 'PostgreSQL',
  merchantConnector: PostgresqlConnectorSpec,
  userConnector: null,
  syncSpec: PostgresqlSyncIntegrationSpec,
  actionSpecs: PostgresqlActionSpecs,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: [],
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
