import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@cohort/merchants/components/toasts/Toast';
import {useToast} from '@cohort/merchants/hooks/toast';

const Toaster: React.FC = () => {
  const {toasts} = useToast();

  return (
    <ToastProvider duration={2000}>
      {toasts.map(function ({id, title, description, action, hideToastClose, ...props}) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && (
                <ToastTitle>
                  <ToastIcon variant={props.variant} />
                  {title}
                </ToastTitle>
              )}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
            {hideToastClose !== true && <ToastClose />}
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
};

export default Toaster;
