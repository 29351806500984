import {create} from 'zustand';

interface BackNavigationStore {
  backPath: string;
  currentPath: string;
  currentSearch: string;
  setCurrentPath: (path: string, search: string) => void;
}

const getCleanPath = (path: string): string => {
  const pathWithoutTestPrefix = path.replace('/test', '');
  const pathWithResolvedAliases = pathWithoutTestPrefix.replace('/settings/apps', '/apps');
  return pathWithResolvedAliases.split('?')[0] ?? '';
};
const getResourceRoot = (path: string): string => {
  const prefix = path.startsWith('/test') ? '/test' : '';
  const cleanPath = getCleanPath(path);
  const parts = cleanPath.split('/');

  return parts.length > 1 ? `${prefix}/${parts[1]}` : `${prefix}/`;
};
const isResourceHome = (path: string): boolean => {
  const cleanPath = getCleanPath(path);
  const parts = cleanPath.split('/');

  return (parts.length === 2 && parts[1] !== '') || path === '/';
};

const computeBackPath = (
  newPreviousPath: string,
  newCurrentPath: string,
  currentBackPath: string
): {backPath: string; shouldUpdateCurrentPath: boolean} => {
  const previousResourceRoot = getResourceRoot(newPreviousPath);
  const currentResourceRoot = getResourceRoot(newCurrentPath);

  const hasSameRootResource = previousResourceRoot === currentResourceRoot;
  const hasSamePath = previousResourceRoot.split('?')[0] === currentResourceRoot.split('?')[0];

  // Use case: clicking on a menu item - eg: /users/:id/activity => /perks
  if (!hasSameRootResource && isResourceHome(newCurrentPath)) {
    return {backPath: newCurrentPath, shouldUpdateCurrentPath: true};
  }

  // Use case: crosslink between resources - eg: /users/:id/cohorts => /cohorts/:id
  if (!hasSameRootResource) {
    return {backPath: newPreviousPath, shouldUpdateCurrentPath: false};
  }

  // Use case: filters changed on resource home - eg: /users?page=1 => /users?page=2
  if (hasSamePath && isResourceHome(newPreviousPath) && isResourceHome(newCurrentPath)) {
    return {backPath: newCurrentPath, shouldUpdateCurrentPath: true};
  }

  // Use case: after returning from a crosslink we lost the original backPath and its filters, so we go to the resource home
  if (newCurrentPath === newPreviousPath && !isResourceHome(currentBackPath)) {
    return {backPath: previousResourceRoot, shouldUpdateCurrentPath: false};
  }

  // Use case: navigating within a resource sub pages - eg: /users => /users/:id/activity
  return {backPath: currentBackPath, shouldUpdateCurrentPath: true};
};

const useBackNavigationStore = create<BackNavigationStore>(set => ({
  currentPath: window.location.pathname,
  currentSearch: window.location.search,
  backPath: getResourceRoot(window.location.pathname),

  setCurrentPath: (path: string, search: string) =>
    set(state => {
      const newPreviousPath = `${state.currentPath}${state.currentSearch}`;
      const newCurrentPath = `${path}${search}`;
      const {backPath, shouldUpdateCurrentPath} = computeBackPath(
        newPreviousPath,
        newCurrentPath,
        state.backPath
      );

      return {
        currentPath: shouldUpdateCurrentPath ? path : state.currentPath,
        currentSearch: shouldUpdateCurrentPath ? search : state.currentSearch,
        backPath,
      };
    }),
}));

export default useBackNavigationStore;
