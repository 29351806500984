import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import ExportUsersFieldSelect from '@cohort/merchants/apps/salesforce/sync/ExportUsersFieldSelect';
import ExportUsersUserPropertySelect from '@cohort/merchants/apps/salesforce/sync/ExportUsersUserPropertySelect';
import type {
  MappedUserProperty,
  SalesforceUserObject,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import Button from '@cohort/merchants/components/buttons/Button';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {ArrowRight, Trash} from '@phosphor-icons/react';
import {useFieldArray} from 'react-hook-form';

interface ExportUsersMappedUserPropertyProps {
  index: number;
  salesforceUserObjects: SalesforceUserObject[];
  userProperties: UserPropertyADto[] | undefined;
  mappedUserProperty: MappedUserProperty;
}

const ExportUsersMappedUserProperty: React.FC<ExportUsersMappedUserPropertyProps> = ({
  index,
  salesforceUserObjects,
  userProperties,
  mappedUserProperty,
}) => {
  const {control, watch} = useCohortForm<Extract<SyncConfigFormValues, {appId: 'salesforce'}>>();

  const mappedUserProperties = watch('userExportConfig.userProperties');

  const {remove: removeUserProperty} = useFieldArray({
    control,
    name: 'userExportConfig.userProperties',
  });

  const handleDeleteField = (index: number): void => {
    removeUserProperty(index);
  };

  const isLoneEmptyUserProperty =
    mappedUserProperties.length === 1 &&
    mappedUserProperties[0]?.salesforceFieldName === '' &&
    mappedUserProperties[0]?.userPropertyId === '';

  return (
    <div key={index} className="flex items-center gap-6">
      <div className="w-[276px]">
        <ExportUsersUserPropertySelect
          index={index}
          salesforceUserObjects={salesforceUserObjects}
          userProperties={userProperties}
          mappedUserProperty={mappedUserProperty}
        />
      </div>
      <ArrowRight size={20} className="text-slate-400" />
      <div className="flex flex-1 items-center">
        <ExportUsersFieldSelect
          index={index}
          salesforceUserObjects={salesforceUserObjects}
          userProperties={userProperties}
          mappedUserProperty={mappedUserProperty}
        />
      </div>
      <Button
        variant="secondary"
        size="icon"
        onClick={() => handleDeleteField(index)}
        disabled={isLoneEmptyUserProperty}
      >
        <Trash size={20} className="text-red-400" />
      </Button>
    </div>
  );
};

export default ExportUsersMappedUserProperty;
