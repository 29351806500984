import type {TriggerIntegration} from '@cohort/merchants/apps';
import GarminBeActiveTriggerIntegrationConfigComponent from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/beActive/ConfigComponent';
import GarminConnectBeActiveTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/beActive/StepCompletionTitleComponent';
import {GarminConnectTriggerIntegrationAvailableActivityGroupsSchema} from '@cohort/shared/apps/garmin-connect/activity';
import type {
  GarminConnectBeActiveTriggerConfig,
  GarminConnectBeActiveTriggerStruct,
} from '@cohort/shared/apps/garmin-connect/triggers/beActive';
import {
  GarminConnectBeActiveTriggerConfigMetricSchema,
  GarminConnectBeActiveTriggerIntegrationSpec,
} from '@cohort/shared/apps/garmin-connect/triggers/beActive';
import {startDateBeforeEndDateRefinement} from '@cohort/shared/schema/common/dates';
import {toKilometers, toMeters, toMinutes, toSeconds} from '@cohort/shared/utils/convert';
import {z} from 'zod';

export const GarminConnectBeActiveTriggerFormConfigSchema = z
  .object({
    activityGroups: z
      .array(GarminConnectTriggerIntegrationAvailableActivityGroupsSchema)
      .nonempty({message: 'errorNoOptionsSelected'})
      .nullable()
      .optional(),
    hasActivityTypes: z.boolean().optional().default(false),
    endDate: z.date({coerce: true}),
    metric: GarminConnectBeActiveTriggerConfigMetricSchema,
    startDate: z.date({coerce: true}),
    value: z.number().min(0),
  })
  .superRefine(
    startDateBeforeEndDateRefinement({
      startDatePropertyName: 'startDate',
      endDatePropertyName: 'endDate',
    })
  );

export type GarminConnectBeActiveTriggerFormConfig = z.infer<
  typeof GarminConnectBeActiveTriggerFormConfigSchema
>;

function fromForm(
  formValues: GarminConnectBeActiveTriggerFormConfig
): GarminConnectBeActiveTriggerConfig {
  const result: GarminConnectBeActiveTriggerConfig = {
    ...formValues,
    endDateTimestamp: new Date(formValues.endDate).getTime(),
    startDateTimestamp: new Date(formValues.startDate).getTime(),
    activityGroups: formValues.hasActivityTypes ? (formValues.activityGroups ?? null) : null,
  };

  switch (formValues.metric) {
    case 'distance':
      result.value = toMeters(formValues.value);
      break;
    case 'duration':
      result.value = toSeconds(formValues.value);
      break;
    case 'elevation':
      result.value = formValues.value;
      break;
  }

  return result;
}

function toForm(
  config: GarminConnectBeActiveTriggerConfig
): GarminConnectBeActiveTriggerFormConfig {
  const result: GarminConnectBeActiveTriggerFormConfig = {
    ...config,
    endDate: new Date(config.endDateTimestamp),
    startDate: new Date(config.startDateTimestamp),
    hasActivityTypes: config.activityGroups !== null,
    activityGroups: config.activityGroups,
  };

  switch (config.metric) {
    case 'distance':
      result.value = toKilometers(config.value);
      break;
    case 'duration':
      result.value = toMinutes(config.value);
      break;
    case 'elevation':
      result.value = config.value;
      break;
  }

  return result;
}

export const GarminConnectConnectBeActiveTriggerIntegration: TriggerIntegration<GarminConnectBeActiveTriggerStruct> =
  {
    spec: GarminConnectBeActiveTriggerIntegrationSpec,
    configComponent: GarminBeActiveTriggerIntegrationConfigComponent,
    configForm: {
      schema: GarminConnectBeActiveTriggerFormConfigSchema,
      toForm,
      fromForm,
    },
    stepCompletionTitleComponent:
      GarminConnectBeActiveTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.be-active.title', {ns: 'app-garmin-connect'}),
  };
