import type {
  ChallengeParticipationADto,
  ChallengeParticipationsParamsADto,
  MarkStepCompletedADto,
  ParticipationAnalyticsADto,
} from '@cohort/admin-schemas/challengeParticipation';
import {
  ChallengeParticipationASchema,
  ParticipationAnalyticsASchema,
} from '@cohort/admin-schemas/challengeParticipation';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto, ExportFormatDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getChallengeParticipation(
  merchantId: string,
  challengeParticipationsId: string
): Promise<ChallengeParticipationADto> {
  return apiCall(
    'GET',
    `/v1/merchants/${merchantId}/challenge-participations/${challengeParticipationsId}`,
    {
      expect: HttpCodes.SUCCESS,
      parser: ChallengeParticipationASchema.parse,
    }
  );
}

export async function getChallengeParticipations(
  merchantId: string,
  params: ChallengeParticipationsParamsADto
): Promise<[PaginationDto, ChallengeParticipationADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/challenge-participations`, {
    expect: HttpCodes.SUCCESS,
    params,
    parser: paginatedParser(ChallengeParticipationASchema),
  });
}

export async function getParticipationAnalytics(
  merchantId: string,
  campaignId: string
): Promise<ParticipationAnalyticsADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/challenge-participations/analytics`, {
    expect: HttpCodes.SUCCESS,
    parser: ParticipationAnalyticsASchema.parse,
    params: {campaignId},
  });
}

export async function markStepCompleted(
  merchantId: string,
  challengeParticipationId: string,
  body: MarkStepCompletedADto
): Promise<ChallengeParticipationADto> {
  return apiCall(
    'POST',
    `/v1/merchants/${merchantId}/challenge-participations/${challengeParticipationId}/mark-step-completed`,
    {
      expect: HttpCodes.CREATED,
      parser: ChallengeParticipationASchema.parse,
      body,
    }
  );
}

export async function exportParticipations(
  merchantId: string,
  campaignId: string,
  format: ExportFormatDto
): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/challenge-participations/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
    params: {
      campaignId,
      format,
    },
  });
}
