import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import {
  getAppsRoute,
  getBrandRoute,
  getCustomizationRoute,
  getIntegrationsSettingsRoute,
  getLanguagesRoute,
  getMembershipPassConfigurationRoute,
  getNotificationsSettingsRoute,
  getPaymentSettingsRoute,
  getQrReadersSettingsRoute,
  getTeamRoute,
  getUserPropertiesSettingsRoute,
} from '@cohort/merchants/lib/Pages';
import {trackNavbarLinkButtonClicked} from '@cohort/merchants/lib/Tracking';
import {Popover, PopoverContent, PopoverTrigger} from '@cohort/shared-frontend/components/Popover';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {
  Bell,
  Code,
  CreditCard,
  Desktop,
  GearSix,
  IdentificationCard,
  Palette,
  QrCode,
  Swatches,
  Translate,
  UserList,
  Users,
} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';

const NavbarSettingsPopUp: React.FC = () => {
  const location = useLocation();
  const {t} = useTranslation('components', {keyPrefix: 'navBar.navbarSettingsPopUp'});
  const testModeEnabled = useTestModeStore(store => store.testModeEnabled);

  const SettingsNavigation = [
    {
      name: t('linkExperienceSpace'),
      href: getBrandRoute().path,
      icon: Swatches,
      pageName: getBrandRoute().name,
    },
    {
      name: t('linkCustomization'),
      href: getCustomizationRoute().path,
      icon: Palette,
      pageName: getCustomizationRoute().name,
    },
    {
      name: t('linkMembershipPassConfiguration'),
      href: getMembershipPassConfigurationRoute().path,
      icon: IdentificationCard,
      pageName: getMembershipPassConfigurationRoute().name,
    },
    {
      name: t('linkConnections'),
      href: getAppsRoute().path,
      icon: Desktop,
      pageName: getAppsRoute().name,
    },
    {
      name: t('linkNotifications'),
      href: getNotificationsSettingsRoute().path,
      icon: Bell,
      pageName: getNotificationsSettingsRoute().name,
    },
    ...(!testModeEnabled
      ? [
          {
            name: t('linkPayments'),
            href: getPaymentSettingsRoute().path,
            icon: CreditCard,
            pageName: getPaymentSettingsRoute().name,
          },
          {
            name: t('linkTeamMembers'),
            href: getTeamRoute().path,
            icon: Users,
            pageName: getTeamRoute().name,
          },
        ]
      : []),
    {
      name: t('linkIntegrations'),
      href: getIntegrationsSettingsRoute().path,
      icon: Code,
      pageName: getIntegrationsSettingsRoute().name,
    },
    {
      name: t('linkQrReaders'),
      href: getQrReadersSettingsRoute().path,
      icon: QrCode,
      pageName: getQrReadersSettingsRoute().name,
    },
    {
      name: t('linkLanguages'),
      href: getLanguagesRoute().path,
      icon: Translate,
      pageName: getLanguagesRoute().name,
    },
    {
      name: t('linkUserProperties'),
      href: getUserPropertiesSettingsRoute().path,
      icon: UserList,
      pageName: getUserPropertiesSettingsRoute().name,
    },
  ];

  return (
    <Popover>
      <PopoverTrigger>
        <div
          className={cn(
            'flex h-16 flex-col justify-center border-b-2 pt-[2px] text-slate-500 hover:text-slate-700',
            location.pathname.startsWith('/settings')
              ? 'border-primary text-slate-700'
              : 'border-b-transparent'
          )}
        >
          <GearSix size={24} />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-60">
        <div className="border-b border-border p-4">
          <p className="text-sm">{t('labelOrgSettings')}</p>
        </div>
        <div className="py-2">
          {SettingsNavigation.map(item => (
            <Link
              key={item.name}
              to={item.href}
              className="flex items-center px-4 py-2 text-sm text-slate-700 hover:bg-slate-50"
              onClick={() => trackNavbarLinkButtonClicked(item.pageName)}
            >
              <item.icon
                className="mr-3 h-5 w-5 text-slate-400 group-hover:text-slate-500"
                aria-hidden="true"
              />
              {item.name}
            </Link>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default NavbarSettingsPopUp;
