import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortPerksCountUserPropertyStruct = UserPropertyStruct<'cohort.perks-count', number>;

export const CohortPerksCountUserPropertySpec: UserPropertySpec<CohortPerksCountUserPropertyStruct> =
  {
    id: 'cohort.perks-count',
    name: 'Number of Perks',
    dataType: 'number',
    scope: 'global',
    defaultValue: 0,
  };
