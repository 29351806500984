import {
  asArray,
  DatetimeSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
  MetadataSchema,
  PositiveIntegerSchema,
} from '@cohort/shared/schema/common';
import {NetworkSchema} from '@cohort/shared/schema/common/blockchain';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const SmartContractASchema = z.object({
  network: NetworkSchema,
  address: z.string(),
  blockExplorerUrl: z.string().url().nullable(),
  openseaUrl: z.string().url().nullable(),
});
export type SmartContractADto = z.infer<typeof SmartContractASchema>;

export const DigitalAssetCollectionASchema = z.object({
  id: z.string().uuid(),
  title: LocalizedStringSchema,
  imageFileKey: z.string(),
  animationFileKey: z.string().nullable(),
  description: LocalizedRichTextSchema,
  internalName: z.string().min(3),
  createdAt: DatetimeSchema,
  updatedAt: DatetimeSchema,
  nftEnabled: z.boolean(),
  nftNonTransferable: z.boolean(),
  smartContract: SmartContractASchema.nullable(),
  metadata: MetadataSchema,
  maxOwnershipsPerUser: PositiveIntegerSchema.nullable(),
});
export type DigitalAssetCollectionADto = z.infer<typeof DigitalAssetCollectionASchema>;

export const DigitalAssetCollectionAnalyticsASchema = DigitalAssetCollectionASchema.extend({
  digitalAssetsCount: z.number(),
  campaignsCount: z.number(),
});
export type DigitalAssetCollectionAnalyticsADto = z.infer<
  typeof DigitalAssetCollectionAnalyticsASchema
>;

export const CreateDigitalAssetCollectionASchema = z.object({
  description: LocalizedRichTextSchema,
  title: LocalizedStringSchema,
  internalName: z.string().min(3),
  imageFileKey: z.string(),
  animationFileKey: z.string().nullable().optional(),
  nftEnabled: z.boolean(),
  nftNonTransferable: z.boolean(),
  maxOwnershipsPerUser: PositiveIntegerSchema.nullable().optional(),
});
export type CreateDigitalAssetCollectionADto = z.infer<typeof CreateDigitalAssetCollectionASchema>;

export const PatchDigitalAssetCollectionRequestASchema = z
  .object({
    description: LocalizedRichTextSchema,
    title: LocalizedStringSchema,
    internalName: z.string().min(3),
    imageFileKey: z.string(),
    animationFileKey: z.string().nullable(),
    nftEnabled: z.literal(true),
    nftNonTransferable: z.boolean(),
    metadata: MetadataSchema,
    maxOwnershipsPerUser: PositiveIntegerSchema.nullable().optional(),
  })
  .partial();

export type PatchDigitalAssetCollectionRequestADto = z.infer<
  typeof PatchDigitalAssetCollectionRequestASchema
>;

export const DuplicateDigitalAssetCollectionASchema = z.object({
  internalName: z.string().min(3),
  nftEnabled: z.boolean(),
  nftNonTransferable: z.boolean(),
});

export type DuplicateDigitalAssetCollectionADto = z.infer<
  typeof DuplicateDigitalAssetCollectionASchema
>;

export const GetDigitalAssetCollectionsSortParamASchema = z.enum([
  'updatedAt',
  'internalName',
  '-updatedAt',
  '-internalName',
]);

export type GetDigitalAssetCollectionsSortParamADto = z.infer<
  typeof GetDigitalAssetCollectionsSortParamASchema
>;

export const GetDigitalAssetCollectionsParamsASchema = PaginationParamsSchema.extend({
  orderBy: GetDigitalAssetCollectionsSortParamASchema.default('-updatedAt'),
  digitalAssetCollectionId: z.preprocess(asArray, z.array(z.string().uuid()).optional()),
  search: z.string().optional(),
});
export type GetDigitalAssetCollectionsParamsADto = z.infer<
  typeof GetDigitalAssetCollectionsParamsASchema
>;
