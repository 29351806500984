import {cn} from '@cohort/shared-frontend/utils/classNames';
import {
  ArrowsInLineVertical,
  ArrowUUpLeft,
  ArrowUUpRight,
  Link,
  ListBullets,
  ListNumbers,
  Paragraph,
  TextB,
  TextHOne,
  TextHTwo,
  TextItalic,
  TextT,
} from '@phosphor-icons/react';
import type {Editor} from '@tiptap/react';
import {useCallback} from 'react';

type MenuBarProps = {
  editor: Editor;
  disabled?: boolean;
};

export const MenuBar: React.FC<MenuBarProps> = ({editor, disabled}) => {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href as string | undefined;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({href: url}).run();
  }, [editor]);

  const TextEditorButtons = [
    {
      name: 'bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: editor.isActive('bold'),
      icon: TextB,
    },
    {
      name: 'italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: editor.isActive('italic'),
      icon: TextItalic,
    },
  ];

  const TextEditorButtons2 = [
    {
      icon: Paragraph,
      name: 'paragraph',
      action: () => editor.chain().focus().setParagraph().run(),
      isActive: editor.isActive('paragraph'),
    },
    {
      icon: TextHOne,
      title: 'Heading 1',
      action: () => editor.chain().focus().toggleHeading({level: 1}).run(),
      isActive: editor.isActive('heading', {level: 1}),
    },
    {
      icon: TextHTwo,
      name: 'Heading 2',
      action: () => editor.chain().focus().toggleHeading({level: 2}).run(),
      isActive: editor.isActive('heading', {level: 2}),
    },
  ];

  const TextEditorButtons3 = [
    {
      icon: ListBullets,
      name: 'Bullet List',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: editor.isActive('bulletList'),
    },
    {
      icon: ListNumbers,
      name: 'Ordered List',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: editor.isActive('orderedList'),
    },
    {
      name: 'link',
      action: setLink,
      icon: Link,
      isActive: editor.isActive('link'),
    },
    {
      icon: ArrowsInLineVertical,
      name: 'Horizontal Rule',
      action: () => editor.chain().focus().setHorizontalRule().run(),
      isActive: false,
    },
    {
      name: 'Clear Format',
      action: () => editor.chain().focus().clearNodes().unsetAllMarks().run(),
      icon: TextT,
      isActive: false,
    },
  ];

  const TextEditorButtons4 = [
    {
      icon: ArrowUUpLeft,
      name: 'Undo',
      action: () => editor.chain().focus().undo().run(),
      isActive: false,
    },
    {
      icon: ArrowUUpRight,
      name: 'Redo',
      action: () => editor.chain().focus().redo().run(),
      isActive: false,
    },
  ];

  const ButtonGroup = [
    [1, TextEditorButtons],
    [2, TextEditorButtons2],
    [3, TextEditorButtons3],
    [4, TextEditorButtons4],
  ] as const;

  return (
    <div
      className={cn(
        'flex items-stretch space-x-5 border-b p-2',
        disabled && 'cursor-not-allowed bg-slate-100 text-slate-400'
      )}
    >
      {ButtonGroup.map(([indexGroup, group]) => (
        <span key={`${indexGroup}IG`} className="space-x-1">
          {group.map(button => (
            <button
              type={'button'}
              key={`k${button.name}`}
              title={button.name}
              onClick={() => !disabled && button.action()}
              className={cn(
                'rounded-md p-1',
                button.isActive ? 'bg-slate-700 text-white' : 'bg-white text-slate-700',
                disabled && 'cursor-not-allowed !bg-slate-100 !text-slate-400'
              )}
            >
              <button.icon size={20} />
            </button>
          ))}
        </span>
      ))}
    </div>
  );
};
