import {cn} from '@cohort/shared-frontend/utils/classNames';

type MerchantLogoPlaceholderProps = {
  merchantName: string;
  size: number;
  shape?: 'circle' | 'square';
};

const MerchantLogoPlaceholder: React.FC<MerchantLogoPlaceholderProps> = ({
  merchantName,
  size,
  shape = 'circle',
}) => (
  <div
    style={{
      height: `${size}px`,
      width: `${size}px`,
      fontSize: `${size / 2}px`,
    }}
    className={cn(
      'flex items-center justify-center border border-gray-200 bg-gray-200 font-semibold [color:var(--cohort-color)]',
      shape === 'circle' ? 'rounded-full' : 'rounded-xl'
    )}
  >
    {merchantName[0]}
  </div>
);

export default MerchantLogoPlaceholder;
