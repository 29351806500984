// i18nOwl-ignore [app-strava.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {createConnectAccountTriggerIntegration} from '@cohort/merchants/apps/common/triggers/ConnectAccountTriggerIntegration';
import translationEn from '@cohort/merchants/apps/strava/locales/en.json';
import translationFr from '@cohort/merchants/apps/strava/locales/fr.json';
import {StravaBeActiveTriggerIntegration} from '@cohort/merchants/apps/strava/triggerIntegratons/beActive/TriggerIntegration';
import {StravaCompleteActivityTriggerIntegration} from '@cohort/merchants/apps/strava/triggerIntegratons/completeActivity/TriggerIntegration';
import type {StravaAppStruct} from '@cohort/shared/apps/strava';
import {StravaAppSpec} from '@cohort/shared/apps/strava';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

// i18nOwl-ignore [app-strava.userEvents.activity.created.title]

const StravaApp: App<StravaAppStruct> = {
  spec: StravaAppSpec,
  ConnectionEditComponent: null,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="strava" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(StravaAppSpec) as TriggerIntegration,
    StravaBeActiveTriggerIntegration as TriggerIntegration,
    StravaCompleteActivityTriggerIntegration as TriggerIntegration,
  ],
  userEvents: [],
};

export default StravaApp;
