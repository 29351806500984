import type {AppStruct} from '@cohort/shared/apps/app';
import type {
  UserPropertyDataType,
  UserPropertyValue,
} from '@cohort/shared/schema/common/userProperty';

type UserPropertyScope = 'global' | 'merchant_connection' | 'profile';

export type UserPropertyStruct<
  Id extends string = string,
  ValueType extends UserPropertyValue = UserPropertyValue,
> = {
  Id: Id;
  ValueType: ValueType;
};

export type UserPropertySpec<UP extends UserPropertyStruct = UserPropertyStruct> = {
  id: UP['Id'];
  dataType: UserPropertyDataType;
  name: string;
  /**
   * Used to differentiate between different instances of the same UserProperty.
   * eg. different servers for discord.roles.
   * For global UserProperties such as {app}.app-connected, this should be set to 'global'.
   */
  scope: UserPropertyScope;
  refreshInterval?: number;
  resource?: string;
  /**
   * If defined, a userAttribute will be created with this default value for every new User.
   */
  defaultValue?: UP['ValueType'];
  // For profile UserProperties, the key in the profile object.
  profileKey?: string;
};

export type ProfileUserPropertySpec<UP extends UserPropertyStruct = UserPropertyStruct> =
  UserPropertySpec<UP> & {
    scope: 'profile';
    profileKey: string;
  };

export type AppConnectedUserPropertyStruct = UserPropertyStruct<string, boolean>;

export function createBaseAppUserPropertySpec<A extends AppStruct>(
  appId: A['Id'],
  appName: string
): Array<UserPropertySpec<AppConnectedUserPropertyStruct>> {
  return [
    createAppConnectedUserPropertySpec(appId, appName),
    createAppIdUserPropertySpec(appId, appName),
  ];
}

export function createAppConnectedUserPropertySpec<A extends AppStruct>(
  appId: A['Id'],
  appName: string
): UserPropertySpec<AppConnectedUserPropertyStruct> {
  return {
    id: `${appId}.app-connected`,
    dataType: 'boolean',
    name: `${appName} Connected`,
    scope: 'global',
    defaultValue: false,
  };
}

export function createAppIdUserPropertySpec<A extends AppStruct>(
  appId: A['Id'],
  appName: string
): UserPropertySpec<AppConnectedUserPropertyStruct> {
  return {
    id: `${appId}.id`,
    dataType: 'string',
    name: `${appName} ID`,
    scope: 'profile',
    profileKey: 'id',
  };
}

export function createAppUsernameUserPropertySpec<A extends AppStruct>(
  appId: A['Id'],
  appName: string
): UserPropertySpec<AppConnectedUserPropertyStruct> {
  return {
    id: `${appId}.username`,
    dataType: 'string',
    name: `${appName} Username`,
    scope: 'profile',
    profileKey: 'username',
  };
}
