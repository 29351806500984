import {IconBadge} from '@cohort/merchants/components/badges/IconBadge';
import type {CampaignType} from '@cohort/shared/schema/common/campaign';
import {Broadcast, Flag, Storefront} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

interface CampaignTypeBadgeProps {
  type: CampaignType;
  size?: 'large' | 'small';
}

const CampaignTypeBadge: React.FC<CampaignTypeBadgeProps> = ({type, size = 'small'}) => {
  const {t} = useTranslation('components', {keyPrefix: 'campaigns.campaignTypeBadge'});

  const icon = match(type)
    .with('store', () => <Storefront size={16} className="mr-1 text-slate-500" />)
    .with('challenge', () => <Flag size={16} className="mr-1 text-slate-500" />)
    .with('airdrop', () => <Broadcast size={16} className="mr-1 text-slate-500" />)
    .exhaustive();

  const text = match(type)
    .with('store', () => t('storeType'))
    .with('challenge', () => t('challengeType'))
    .with('airdrop', () => t('airdropType'))
    .exhaustive();

  return <IconBadge size={size} text={text} icon={icon} />;
};

export default CampaignTypeBadge;
