import {TriggerIntegrationIdSchema} from '@cohort/shared/apps';
import {LocalizedRichTextSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import {ChallengeStepOrchestrationSchema} from '@cohort/shared/schema/common/challengeStep';
import {z} from 'zod';

export const ChallengeStepTriggerASchema = z.object({
  id: z.string().uuid(),
  title: LocalizedStringSchema,
  triggerIntegrationId: TriggerIntegrationIdSchema,
  triggerIntegrationConfig: z.unknown(),
  connectionId: z.string().uuid().nullable(),
  maxVerificationAttempts: z.number().int().min(1).nullable(),
  verificationAttemptsLeft: z.number().int().nullable(),
});
export type ChallengeStepTriggerADto = z.infer<typeof ChallengeStepTriggerASchema>;

export const ChallengeStepASchema = z.object({
  id: z.string().uuid(),
  triggers: ChallengeStepTriggerASchema.array(),
  title: LocalizedStringSchema,
  description: LocalizedRichTextSchema,
  icon: z.string().emoji(),
  orchestration: ChallengeStepOrchestrationSchema,
});
export type ChallengeStepADto = z.infer<typeof ChallengeStepASchema>;

export const ChallengeStepsResponseASchema = z.array(ChallengeStepASchema);

export const ChallengeStepTriggerInputASchema = z.object({
  id: z.string().uuid().nullable(),
  triggerIntegrationId: TriggerIntegrationIdSchema,
  triggerIntegrationConfig: z.unknown(),
  connectionId: z.string().uuid().nullable(),
  maxVerificationAttempts: z.number().int().min(1).nullable(),
  title: LocalizedStringSchema.optional(),
});
export type ChallengeStepTriggerInputADto = z.infer<typeof ChallengeStepTriggerInputASchema>;

export const ChallengeStepInputASchema = z.object({
  id: z.string().uuid().nullable(),
  triggers: ChallengeStepTriggerInputASchema.array(),
  title: LocalizedStringSchema,
  description: LocalizedRichTextSchema,
  icon: z.string().emoji(),
  orchestration: ChallengeStepOrchestrationSchema,
});
export type ChallengeStepInputADto = z.infer<typeof ChallengeStepInputASchema>;

export const UpdateChallengeStepsASchema = z.array(ChallengeStepInputASchema);
export type UpdateChallengeStepsADto = z.infer<typeof UpdateChallengeStepsASchema>;
