import type {StylesConfig, Theme} from 'react-select';
import colors from 'tailwindcss/colors';

const SELECT_GRAY = colors.slate[100];

export const getTheme = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'hsl(var(--primary))',
    primary75: SELECT_GRAY,
    primary50: SELECT_GRAY,
    primary25: SELECT_GRAY,
    neutral20: 'hsl(var(--border))',
  },
});

export const defaultStyle: StylesConfig = {
  container: baseStyles => ({
    ...baseStyles,
    boxShadow: '0px 1px 2px 0px #0000000D',
  }),
  control: baseStyles => ({
    ...baseStyles,
    borderRadius: '6px',
    fontSize: '14px',
    '&:hover': null,
  }),
  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    color: colors.slate[400],
  }),
  multiValue: baseStyles => ({
    ...baseStyles,
    backgroundColor: SELECT_GRAY,
    borderRadius: '4px',
  }),
  multiValueLabel: baseStyles => ({
    ...baseStyles,
    color: 'hsl(var(--foreground))',
    fontSize: '12px',
  }),
  menuList: baseStyles => ({
    ...baseStyles,
    padding: '4px',
  }),
  menu: baseStyles => ({
    ...baseStyles,
    borderRadius: '6px',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isSelected || state.isFocused ? SELECT_GRAY : 'transparent',
    color: 'hsl(var(--foreground))',
    borderRadius: '4px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: SELECT_GRAY,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
