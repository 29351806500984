import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import {useApps} from '@cohort/merchants/apps/useApps';
import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {getAppConnectionEditRoute, getAppConnectionSyncRoute} from '@cohort/merchants/lib/Pages';
import {ArrowsClockwise, Link} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

type Props = {
  connection: ConnectionADto;
};

const ConnectionMenu: React.FC<Props> = ({connection}) => {
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.connections.connection.menu'});
  const {app, appId, appSpec} = useCurrentApp();
  const {getSyncTitle} = useApps();

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('itemConnection'),
          path: getAppConnectionEditRoute(appId, connection.id).path,
          icon: <Link size={20} />,
        },
        ...(app.sync?.syncConfigComponent
          ? [
              {
                name: getSyncTitle(appSpec),
                path: getAppConnectionSyncRoute(appId, connection.id).path,
                icon: <ArrowsClockwise size={20} />,
              },
            ]
          : []),
      ]}
    />
  );
};
export default ConnectionMenu;
