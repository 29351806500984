// i18nOwl-ignore [app-spotify.description, app-spotify.sync.description, app-spotify.sync.title]
import type {App, ContentMedia, TriggerIntegration} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import {createConnectAccountTriggerIntegration} from '@cohort/merchants/apps/common/triggers/ConnectAccountTriggerIntegration';
import translationEn from '@cohort/merchants/apps/spotify/locales/en.json';
import translationFr from '@cohort/merchants/apps/spotify/locales/fr.json';
import {SpotifyPlaylistMedia} from '@cohort/merchants/apps/spotify/medias/playlist/Media';
import {SpotifySyncIntegration} from '@cohort/merchants/apps/spotify/sync/SyncIntegration';
import {SpotifyFollowPlaylistTriggerIntegration} from '@cohort/merchants/apps/spotify/triggers/followPlaylist/TriggerIntegration';
import {SpotifyFollowUserTriggerIntegration} from '@cohort/merchants/apps/spotify/triggers/followUser/TriggerIntegration';
import type {SpotifyAppStruct} from '@cohort/shared/apps/spotify';
import {SpotifyAppSpec} from '@cohort/shared/apps/spotify';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const SpotifyApp: App<SpotifyAppStruct> = {
  spec: SpotifyAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('spotify'),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="spotify" height={40} width={40} />,
  medias: [SpotifyPlaylistMedia] as Array<ContentMedia>,
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [
    createConnectAccountTriggerIntegration(SpotifyAppSpec),
    SpotifyFollowPlaylistTriggerIntegration,
    SpotifyFollowUserTriggerIntegration,
  ] as Array<TriggerIntegration>,
  userEvents: [],
  sync: SpotifySyncIntegration,
};

export default SpotifyApp;
