import {formatCohortFormResponse} from '@cohort/merchants/apps/cohort/utils/format';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortFillFormVerificationAttemptData} from '@cohort/shared/apps/cohort/triggers/fillForm';
import {
  buildLocalizationConfig,
  getLocalizedContentOrThrow,
} from '@cohort/shared/utils/localization';

const CohortFillFormTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: CohortFillFormVerificationAttemptData;
}> = ({data}) => {
  const merchant = useCurrentMerchant();
  const localizationConfig = buildLocalizationConfig(
    merchant.defaultLanguage,
    merchant.supportedLanguages
  );

  const tableData = Object.values(data.answers).map(answer => {
    const label = getLocalizedContentOrThrow(answer.questionName, localizationConfig);
    const value = formatCohortFormResponse(answer.questionType, answer.answer);
    return {label, value};
  });

  return (
    <div className="flex flex-col space-y-4 rounded-md border border-border bg-slate-50">
      {tableData.map(row => (
        <div
          className="flex flex-row justify-between border-b border-border p-4 last:border-none"
          key={row.label}
        >
          <p className="text-slate-500">{row.label}</p>
          <p className="font-medium">{row.value}</p>
        </div>
      ))}
    </div>
  );
};

export default CohortFillFormTriggerIntegrationStepCompletionContextComponent;
