import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import ExportUserEventsUserPropertySelect from '@cohort/merchants/apps/talon-one/sync/ExportUserEventsUserPropertySelect';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import UserEventsPicker from '@cohort/merchants/components/UserEventsPicker';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const ExportUserEventsConfig: React.FC<SyncConfigComponentProps> = () => {
  const {setValue, register, control, watch} =
    useCohortForm<Extract<SyncConfigFormValues, {appId: 'talon-one'}>>();

  const {t} = useTranslation('app-talon-one', {
    keyPrefix: 'sync.exportUserEventsConfig',
  });

  const isEnabled = watch('userEventExportEnabled');
  const {allUserEventIds, userEventIds, exportEventsForAnonymousCustomers} =
    watch('userEventExportConfig') ?? {};

  useEffect(() => {
    if (allUserEventIds === true && userEventIds === undefined) {
      setValue('userEventExportConfig.userEventIds', []);
    }
    if (exportEventsForAnonymousCustomers !== true) {
      setValue('userEventExportConfig.exportEventsForAnonymousCustomers', false);
    }
  }, [allUserEventIds, exportEventsForAnonymousCustomers, setValue, userEventIds]);

  return (
    <div className="flex flex-col space-y-4">
      <RadioCards
        name="userEventExportConfig.allUserEventIds"
        label={t('labelEventIds')}
        register={register}
        control={control}
        disabled={!isEnabled}
        options={[
          {label: t('optionAllUserEvents'), value: 'true'},
          {label: t('optionSelectedUserEvents'), value: 'false'},
        ]}
        onChange={e => {
          setValue('userEventExportConfig.allUserEventIds', e.target.value === 'true');
        }}
        preventFieldOnChange
      />
      {allUserEventIds === false && (
        <UserEventsPicker
          name="userEventExportConfig.userEventIds"
          label={t('labelSelectUserEvents')}
          control={control}
          register={register}
        />
      )}
      <ExportUserEventsUserPropertySelect />
      <div className="grid items-center gap-4 [grid-template-columns:max-content_1fr]">
        <SwitchInput
          name="userEventExportConfig.exportEventsForAnonymousCustomers"
          labelPosition="right"
          register={register}
          control={control}
          disabled={!isEnabled}
        />
        <div>
          <p className="text-sm font-medium text-slate-700">
            {t('labelExportEventsForAnonymousCustomers')}
          </p>
          <p className="text-sm text-slate-500">
            {t('descriptionExportEventsForAnonymousCustomers')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExportUserEventsConfig;
