import type {
  DigitalAssetCollectionADto,
  DuplicateDigitalAssetCollectionADto,
} from '@cohort/admin-schemas/digitalAssetCollection';
import Button from '@cohort/merchants/components/buttons/Button';
import {CohortFormProvider} from '@cohort/merchants/components/form/CohortFormContext';
import Input from '@cohort/merchants/components/form/input/Input';
import Header from '@cohort/merchants/components/Header';
import {Dialog, DialogContent} from '@cohort/merchants/components/modals/Dialog';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import {duplicateDigitalAssetCollection} from '@cohort/merchants/lib/api/DigitalAssetCollections';
import {trackDigitalAssetCollectionDuplicateSuccess} from '@cohort/merchants/lib/Tracking';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type DuplicateDigitalAssetCollectionModalProps = {
  onClose: () => void;
  digitalAssetCollection: DigitalAssetCollectionADto;
};

const DuplicateDigitalAssetCollectionModalFormSchema = z.object({
  internalName: z.string().min(3, {message: 'errorTooShort3'}),
  nftEnabled: z.boolean(),
  nftNonTransferable: z.boolean(),
});
type DuplicateDigitalAssetCollectionModalForm = z.infer<
  typeof DuplicateDigitalAssetCollectionModalFormSchema
>;

const DuplicateDigitalAssetCollectionForm: React.FC<DuplicateDigitalAssetCollectionModalProps> = ({
  onClose,
  digitalAssetCollection,
}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.overview.duplicateDigitalAssetCollectionModal',
  });
  const {register, control, watch, handleSubmit, formState} =
    useCohortForm<DuplicateDigitalAssetCollectionModalForm>();
  const nftEnabled = watch('nftEnabled');

  const {isLoading, mutate: duplicateDigitalAssetCollectionMutation} = useCohortMutation({
    mutationFn: async (data: DuplicateDigitalAssetCollectionADto) =>
      duplicateDigitalAssetCollection(merchant.id, digitalAssetCollection.id, data),
    notifySuccessMessage: t('notificationDuplicateSuccess'),
    onSuccess: async newDigitalAsset => {
      await queryClient.invalidateQueries(digitalAssetCollectionsKeys.list(merchant.id));
      onClose();
      trackDigitalAssetCollectionDuplicateSuccess(newDigitalAsset);
    },
  });

  return (
    <form
      className="flex flex-col gap-6"
      onSubmit={handleSubmit(async data => duplicateDigitalAssetCollectionMutation(data))}
    >
      <Header title={t('duplicate')} subtitle={t('subtitle')} />
      <Input
        type="text"
        label={t('labelInternalName')}
        name="internalName"
        register={register}
        control={control}
        description={t('labelInternalNameDescription')}
      />
      <Input
        type="checkbox"
        name="nftEnabled"
        label={t('labelNftEnabled')}
        labelPosition="right"
        control={control}
        register={register}
      />
      {nftEnabled && (
        <Input
          type="checkbox"
          name="nftNonTransferable"
          label={t('labelNftNonTransferable')}
          labelPosition="right"
          control={control}
          register={register}
        />
      )}
      <div className="flex justify-between">
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          {t('buttonCancel')}
        </Button>
        <Button type="submit" loading={isLoading} disabled={!formState.isDirty}>
          {t('buttonDuplicate')}
        </Button>
      </div>
    </form>
  );
};

const DuplicateDigitalAssetCollectionModal: React.FC<DuplicateDigitalAssetCollectionModalProps> = ({
  onClose,
  digitalAssetCollection,
}) => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.overview.duplicateDigitalAssetCollectionModal',
  });

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="p-6" displayCloseButton={false} onClick={e => e.stopPropagation()}>
        <CohortFormProvider
          schema={DuplicateDigitalAssetCollectionModalFormSchema}
          defaultValues={{
            internalName: `${digitalAssetCollection.internalName} ${t('copySuffix')}`,
            nftEnabled: digitalAssetCollection.nftEnabled,
            nftNonTransferable: digitalAssetCollection.nftNonTransferable,
          }}
        >
          <DuplicateDigitalAssetCollectionForm
            onClose={onClose}
            digitalAssetCollection={digitalAssetCollection}
          />
        </CohortFormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default DuplicateDigitalAssetCollectionModal;
