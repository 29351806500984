import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import StravaActivitySelectPicker from '@cohort/merchants/apps/strava/triggerIntegratons/ActivitySelectPicker';
import StravaCompleteActivityMetricsForm from '@cohort/merchants/apps/strava/triggerIntegratons/completeActivity/ActivityMetricsForm';
import TriggerIntegrationDateRangeInput from '@cohort/merchants/components/trigger-integration/TriggerIntegrationDateRangeInput';

const StravaCompleteActivityTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = props => {
  return (
    <div className="flex flex-col gap-4">
      <TriggerIntegrationDateRangeInput
        {...props}
        startDateName="triggerIntegrationConfig.activityStartDate"
        endDateName="triggerIntegrationConfig.activityEndDate"
      />

      <StravaActivitySelectPicker
        {...props}
        fieldName="triggerIntegrationConfig.activityGroups"
        hasFieldName="triggerIntegrationConfig.hasActivityTypes"
      />

      <StravaCompleteActivityMetricsForm {...props} />
    </div>
  );
};
export default StravaCompleteActivityTriggerIntegrationConfigComponent;
