import type {AssetKind} from '@cohort/shared/schema/common/assets';
import {isImageFile, isVideoFile} from '@cohort/shared/utils/mimeTypes';

export const MAX_BYTES_SIZE = 1024 * 1024 * 1024; // 1Gb

export type SizedAssetKind =
  | 'logo'
  | 'banner'
  | 'contentImage'
  | 'perkBanner'
  | 'membershipPassBanner';

export type AssetMinDimension = {
  width: number;
  height: number;
};

export type AssetMinDimensions = Record<SizedAssetKind, AssetMinDimension>;

export const ASSETS_MIN_DIMENSIONS = {
  banner: {width: 2048, height: 2048},
  logo: {width: 256, height: 256},
  membershipPassBanner: {width: 1280, height: 729},
  perkBanner: {width: 1280, height: 729},
  contentImage: {width: 1280, height: 729},
} as AssetMinDimensions;

export const isLandscapeVisual = (assetKind: AssetKind): boolean => {
  return ['banner', 'perkBanner', 'membershipPassBanner', 'contentImage'].includes(assetKind);
};

export const MAX_FILE_SIZE_IMAGE_MB = 20;

export const MAX_FILE_SIZE_VIDEO_MB = 300;

export function getMaxFileSize(
  assetKind: AssetKind,
  mimeType: string
): {size: number; label: string} {
  if (assetKind === 'privateContent' || assetKind === 'termsAndConditions') {
    return {size: MAX_BYTES_SIZE, label: '1 Gb'};
  }
  if (isVideoFile(mimeType)) {
    return {size: MAX_FILE_SIZE_VIDEO_MB * 1024 * 1024, label: `${MAX_FILE_SIZE_VIDEO_MB} Mb`};
  }
  if (isImageFile(mimeType)) {
    return {size: MAX_FILE_SIZE_IMAGE_MB * 1024 * 1024, label: `${MAX_FILE_SIZE_IMAGE_MB} Mb`};
  }
  return {size: MAX_BYTES_SIZE, label: '1 Gb'};
}
