import {SelectMediaSheet} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {
  SelectMediaSheetCards,
  SelectMediaSheetPanel,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import FacebookPostCard from '@cohort/merchants/apps/facebook/triggers/FacebookPostCard';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import type {UseFormSetValue} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type SelectPostSheetProps = {
  posts: FacebookPost[];
  setValue: UseFormSetValue<StepTriggerFormType>;
};
const SelectPostSheet: React.FC<SelectPostSheetProps> = ({posts, setValue}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'components.selectPostSheet',
  });

  const onSubmit = (selectedMediaId: string): void => {
    const selectedPost = posts.find(post => post.id === selectedMediaId);
    if (selectedPost === undefined) {
      throw new Error('Selected post not found');
    }
    setValue('triggerIntegrationConfig.postId', selectedPost.id);
    setValue('triggerIntegrationConfig.postPermalink', selectedPost.permalinkUrl);
  };

  return (
    <SelectMediaSheet onSubmit={onSubmit} title={t('title')}>
      <SelectMediaSheetPanel isEmpty={posts.length === 0}>
        <SelectMediaSheetCards medias={posts} Card={FacebookPostCard} />
      </SelectMediaSheetPanel>
    </SelectMediaSheet>
  );
};

export default SelectPostSheet;
