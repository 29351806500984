import Button from '@cohort/merchants/components/buttons/Button';
import Input from '@cohort/merchants/components/form/input/Input';
import {PlusCircle, Trash} from '@phosphor-icons/react';
import {Fragment} from 'react';
import type {
  ArrayPath,
  Control,
  FieldArray,
  FieldArrayWithId,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import {useController, useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type DynamicKeyValueInputProps<T extends FieldValues> = {
  name: ArrayPath<T>;
  control: Control<T>;
  register: UseFormRegister<T>;
  labels: {
    title: string;
    addBtn: string;
    key: string;
    value: string;
  };
};

export default function DynamicKeyValueInput<T extends FieldValues>({
  name,
  control,
  register,
  labels,
}: DynamicKeyValueInputProps<T>): JSX.Element {
  const {t} = useTranslation('forms', {keyPrefix: 'validation'});
  const {field, fieldState} = useController({control, name: name as Path<T>});
  const {fields, append, remove} = useFieldArray({
    name,
    control,
  });

  // @Devs - Add a default field to avoid empty arrays when values are not set in form defaultValues.
  const defaultFields: FieldArrayWithId<T, ArrayPath<T>, 'id'>[] = [
    {
      id: '0',
      key: '',
      value: '',
    } as unknown as FieldArrayWithId<T, ArrayPath<T>, 'id'>,
  ];
  const initialFields = fields.length === 0 ? defaultFields : fields;

  return (
    <Fragment>
      <div id="metadata-section" className="mb-2 flex items-center justify-between">
        <p className="text-sm font-medium">{labels.title}</p>
      </div>
      <ul className="relative mb-4 flex flex-col gap-4">
        {/*
          absolute positioning and opacity-0 to have it existing in the page
          but not visible nor interactive. We use this trick to enable focus
          on form validation
        */}
        <input
          className="absolute h-0 w-0 opacity-0"
          type="text"
          name={field.name}
          ref={field.ref}
        />
        {initialFields.map((item, index) => (
          <li key={item.id} className="flex items-center justify-between gap-4">
            <div className="flex-1">
              <Input
                type="text"
                name={`${name}.${index}.key` as Path<T>}
                register={register}
                control={control}
                placeholder={labels.key}
              />
            </div>
            <div className="flex-1">
              <Input
                type="text"
                name={`${name}.${index}.value` as Path<T>}
                register={register}
                control={control}
                placeholder={labels.value}
              />
            </div>
            {fields.length > 1 && (
              <div className="flex items-center self-end rounded-md border border-slate-200 p-2 text-slate-400 shadow-sm">
                <button onClick={() => remove(index)}>
                  <Trash size={20} />
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>
      {fieldState.error?.message && (
        <p data-testid={`${name}-error`} className="mb-2 text-sm text-red-500">
          {t(fieldState.error.message)}
        </p>
      )}
      <Button
        variant="ghost"
        className="px-2"
        data-testid="add-metadata-field-btn"
        onClick={() =>
          append({
            key: '',
            value: '',
          } as FieldArray<T>)
        }
      >
        <PlusCircle size={20} className="mr-2 stroke-2" />
        {labels.addBtn}
      </Button>
    </Fragment>
  );
}
