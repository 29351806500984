import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {UserPropertyDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export const SalesforceUserPropertyColumnSchema = z.object({
  columnName: z.string(),
  referenceId: z.string(),
  propertyName: z.string(),
  dataType: UserPropertyDataTypeSchema,
});
export type SalesforceUserPropertyColumn = z.infer<typeof SalesforceUserPropertyColumnSchema>;

// User sync
export const SalesforceUserSyncConfigSchema = z.object({
  createUsers: z.boolean(),
  salesforceObjectName: z.string(),
  emailFieldName: z.string(),
  userPropertyColumns: z
    .array(SalesforceUserPropertyColumnSchema)
    .transform(columns => columns.filter(column => column.referenceId !== '')),
});
export type SalesforceUserSyncConfig = z.infer<typeof SalesforceUserSyncConfigSchema>;

export const SalesforceUserSyncStateSchema = z.object({
  maxUpdatedAt: z.string().nullable(),
});
export type SalesforceUserSyncState = z.infer<typeof SalesforceUserSyncStateSchema>;

// User export
export const SalesforceUserExportConfigSchema = z.object({
  createSalesforceUsers: z.boolean(),
  salesforceObjectName: z.string(),
  emailFieldName: z.string(),
  externalIdFieldName: z.string().min(1),
  userProperties: z
    .preprocess(
      // Preprocess userProperties array to filter out invalid properties before validation
      data => {
        if (Array.isArray(data)) {
          return (data as Record<string, string>[]).filter(
            userProperty =>
              userProperty.userPropertyId !== '' && userProperty.salesforceFieldName !== ''
          );
        }
        return data;
      },
      z.array(
        z.object({
          userPropertyId: z.string().uuid(),
          salesforceFieldName: z.string(),
        })
      )
    )
    .transform(userProperties =>
      userProperties.filter(
        userProperty =>
          userProperty.userPropertyId !== '' && userProperty.salesforceFieldName !== ''
      )
    ),
});
export type SalesforceUserExportConfig = z.infer<typeof SalesforceUserExportConfigSchema>;

export const SalesforceUserExportStateSchema = z.object({
  salesforceIdAttributeLastCreatedAt: z.string().nullable(),
  userAttributeLastUpdatedAt: z.string().nullable(),
});
export type SalesforceUserExportState = z.infer<typeof SalesforceUserExportStateSchema>;

export type SalesforceSyncStruct = SyncIntegrationStruct<
  SalesforceUserSyncConfig,
  SalesforceUserSyncState,
  never,
  never,
  SalesforceUserExportConfig,
  SalesforceUserExportState
>;

export const SalesforceSyncIntegrationSpec: SyncIntegrationSpec<SalesforceSyncStruct> = {
  userSyncsConfigSchema: SalesforceUserSyncConfigSchema as z.ZodSchema<
    SalesforceSyncStruct['UserSyncConfig'] // defzault breaks the type inference
  >,
  userSyncsStateSchema: SalesforceUserSyncStateSchema,
  userEventExportConfigSchema: null,
  contentSyncConfigSchema: null,
  userExportConfigSchema: SalesforceUserExportConfigSchema as z.ZodSchema<
    SalesforceSyncStruct['UserExportConfig'] // preprocess breaks the type inference
  >,
  userExportStateSchema: SalesforceUserExportStateSchema,
};
