import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import BaseTextEditor from '@cohort/merchants/components/form/textEditor/BaseTextEditor';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import {updateLocalizedString} from '@cohort/merchants/lib/Utils';
import type {Language} from '@cohort/shared/schema/common';
import get from 'lodash/get';
import type {FieldValues} from 'react-hook-form';
import {useController} from 'react-hook-form';

type LocalizedTextEditorInputProps<T extends FieldValues> = WrappedField &
  FormField<T> & {
    selectedLanguage: Language;
    placeholder?: string;
    disabled?: boolean;
  };

export default function LocalizedTextEditorInput<T extends FieldValues>({
  label,
  description,
  name,
  placeholder,
  disabled,
  control,
  optional,
  selectedLanguage,
}: LocalizedTextEditorInputProps<T>): JSX.Element {
  const {field, fieldState} = useController({
    control,
    name,
  });

  const errorMessage =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    get(fieldState, `error.${selectedLanguage}.message`) ?? get(fieldState, `error.message`);

  return (
    <BaseTextEditor
      label={label}
      name={name}
      description={description}
      error={errorMessage}
      onChange={(value: string | null) => {
        field.onChange(updateLocalizedString(selectedLanguage, field.value, value));
      }}
      onBlur={field.onBlur}
      placeholder={placeholder}
      disabled={disabled}
      value={field.value?.[selectedLanguage] ?? ''}
      key={`${name}.${selectedLanguage}`}
      optional={optional}
    />
  );
}
