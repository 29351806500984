import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {TalonOneCampaignSchema} from '@cohort/shared/apps/talon-one/schema';
import {z} from 'zod';

export const ListCampaignsInputSchema = z.object({});
export type ListCampaignsInput = z.infer<typeof ListCampaignsInputSchema>;

export const ListCampaignsOutputSchema = z.object({
  campaigns: z.array(TalonOneCampaignSchema),
});
export type ListCampaignsOutput = z.infer<typeof ListCampaignsOutputSchema>;

export type ListCampaignsActionStruct = ActionStruct<
  'list-campaigns',
  ListCampaignsInput,
  ListCampaignsOutput
>;
export const ListCampaignsActionSpec: ActionSpec<ListCampaignsActionStruct> = {
  id: 'list-campaigns',
  inputSchema: ListCampaignsInputSchema,
  outputSchema: ListCampaignsOutputSchema,
};
