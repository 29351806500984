import {Badge} from '@cohort/merchants/components/Badge';
import {useTranslation} from 'react-i18next';

interface DigitalAssetCollectionStatusBadgeProps {
  size?: 'large' | 'small';
  campaignsCount: number;
}

export const DigitalAssetCollectionStatusBadge: React.FC<
  DigitalAssetCollectionStatusBadgeProps
> = ({size, campaignsCount}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'digitalAssets.digitalAssetCollectionStatusBadge',
  });

  return (
    <Badge
      text={campaignsCount < 1 ? t('statusLive') : t('statusLive', {count: campaignsCount})}
      backgroundColor="bg-green-100"
      textColor="text-green-800"
      dotColor="text-green-400"
      size={size ?? 'large'}
    />
  );
};
