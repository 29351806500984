import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {BaseUserEventExportConfigSchema} from '@cohort/shared/apps/sync';
import type {z} from 'zod';

export const BrevoUserEventExportConfigSchema = BaseUserEventExportConfigSchema.extend({});
export type BrevoUserEventExportConfig = z.infer<typeof BrevoUserEventExportConfigSchema>;

export type BrevoSyncStruct = SyncIntegrationStruct<
  never,
  never,
  never,
  BrevoUserEventExportConfig,
  never,
  never
>;

export const BrevoSyncSpec: SyncIntegrationSpec<BrevoSyncStruct> = {
  contentSyncConfigSchema: null,
  userSyncsConfigSchema: null,
  userSyncsStateSchema: null,
  userEventExportConfigSchema: BrevoUserEventExportConfigSchema,
  userExportConfigSchema: null,
  userExportStateSchema: null,
};
