/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import {SHOPIFY_APP_ID} from '@cohort/shared/apps/shopify';
import type {ShopifyGetShopActionStruct} from '@cohort/shared/apps/shopify/actions/getShop';

export const shopifyAppKeys = {
  shopifyApp: ['shopifyApp'] as const,
  getShop: (merchantId: string) => [...shopifyAppKeys.shopifyApp, merchantId, 'get-shop'] as const,
};

export const useShopifyShop = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
) => {
  return useCohortQuery({
    queryKey: shopifyAppKeys.getShop(merchantId),
    queryFn: async () => {
      return executeAction<ShopifyGetShopActionStruct>(merchantId, {
        appId: SHOPIFY_APP_ID,
        actionId: 'get-shop',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};
