import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import Input from '@cohort/merchants/components/form/input/Input';
import {useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

function setDefaultDateFieldValue(
  date: {value: Date; onChange: (value?: string) => void},
  defaultValue: Date
): void {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (date.value) {
    if (typeof date.value === 'string') {
      date.value = new Date(date.value);
    }

    /**
     * According to MDN, the value of an input of type datetime-local must be
     * in the format "yyyy-MM-ddThh:mm" (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#value).
     */
    date.onChange(
      [
        [
          date.value.getFullYear(),
          `0${date.value.getMonth() + 1}`.slice(-2),
          `0${date.value.getDate()}`.slice(-2),
        ].join('-'),
        date.value.toLocaleTimeString().split(':').slice(0, -1).join(':'),
      ].join('T')
    );
  } else {
    date.onChange(defaultValue.toISOString().split(':').slice(0, -1).join(':'));
  }
}

const TriggerIntegrationDateRangeInput: React.FC<
  {
    endDateName: `triggerIntegrationConfig.${string}`;
    startDateName: `triggerIntegrationConfig.${string}`;
  } & TriggerIntegrationConfigComponentProps
> = ({startDateName, endDateName, formReturn: {register, control}}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'triggerIntegrations',
  });

  const {field: startDate} = useController({
    control,
    name: startDateName,
  });
  const {field: endDate} = useController({
    control,
    name: endDateName,
  });
  const [minEndDate, setMinEndDate] = useState(startDate.value);
  const [datesKey, setDatesKey] = useState(0);

  // Setting default values
  useEffect(() => {
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);
    setDefaultDateFieldValue(startDate, now);

    const oneWeekFromNow = new Date(now.setDate(now.getDate() + 7));
    oneWeekFromNow.setUTCHours(23, 59, 59, 999);

    setDefaultDateFieldValue(endDate, oneWeekFromNow);

    setDatesKey(datesKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMinEndDate(startDate.value);
  }, [startDate]);

  return (
    <div key={datesKey} className="flex flex-row gap-4">
      <Input
        name={startDateName}
        label={t('labelActivityStartDate')}
        register={register}
        control={control}
        type="datetime-local"
      />
      <Input
        name={endDateName}
        label={t('labelActivityEndDate')}
        register={register}
        control={control}
        type="datetime-local"
        min={minEndDate}
      />
    </div>
  );
};

export default TriggerIntegrationDateRangeInput;
