import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortBadgeLostPropertiesSchema = z.object({
  badgeId: z.string(),
  cohortId: z.string().uuid(),
  resourceDeleted: z.boolean(),
});
export type CohortBadgeLostProperties = z.infer<typeof CohortBadgeLostPropertiesSchema>;

export type CohortBadgeLostEventStruct = UserEventStruct<
  'cohort.badge.lost',
  keyof CohortBadgeLostProperties,
  CohortBadgeLostProperties,
  CohortBadgeLostProperties
>;

export const CohortBadgeLostEventSpec: UserEventSpec<CohortBadgeLostEventStruct> = {
  id: 'cohort.badge.lost',
  name: 'Lost a Badge',
  propertiesSchema: CohortBadgeLostPropertiesSchema,
  exposedPropertiesSchema: CohortBadgeLostPropertiesSchema,
  description: 'Event Triggerred when a user loses a badge',
  resources: {
    badgeId: 'cohort.badge',
    cohortId: 'cohort.cohort',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['badgeId'],
  },
};
