import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {UrlInputSchema} from '@cohort/shared/schema/common/urls';
import {z} from 'zod';

export const CohortLinkMediaConfigSchema = z.object({
  url: UrlInputSchema,
  imageSrc: z.string().nullable(),
  title: z.string().nullable(),
});
export type CohortLinkMediaConfig = z.infer<typeof CohortLinkMediaConfigSchema>;

export type CohortLinkMediaStruct = MediaStruct<'cohort.link', CohortLinkMediaConfig>;

export const CohortLinkMediaSpec: MediaSpec<CohortLinkMediaStruct> = {
  kind: 'cohort.link' as const,
  configSchema: CohortLinkMediaConfigSchema,
};
