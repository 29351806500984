import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import type {CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {
  CohortFormAnswerSchema,
  CohortFormConfigSchema,
  CohortFormQuestionTypeSchema,
} from '@cohort/shared/schema/common/cohortForm';
import {z} from 'zod';

export const CohortFormStateSchema = z.object({});
export type CohortFormState = z.infer<typeof CohortFormStateSchema>;

export const CohortFormAccessDataSchema = z.object({});
export type CohortFormAccessData = z.infer<typeof CohortFormAccessDataSchema>;

export const CohortFormUsageDataSchema = z.object({
  values: z.record(z.string(), CohortFormAnswerSchema),
});
export type CohortFormUsageData = z.infer<typeof CohortFormUsageDataSchema>;

export const ExposedCohortFormUsageAnswerDataSchema = z.object({
  questionId: z.string().describe('The ID of the question'),
  questionType: CohortFormQuestionTypeSchema.describe('The type of the question'),
  referenceId: z.string().nullable().describe('The reference ID of the question, if any'),
  answer: CohortFormAnswerSchema.describe(
    'The answer to the question Checkboxes will be represented as booleans, and other fields as strings. Optional fields that were not filled by the user will be represented as null.'
  ),
});
export type ExposedCohortFormUsageAnswerData = z.infer<
  typeof ExposedCohortFormUsageAnswerDataSchema
>;

export const ExposedCohortFormUsageDataSchema = z
  .object({
    answers: z
      .record(ExposedCohortFormUsageAnswerDataSchema)
      .describe('A dictionnary containing all the answers filled by the user.'),
  })
  .describe('The data for the `form` perk App');
export type ExposedCohortFormUsageData = z.infer<typeof ExposedCohortFormUsageDataSchema>;

export type CohortFormPerkIntegrationStruct = PerkIntegrationStruct<
  'cohort.form',
  CohortFormConfig,
  CohortFormState,
  CohortFormAccessData,
  CohortFormUsageData,
  CohortFormUsageData,
  ExposedCohortFormUsageData
>;

export const CohortFormPerkIntegrationSpec: PerkIntegrationSpec<CohortFormPerkIntegrationStruct> = {
  id: 'cohort.form' as const,
  configSchema: CohortFormConfigSchema,
  stateSchema: CohortFormStateSchema,
  accessDataSchema: CohortFormAccessDataSchema,
  usageInputDataSchema: CohortFormUsageDataSchema,
  internalUsageDataSchema: CohortFormUsageDataSchema,
  exposedUsageDataSchema: ExposedCohortFormUsageDataSchema,
  defaultMaxUsagesPerToken: 1,
  requiresMerchantConnection: false,
};
