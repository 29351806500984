import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';

interface LoaderProps {
  size?: number;
  color?: 'white' | 'gray';
}

const Loader: React.FC<LoaderProps> = ({size = 40, color = 'white'}) => {
  const loaderDivsSize = size - size * 0.2; // We apply 80% of the size of the loader
  const loaderDivsStyle = `block absolute border-4 rounded-full animate-cohort-loader`;
  const loaderDivsColor =
    color === 'white'
      ? '[border-color:#fff_transparent_transparent_transparent]'
      : '[border-color:#aaa_transparent_transparent_transparent]';
  const divStyleParam = {
    width: `${loaderDivsSize}px`,
    height: `${loaderDivsSize}px`,
  };

  return (
    <div
      className="relative flex items-center"
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <div
        className={cn(loaderDivsStyle, loaderDivsColor, '[animation-delay:-0.45s!important]')}
        style={divStyleParam}
      ></div>
      <div
        className={cn(loaderDivsStyle, loaderDivsColor, '[animation-delay:-0.3s!important]')}
        style={divStyleParam}
      ></div>
      <div
        className={cn(loaderDivsStyle, loaderDivsColor, '[animation-delay:-0.15s!important]')}
        style={divStyleParam}
      ></div>
      <div></div>
    </div>
  );
};

export default Loader;
