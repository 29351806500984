import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useTranslation} from 'react-i18next';

const YoutubeSyncConfigComponent: React.FC<SyncConfigComponentProps> = () => {
  const {t} = useTranslation('app-youtube', {
    keyPrefix: 'sync.syncConfigComponent',
  });

  const {register, control} = useCohortForm<Extract<SyncConfigFormValues, {appId: 'youtube'}>>();

  return (
    <div className="mb-24 space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border border-slate-200 p-6">
        <SwitchInput
          name="contentSyncEnabled"
          label={t('labelEnableContentSync')}
          labelPosition="right"
          register={register}
          control={control}
        />
      </div>
    </div>
  );
};

export default YoutubeSyncConfigComponent;
