type StepCompletionLinkProps = {
  url: string;
  children?: React.ReactNode; // will be automatically injected by the Trans component from react-i18next
};

const StepCompletionLink: React.FC<StepCompletionLinkProps> = ({url, children}) => (
  <a
    className="font-semibold text-gray-900"
    target="_blank"
    rel="noopener noreferrer nofollow"
    href={url}
  >
    {children}
  </a>
);

export default StepCompletionLink;
