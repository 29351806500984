import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import {createConnectAccountTriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {TwitterMerchantConnectorStruct} from '@cohort/shared/apps/twitter/merchantConnector';
import {TwitterMerchantConnectorSpec} from '@cohort/shared/apps/twitter/merchantConnector';
import {TwitterFollowTriggerIntegrationSpec} from '@cohort/shared/apps/twitter/triggers/follow';
import type {TwitterConnectorStruct} from '@cohort/shared/apps/twitter/userConnector';
import {TwitterConnectorSpec} from '@cohort/shared/apps/twitter/userConnector';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {
  createAppUsernameUserPropertySpec,
  createBaseAppUserPropertySpec,
} from '@cohort/shared/apps/userProperty';

export const TWITTER_APP_ID = 'twitter' as const;
export const TWITTER_APP_NAME = 'X' as const;

export const TwitterUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(TWITTER_APP_ID, TWITTER_APP_NAME),
  createAppUsernameUserPropertySpec(TWITTER_APP_ID, TWITTER_APP_NAME),
  {
    id: 'twitter.followers-count',
    dataType: 'number',
    name: 'X Number of Followers',
    scope: 'profile',
    profileKey: 'followersCount',
  },
  {
    id: 'twitter.following-count',
    dataType: 'number',
    name: 'X Number of Following',
    scope: 'profile',
    profileKey: 'followingCount',
  },
  {
    id: 'twitter.tweets-count',
    dataType: 'number',
    name: 'X Number of Tweets',
    scope: 'profile',
    profileKey: 'tweetsCount',
  },
  {
    id: 'twitter.likes-count',
    dataType: 'number',
    name: 'X Number of Likes',
    scope: 'profile',
    profileKey: 'likesCount',
  },
  {
    id: 'twitter.is-verified',
    dataType: 'boolean',
    name: 'X Account Verified',
    scope: 'profile',
    profileKey: 'isVerified',
  },
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type TwitterUserPropertyIds = (typeof TwitterUserPropertiesSpecs)[number]['id'];

export const TwitterTriggerIntegrationSpecs = [
  createConnectAccountTriggerIntegrationSpec(TWITTER_APP_ID),
  TwitterFollowTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;

type TwitterTriggerIntegrationIds = (typeof TwitterTriggerIntegrationSpecs)[number]['id'];

export type TwitterAppStruct = AppStruct<
  'twitter',
  TwitterMerchantConnectorStruct,
  TwitterConnectorStruct,
  null,
  never,
  never,
  never,
  TwitterTriggerIntegrationIds,
  never,
  TwitterUserPropertyIds,
  never,
  never
>;

export const TwitterAppSpec: AppSpec<TwitterAppStruct> = {
  id: TWITTER_APP_ID,
  name: TWITTER_APP_NAME,
  category: 'social-media',
  merchantConnector: TwitterMerchantConnectorSpec,
  userConnector: TwitterConnectorSpec,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: TwitterTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: TwitterUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: [],
};
