import type {
  CheckReferenceIdAvailabilityResponseADto,
  CreateUserPropertyDataADto,
  ListUserPropertiesParamsADto,
  UpdateUserPropertiesDataADto,
  UpdateUserPropertyDataADto,
  UserPropertyADto,
} from '@cohort/admin-schemas/userProperty';
import {
  CheckReferenceIdAvailabilityResponseASchema,
  UserPropertyASchema,
} from '@cohort/admin-schemas/userProperty';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';
import {z} from 'zod';

export async function getUserProperty(
  merchantId: string,
  userPropertyId: string
): Promise<UserPropertyADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/user-properties/${userPropertyId}`, {
    expect: HttpCodes.SUCCESS,
    parser: UserPropertyASchema.parse,
  });
}

export async function getUserPropertyReferenceIdAvailability(
  merchantId: string,
  params: {
    appId: string | null;
    referenceId: string;
  }
): Promise<CheckReferenceIdAvailabilityResponseADto> {
  return apiCall(
    'GET',
    `/v1/merchants/${merchantId}/user-properties/check-referenceId-availability/`,
    {
      expect: HttpCodes.SUCCESS,
      parser: CheckReferenceIdAvailabilityResponseASchema.parse,
      params,
    }
  );
}

export async function getUserProperties(
  merchantId: string,
  params: ListUserPropertiesParamsADto
): Promise<[PaginationDto, UserPropertyADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/user-properties`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(UserPropertyASchema),
    params,
  });
}

export async function createUserProperty(
  merchantId: string,
  body: CreateUserPropertyDataADto
): Promise<UserPropertyADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/user-properties`, {
    expect: HttpCodes.CREATED,
    parser: UserPropertyASchema.parse,
    body,
  });
}

export async function updateUserProperty(
  merchantId: string,
  userPropertyId: string,
  body: UpdateUserPropertyDataADto
): Promise<UserPropertyADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/user-properties/${userPropertyId}`, {
    expect: HttpCodes.SUCCESS,
    parser: UserPropertyASchema.parse,
    body,
  });
}

export const updateUserProperties = async (
  merchantId: string,
  data: UpdateUserPropertiesDataADto
): Promise<Array<UserPropertyADto>> => {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/user-properties`, {
    expect: HttpCodes.SUCCESS,
    parser: z.array(UserPropertyASchema).parse,
    body: data,
  });
};

export async function deleteUserProperty(
  merchantId: string,
  userPropertyId: string
): Promise<object> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/user-properties/${userPropertyId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}
