import type {ConnectorSpec} from '@cohort/shared/apps/app';
import type {BaseProfileProperties, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials} from '@cohort/shared/apps/oauth';
import {OAuth2AppCredentialsSchema, OAuth2CredentialsSchema} from '@cohort/shared/apps/oauth';
import type {z} from 'zod';

export const KlaviyoCredentialsSchema = OAuth2CredentialsSchema;
export type KlaviyoCredentials = z.infer<typeof KlaviyoCredentialsSchema>;

export type KlaviyoConnectorStruct = ConnectorStruct<
  'klaviyo',
  OAuth2AppCredentials,
  KlaviyoCredentials,
  BaseProfileProperties,
  null
>;

export const KlaviyoConnectorSpec = {
  id: 'klaviyo',
  type: 'oauth2',
  appCredentialsSchema: OAuth2AppCredentialsSchema,
  credentialsSchema: KlaviyoCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<KlaviyoConnectorStruct>;
