import Loader from '@cohort/merchants/components/Loader';
import {cn} from '@cohort/shared-frontend/utils/classNames';

type LoadingStateProps = {
  className?: string;
};
const LoadingState: React.FC<LoadingStateProps> = ({className}) => (
  <div
    className={cn(
      'flex h-80 flex-row items-center justify-center rounded-md bg-slate-50',
      className
    )}
  >
    <Loader size={60} color="gray" />
  </div>
);

export default LoadingState;
