import type {ConnectionEditComponentProps} from '@cohort/merchants/apps';
import type {
  CustomOAuthErrorMessage,
  PostConnectionConfigComponentProps,
  PreConnectionConfigComponentProps,
} from '@cohort/merchants/components/connections/OAuthEditConnectionComponent';
import OAuthEditConnectionComponent from '@cohort/merchants/components/connections/OAuthEditConnectionComponent';
import type {AppId} from '@cohort/shared/apps';
import type {AppStruct} from '@cohort/shared/apps/app';

export function makeOauthEditConnectionComponent<A extends AppStruct>(
  appId: A['Id'],
  config?: {
    customOauthErrorMessage?: CustomOAuthErrorMessage;
    preConnectionConfigComponent?: React.FC<PreConnectionConfigComponentProps>;
    postConnectionConfigComponent?: React.FC<PostConnectionConfigComponentProps>;
  }
): React.FC<ConnectionEditComponentProps> {
  return (props: ConnectionEditComponentProps) => (
    <OAuthEditConnectionComponent
      {...props}
      appId={appId as AppId}
      customOauthErrorMessage={config?.customOauthErrorMessage}
      PreConnectionConfigComponent={config?.preConnectionConfigComponent}
      PostConnectionConfigComponent={config?.postConnectionConfigComponent}
    />
  );
}
