import type {MediaItem} from '@cohort/merchants/apps/common/triggers/utils';
import {cn} from '@cohort/shared-frontend/utils/classNames';

export type MediaCardProps<M extends MediaItem> = {
  media?: M;
  isSelected?: boolean;
  onClick?: () => void;
};

type TriggerMediaCardWrapperProps = {
  hasMedia: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
};

const TriggerMediaCardWrapper: React.FC<TriggerMediaCardWrapperProps> = ({
  hasMedia,
  children,
  onClick,
  isSelected,
}) => {
  return (
    <div
      className={cn(
        'flex h-full w-full flex-col space-y-4 overflow-hidden rounded-lg bg-white p-4 shadow-sm',
        !hasMedia ? 'size-[120px] items-center justify-center' : 'max-h-[270px]',
        isSelected && 'border-primary ring-2 ring-primary',
        onClick && 'cursor-pointer'
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default TriggerMediaCardWrapper;
