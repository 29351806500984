type EnvConfigKey = 'cloudinaryFolder' | 'launchDarklyClientId';

const CohortEnvs = ['development', 'staging', 'prod', 'test'] as const;

export type CohortEnv = (typeof CohortEnvs)[number];

export const ApiHosts = {
  admin: /^admin-api(--\w+)?\./u,
  auth: /^app-auth(--\w+)?\./u, // TODO: Change to auth
  internal: /^internal-api(--\w+)?\./u,
  links: /^links(--\w+)?\./u,
  public: /^api(--\w+)?\./u,
  qrReader: /^qr-reader-api(--\w+)?\./u,
  wallet: /^app-api(--\w+)?\./u,
} as const;

const CONFIG: Record<CohortEnv, Record<EnvConfigKey, string>> = {
  test: {
    cloudinaryFolder: 'test',
    launchDarklyClientId: 'test',
  },
  development: {
    cloudinaryFolder: 'cohort-dev',
    launchDarklyClientId: '63dad4202361f712d5126705',
  },
  staging: {
    cloudinaryFolder: 'cohort-staging',
    launchDarklyClientId: '63dad3bc2311f913222a9541',
  },
  prod: {
    cloudinaryFolder: 'cohort-prod',
    launchDarklyClientId: '63dad3bc2311f913222a9542',
  },
};

export function getEnv(env: string): CohortEnv {
  if (!(CohortEnvs as readonly string[]).includes(env)) {
    // Renamed the error to remove COHORT_ENV from the error message as it gets replaced by Vite during build and breaks the error message formatting resulting in error at build time.
    throw new Error('Cohort environment variable is not set');
  }
  return env as CohortEnv;
}

export function getConfigVar(env: string, key: EnvConfigKey): string {
  return CONFIG[getEnv(env)][key];
}
