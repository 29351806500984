import type {DigitalAssetCollectionAnalyticsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useDigitalAssetCollection} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import SkeletonLoader from '@cohort/merchants/layouts/SkeletonLoader';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentDigitalAssetCollectionProviderProps = {
  children: React.ReactNode;
};

export const CurrentDigitalAssetCollectionContext =
  createContext<DigitalAssetCollectionAnalyticsADto | null>(null);

export const CurrentDigitalAssetCollectionProvider: React.FC<
  CurrentDigitalAssetCollectionProviderProps
> = ({children}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {digitalAssetCollectionId} = useParams();

  const {data: digitalAssetCollection, isFetched} = useDigitalAssetCollection(
    merchantId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    digitalAssetCollectionId!,
    {enabled: digitalAssetCollectionId !== undefined}
  );

  if (digitalAssetCollectionId === undefined) {
    return <NotFoundSection />;
  }

  if (isFetched && !digitalAssetCollection) {
    return <NotFoundSection />;
  }

  if (digitalAssetCollection === undefined) {
    return <SkeletonLoader />;
  }

  return (
    <CurrentDigitalAssetCollectionContext.Provider value={digitalAssetCollection}>
      {children}
    </CurrentDigitalAssetCollectionContext.Provider>
  );
};
