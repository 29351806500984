import type {ConnectorSpec, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials} from '@cohort/shared/apps/oauth';
import {OAuth2AppCredentialsSchema, OAuth2CredentialsSchema} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const FacebookMerchantProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  username: z.string().nullable(),
});
export type FacebookMerchantProfileProperties = z.infer<
  typeof FacebookMerchantProfilePropertiesSchema
>;

export const FacebookMerchantUserCredentialsSchema = OAuth2CredentialsSchema.extend({
  pagesAccessTokens: z.record(z.string()),
});
export type FacebookMerchantUserCredentials = z.infer<typeof FacebookMerchantUserCredentialsSchema>;

export const FacebookMerchantConnectionConfigSchema = z.object({
  facebookPageId: z.string(),
});
export type FacebookMerchantConnectionConfig = z.infer<
  typeof FacebookMerchantConnectionConfigSchema
>;

export type FacebookMerchantConnectorStruct = ConnectorStruct<
  'facebook-merchant',
  OAuth2AppCredentials,
  FacebookMerchantUserCredentials,
  FacebookMerchantProfileProperties,
  FacebookMerchantConnectionConfig
>;

export const FacebookMerchantConnectorSpec = {
  id: 'facebook-merchant' as const,
  type: 'oauth2',
  appCredentialsSchema: OAuth2AppCredentialsSchema,
  credentialsSchema: FacebookMerchantUserCredentialsSchema,
  profilePropertiesSchema: FacebookMerchantProfilePropertiesSchema,
  connectionConfigSchema: FacebookMerchantConnectionConfigSchema,
} satisfies ConnectorSpec<FacebookMerchantConnectorStruct>;
