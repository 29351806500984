import type {ConnectorStruct} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import type {TwitterProfileProperties} from '@cohort/shared/apps/twitter/common';
import {TwitterProfilePropertiesSchema} from '@cohort/shared/apps/twitter/common';

export type TwitterMerchantConnectorStruct = ConnectorStruct<
  'twitter-merchant',
  OAuth2AppCredentials,
  OAuth2Credentials,
  TwitterProfileProperties,
  null
>;

export const TwitterMerchantConnectorSpec =
  basicOauth2ConnectorSpec<TwitterMerchantConnectorStruct>(
    'twitter-merchant',
    TwitterProfilePropertiesSchema
  );
