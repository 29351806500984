import {cn} from '@cohort/shared-frontend/utils/classNames';

interface SectionSeparatorProps {
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const sizeMargin = {
  small: 'my-4',
  medium: 'my-8',
  large: 'my-12',
};

const SectionSeparator: React.FC<SectionSeparatorProps> = ({size, className}) => (
  <hr className={cn('border-t-1', size && sizeMargin[size], className)} />
);

export default SectionSeparator;
