import {SelectMediaSheet} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {
  SelectMediaSheetCards,
  SelectMediaSheetCardsGroup,
  SelectMediaSheetPanel,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaSheet';
import {groupMediasByMonth} from '@cohort/merchants/apps/common/triggers/utils';
import InstagramMediaCard from '@cohort/merchants/apps/instagram/triggerIntegrations/components/InstagramMediaCard';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {InstagramMedia} from '@cohort/shared/apps/instagram/common';
import type {UseFormSetValue} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type SelectPostSheetProps = {
  medias: InstagramMedia[];
  setValue: UseFormSetValue<StepTriggerFormType>;
};
const SelectPostSheet: React.FC<SelectPostSheetProps> = ({medias, setValue}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.components.selectPostSheet',
  });

  const onSubmit = (selectedMediaId: string): void =>
    setValue('triggerIntegrationConfig.mediaId', selectedMediaId);

  const mediasByMonth = groupMediasByMonth(medias, media => media.createdAt);

  return (
    <SelectMediaSheet onSubmit={onSubmit} title={t('title')}>
      <SelectMediaSheetPanel isEmpty={medias.length === 0}>
        {Array.from(mediasByMonth).map(([month, medias]) => (
          <SelectMediaSheetCardsGroup label={month} key={month}>
            <SelectMediaSheetCards
              medias={medias}
              Card={InstagramMediaCard}
              className="grid-cols-3"
            />
          </SelectMediaSheetCardsGroup>
        ))}
      </SelectMediaSheetPanel>
    </SelectMediaSheet>
  );
};

export default SelectPostSheet;
