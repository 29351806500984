import type {
  CreatePerkRequestADto,
  GetPerksParamsADto,
  PatchPerkRequestADto,
  PerkAnalyticsADto,
} from '@cohort/admin-schemas/perk';
import {PerkAnalyticsASchema} from '@cohort/admin-schemas/perk';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getPerks(
  merchantId: string,
  params: GetPerksParamsADto
): Promise<[PaginationDto, PerkAnalyticsADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/perks`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(PerkAnalyticsASchema),
    params,
  });
}

export async function getPerk(merchantId: string, perkId: string): Promise<PerkAnalyticsADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/perks/${perkId}`, {
    expect: HttpCodes.SUCCESS,
    parser: PerkAnalyticsASchema.parse,
  });
}

export async function createPerk(
  merchantId: string,
  perk: CreatePerkRequestADto
): Promise<PerkAnalyticsADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/perks`, {
    expect: HttpCodes.CREATED,
    parser: PerkAnalyticsASchema.parse,
    body: {...perk},
  });
}

export async function patchPerk(
  merchantId: string,
  perkId: string,
  perk: PatchPerkRequestADto
): Promise<PerkAnalyticsADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/perks/${perkId}`, {
    expect: HttpCodes.SUCCESS,
    parser: PerkAnalyticsASchema.parse,
    body: {...perk},
  });
}

export async function publishPerk(merchantId: string, perkId: string): Promise<PerkAnalyticsADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/perks/${perkId}/publish`, {
    expect: HttpCodes.CREATED,
    parser: PerkAnalyticsASchema.parse,
  });
}

export async function archivePerk(merchantId: string, perkId: string): Promise<PerkAnalyticsADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/perks/${perkId}/archive`, {
    expect: HttpCodes.CREATED,
    parser: PerkAnalyticsASchema.parse,
  });
}

export async function unarchivePerk(
  merchantId: string,
  perkId: string
): Promise<PerkAnalyticsADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/perks/${perkId}/unarchive`, {
    expect: HttpCodes.CREATED,
    parser: PerkAnalyticsASchema.parse,
  });
}

export async function deletePerk(merchantId: string, perkId: string): Promise<object> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/perks/${perkId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}

export async function duplicatePerk(
  merchantId: string,
  perkId: string
): Promise<PerkAnalyticsADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/perks/${perkId}/duplicate`, {
    expect: HttpCodes.CREATED,
    parser: PerkAnalyticsASchema.parse,
  });
}
