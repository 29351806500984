// i18nOwl-ignore [app-talon-one.perkIntegrations.coupon.title, app-talon-one.perkIntegrations.coupon.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import TalonOneCouponPerkIntegrationConfigComponent from '@cohort/merchants/apps/talon-one/perkIntegrations/coupon/ConfigComponent';
import TalonOneCouponPerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/talon-one/perkIntegrations/coupon/UsageViewComponent';
import type {TalonOneCouponPerkIntegrationStruct} from '@cohort/shared/apps/talon-one/perks/coupon';
import {TalonOneCouponPerkIntegrationSpec} from '@cohort/shared/apps/talon-one/perks/coupon';

export const TalonOneCouponPerkIntegration: PerkIntegration<TalonOneCouponPerkIntegrationStruct> = {
  spec: TalonOneCouponPerkIntegrationSpec,
  configComponent: TalonOneCouponPerkIntegrationConfigComponent,
  usageViewComponent: TalonOneCouponPerkIntegrationsUsageViewComponent,
  iconColor: 'text-blue-500',
  backgroundColor: 'bg-blue-100',
};
