import type {ActionSpec, AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {MediaSpec} from '@cohort/shared/apps/media';
import {ListPlaylistsActionSpec} from '@cohort/shared/apps/spotify/actions/listPlaylists';
import {SpotifyPlaylistMediaSpec} from '@cohort/shared/apps/spotify/medias/playlist';
import type {SpotifyMerchantConnectorStruct} from '@cohort/shared/apps/spotify/merchantConnector';
import {SpotifyMerchantConnectorSpec} from '@cohort/shared/apps/spotify/merchantConnector';
import type {SpotifySyncStruct} from '@cohort/shared/apps/spotify/sync';
import {SpotifySyncSpec} from '@cohort/shared/apps/spotify/sync';
import {SpotifyFollowPlaylistTriggerSpec} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import {SpotifyFollowUserTriggerSpec} from '@cohort/shared/apps/spotify/triggers/followUser';
import type {SpotifyUserConnectorStruct} from '@cohort/shared/apps/spotify/userConnector';
import {SpotifyUserConnectorSpec} from '@cohort/shared/apps/spotify/userConnector';
import {SpotifyIsFollowerUserPropertySpec} from '@cohort/shared/apps/spotify/userProperties/isFollower';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import {createConnectAccountTriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import {createBaseAppUserPropertySpec} from '@cohort/shared/apps/userProperty';

export const SPOTIFY_APP_ID = 'spotify' as const;
export const SPOTIFY_APP_NAME = 'Spotify' as const;

export const SpotifyActionSpecs = [
  ListPlaylistsActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type SpotifyActionIds = (typeof SpotifyActionSpecs)[number]['id'];

export const SpotifyMediaSpecs = [
  SpotifyPlaylistMediaSpec,
] as const satisfies ReadonlyArray<MediaSpec>;
type SpotifyMediaIds = (typeof SpotifyMediaSpecs)[number]['kind'];

export const SpotifyUserPropertiesSpecs = [
  ...createBaseAppUserPropertySpec(SPOTIFY_APP_ID, SPOTIFY_APP_NAME),
  SpotifyIsFollowerUserPropertySpec,
  {
    id: 'spotify.followers-count',
    dataType: 'number',
    name: 'Spotify Number of Followers',
    scope: 'profile',
    profileKey: 'followersCount',
  },
  {
    id: 'spotify.country',
    dataType: 'string',
    name: 'Spotify Country',
    scope: 'profile',
    profileKey: 'country',
  },
  {
    id: 'spotify.name',
    dataType: 'string',
    name: 'Spotify Name',
    scope: 'profile',
    profileKey: 'name',
  },
  {
    id: 'spotify.subscription',
    dataType: 'string',
    name: 'Spotify Subscription',
    scope: 'profile',
    profileKey: 'subscription',
  },
] as const satisfies ReadonlyArray<UserPropertySpec<UserPropertyStruct>>;
type SpotifyUserPropertyIds = (typeof SpotifyUserPropertiesSpecs)[number]['id'];

export const SpotifyTriggerIntegrationSpecs = [
  createConnectAccountTriggerIntegrationSpec(SPOTIFY_APP_ID),
  SpotifyFollowPlaylistTriggerSpec,
  SpotifyFollowUserTriggerSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type SpotifyTriggerIntegrationIds = (typeof SpotifyTriggerIntegrationSpecs)[number]['id'];

export type SpotifyAppStruct = AppStruct<
  'spotify',
  SpotifyMerchantConnectorStruct,
  SpotifyUserConnectorStruct,
  SpotifySyncStruct,
  SpotifyActionIds,
  never,
  never,
  SpotifyTriggerIntegrationIds,
  never,
  SpotifyUserPropertyIds,
  never,
  SpotifyMediaIds
>;

export const SpotifyAppSpec: AppSpec<SpotifyAppStruct> = {
  id: SPOTIFY_APP_ID,
  name: SPOTIFY_APP_NAME,
  merchantConnector: SpotifyMerchantConnectorSpec,
  userConnector: SpotifyUserConnectorSpec,
  syncSpec: SpotifySyncSpec,
  actionSpecs: SpotifyActionSpecs,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: SpotifyTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: SpotifyUserPropertiesSpecs,
  resourceSpecs: [],
  mediaSpecs: SpotifyMediaSpecs,
};
