import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import type {CohortDigitalAssetDeletedEventStruct} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetDeleted';
import {CohortDigitalAssetDeletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetDeleted';
import {Trans} from 'react-i18next';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const digitalAssetCollectionInternalName = userEvent.digitalAssetCollectionInternalName;

  return (
    <Trans
      i18nKey="userEvents.digital-asset.deleted.detail"
      ns="app-cohort"
      values={{
        digitalAssetCollectionInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent),
      }}
    />
  );
};

export const CohortDigitalAssetDeletedUserEvent: UserEvent<CohortDigitalAssetDeletedEventStruct> = {
  spec: CohortDigitalAssetDeletedEventSpec,
  detailComponent: DetailComponent,
};
