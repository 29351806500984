import {useCurrentConnection} from '@cohort/merchants/hooks/contexts/currentConnection';
import {useAppPageStore} from '@cohort/merchants/hooks/stores/appsPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import PageOutlet from '@cohort/merchants/layouts/PageOutlet';
import ConnectionMenu from '@cohort/merchants/pages/apps/app/connections/connection/ConnectionMenu';
import ConnectionsBreadcrumb from '@cohort/merchants/pages/apps/app/connections/ConnectionsBreadcrumb';
import {CurrentAppProvider} from '@cohort/merchants/pages/apps/app/CurrentAppContext';
import {CurrentConnectionProvider} from '@cohort/merchants/pages/campaigns/campaign/CurrentConnectionContext';

const ConnectionPageSkeleton: React.FC = () => {
  const connection = useCurrentConnection();

  const store = useAppPageStore(store => ({
    footer: store.footer,
  }));

  return (
    <PageLayout
      title={connection.name}
      breadcrumb={<ConnectionsBreadcrumb connection={connection} />}
      menu={<ConnectionMenu connection={connection} />}
      footer={store.footer}
    >
      <PageOutlet />
    </PageLayout>
  );
};

const ConnectionPageLayout: React.FC = () => {
  return (
    <CurrentAppProvider>
      <CurrentConnectionProvider>
        <ConnectionPageSkeleton />
      </CurrentConnectionProvider>
    </CurrentAppProvider>
  );
};
export default ConnectionPageLayout;
