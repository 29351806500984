import {z} from 'zod';

export const OwnershipStatusSchema = z.enum([
  'owned',
  'pending_transfer',
  'transferring',
  'transferred',
  'transfer_failed',
]);
export type OwnershipStatus = z.infer<typeof OwnershipStatusSchema>;

export const OwnershipObtentionTypeSchema = z.enum(['order', 'transfer', 'airdrop', 'challenge']);
export type OwnershipObtentionType = z.infer<typeof OwnershipObtentionTypeSchema>;
