import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const TypeformFormConfigSchema = z.object({
  formId: z.string().min(1, {message: 'errorRequired'}),
});
export type TypeformFormConfig = z.infer<typeof TypeformFormConfigSchema>;

export const TypeformFormStateSchema = z.object({});
export type TypeformFormState = z.infer<typeof TypeformFormStateSchema>;

export const TypeformFormAccessDataSchema = z.object({});
export type TypeformFormAccessData = z.infer<typeof TypeformFormAccessDataSchema>;

export const TypeformFormUsageDataSchema = z.object({
  responseId: z.string(),
});
export type TypeformFormUsageData = z.infer<typeof TypeformFormUsageDataSchema>;

export type TypeformFormPerkIntegrationStruct = PerkIntegrationStruct<
  'typeform.form',
  TypeformFormConfig,
  TypeformFormState,
  TypeformFormAccessData,
  TypeformFormAccessData,
  TypeformFormUsageData
>;

export const TypeformFormPerkIntegrationSpec: PerkIntegrationSpec<TypeformFormPerkIntegrationStruct> =
  {
    id: 'typeform.form' as const,
    configSchema: TypeformFormConfigSchema,
    stateSchema: TypeformFormStateSchema,
    accessDataSchema: TypeformFormAccessDataSchema,
    usageInputDataSchema: TypeformFormAccessDataSchema,
    internalUsageDataSchema: TypeformFormUsageDataSchema,
    exposedUsageDataSchema: TypeformFormUsageDataSchema,
    defaultMaxUsagesPerToken: 1,
    requiresMerchantConnection: true,
  };
