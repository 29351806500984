import type {FacebookPost} from '@cohort/shared/apps/facebook/common';
import type {InstagramMedia} from '@cohort/shared/apps/instagram/common';
import type {SpotifyPlaylist} from '@cohort/shared/apps/spotify/schema';
import type {TikTokVideo} from '@cohort/shared/apps/tiktok/common';
import type {YoutubeVideo} from '@cohort/shared/apps/youtube/common';
import dayjs from 'dayjs';

export type MediaItem =
  | YoutubeVideo
  | TikTokVideo
  | SpotifyPlaylist
  | FacebookPost
  | InstagramMedia;

export const groupMediasByMonth = <M extends MediaItem>(
  medias: M[],
  getMediaDate: (media: M) => Date
): Map<string, M[]> =>
  medias.reduce((result, media) => {
    const month = dayjs(getMediaDate(media)).format('MMMM YYYY');
    if (!result.has(month)) {
      result.set(month, []);
    }
    result.get(month)?.push(media);
    return result;
  }, new Map<string, M[]>());
