import {cn} from '@cohort/shared-frontend/utils/classNames';
import {MagnifyingGlass} from '@phosphor-icons/react';
import {useRef, useState} from 'react';

type SearchInputProps = {
  value: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
  delay?: number;
  className?: string;
};

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
  className,
  delay = 0,
}) => {
  const timerId = useRef<NodeJS.Timeout>();
  const [search, setSearch] = useState(value);

  function onChangeDebounced(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.currentTarget.value;

    clearTimeout(timerId.current);
    timerId.current = setTimeout(() => onChange(value), delay);
    setSearch(value);
  }

  return (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center px-3">
        <MagnifyingGlass
          className={cn(
            'h-5 w-5 stroke-2',
            search === '' ? 'text-slate-500' : 'text-primary-darker'
          )}
        />
      </div>
      <input
        className={cn(
          'w-45 rounded-md border border-border border-slate-200 py-1.5 text-sm font-medium text-primary-darker shadow-sm focus:border-primary focus:ring-primary',
          search !== '' && 'border-transparent bg-primary/10',
          className
        )}
        style={{textIndent: '30px'}}
        name="search"
        type="text"
        placeholder={placeholder}
        value={search ?? ''}
        onChange={onChangeDebounced}
      />
    </div>
  );
};

export default SearchInput;
