import CohortLogo from '@cohort/merchants/components/CohortLogo';
import NavbarOrganizationsPopUp from '@cohort/merchants/components/navbar/NavbarOrganizationsPopUp';
import NavbarPagesLinks from '@cohort/merchants/components/navbar/NavbarPagesLinks';
import NavbarSettingsPopUp from '@cohort/merchants/components/navbar/NavbarSettingsPopUp';
import NavbarUserPopUp from '@cohort/merchants/components/navbar/NavbarUserPopUp';
import TestModeToggle from '@cohort/merchants/components/test-mode/TestModeToggle';
import {trackNavbarLinkButtonClicked} from '@cohort/merchants/lib/Tracking';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Disclosure} from '@headlessui/react';
import {List, X} from '@phosphor-icons/react';
import React from 'react';
import {Link} from 'react-router-dom';

const Navbar: React.FC = () => (
  <div className="w-full border-b bg-slate-50">
    <div className="m-auto max-w-[var(--content-max-width)] px-8">
      <Disclosure as="nav" className="flex w-full">
        {({open}) => (
          <div className="w-full">
            <div className={cn('flex justify-between md:h-16 md:items-center', !open && 'md:h-16')}>
              <div className="flex flex-col items-center justify-center md:flex-row">
                <Link
                  className="flex h-16 flex-col justify-center"
                  to="/"
                  onClick={() => trackNavbarLinkButtonClicked('home')}
                >
                  <CohortLogo />
                </Link>
                {/* mobile menu */}
                <div className={cn('my-10 flex flex-col space-y-6 md:hidden', !open && 'hidden')}>
                  <NavbarPagesLinks />
                  <TestModeToggle />
                  <NavbarOrganizationsPopUp />
                  <NavbarSettingsPopUp />
                  <NavbarUserPopUp />
                </div>
                <div className="hidden md:block">
                  <NavbarPagesLinks />
                </div>
              </div>
              <div className="hidden space-x-4 md:flex">
                <TestModeToggle />
                <NavbarOrganizationsPopUp />
                <NavbarSettingsPopUp />
                <NavbarUserPopUp />
              </div>
              <div className="flex h-16 flex-col justify-center md:hidden">
                <Disclosure.Button>
                  {open ? (
                    <X className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <List className="h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
        )}
      </Disclosure>
    </div>
  </div>
);

export default Navbar;
