import {create} from 'zustand';

export interface AppPageStore {
  breadcrumb?: React.ReactNode;
  setBreadcrumb: (breadcrumb: React.ReactNode) => void;
  footer?: React.ReactNode;
  setFooter: (footer: React.ReactNode) => void;
  menu?: React.ReactNode;
  setMenu: (menu: React.ReactNode) => void;
}

export const useAppPageStore = create<AppPageStore>(set => ({
  breadcrumb: undefined,
  setBreadcrumb: breadcrumb => set({breadcrumb}),
  footer: undefined,
  setFooter: footer => set({footer}),
  menu: undefined,
  setMenu: menu => set({menu}),
}));
