import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortNewChallengeStepAvailablePropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  challengeParticipationId: z.string().uuid(),
  challengeStepId: z.string().uuid(),
});
export type CohortNewChallengeStepAvailableProperties = z.infer<
  typeof CohortNewChallengeStepAvailablePropertiesSchema
>;

export type CohortNewChallengeStepAvailableEventStruct = UserEventStruct<
  'cohort.challenge.new-step-available',
  keyof CohortNewChallengeStepAvailableProperties,
  CohortNewChallengeStepAvailableProperties,
  CohortNewChallengeStepAvailableProperties
>;

export const CohortNewChallengeStepAvailableEventSpec: UserEventSpec<CohortNewChallengeStepAvailableEventStruct> =
  {
    id: 'cohort.challenge.new-step-available',
    name: 'A New Challenge Step Available',
    description: 'Event Triggerred when a new step is available to a user in a Challenge',
    propertiesSchema: CohortNewChallengeStepAvailablePropertiesSchema,
    exposedPropertiesSchema: CohortNewChallengeStepAvailablePropertiesSchema,
    resources: {
      campaignId: 'cohort.challenge',
    },
    rulesEngineConfig: {
      isVisible: false,
      exposedFields: [],
    },
  };
export default CohortNewChallengeStepAvailableEventSpec;
