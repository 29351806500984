import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import type {TypeformFormUsageData} from '@cohort/shared/apps/typeform/perks/form';
import {useTranslation} from 'react-i18next';

const TypeformFormPerkIntegrationsUsageViewComponent: React.FC<PerkUsageViewComponentProps> = ({
  usage,
}) => {
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'perkIntegrations.form.usageViewComponent',
  });
  const data = usage.data as TypeformFormUsageData;

  return (
    <PerkUsageLayout.Grid>
      <PerkUsageLayout.Row name={t('labelResponseId')} value={data.responseId} />
    </PerkUsageLayout.Grid>
  );
};

export default TypeformFormPerkIntegrationsUsageViewComponent;
