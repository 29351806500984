import {Badge} from '@cohort/merchants/components/Badge';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  status: string | undefined;
  liveCount?: number;
  size?: 'large' | 'small';
}

export const PerkStatusBadge = ({status, liveCount, size}: Props): JSX.Element => {
  const {t} = useTranslation('components', {keyPrefix: 'perks.perkStatusBadge'});
  const count = liveCount ?? 0;

  let badgeData;

  if (status === undefined) {
    return <Fragment />;
  }

  switch (status) {
    case 'draft':
      badgeData = {
        text: t('statusDraft'),
        backgroundColor: 'bg-yellow-100',
        textColor: 'text-yellow-800',
        dotColor: 'text-yellow-400',
      };
      break;
    case 'archived':
      badgeData = {
        text: t('statusArchived'),
        backgroundColor: 'bg-slate-100',
        textColor: 'text-slate-800',
        dotColor: 'text-slate-400',
      };
      break;
    case 'live':
      badgeData = {
        text: count === 0 ? t('statusReady') : t('statusLive', {count}),
        backgroundColor: 'bg-green-100',
        textColor: 'text-green-800',
        dotColor: 'text-green-400',
      };
      break;
    default:
      throw new Error('Unsupported status');
  }

  return (
    <Badge
      text={badgeData.text}
      backgroundColor={badgeData.backgroundColor}
      textColor={badgeData.textColor}
      dotColor={badgeData.dotColor}
      size={size ?? 'large'}
    />
  );
};
