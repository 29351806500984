import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const FacebookLikePageTriggerConfigSchema = z.object({
  pageId: z.string(),
  pageName: z.string(),
});
type FacebookLikePageTriggerConfig = z.infer<typeof FacebookLikePageTriggerConfigSchema>;

const FacebookLikePageVerificationAttemptDataSchema = z.object({
  userId: z.string(),
  username: z.string(),
  link: z.string().nullable(),
});
export type FacebookLikePageVerificationAttemptData = z.infer<
  typeof FacebookLikePageVerificationAttemptDataSchema
>;

export type FacebookLikePageTriggerStruct = TriggerIntegrationStruct<
  'facebook.like-page',
  FacebookLikePageTriggerConfig,
  FacebookLikePageVerificationAttemptData
>;
export const FacebookLikePageTriggerSpec: TriggerIntegrationSpec<FacebookLikePageTriggerStruct> = {
  id: 'facebook.like-page' as const,
  type: 'verification',
  configurableMaxAttempts: false,
  configSchema: FacebookLikePageTriggerConfigSchema,
  verificationAttemptDataSchema: FacebookLikePageVerificationAttemptDataSchema,
  verificationAttemptInputSchema: z.object({}),
  requiresMerchantConnection: true,
};
