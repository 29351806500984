import type {MerchantADto} from '@cohort/admin-schemas/merchant';
import {CurrentMerchantContext} from '@cohort/merchants/layouts/CurrentMerchantContext';
import {useContext} from 'react';

export const useCurrentMerchant = (): MerchantADto => {
  const context = useContext(CurrentMerchantContext);

  if (context === null) {
    throw new Error('useCurrentMerchant must be used within a CurrentMerchantProvider');
  }
  return context;
};
