import Title from '@cohort/components-xps/components/Title';
import RichText from '@cohort/shared-frontend/components/RichText';

type MediaContentInfosProps = {
  title: string;
  description?: string | null;
};

/**
 * Title and description of the media content.
 */
const MediaContentInfos: React.FC<MediaContentInfosProps> = ({title, description}) => (
  <div className="flex flex-col gap-y-2">
    <Title>{title}</Title>
    {description && <RichText html={description} />}
  </div>
);

export default MediaContentInfos;
