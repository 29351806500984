import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {SpotifyPlaylistSchema} from '@cohort/shared/apps/spotify/schema';
import {z} from 'zod';

export const ListPlaylistsInputSchema = z.object({});
export type ListPlaylistsInput = z.infer<typeof ListPlaylistsInputSchema>;

export const ListPlaylistsOutputSchema = z.object({
  playlists: z.array(SpotifyPlaylistSchema),
});
export type ListPlaylistsOutput = z.infer<typeof ListPlaylistsOutputSchema>;

export type ListPlaylistsActionStruct = ActionStruct<
  'list-playlists',
  ListPlaylistsInput,
  ListPlaylistsOutput
>;
export const ListPlaylistsActionSpec: ActionSpec<ListPlaylistsActionStruct> = {
  id: 'list-playlists',
  inputSchema: ListPlaylistsInputSchema,
  outputSchema: ListPlaylistsOutputSchema,
};
