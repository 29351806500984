import type {
  UserEvent,
  UserEventDetailComponentProps,
  UserEventDetailModalComponentProps,
} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import Loader from '@cohort/merchants/components/Loader';
import PerkUsageView from '@cohort/merchants/components/perks/PerkUsageView';
import {usePerkAccess} from '@cohort/merchants/hooks/api/PerkAccesses';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getPerkRoute} from '@cohort/merchants/lib/Pages';
import type {CohortPerkUsedEventStruct} from '@cohort/shared/apps/cohort/userEvents/perk/perkUsed';
import {CohortPerkUsedEventSpec} from '@cohort/shared/apps/cohort/userEvents/perk/perkUsed';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const perkId = (userEvent.properties as CohortPerkUsedEventStruct['Properties']).perkId;
  const perkInternalName = userEvent.perkInternalName;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.perk.used.detail"
      ns="app-cohort"
      values={{
        perkInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () => navigate(getPerkRoute(perkId).path)),
      }}
    />
  );
};

const DetailModalComponent: React.FC<UserEventDetailModalComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as CohortPerkUsedEventStruct['Properties'];
  const merchant = useCurrentMerchant();
  const {data: perkAccess} = usePerkAccess(merchant.id, properties.perkAccessId);
  if (perkAccess === undefined) {
    return <Loader />;
  }
  return (
    <ul className="flex flex-col space-y-6">
      {perkAccess.usages.map(usage => (
        <li key={usage.id}>
          <PerkUsageView usage={usage} />
        </li>
      ))}
    </ul>
  );
};

export const CohortPerkUsedUserEvent: UserEvent<CohortPerkUsedEventStruct> = {
  spec: CohortPerkUsedEventSpec,
  detailComponent: DetailComponent,
  detailModalComponent: DetailModalComponent,
};
