import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import Input from '@cohort/merchants/components/form/input/Input';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export type CompleteActivityMetricsFormMetric = {
  hasPropertyName: `triggerIntegrationConfig.has${string}`;
  propertyName: `triggerIntegrationConfig.${string}`;
  showPropertyLabel: string;
  label: string;
  description: string;
};

const GarminConnectCompleteActivityMetricsForm: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {register, control}}) => {
  const {t} = useTranslation('app-garmin-connect', {
    keyPrefix: 'triggerIntegrations.complete-activity.configComponent',
  });

  const metrics: CompleteActivityMetricsFormMetric[] = [
    {
      hasPropertyName: 'triggerIntegrationConfig.hasDistance',
      propertyName: 'triggerIntegrationConfig.minDistanceKilometers',
      showPropertyLabel: t('labelShowDistance'),
      label: t('labelActivityDistance'),
      description: t('descriptionInKilometers'),
    },
    {
      hasPropertyName: 'triggerIntegrationConfig.hasAverageElevationGain',
      propertyName: 'triggerIntegrationConfig.minAverageElevationGainMeters',
      showPropertyLabel: t('labelShowAverageElevationGain'),
      label: t('labelActivityAverageElevationGain'),
      description: t('descriptionInMeters'),
    },
    {
      hasPropertyName: 'triggerIntegrationConfig.hasAverageSpeed',
      propertyName: 'triggerIntegrationConfig.minAverageSpeedKilometersPerHour',
      showPropertyLabel: t('labelShowAverageSpeed'),
      label: t('labelActivityAverageSpeed'),
      description: t('descriptionInKilometerPerHour'),
    },
    {
      hasPropertyName: 'triggerIntegrationConfig.hasDuration',
      propertyName: 'triggerIntegrationConfig.minDurationMinutes',
      showPropertyLabel: t('labelShowDuration'),
      label: t('labelActivityDuration'),
      description: t('descriptionInMinutes'),
    },
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fields = metrics.map(metric => useController({name: metric.hasPropertyName, control}));

  return (
    <div>
      <div className="flex flex-row gap-4">
        {metrics.map(metric => (
          <Input
            name={metric.hasPropertyName}
            label={metric.showPropertyLabel}
            register={register}
            control={control}
            type="checkbox"
            labelPosition="right"
          />
        ))}
      </div>

      <div className="mt-4 flex flex-row gap-4">
        {metrics.map((metric, index) => {
          const hasMetric = fields[index];
          return (
            hasMetric?.field.value && (
              <Input
                name={metric.propertyName}
                label={metric.label}
                type="number"
                placeholder="0"
                description={metric.description}
                register={register}
                control={control}
                min={0}
              />
            )
          );
        })}
      </div>
    </div>
  );
};

export default GarminConnectCompleteActivityMetricsForm;
