import type {UserAttributeADto} from '@cohort/admin-schemas/userAttributes';
import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import {getCookieValue} from '@cohort/merchants/lib/Cookies';
import {OAUTH_CALLBACK_PATH} from '@cohort/merchants/lib/Pages';
import {
  ADMIN_DEFAULT_SIGNIN_SUBDOMAIN,
  ADMIN_OAUTH_REDIRECT_SUBDOMAIN,
  ADMIN_ORGANIZATION_COOKIE,
} from '@cohort/shared/constants';
import type {Language, LocalizedString} from '@cohort/shared/schema/common';
import type {UserPropertyValue} from '@cohort/shared/schema/common/userProperty';
import {match} from 'ts-pattern';

export function waitlistFormRedirect(): void {
  window.location.assign('https://form.typeform.com/to/wQjjqy4p');
}

export const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getUserLocale = (): string => {
  const lang = navigator.languages[0];
  return lang !== undefined ? lang : navigator.language;
};

export const getCurrencySymbol = (currency: string, locale?: string): string => {
  return (0)
    .toLocaleString(locale ?? getUserLocale(), {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/u, '')
    .trim();
};

type SupportedLocales = 'fr_FR' | 'en_US';

export function languageToLocale(language: string): SupportedLocales {
  switch (language) {
    case 'fr-FR':
      return 'fr_FR';
    case 'fr':
      return 'fr_FR';
    case 'en-EN':
      return 'en_US';
    case 'en':
      return 'en_US';
    default:
      return 'en_US';
  }
}

export function timeJsLocale(locale: SupportedLocales): string {
  switch (locale) {
    case 'fr_FR':
      return 'fr';
    case 'en_US':
      return 'en';
    default:
      return 'en';
  }
}

export function updateLocalizedString(
  language: Language,
  localizedString: LocalizedString,
  value: string | null
): LocalizedString {
  const dictionary = {...localizedString};
  if (value === '' || value === null) {
    delete dictionary[language];
  } else {
    dictionary[language] = value;
  }
  return dictionary;
}

export function getOrganizationSlugFromSubdomain(): string | null {
  const subdomain = window.location.hostname.split('.')[0];

  if (
    subdomain === undefined ||
    subdomain === ADMIN_DEFAULT_SIGNIN_SUBDOMAIN ||
    subdomain === ADMIN_OAUTH_REDIRECT_SUBDOMAIN
  ) {
    return null;
  }
  return subdomain;
}

export function getOrganizationSlugFromCookie(): string | null {
  const organization = getCookieValue(ADMIN_ORGANIZATION_COOKIE);
  return organization === undefined ? null : organization;
}

export function getSubdomainUrl(subdomain: string): string {
  const hostname = window.location.hostname;
  const hostnameParts = hostname.split('.');
  hostnameParts[0] = subdomain;
  return `${window.location.protocol}//${hostnameParts.join('.')}`;
}

export function getOrganizationSigninUrl(organizationSlug: string): string {
  return `${getSubdomainUrl(organizationSlug)}/sign-in`;
}

export function getSigninSubdomainUrl(destinationPath?: string | null): string {
  return `${getSubdomainUrl(ADMIN_DEFAULT_SIGNIN_SUBDOMAIN)}/sign-in${
    destinationPath ? `?destination=${destinationPath}` : ''
  }`;
}

export function getOauthRedirectUrl(): string {
  return `${getSubdomainUrl(ADMIN_OAUTH_REDIRECT_SUBDOMAIN)}/${OAUTH_CALLBACK_PATH}`;
}

export function bytesToKb(bytes: number): number {
  return bytes / 1024;
}
export function bytesToMb(bytes: number): number {
  return bytes / 1024 / 1024;
}
export function mbToBytes(bytes: number): number {
  return bytes * 1024 * 1024;
}

// Used for the user properties table in the /users table and user analytics page.
// not-null values are passed to inputs to keep them controlled
export const getUserAttributeValue = (
  userProperty: UserPropertyADto,
  userAttribute: UserAttributeADto | null
): UserPropertyValue => {
  if (!userAttribute) {
    return match(userProperty.dataType)
      .with('string_list', 'resource_list', () => [])
      .otherwise(() => '');
  }
  return userAttribute.value;
};
