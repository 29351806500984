import type {TriggerIntegration} from '@cohort/merchants/apps';
import DiscordHasRoleTriggerIntegrationConfigComponent from '@cohort/merchants/apps/discord/triggerIntegrations/hasRole/ConfigComponent';
import DiscordHasRoleTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/discord/triggerIntegrations/hasRole/StepCompletionTitleComponent';
import type {DiscordHasRoleTriggerStruct} from '@cohort/shared/apps/discord/triggers/hasRole';
import {DiscordHasRoleTriggerIntegrationSpec} from '@cohort/shared/apps/discord/triggers/hasRole';

export const DiscordHasRoleTriggerIntegration: TriggerIntegration<DiscordHasRoleTriggerStruct> = {
  spec: DiscordHasRoleTriggerIntegrationSpec,
  configComponent: DiscordHasRoleTriggerIntegrationConfigComponent,
  stepCompletionTitleComponent: DiscordHasRoleTriggerIntegrationStepCompletionTitleComponent,
  getTitle: t => t('triggerIntegrations.has-role.title', {ns: 'app-discord'}),
};
