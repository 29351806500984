import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment} from 'react';

type ModalBackgroundProps = {
  children: JSX.Element;
  open?: boolean;
  lightMode?: boolean;
};

// this enable clicks outside modals to make the SelectPicker work
// https://github.com/radix-ui/primitives/issues/1859
export const ModalBackground: React.FC<ModalBackgroundProps> = ({
  open,
  children,
  lightMode = false,
}) => (
  <Fragment>
    {open && (
      <div
        className={cn(
          'fixed left-0 top-0 z-50 !m-0 h-full w-full',
          lightMode ? 'bg-black/15 backdrop-blur' : 'bg-black/50 backdrop-blur-sm'
        )}
      ></div>
    )}
    {children}
  </Fragment>
);
