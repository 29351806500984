import type {ConnectorSpec, ConnectorStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const OAuth1AppCredentialsSchema = z.object({
  consumerKey: z.string(),
  consumerSecret: z.string(),
});
export type OAuth1AppCredentials = z.infer<typeof OAuth1AppCredentialsSchema>;

export const OAuth2AppCredentialsSchema = z.object({
  clientId: z.string(),
  clientSecret: z.string(),
});
export type OAuth2AppCredentials = z.infer<typeof OAuth2AppCredentialsSchema>;

export const OAuth1CredentialsSchema = z.object({
  accessToken: z.string(),
  accessTokenSecret: z.string(),
});
export type OAuth1Credentials = z.infer<typeof OAuth1CredentialsSchema>;

export const OAuth2CredentialsSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
});
export type OAuth2Credentials = z.infer<typeof OAuth2CredentialsSchema>;

export function basicOauth2ConnectorSpec<
  T extends ConnectorStruct<string, OAuth2AppCredentials, OAuth2Credentials> = ConnectorStruct<
    string,
    OAuth2AppCredentials,
    OAuth2Credentials
  >,
>(id: T['Id'], profilePropertiesSchema: z.ZodSchema<T['ProfileProperties']>): ConnectorSpec<T> {
  return {
    id,
    type: 'oauth2',
    appCredentialsSchema: OAuth2AppCredentialsSchema,
    credentialsSchema: OAuth2CredentialsSchema,
    profilePropertiesSchema,
    connectionConfigSchema: null,
  };
}

export type OAuth2 = {
  type: 'oauth2';
  authorizeUrl: string;
  scopes: string[];
  extraAuthorizeParams?: Record<string, string | null>;
};

// This is a state object that we use to pass context during a OAuth1 or OAuth2 authorization flow
export const OAuthStateSchema = z.object({
  connectorId: z.string(),
  userId: z.string().nullable(),
  merchantId: z.string(),
  codeVerifier: z.string(), // This is either the verifier in OAuth2 or the oauth_token_secret in OAuth1
  backendRedirectHostname: z.string().optional(),
  embedUrl: z.string().url().nullable(),
  frontendRedirectUrl: z.string(),
  existingConnectionId: z.string().optional(),
  context: z.record(z.unknown()).optional(),
});
export type OAuthState = z.infer<typeof OAuthStateSchema>;

export const OAuthStoredStateSchema = z.object({
  key: z.string(),
  backendRedirectHostname: z.string().optional(),
});
export type OAuthStoredState = z.infer<typeof OAuthStoredStateSchema>;
