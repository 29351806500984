import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import Loader from '@cohort/merchants/components/Loader';
import {useDigitalAssetCollections} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useEffect} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const CohortDigitalAssetOwnedTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {register, control}}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.digital-asset-owned.configComponent',
  });
  const {data: collectionsList, isFetched} = useDigitalAssetCollections(merchant.id, {
    page: 1,
    pageSize: 100,
    orderBy: 'internalName',
  });
  const [, collections] = collectionsList ?? [];
  const {field: digitalAssetCollectionId} = useController({
    control,
    name: 'triggerIntegrationConfig.digitalAssetCollectionId',
  });

  useEffect(() => {
    if (
      digitalAssetCollectionId.value === undefined &&
      isFetched &&
      collectionsList &&
      collectionsList[0].total > 0
    ) {
      const [, collections] = collectionsList;

      digitalAssetCollectionId.onChange(collections[0]?.id);
    }
  }, [collectionsList, digitalAssetCollectionId, isFetched]);

  if (!isFetched) {
    return <Loader size={30} color="gray" />;
  }

  return (
    <SelectInput
      name="triggerIntegrationConfig.digitalAssetCollectionId"
      label={t('labelDigitalAssetCollection')}
      register={register}
      control={control}
      options={
        collections?.map(({id, internalName}) => ({
          value: id,
          label: internalName,
        })) ?? []
      }
    />
  );
};

export default CohortDigitalAssetOwnedTriggerIntegrationConfigComponent;
