import {useTypeformWorkspaces} from '@cohort/merchants/apps/typeform/api';
import WorkspacePicker from '@cohort/merchants/apps/typeform/connector/WorkspacePicker';
import type {PostConnectionConfigComponentProps} from '@cohort/merchants/components/connections/OAuthEditConnectionComponent';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Loader from '@cohort/merchants/components/Loader';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {configureConnection} from '@cohort/merchants/lib/api/Connections';
import type {TypeformMerchantConnectorStruct} from '@cohort/shared/apps/typeform/merchantConnector';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';

const SetupConnectionWorkspace: React.FC<PostConnectionConfigComponentProps> = ({
  connection,
  onConfigFinished,
}) => {
  const queryClient = useQueryClient();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'setupConnectionWorkspace',
  });

  const {
    data: workspaces,
    isLoading: isTypeformWorkspacesLoading,
    isError,
  } = useTypeformWorkspaces(merchant.id, connection.id);

  const {mutate: onWorkspaceSelected} = useCohortMutation({
    mutationFn: async (workspaceId: string) =>
      await configureConnection<TypeformMerchantConnectorStruct>(merchant.id, connection.id, {
        workspaceId,
      }),
    onSuccess: connection => {
      queryClient.invalidateQueries(connectionsKeys.list(merchant.id));
      onConfigFinished(connection);
    },
  });

  if (isTypeformWorkspacesLoading || workspaces === undefined) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorState />;
  }
  return (
    <div>
      <p>{t('selectWorkspaceLabel')}</p>
      <WorkspacePicker
        workspaces={workspaces.output.workspaces}
        onWorkspaceSelected={async (workspaceId: string) => onWorkspaceSelected(workspaceId)}
      />
    </div>
  );
};

export default SetupConnectionWorkspace;
