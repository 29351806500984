import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {TIKTOK_URL} from '@cohort/shared/apps/tiktok/common';
import type {TikTokKeywordInBioVerificationAttemptData} from '@cohort/shared/apps/tiktok/triggers/keywordInBio';
import {Trans} from 'react-i18next';

const TikTokKeywordInBioTriggerStepCompletionTitleComponent: React.FC<{
  data: TikTokKeywordInBioVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const userLink = `${TIKTOK_URL}/@${data.username}`;

  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggers.keyword-in-bio.stepCompletionTitleComponent.error"
        ns="app-tiktok"
        values={{username: data.username, keyword: data.keyword}}
        components={{
          userLink: <StepCompletionLink url={userLink} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggers.keyword-in-bio.stepCompletionTitleComponent.success"
      ns="app-tiktok"
      values={{username: data.username, keyword: data.keyword}}
      components={{
        userLink: <StepCompletionLink url={userLink} />,
      }}
    />
  );
};

export default TikTokKeywordInBioTriggerStepCompletionTitleComponent;
