import TypeformQuestionWithAnswerList from '@cohort/merchants/apps/typeform/triggerIntegrations/TypeformQuestionWithAnswerList';
import type {TypeformFillFormVerificationAttemptData} from '@cohort/shared/apps/typeform/triggers/fillForm';
import {Fragment} from 'react';

const TypeformFillFormTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: TypeformFillFormVerificationAttemptData;
}> = ({data}) => {
  const {questionsWithAnswers} = data;

  if (questionsWithAnswers.length === 0) {
    return <Fragment />;
  }

  return (
    <div className="mb-2 rounded-sm border border-border bg-slate-50 p-4">
      <TypeformQuestionWithAnswerList questionsWithAnswers={questionsWithAnswers} />
    </div>
  );
};

export default TypeformFillFormTriggerIntegrationStepCompletionContextComponent;
