import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {BaseResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {MetadataFormattedSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortDigitalAssetCollectionResourceSchema = BaseResourcePropertiesSchema.extend({
  id: z.string().uuid(),
  metadata: MetadataFormattedSchema,
});

export type CohortDigitalAssetCollectionResourceType = z.infer<
  typeof CohortDigitalAssetCollectionResourceSchema
>;

export type CohortDigitalAssetCollectionResourceStruct = ResourceStruct<
  'cohort.digital-asset-collection',
  CohortDigitalAssetCollectionResourceType
>;

export const CohortDigitalAssetCollectionResourceSpec: ResourceSpec<CohortDigitalAssetCollectionResourceStruct> =
  {
    id: 'cohort.digital-asset-collection',
    schema: CohortDigitalAssetCollectionResourceSchema,
  };
