import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {useTranslation} from 'react-i18next';

const CohortArticleMediaConfigComponent: React.FC = () => {
  const {control, watch, register} = useCohortForm<ContentSettingsStepValues>();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'medias.article.configComponent',
  });
  const selectedLanguage = watch('selectedLanguage');
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <LocalizedTextEditorInput
      label={t('labelArticleContent')}
      name="media.config.content"
      register={register}
      control={control}
      selectedLanguage={selectedLanguage}
      placeholder={t('placeholderArticleContent')}
      optional={!isDefaultLanguageSelected}
    />
  );
};

export default CohortArticleMediaConfigComponent;
