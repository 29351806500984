import {EventIdSchema} from '@cohort/shared/apps';
import {DataTypeSchema} from '@cohort/shared/schema/common/rules/dataTypes';
import {
  CohortMembershipCriterionOperatorSchema,
  UserEventCriterionFilterOperatorSchema,
  UserEventCriterionOperatorSchema,
  UserPropertyCriterionOperatorSchema,
} from '@cohort/shared/schema/common/rules/operators';
import {z} from 'zod';

export const UserPropertyCriterionASchema = z.object({
  id: z.string().uuid(),
  userPropertyId: z.string().uuid(),
  userPropertyType: DataTypeSchema,
  operator: UserPropertyCriterionOperatorSchema,
  value: z.unknown(),
  valueType: DataTypeSchema,
  type: z.literal('user-property'),
});
export type UserPropertyCriterionADto = z.infer<typeof UserPropertyCriterionASchema>;

export const UserPropertyCriterionInputASchema = UserPropertyCriterionASchema.extend({
  id: z.string().uuid().nullable(),
}).omit({userPropertyType: true});
export type UserPropertyCriterionInputADto = z.infer<typeof UserPropertyCriterionInputASchema>;

export const UserEventCriterionFilterASchema = z.object({
  id: z.string().uuid(),
  propertyName: z.string(),
  propertyType: DataTypeSchema,
  operator: UserEventCriterionFilterOperatorSchema,
  value: z.unknown(),
  valueType: DataTypeSchema,
});
export type UserEventCriterionFilterADto = z.infer<typeof UserEventCriterionFilterASchema>;

export const UserEventCriterionFilterInputASchema = UserEventCriterionFilterASchema.extend({
  id: z.string().uuid().nullable(),
}).omit({propertyType: true});
export type UserEventCriterionFilterInputADto = z.infer<
  typeof UserEventCriterionFilterInputASchema
>;

export const UserEventCriterionASchema = z.object({
  id: z.string().uuid(),
  userEventId: EventIdSchema,
  operator: UserEventCriterionOperatorSchema,
  count: z.number(),
  filters: z.array(UserEventCriterionFilterASchema),
  type: z.literal('user-event'),
});
export type UserEventCriterionADto = z.infer<typeof UserEventCriterionASchema>;

export const UserEventCriterionInputASchema = UserEventCriterionASchema.extend({
  id: z.string().uuid().nullable(),
  filters: z.array(UserEventCriterionFilterInputASchema),
});
export type UserEventCriterionInputADto = z.infer<typeof UserEventCriterionInputASchema>;

export const CohortMembershipCriterionASchema = z.object({
  id: z.string().uuid(),
  cohortId: z.string().uuid(),
  operator: CohortMembershipCriterionOperatorSchema,
  type: z.literal('cohort-membership'),
});
export type CohortMembershipCriterionADto = z.infer<typeof CohortMembershipCriterionASchema>;

export const CohortMembershipCriterionInputASchema = CohortMembershipCriterionASchema.extend({
  id: z.string().uuid().nullable(),
});
export type CohortMembershipCriterionInputADto = z.infer<
  typeof CohortMembershipCriterionInputASchema
>;

export const CriterionASchema = z.discriminatedUnion('type', [
  UserPropertyCriterionASchema,
  UserEventCriterionASchema,
  CohortMembershipCriterionASchema,
]);
export type CriterionADto = z.infer<typeof CriterionASchema>;

export const CriterionInputASchema = z.discriminatedUnion('type', [
  UserPropertyCriterionInputASchema,
  UserEventCriterionInputASchema,
  CohortMembershipCriterionInputASchema,
]);
export type CriterionInputADto = z.infer<typeof CriterionInputASchema>;

export const RuleGroupASchema = z.object({
  id: z.string().uuid(),
  criteria: z.array(CriterionASchema),
});
export type RuleGroupADto = z.infer<typeof RuleGroupASchema>;

export const RuleGroupInputASchema = z.object({
  id: z.string().uuid().nullable(),
  criteria: z.array(CriterionInputASchema),
});
export type RuleGroupInputADto = z.infer<typeof RuleGroupInputASchema>;

export const RuleASchema = z.object({
  id: z.string().uuid(),
  groups: z.array(RuleGroupASchema),
});
export type RuleADto = z.infer<typeof RuleASchema>;

export const RuleInputASchema = z.object({
  id: z.string().uuid().nullable(),
  groups: z.array(RuleGroupInputASchema),
});
export type RuleInputADto = z.infer<typeof RuleInputASchema>;
