import type {MediaCardProps} from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import TriggerMediaCardWrapper from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import type {InstagramMedia} from '@cohort/shared/apps/instagram/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {InstagramLogo} from '@phosphor-icons/react';

type InstagramMediaCardProps = MediaCardProps<InstagramMedia>;

const InstagramMediaCard: React.FC<InstagramMediaCardProps> = ({
  media: post,
  isSelected = false,
  onClick,
}) => (
  <TriggerMediaCardWrapper hasMedia={post !== undefined} onClick={onClick} isSelected={isSelected}>
    {!post ? (
      <div className="flex h-[120px] w-[120px] items-center justify-center">
        <InstagramLogo size={24} weight="fill" className="text-slate-200" />
      </div>
    ) : (
      <div
        className={cn(
          'flex aspect-square h-[150px] w-[150px] flex-shrink-0 items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-slate-50 shadow-sm',
          isSelected && 'opacity-50'
        )}
      >
        <img src={post.thumbnailUrl} alt="Instagram media" />
      </div>
    )}
  </TriggerMediaCardWrapper>
);

export default InstagramMediaCard;
