import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const ShopifyShopSchema = z.object({
  countryCode: z.string(),
  currency: z.string(),
  name: z.string(),
});
export type ShopifyShop = z.infer<typeof ShopifyShopSchema>;

export const ShopifyGetShopInputSchema = z.object({});
export type ShopifyGetShopInput = z.infer<typeof ShopifyGetShopInputSchema>;

export const ShopifyGetShopOutputSchema = ShopifyShopSchema;
export type ShopifyGetShopOutput = z.infer<typeof ShopifyGetShopOutputSchema>;

export type ShopifyGetShopActionStruct = ActionStruct<
  'get-shop',
  ShopifyGetShopInput,
  ShopifyGetShopOutput
>;

export const ShopifyGetShopActionSpec: ActionSpec<ShopifyGetShopActionStruct> = {
  id: 'get-shop',
  inputSchema: ShopifyGetShopInputSchema,
  outputSchema: ShopifyGetShopOutputSchema,
};
