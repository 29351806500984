import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {getYoutubeProfileUrl, getYoutubeVideoUrl} from '@cohort/shared/apps/youtube/common';
import type {YoutubeLikeVerificationAttemptData} from '@cohort/shared/apps/youtube/triggers/like';
import {Trans} from 'react-i18next';

const YoutubeLikeTriggerStepCompletionTitleComponent: React.FC<{
  data: YoutubeLikeVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {channelId, channelTitle, channelHandle, videoId} = data;

  if (!completionSuccess && channelId !== null && channelTitle !== null) {
    return (
      <Trans
        i18nKey="triggerIntegrations.like.stepCompletionTitleComponent.errorWithChannel"
        ns="app-youtube"
        values={{username: channelHandle ?? channelTitle}}
        components={{
          userLink: <StepCompletionLink url={getYoutubeProfileUrl(channelId)} />,
          videoLink: <StepCompletionLink url={getYoutubeVideoUrl(videoId)} />,
        }}
      />
    );
  }

  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggerIntegrations.like.stepCompletionTitleComponent.error"
        ns="app-youtube"
        components={{videoLink: <StepCompletionLink url={getYoutubeVideoUrl(videoId)} />}}
      />
    );
  }

  if (channelId !== null && channelTitle !== null) {
    return (
      <Trans
        i18nKey="triggerIntegrations.like.stepCompletionTitleComponent.successWithChannel"
        ns="app-youtube"
        values={{username: channelHandle ?? channelTitle}}
        components={{
          userLink: <StepCompletionLink url={getYoutubeProfileUrl(channelId)} />,
          videoLink: <StepCompletionLink url={getYoutubeVideoUrl(videoId)} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.like.stepCompletionTitleComponent.success"
      ns="app-youtube"
      components={{videoLink: <StepCompletionLink url={getYoutubeVideoUrl(videoId)} />}}
    />
  );
};

export default YoutubeLikeTriggerStepCompletionTitleComponent;
