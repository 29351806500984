import type {ContentADto} from '@cohort/admin-schemas/content';
import {
  Breadcrumb,
  BreadcrumbBadges,
  BreadcrumbContent,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
} from '@cohort/merchants/components/Breadcrumb';
import Button from '@cohort/merchants/components/buttons/Button';
import {ContentStatusBadge} from '@cohort/merchants/components/contents/ContentStatusBadge';
import {CreateEntityModalFormSchema} from '@cohort/merchants/components/modals/CreateEntityModal';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {contentsKeys} from '@cohort/merchants/hooks/api/Contents';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentContent} from '@cohort/merchants/hooks/contexts/currentContent';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {deleteContent, updateContent} from '@cohort/merchants/lib/api/Contents';
import {getContentsRoute} from '@cohort/merchants/lib/Pages';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type DeleteContentButtonProps = {
  content: ContentADto;
};

const DeleteContentButton: React.FC<DeleteContentButtonProps> = ({content}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {t} = useTranslation('pages', {
    keyPrefix: 'contents.content.contentPageBreadcrumb',
  });

  const {mutate: deleteContentMutation} = useCohortMutation({
    mutationFn: async () => deleteContent(merchant.id, content.id),
    notifySuccessMessage: t('deleteContent.notificationDeleteSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(contentsKeys.contents);
      navigate(getContentsRoute().path);
    },
  });

  return (
    <div>
      <Button variant="secondary" size="icon" onClick={() => setShowDeleteModal(true)}>
        <Trash size={20} className="text-red-400" />
      </Button>
      {showDeleteModal && (
        <DeletionModal
          title={t('deleteContent.deleteModalTitle')}
          subtitle={t('deleteContent.deleteModalSubtitle')}
          onClose={async () => setShowDeleteModal(false)}
          show={showDeleteModal}
          onDelete={deleteContentMutation}
        />
      )}
    </div>
  );
};

const ContentPageBreadcrumb = (): JSX.Element => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'contents.content.contentPageBreadcrumb'});
  const content = useCurrentContent();
  const queryClient = useQueryClient();

  const {mutate: updateContentInternalName} = useCohortMutation({
    mutationFn: async (name: string) =>
      updateContent(merchant.id, content.id, {internalName: name}),
    notifySuccessMessage: t('updateNotificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(contentsKeys.getById(merchant.id, content.id));
      queryClient.invalidateQueries(contentsKeys.contents);
    },
  });

  return (
    <Breadcrumb>
      <BreadcrumbContent>
        <BreadcrumbLink name={t('name')} path={getContentsRoute().path} />
        <BreadcrumbEditableTitle
          value={content.internalName}
          onChange={(name: string) => updateContentInternalName(name)}
          validator={(internalName: string) =>
            CreateEntityModalFormSchema.safeParse({internalName}).success
          }
        />
        <BreadcrumbBadges>
          <ContentStatusBadge size="small" status={content.status} />
        </BreadcrumbBadges>
      </BreadcrumbContent>
      <DeleteContentButton content={content} />
    </Breadcrumb>
  );
};

export default ContentPageBreadcrumb;
