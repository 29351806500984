import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import Input from '@cohort/merchants/components/form/input/Input';
import {useTranslation} from 'react-i18next';

const TikTokKeywordInDescriptionTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {control, register}}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.keyword-in-description.configComponent',
  });

  return (
    <Input
      name="triggerIntegrationConfig.keyword"
      label={t('labelKeyword')}
      register={register}
      control={control}
    />
  );
};

export default TikTokKeywordInDescriptionTriggerConfigComponent;
