import type {DigitalAssetCollectionAnalyticsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import {CurrentDigitalAssetCollectionContext} from '@cohort/merchants/pages/digital-asset-collections/digital-asset-collection/CurrentDigitalAssetCollectionContext';
import {useContext} from 'react';

export const useCurrentDigitalAssetCollection = (): DigitalAssetCollectionAnalyticsADto => {
  const context = useContext(CurrentDigitalAssetCollectionContext);

  if (context === null) {
    throw new Error(
      'useCurrentDigitalAssetCollection must be used within a CurrentDigitalAssetCollectionProvider'
    );
  }
  return context;
};
