import type {ActionSpec, AppSpec, AppStruct, PerkIntegrationSpec} from '@cohort/shared/apps/app';
import {ListCampaignsActionSpec} from '@cohort/shared/apps/talon-one/actions/listCampaigns';
import type {TalonOneConnectorStruct} from '@cohort/shared/apps/talon-one/connector';
import {TalonOneConnectorSpec} from '@cohort/shared/apps/talon-one/connector';
import {TalonOneCouponPerkIntegrationSpec} from '@cohort/shared/apps/talon-one/perks/coupon';
import type {TalonOneSyncStruct} from '@cohort/shared/apps/talon-one/sync';
import {TalonOneSyncSpec} from '@cohort/shared/apps/talon-one/sync';

export const TALON_ONE_APP_ID = 'talon-one';
export const TALON_ONE_APP_NAME = 'Talon.One';

export const TalonOneActionSpecs = [
  ListCampaignsActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type TalonOneActionIds = (typeof TalonOneActionSpecs)[number]['id'];

export const TalonOnePerkIntegrationSpecs = [
  TalonOneCouponPerkIntegrationSpec,
] as const satisfies ReadonlyArray<PerkIntegrationSpec>;
type TalonOnePerkIntegrationIds = (typeof TalonOnePerkIntegrationSpecs)[number]['id'];

export type TalonOneAppStruct = AppStruct<
  'talon-one',
  TalonOneConnectorStruct,
  null,
  TalonOneSyncStruct,
  TalonOneActionIds,
  never,
  TalonOnePerkIntegrationIds,
  never,
  never,
  never,
  never,
  never
>;

export const TalonOneAppSpec: AppSpec<TalonOneAppStruct> = {
  id: TALON_ONE_APP_ID,
  name: TALON_ONE_APP_NAME,
  merchantConnector: TalonOneConnectorSpec,
  userConnector: null,
  syncSpec: TalonOneSyncSpec,
  actionSpecs: TalonOneActionSpecs,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: TalonOnePerkIntegrationSpecs,
  triggerIntegrationSpecs: [],
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
