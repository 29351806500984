import type {DataType} from '@cohort/shared/schema/common/rules/dataTypes';
import {Calendar, Hash, ListBullets, TextAa, ToggleLeft} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {match} from 'ts-pattern';

type DataTypeIconProps = {
  dataType: DataType;
  size?: number;
};

const DataTypeIcon: React.FC<DataTypeIconProps> = ({dataType, size = 20}) => {
  return match(dataType)
    .with('boolean', () => <ToggleLeft size={size} className="text-slate-400" />)
    .with('string', () => <TextAa size={size} className="text-slate-400" />)
    .with('date', () => <Calendar size={size} className="text-slate-400" />)
    .with('number', () => <Hash size={size} className="text-slate-400" />)
    .with('resource_list', () => <ListBullets size={size} className="text-slate-400" />)
    .with('string_list', () => <ListBullets size={size} className="text-slate-400" />)
    .with('resource', 'null', () => <Fragment />) // Currently not supported.
    .exhaustive();
};

export default DataTypeIcon;
