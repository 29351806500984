import {AppIdSchema} from '@cohort/shared/apps';
import {asArray, DatetimeSchema} from '@cohort/shared/schema/common';
import {parseValue} from '@cohort/shared/schema/common/userAttribute';
import {UserPropertyDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export const UserAttributeASchema = z
  .object({
    id: z.string().uuid(),
    userId: z.string().uuid(),
    userPropertyId: z.string().uuid(),
    dataType: UserPropertyDataTypeSchema,
    referenceId: z.string(),
    appId: AppIdSchema.nullable(),
    value: z.unknown(),
    createdAt: DatetimeSchema,
    updatedAt: DatetimeSchema,
  })
  .transform(data => {
    const value = parseValue(data.dataType, data.value);
    return {
      ...data,
      value,
    };
  });
export type UserAttributeADto = z.infer<typeof UserAttributeASchema>;

// List user attributes
export const ListUserAttributesParamsASchema = z
  .object({
    userId: z.string().uuid(),
    userPropertyIds: z.preprocess(asArray, z.array(z.string().uuid()).optional()),
    userPropertyReferenceIds: z.preprocess(asArray, z.array(z.string()).optional()),
  })
  .refine(
    data =>
      (data.userPropertyIds !== undefined && data.userPropertyReferenceIds === undefined) ||
      (data.userPropertyIds === undefined && data.userPropertyReferenceIds !== undefined),
    {
      message:
        'Only one of userPropertyIds or userPropertyReferenceIds must be provided, but not both.',
      path: ['userPropertyIds', 'userPropertyReferenceIds'],
    }
  );
export type ListUserAttributesParamsADto = z.infer<typeof ListUserAttributesParamsASchema>;

// Update user attributes
export const UpdateAttributesDataASchema = z
  .array(
    z.object({
      userId: z.string().uuid(),
      userPropertyId: z.string().uuid(),
      value: z.unknown(),
    })
  )
  .min(1)
  .max(1000);
export type UpdateUserAttributesDataADto = z.infer<typeof UpdateAttributesDataASchema>;
