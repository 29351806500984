import type {
  ConnectionADto,
  CreateConnectionDataADto,
  GetAuthorizationUrlDataADto,
  GetAuthorizationUrlResponseADto,
  ListConnectionsParamsADto,
  PatchConnectionDataADto,
} from '@cohort/admin-schemas/connection';
import {
  ConnectionASchema,
  ConnectionProfileASchema,
  GetAuthorizationUrlResponseASchema,
  ListConnectionsResponseASchema,
} from '@cohort/admin-schemas/connection';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ConnectorStruct, ExternalUserProfile} from '@cohort/shared/apps/app';

export async function getConnectionById(
  merchantId: string,
  connectionId: string
): Promise<ConnectionADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/connections/${connectionId}`, {
    expect: HttpCodes.SUCCESS,
    parser: ConnectionASchema.parse,
  });
}

export async function getConnectionProfile<Connector extends ConnectorStruct>(
  merchantId: string,
  connectionId: string
): Promise<ExternalUserProfile<Connector>> {
  return apiCall('GET', `/v1/merchants/${merchantId}/connections/${connectionId}/profile`, {
    expect: HttpCodes.SUCCESS,
    parser: data => {
      ConnectionProfileASchema.parse(data);
      return data as ExternalUserProfile<Connector>;
    },
  });
}

export async function listConnections(
  merchantId: string,
  params?: ListConnectionsParamsADto
): Promise<ConnectionADto[]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/connections`, {
    expect: HttpCodes.SUCCESS,
    parser: ListConnectionsResponseASchema.parse,
    params,
  });
}

export async function getOauthAuthorizationUrl(
  merchantId: string,
  data: GetAuthorizationUrlDataADto
): Promise<GetAuthorizationUrlResponseADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/connections/oauth-authorization-url`, {
    expect: HttpCodes.CREATED,
    parser: GetAuthorizationUrlResponseASchema.parse,
    body: data,
  });
}

export async function createConnection(
  merchantId: string,
  data: CreateConnectionDataADto
): Promise<ConnectionADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/connections`, {
    expect: HttpCodes.CREATED,
    parser: ConnectionASchema.parse,
    body: data,
  });
}

export async function patchConnection(
  merchantId: string,
  connectionId: string,
  data: PatchConnectionDataADto
): Promise<ConnectionADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/connections/${connectionId}`, {
    expect: HttpCodes.SUCCESS,
    parser: ConnectionASchema.parse,
    body: data,
  });
}

export async function configureConnection<T extends ConnectorStruct = ConnectorStruct>(
  merchantId: string,
  connectionId: string,
  config: T['ConnectionConfig']
): Promise<ConnectionADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/connections/${connectionId}/configure`, {
    expect: HttpCodes.CREATED,
    parser: ConnectionASchema.parse,
    body: {config},
  });
}

export async function deleteConnection(merchantId: string, connectionId: string): Promise<object> {
  return apiCall('DELETE', `/v1/merchants/${merchantId}/connections/${connectionId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => ({}),
  });
}
