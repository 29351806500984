export const urlValidator = (val: string): boolean =>
  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?/u.test(val);

// Ensure the URL starts with https://
export const urlTransformer = (url: string, opts?: {encodeUrl: boolean}): string => {
  if (opts?.encodeUrl) {
    const urlObj = new URL(url);
    const encodedParams = new URLSearchParams(urlObj.search).toString();
    return `${urlObj.origin}${urlObj.pathname}${encodedParams ? '?' + encodedParams : ''}`; // Remove the "?" from search params if there are none
  }

  if (url.startsWith('https://')) {
    return url;
  }

  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  } else {
    return `https://${url}`;
  }
};

export const definedKeyValueValidator = (val: Array<{key?: string; value?: string}>): boolean => {
  return val.every(({key, value}) => {
    return key !== undefined && value !== undefined;
  });
};

export const requestBodyTransformer = (body: string | null): string | null => {
  if (!body || body.trim().length === 0) {
    return null;
  }
  return body;
};
