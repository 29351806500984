import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortChallengeOptedInPropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  challengeParticipationId: z.string().uuid(),
});
export type CohortChallengeOptedInProperties = z.infer<
  typeof CohortChallengeOptedInPropertiesSchema
>;

export type CohortChallengeOptedInEventStruct = UserEventStruct<
  'cohort.challenge.opted-in',
  keyof CohortChallengeOptedInProperties,
  CohortChallengeOptedInProperties,
  CohortChallengeOptedInProperties
>;

export const CohortChallengeOptedInEventSpec: UserEventSpec<CohortChallengeOptedInEventStruct> = {
  id: 'cohort.challenge.opted-in',
  name: 'Joined a Challenge',
  description: 'Event Triggerred when a user opts-in a Challenge',
  propertiesSchema: CohortChallengeOptedInPropertiesSchema,
  exposedPropertiesSchema: CohortChallengeOptedInPropertiesSchema,
  resources: {
    campaignId: 'cohort.challenge',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['campaignId'],
  },
};
