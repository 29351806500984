import type {ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const YoutubeMerchantProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  channelId: z.string(),
  channelHandle: z.string().nullable(),
  channelTitle: z.string(),
  subscribersCount: z.number(),
  videosCount: z.number(),
  viewsCount: z.number(),
  isVerified: z.boolean(),
  isMonetized: z.boolean(),
});
export type YoutubeMerchantProfileProperties = z.infer<
  typeof YoutubeMerchantProfilePropertiesSchema
>;

export type YoutubeMerchantConnectorStruct = ConnectorStruct<
  'youtube-merchant',
  OAuth2AppCredentials,
  OAuth2Credentials,
  YoutubeMerchantProfileProperties,
  null
>;

export const YoutubeMerchantConnectorSpec =
  basicOauth2ConnectorSpec<YoutubeMerchantConnectorStruct>(
    'youtube-merchant',
    YoutubeMerchantProfilePropertiesSchema
  );
