import type {TriggerIntegration} from '@cohort/merchants/apps';
import FacebookPageInfoConfigComponent from '@cohort/merchants/apps/facebook/triggers/FacebookPageInfoConfigComponent';
import FacebookLikePageTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/likePage/StepCompletionTitleComponent';
import type {FacebookLikePageTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePage';
import {FacebookLikePageTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePage';

export const FacebookLikePageTriggerIntegration: TriggerIntegration<FacebookLikePageTriggerStruct> =
  {
    spec: FacebookLikePageTriggerSpec,
    configComponent: FacebookPageInfoConfigComponent,
    stepCompletionTitleComponent: FacebookLikePageTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggers.like-page.title', {ns: 'app-facebook'}),
  };
