import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import GarminConnectActivitySelectPicker from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/ActivitySelectPicker';
import GarminConnectCompleteActivityMetricsForm from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/completeActivity/ActivityMetricsForm';
import TriggerIntegrationDateRangeInput from '@cohort/merchants/components/trigger-integration/TriggerIntegrationDateRangeInput';

const GarminConnectCompleteActivityTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = props => {
  return (
    <div className="flex flex-col gap-4">
      <TriggerIntegrationDateRangeInput
        {...props}
        startDateName="triggerIntegrationConfig.activityStartDate"
        endDateName="triggerIntegrationConfig.activityEndDate"
      />

      <GarminConnectActivitySelectPicker
        {...props}
        fieldName="triggerIntegrationConfig.activityGroups"
        hasFieldName="triggerIntegrationConfig.hasActivityTypes"
      />

      <GarminConnectCompleteActivityMetricsForm {...props} />
    </div>
  );
};

export default GarminConnectCompleteActivityTriggerIntegrationConfigComponent;
