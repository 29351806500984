import Button from '@cohort/merchants/components/buttons/Button';
import type {FacebookTagInPostVerificationAttemptData} from '@cohort/shared/apps/facebook/triggers/tagInPost';
import {FacebookLogo} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const FacebookTagInPostTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: FacebookTagInPostVerificationAttemptData;
}> = ({data}) => {
  const {post} = data;

  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggers.tag-in-post.stepCompletionContextComponent',
  });

  if (post === undefined) {
    return <Fragment />;
  }

  return (
    <div className="flex w-full flex-col space-y-4 overflow-hidden rounded-lg border border-slate-200 bg-slate-50 p-4 shadow-sm">
      <div className="flex gap-4">
        <div className="flex aspect-square h-[136px] w-[136px] flex-shrink-0 items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-slate-50 shadow-sm">
          {post.thumbnailUrl !== undefined ? (
            <img src={post.thumbnailUrl} alt="Post thumbnail" />
          ) : (
            <FacebookLogo size={40} weight="fill" className="text-slate-200" />
          )}
        </div>
        <div className="flex flex-grow flex-col justify-between gap-3">
          <div className="line-clamp-5 flex-grow whitespace-pre-wrap text-sm font-normal">
            {post.message !== undefined ? post.message : ''}
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-grow text-xs font-medium text-slate-500">
              {dayjs(post.createdTime).format('ll')}
            </div>
            <div>
              <Button
                size="xs"
                variant="secondary"
                onClick={() => window.open(post.permalinkUrl, '_blank')}
                className="flex items-center gap-1"
              >
                <FacebookLogo size={16} weight="fill" />
                {t('buttonSeeOnFacebook')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookTagInPostTriggerIntegrationStepCompletionContextComponent;
