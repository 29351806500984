import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import type {ResourceListDataType} from '@cohort/shared/schema/common/userProperty';

export type CohortDigitalAssetsUserPropertyStruct = UserPropertyStruct<
  'cohort.digital-assets',
  ResourceListDataType
>;

export const CohortDigitalAssetsUserPropertySpec: UserPropertySpec<CohortDigitalAssetsUserPropertyStruct> =
  {
    id: 'cohort.digital-assets',
    name: 'Digital Assets',
    dataType: 'resource_list',
    scope: 'global',
    resource: 'cohort.digital-asset-collection',
    defaultValue: [],
  };
