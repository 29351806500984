import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import ExportUserEventsConfig from '@cohort/merchants/apps/talon-one/sync/ExportUserEventsConfig';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useTranslation} from 'react-i18next';

const TalonOneSyncConfigComponent: React.FC<SyncConfigComponentProps> = ({connection}) => {
  const {t} = useTranslation('app-talon-one', {
    keyPrefix: 'sync.syncConfigComponent',
  });

  const {register, control} = useCohortForm<Extract<SyncConfigFormValues, {appId: 'talon-one'}>>();

  return (
    <div className="mb-24 space-y-6">
      <div className="flex flex-col gap-6 rounded-lg border border-slate-200 p-6">
        <div className="flex items-center gap-2">
          <SwitchInput
            name="userEventExportEnabled"
            label={t('labelEnableUserEventExport')}
            labelPosition="right"
            register={register}
            control={control}
          />
        </div>
        <ExportUserEventsConfig connection={connection} />
      </div>
    </div>
  );
};

export default TalonOneSyncConfigComponent;
