import type {UserAnalyticsADto} from '@cohort/admin-schemas/userAnalytics';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useUser} from '@cohort/merchants/hooks/api/Users';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import SkeletonLoader from '@cohort/merchants/layouts/SkeletonLoader';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentUserProviderProps = {
  children: React.ReactNode;
};

export const CurrentUserContext = createContext<UserAnalyticsADto | null>(null);

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({children}) => {
  const merchant = useCurrentMerchant();
  const {userId} = useParams();

  const {data: user, isFetched} = useUser(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    userId!,
    merchant.id
  );

  if (userId === undefined) {
    return <NotFoundSection />;
  }

  if (isFetched && !user) {
    return <NotFoundSection />;
  }

  if (user === undefined) {
    return <SkeletonLoader />;
  }

  return <CurrentUserContext.Provider value={user}>{children}</CurrentUserContext.Provider>;
};
