export const OAUTH_COOKIE_NAME = 'oAuthMessage';

const getCurrentDomain = (): string => {
  return window.location.hostname.split('.').slice(-2).join('.'); // remove subdomains
};

export const getCookieValue = (cookieName: string): string | undefined => {
  const cookie = document.cookie.split('; ').find(row => row.startsWith(`${cookieName}=`));
  return cookie?.split('=')[1] ?? undefined;
};

export const createCookie = (cookieName: string, value: string): void => {
  document.cookie = `${cookieName}=${value}; domain=.${getCurrentDomain()}; path=/`;
};

export const resetCookie = (cookieName: string): void => {
  document.cookie = `${cookieName}=; Max-Age=0; path=/; domain=.${getCurrentDomain()}`;
};
