import type {BaseInputProps} from '@cohort/merchants/components/form/input/BaseInput';
import BaseInput from '@cohort/merchants/components/form/input/BaseInput';
import type {FormField} from '@cohort/merchants/lib/form/utils';
import get from 'lodash/get';
import type {FieldValues} from 'react-hook-form';
import {useFormState} from 'react-hook-form';

type InputProps<T extends FieldValues> = BaseInputProps &
  FormField<T> & {
    hideError?: boolean;
  };

export default function Input<T extends FieldValues>({
  insetPrefix,
  register,
  control,
  label,
  description,
  rules,
  labelPosition,
  hideError,
  onChange,
  ...props
}: InputProps<T>): JSX.Element {
  const {errors} = useFormState({
    control,
    name: props.name,
  });

  const registeredProps = register(props.name, {
    ...rules,
    setValueAs: (value: unknown) => {
      if (props.type === 'number') {
        return value === '' || value === null ? null : parseFloat(value as string);
      }
      return value;
    },
  });

  return (
    <BaseInput
      insetPrefix={insetPrefix}
      label={label}
      description={description}
      labelPosition={labelPosition}
      error={(!hideError ? get(errors, `${props.name}.message`) : undefined) as string | undefined}
      {...props}
      {...registeredProps}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        registeredProps.onChange(e);
        onChange?.(e);
      }}
    />
  );
}
