import Router from '@cohort/merchants/router/Router';
import {getConfigVar} from '@cohort/shared/utils/config';
import {withLDProvider} from 'launchdarkly-react-client-sdk';

const RouterWithFlags = withLDProvider({
  clientSideID: getConfigVar(import.meta.env.COHORT_ENV, 'launchDarklyClientId'),
  options: {
    fetchGoals: false,
    sendEvents: false,
    streaming: false, // This is needed to make tests work with networkidle
  },
})(Router);

export default RouterWithFlags;
