import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import BaseFacebookSelectPostTriggerIntegrationConfig from '@cohort/merchants/apps/facebook/triggers/BaseFacebookSelectPostTriggerIntegrationConfig';
import {useTranslation} from 'react-i18next';

const FacebookLikePostTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggers.like-post.configComponent',
  });

  return (
    <BaseFacebookSelectPostTriggerIntegrationConfig
      connectionId={connectionId}
      formReturn={formReturn}
      description={t('descriptionPostSelect')}
      title={t('titlePostSelect')}
      noPostsMessage={t('noPostsMessage')}
    />
  );
};

export default FacebookLikePostTriggerIntegrationConfigComponent;
