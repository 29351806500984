import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import {CurrentCampaignContext} from '@cohort/merchants/pages/campaigns/campaign/CurrentCampaignContext';
import {useContext} from 'react';

export const useCurrentCampaign = (): CampaignADto => {
  const context = useContext(CurrentCampaignContext);

  if (context === null) {
    throw new Error('useCurrentCampaign must be used within a CurrentCampaignProvider');
  }
  return context;
};
