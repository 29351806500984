import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import CohortFormConfig from '@cohort/merchants/components/form/cohortForm/CohortFormConfig';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';

const CohortFormPerkIntegrationConfigComponent: React.FC<
  PerkIntegrationConfigComponentProps
> = () => {
  const perk = useCurrentPerk();
  const {register, control} = useCohortForm<PerkFormValues>();

  return (
    <CohortFormConfig
      register={register}
      control={control}
      fieldPaths={{
        questions: 'integration.config.questions',
        description: 'integration.config.description',
      }}
      existingConfig={perk.integration?.config}
    />
  );
};

export default CohortFormPerkIntegrationConfigComponent;
