import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {INSTAGRAM_URL} from '@cohort/shared/apps/instagram/common';
import type {InstagramTagInPostVerificationAttemptData} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import {Trans} from 'react-i18next';

const InstagramTagInPostTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: InstagramTagInPostVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {username, media} = data;
  const userLink = `${INSTAGRAM_URL}/${username}`;

  if (!completionSuccess || media === null) {
    return (
      <Trans
        i18nKey="triggerIntegrations.tag-in-post.stepCompletionTitleComponent.error"
        ns="app-instagram"
        values={{username: username}}
        components={{
          userLink: <StepCompletionLink url={userLink} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.tag-in-post.stepCompletionTitleComponent.success"
      ns="app-instagram"
      values={{username}}
      components={{
        userLink: <StepCompletionLink url={userLink} />,
        postLink: <StepCompletionLink url={media.permalink} />,
      }}
    />
  );
};

export default InstagramTagInPostTriggerIntegrationStepCompletionTitleComponent;
