import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {TikTokVideoSchema} from '@cohort/shared/apps/tiktok/common';
import {z} from 'zod';

export const ListVideosInputSchema = z.object({});
export type ListVideosInput = z.infer<typeof ListVideosInputSchema>;

export const ListVideosOutputSchema = z.object({
  videos: z.array(TikTokVideoSchema),
});
export type ListVideosOutput = z.infer<typeof ListVideosOutputSchema>;

export type ListVideosActionStruct = ActionStruct<'list-videos', ListVideosInput, ListVideosOutput>;
export const ListVideosActionSpec: ActionSpec<ListVideosActionStruct> = {
  id: 'list-videos',
  inputSchema: ListVideosInputSchema,
  outputSchema: ListVideosOutputSchema,
};
