import type {ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const FacebookUserProfileSchema = BaseProfilePropertiesSchema.extend({
  id: z.string(),
  username: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  gender: z.string().nullable(),
  location: z.string().nullable(),
  birthday: z.string().nullable(),
  link: z.string().nullable(),
});
export type FacebookUserProfile = z.infer<typeof FacebookUserProfileSchema>;

export type FacebookUserConnectorStruct = ConnectorStruct<
  'facebook',
  OAuth2AppCredentials,
  OAuth2Credentials,
  FacebookUserProfile,
  null
>;

export const FacebookUserConnectorSpec = basicOauth2ConnectorSpec<FacebookUserConnectorStruct>(
  'facebook',
  FacebookUserProfileSchema
);
