import {Badge} from '@cohort/merchants/components/Badge';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment} from 'react';

type IconBadgeProps = {
  text: string | JSX.Element;
  icon: JSX.Element;
  size?: 'large' | 'small';
  backgroundColor?: string;
};

export const IconBadge: React.FC<IconBadgeProps> = ({text, icon, size, backgroundColor}) => (
  <Badge
    textColor="text-slate-800"
    backgroundColor={cn(backgroundColor ?? 'bg-slate-50')}
    size={size}
    text={
      <Fragment>
        {icon}
        {text}
      </Fragment>
    }
  />
);
