import useLazyRouteLoader from '@cohort/merchants/hooks/lazyRouteLoader';
import {Fragment} from 'react';
import {Outlet} from 'react-router-dom';

const PageOutlet = (): JSX.Element => {
  const loader = useLazyRouteLoader();

  return (
    <Fragment>
      {loader}
      <Outlet />
    </Fragment>
  );
};

export default PageOutlet;
