import {
  SelectMediaConfig,
  SelectMediaConfigButton,
  SelectMediaConfigEmptyState,
} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaConfig';
import {SelectMediaContextProvider} from '@cohort/merchants/apps/common/triggers/SelectMedia/SelectMediaContextProvider';
import SelectVideoSheet from '@cohort/merchants/apps/tiktok/triggers/components/SelectVideoSheet';
import TikTokVideoCard from '@cohort/merchants/apps/tiktok/triggers/components/TikTokVideoCard';
import type {StepTriggerFormType} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {TikTokVideo} from '@cohort/shared/apps/tiktok/common';
import {Fragment} from 'react/jsx-runtime';
import type {UseFormSetValue} from 'react-hook-form';

type TikTokVideoSelectorProps = {
  description: string;
  setValue: UseFormSetValue<StepTriggerFormType>;
  title: string;
  videos: TikTokVideo[];
  selectedVideo?: TikTokVideo;
};

const TikTokVideoSelector: React.FC<TikTokVideoSelectorProps> = ({
  description,
  setValue,
  selectedVideo,
  title,
  videos,
}) => (
  <SelectMediaContextProvider defaultMediaId={selectedVideo?.id ?? null}>
    <SelectMediaConfig title={title} description={description}>
      {selectedVideo === undefined ? (
        <Fragment>
          <SelectMediaConfigEmptyState />
          <SelectMediaConfigButton mode="add" />
        </Fragment>
      ) : (
        <Fragment>
          <TikTokVideoCard media={selectedVideo} />
          <SelectMediaConfigButton mode="change" />
        </Fragment>
      )}
      <SelectVideoSheet setValue={setValue} videos={videos} />
    </SelectMediaConfig>
  </SelectMediaContextProvider>
);

export default TikTokVideoSelector;
