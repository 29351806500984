import {useCurrentCohort} from '@cohort/merchants/hooks/contexts/currentCohort';
import {useCohortPageStore} from '@cohort/merchants/hooks/stores/cohortPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import PageOutlet from '@cohort/merchants/layouts/PageOutlet';
import CohortPageBreadcrumb from '@cohort/merchants/pages/users/cohort/CohortPageBreadcrumb';
import {CurrentCohortProvider} from '@cohort/merchants/pages/users/cohort/CurrentCohortContext';
import SegmentCohortPageMenu from '@cohort/merchants/pages/users/cohort/SegmentCohortPageMenu';

const CohortPageSkeleton: React.FC = () => {
  const cohort = useCurrentCohort();
  const footer = useCohortPageStore(store => store.footer);

  return (
    <PageLayout
      title={cohort.name}
      breadcrumb={<CohortPageBreadcrumb />}
      menu={cohort.type === 'segment' ? <SegmentCohortPageMenu /> : undefined}
      footer={footer}
    >
      <PageOutlet />
    </PageLayout>
  );
};

const CohortPageLayout = (): JSX.Element => (
  <CurrentCohortProvider>
    <CohortPageSkeleton />
  </CurrentCohortProvider>
);

export default CohortPageLayout;
