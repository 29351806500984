import useSelectMediaContext from '@cohort/merchants/apps/common/triggers/SelectMedia/useSelectMediaContext';
import Button from '@cohort/merchants/components/buttons/Button';
import HighlightText from '@cohort/merchants/components/HighlightText';
import {ArrowsCounterClockwise, ImagesSquare} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

type SelectMediaConfigProps = {
  title: string;
  description: string;
  children: React.ReactNode;
};

const SelectMediaConfig: React.FC<SelectMediaConfigProps> = ({title, description, children}) => (
  <div className="flex flex-col gap-y-2">
    <div className="flex flex-col gap-y-1">
      <p className="pb-1 text-sm font-medium">{title}</p>
      <p className="pb-2 text-sm">{description}</p>
    </div>
    <div className="flex flex-col gap-y-4 *:w-fit">{children}</div>
  </div>
);

type SelectMediaConfigButtonProps = {
  mode: 'add' | 'change';
};

const SelectMediaConfigButton: React.FC<SelectMediaConfigButtonProps> = ({mode}) => {
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.selectMediaConfig',
  });
  const {setSelectMediaSheetOpened} = useSelectMediaContext();

  const buttonText = mode === 'add' ? t('buttonSelectMedia') : t('buttonChangeMedia');

  return (
    <Button variant="ghost" onClick={() => setSelectMediaSheetOpened(true)}>
      {mode === 'add' ? (
        <ImagesSquare className="mr-2 h-5 w-5" />
      ) : (
        <ArrowsCounterClockwise className="mr-2 h-5 w-5" />
      )}
      {buttonText}
    </Button>
  );
};

const SelectMediaConfigEmptyState: React.FC = () => {
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.selectMediaConfig',
  });
  return (
    <div className="mt-4">
      <HighlightText type="warning" text={t('emptyStateText')} />
    </div>
  );
};

export {SelectMediaConfig, SelectMediaConfigButton, SelectMediaConfigEmptyState};
