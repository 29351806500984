import type {BaseProfileProperties, ConnectorSpec, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth1AppCredentials, OAuth1Credentials} from '@cohort/shared/apps/oauth';
import {OAuth1AppCredentialsSchema, OAuth1CredentialsSchema} from '@cohort/shared/apps/oauth';

export type GarminConnectUserConnectorStruct = ConnectorStruct<
  'garmin-connect',
  OAuth1AppCredentials,
  OAuth1Credentials,
  BaseProfileProperties,
  null
>;

export const GarminConnectUserConnectorSpec = {
  id: 'garmin-connect',
  type: 'oauth1',
  appCredentialsSchema: OAuth1AppCredentialsSchema,
  credentialsSchema: OAuth1CredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<GarminConnectUserConnectorStruct>;
