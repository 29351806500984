import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {KlaviyoConnectorStruct} from '@cohort/shared/apps/klaviyo/connector';
import {KlaviyoConnectorSpec} from '@cohort/shared/apps/klaviyo/connector';
import type {KlaviyoSyncStruct} from '@cohort/shared/apps/klaviyo/sync';
import {KlaviyoSyncSpec} from '@cohort/shared/apps/klaviyo/sync';

export const KLAVIYO_APP_ID = 'klaviyo';
export const KLAVIYO_APP_NAME = 'Klaviyo';

export type KlaviyoAppStruct = AppStruct<
  'klaviyo',
  KlaviyoConnectorStruct,
  null,
  KlaviyoSyncStruct,
  never,
  never,
  never,
  never,
  never,
  never,
  never,
  never
>;

export const KlaviyoAppSpec: AppSpec<KlaviyoAppStruct> = {
  id: KLAVIYO_APP_ID,
  name: KLAVIYO_APP_NAME,
  merchantConnector: KlaviyoConnectorSpec,
  userConnector: null,
  syncSpec: KlaviyoSyncSpec,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: [],
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
