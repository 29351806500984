import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {Fragment} from 'react';

const InstagramTagInPostTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = () => {
  return <Fragment />;
};

export default InstagramTagInPostTriggerIntegrationConfigComponent;
