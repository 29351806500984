import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortPerkReceivedPropertiesSchema = z.object({
  perkAccessId: z.string().uuid(),
  perkId: z.string().uuid(),
});
export type CohortPerkReceivedProperties = z.infer<typeof CohortPerkReceivedPropertiesSchema>;

export type CohortPerkReceivedEventStruct = UserEventStruct<
  'cohort.perk.received',
  keyof CohortPerkReceivedProperties,
  CohortPerkReceivedProperties,
  CohortPerkReceivedProperties
>;

export const CohortPerkReceivedEventSpec: UserEventSpec<CohortPerkReceivedEventStruct> = {
  id: 'cohort.perk.received',
  name: 'Received a Perk',
  description: 'Event Triggerred when a user receives a new Perk',
  propertiesSchema: CohortPerkReceivedPropertiesSchema,
  exposedPropertiesSchema: CohortPerkReceivedPropertiesSchema,
  resources: {
    perkId: 'cohort.perk',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['perkId'],
  },
};
