import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {TypeformFormFieldSchema} from '@cohort/shared/apps/typeform/common';
import {z} from 'zod';

export const TypeformFormSchema = z.object({
  id: z.string(),
  title: z.string(),
  settings: z.object({
    is_public: z.boolean(),
  }),
  variables: z
    .object({
      score: z.number(),
    })
    .optional(),
  fields: z.array(TypeformFormFieldSchema).optional(),
  hidden: z.array(z.string()).optional(),
  workspace: z
    .object({
      href: z.string(),
    })
    .optional(),
});
export type TypeformForm = z.infer<typeof TypeformFormSchema>;

export const ListFormsInputSchema = z.object({});
type ListFormsInput = z.infer<typeof ListFormsInputSchema>;

export const ListFormsOutputSchema = z.object({
  forms: z.array(TypeformFormSchema),
});
export type ListFormsOutput = z.infer<typeof ListFormsOutputSchema>;

export type ListFormsActionStruct = ActionStruct<'list-forms', ListFormsInput, ListFormsOutput>;

export const ListFormsActionSpec: ActionSpec<ListFormsActionStruct> = {
  id: 'list-forms',
  inputSchema: ListFormsInputSchema,
  outputSchema: ListFormsOutputSchema,
};
