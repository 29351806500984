import type {UserPropertyADto} from '@cohort/admin-schemas/userProperty';
import type {GetUserObjectsOutput} from '@cohort/shared/apps/salesforce/actions/getUserObjects';
import type {
  SalesforceUserExportConfig,
  SalesforceUserSyncConfig,
} from '@cohort/shared/apps/salesforce/sync';
import type {UserPropertyDataType} from '@cohort/shared/schema/common/userProperty';

export const SALESFORCE_USER_PROPERTY_PREFIX = 'Salesforce';

export type SalesforceUserObject = GetUserObjectsOutput['objects'][0];
export type SalesforceUserObjectField = SalesforceUserObject['fields'][0];
export type MappedUserProperty = {
  userProperty: UserPropertyADto | null;
  salesforceField: SalesforceUserObjectField | null;
};

export const buildDefaultUserPropertyColumn =
  (): SalesforceUserSyncConfig['userPropertyColumns'][0] => {
    return {referenceId: '', columnName: '', propertyName: '', dataType: 'string'};
  };
export const buildDefaultMappedUserProperty =
  (): SalesforceUserExportConfig['userProperties'][0] => {
    return {userPropertyId: '', salesforceFieldName: ''};
  };

export const getDataTypeFromSalesforceFieldType = (fieldType: string): UserPropertyDataType => {
  switch (fieldType) {
    case 'boolean':
      return 'boolean';

    case 'currency':
    case 'double':
    case 'int':
    case 'percent':
      return 'number';

    case 'date':
    case 'datetime':
      return 'date';

    default:
      return 'string';
  }
};

export const getEmailFieldsFromObject = (
  object: SalesforceUserObject
): SalesforceUserObjectField[] => {
  return object.fields.filter(field => field.type === 'email');
};

export const getExternalIdFieldsFromObject = (
  object: SalesforceUserObject
): SalesforceUserObjectField[] => {
  return object.fields.filter(field => field.externalId);
};

export const getSalesforceObjectByName = (
  salesforceObjects: SalesforceUserObject[] | undefined,
  name: string
): SalesforceUserObject | undefined => {
  return salesforceObjects?.find(object => object.name === name);
};
