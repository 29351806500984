import {createCookie, OAUTH_COOKIE_NAME} from '@cohort/merchants/lib/Cookies';
import {getSubdomainUrl} from '@cohort/merchants/lib/Utils';
import type {ConnectorId} from '@cohort/shared/apps';
import {ADMIN_OAUTH_REDIRECT_SUBDOMAIN} from '@cohort/shared/constants';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {useEffect} from 'react';

export type OAuthSuccessMessage = {
  code: 'oauth-success';
  connectionId: string;
};
export type OAuthErrorMessage = {
  code: 'oauth-error';
  errorCode:
    | 'oauth.access-denied'
    | 'oauth.authorization-code-invalid'
    | 'oauth.authorization-error-retryable'
    | 'oauth.authorization-error-unretryable'
    | 'connector.insufficient-permissions';
  connectorId: ConnectorId;
  cause: CohortErrorCode | null;
};

export type Message = OAuthSuccessMessage | OAuthErrorMessage;

export function sendMessage(message: Message): void {
  if (!window.opener) {
    // Fallback strategy: save the message in a cookie
    createCookie(OAUTH_COOKIE_NAME, JSON.stringify(message));

    return;
  }

  // Since the OAuth popup is on a different domain, we need to use postMessage
  // with a wildcard origin to send the message.
  window.opener.postMessage(message, '*');
}

export function useMessageListener(
  codes: Array<Message['code']>,
  callback: (message: Message) => void
): void {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const listener = (event: MessageEvent) => {
      // check that the event is coming from redirect subdomain for security
      if (
        event.origin === getSubdomainUrl(ADMIN_OAUTH_REDIRECT_SUBDOMAIN) &&
        codes.includes(event.data.code)
      ) {
        callback(event.data as Message);
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codes]);
}
