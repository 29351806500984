import ErrorBg from '@cohort/merchants/components/error-pages/ErrorBg.svg';
import type {Icon as IconType} from '@phosphor-icons/react';

export type ErrorSectionTemplateProps = {
  Icon?: IconType;
  title: string;
  description: string;
  cta?: JSX.Element;
};

const ErrorSectionTemplate: React.FC<ErrorSectionTemplateProps> = ({
  Icon,
  title,
  description,
  cta,
}) => (
  <div
    className="flex h-full items-center justify-center bg-cover bg-center bg-no-repeat"
    style={{
      backgroundImage: `url(${ErrorBg})`,
      backgroundPositionY: '80px',
    }}
  >
    <div className="flex flex-col items-center gap-4">
      {Icon && <Icon size={64} className="text-primary" weight="duotone" />}
      <h1 className="text-2xl font-semibold">{title}</h1>
      <h3 className="text-base text-slate-500">{description}</h3>
      {cta}
    </div>
  </div>
);

export default ErrorSectionTemplate;
