import type {TriggerIntegration} from '@cohort/merchants/apps';
import InstagramLikeMediaTriggerIntegrationConfigComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/likeMedia/ConfigComponent';
import InstagramLikeMediaTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/likeMedia/StepCompletionTitleComponent';
import type {InstagramLikeMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import {InstagramLikeMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/likeMedia';

export const InstagramLikeMediaTriggerIntegration: TriggerIntegration<InstagramLikeMediaTriggerStruct> =
  {
    spec: InstagramLikeMediaTriggerIntegrationSpec,
    configComponent: InstagramLikeMediaTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: InstagramLikeMediaTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.like-media.title', {ns: 'app-instagram'}),
  };
