import type {CohortADto} from '@cohort/admin-schemas/cohort';
import Button from '@cohort/merchants/components/buttons/Button';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {cohortsKeys} from '@cohort/merchants/hooks/api/Cohorts';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {deleteCohort} from '@cohort/merchants/lib/api/Cohorts';
import {getCohortsRoute} from '@cohort/merchants/lib/Pages';
import {isList} from '@cohort/merchants/pages/users/cohort/utils';
import type {CohortError} from '@cohort/shared/schema/common/errors';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {Trash} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const notifyDeleteCohortError = (
  titleKey: string,
  contentKey: string,
  values: Record<string, string>
): void =>
  notify('error', titleKey, {
    description: (
      <p className="text-sm">
        <Trans
          i18nKey={contentKey}
          ns="pages"
          components={{
            list: <span className="font-medium"></span>,
          }}
          values={values}
        />
      </p>
    ),
    duration: 7000,
  });

type DeleteCohortProps = {
  cohort: CohortADto;
};

const DeleteCohort: React.FC<DeleteCohortProps> = ({cohort}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.deleteCohort',
  });

  const deleteCohortErrorTitle = isList(cohort)
    ? t('cohortDeleteErrorTitleList')
    : t('cohortDeleteErrorTitleSegment');

  // i18nOwl-ignore [cohortDeleteErrorDescriptionList, cohortDeleteErrorDescriptionSegment]
  const notifyCohortUsedInRuleError = (err: CohortError): void => {
    const cohortList = (err.context.cohorts as Array<{id: string; name: string}>)
      .map(cohort => cohort.name)
      .join(', ');

    return notifyDeleteCohortError(
      deleteCohortErrorTitle,
      `users.cohort.deleteCohort.${
        isList(cohort) ? 'cohortDeleteErrorDescriptionList' : 'cohortDeleteErrorDescriptionSegment'
      }`,
      {
        listsAndSegments: cohortList,
      }
    );
  };

  // i18nOwl-ignore [cohortDeleteBadgeErrorDescriptionList, cohortDeleteBadgeErrorDescriptionSegment]
  const notifyCohortUsedInBadgeError = (err: CohortError): void => {
    const badgeList = (err.context.badges as Array<{id: string; internalName: string}>)
      .map(badge => badge.internalName)
      .join(', ');

    return notifyDeleteCohortError(
      deleteCohortErrorTitle,
      `users.cohort.deleteCohort.${
        isList(cohort)
          ? 'cohortDeleteBadgeErrorDescriptionList'
          : 'cohortDeleteBadgeErrorDescriptionSegment'
      }`,
      {
        badges: badgeList,
      }
    );
  };

  // i18nOwl-ignore [cohortDeleteCampaignErrorDescriptionList, cohortDeleteCampaignErrorDescriptionSegment]
  const notifyCohortUsedInCampaignError = (err: CohortError): void => {
    const campaignsList = (err.context.campaigns as Array<{id: string; internalName: string}>)
      .map(campaign => campaign.internalName)
      .join(', ');

    return notifyDeleteCohortError(
      deleteCohortErrorTitle,
      `users.cohort.deleteCohort.${
        isList(cohort)
          ? 'cohortDeleteCampaignErrorDescriptionList'
          : 'cohortDeleteCampaignErrorDescriptionSegment'
      }`,
      {
        campaigns: campaignsList,
      }
    );
  };

  const {mutate: handleCohortDelete} = useCohortMutation({
    mutationFn: async () => deleteCohort(merchantId, cohort.id),
    notifySuccessMessage: t('notificationDeleteSuccess'),
    onSuccess: async () => {
      await queryClient.invalidateQueries(cohortsKeys.list(merchantId));
      navigate(getCohortsRoute().path);
    },
    onError: err => {
      if (
        isCohortError(err, [
          'cohort.used-in-rule',
          'cohort.used-in-badge',
          'cohort.used-in-campaign',
        ])
      ) {
        if (err.code === 'cohort.used-in-rule') {
          return notifyCohortUsedInRuleError(err);
        }
        if (err.code === 'cohort.used-in-campaign') {
          return notifyCohortUsedInCampaignError(err);
        }
        return notifyCohortUsedInBadgeError(err);
      }
      throw err;
    },
  });

  return (
    <div>
      <Button variant="secondary" size="icon" onClick={() => setShowDeleteModal(true)}>
        <Trash size={20} className="text-red-400" />
      </Button>
      {showDeleteModal && (
        <DeletionModal
          title={isList(cohort) ? t('deleteModalTitleList') : t('deleteModalTitleSegment')}
          subtitle={isList(cohort) ? t('deleteModalSubtitleList') : t('deleteModalSubtitleSegment')}
          onClose={() => setShowDeleteModal(false)}
          show={true}
          onDelete={handleCohortDelete}
        />
      )}
    </div>
  );
};

export default DeleteCohort;
