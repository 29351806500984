import {useCallback, useContext, useEffect} from 'react';
import {UNSAFE_NavigationContext as NavigationContext, useLocation} from 'react-router-dom';

type NavigationPushParams = {
  hash: string;
  pathname: string;
  search: string;
};

function useConfirmExit(confirmExit: () => boolean, when = true): void {
  const {navigator} = useContext(NavigationContext);
  const location = useLocation();

  useEffect(() => {
    if (!when) {
      return;
    }
    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const navigationPushParams = args[0] as NavigationPushParams;

      // Prevent the prompt when navigating to the same page
      if (location.pathname === navigationPushParams.pathname) {
        return push(...args);
      }

      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when, location.pathname]);
}

export function usePrompt(message: string, when = true): void {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);

    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}
