import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const TypeformWorkspaceSchema = z.object({
  id: z.string(),
  name: z.string(),
  shared: z.boolean(),
});
export type TypeformWorkspace = z.infer<typeof TypeformWorkspaceSchema>;

export const ListWorkspacesInputSchema = z.object({});
export type ListWorkspacesInput = z.infer<typeof ListWorkspacesInputSchema>;

export const ListWorkspacesOutputSchema = z.object({
  workspaces: z.array(TypeformWorkspaceSchema),
});
export type ListWorkspacesOutput = z.infer<typeof ListWorkspacesOutputSchema>;

export type ListWorkspacesActionStruct = ActionStruct<
  'list-workspaces',
  ListWorkspacesInput,
  ListWorkspacesOutput
>;

export const ListWorkspacesActionSpec: ActionSpec<ListWorkspacesActionStruct> = {
  id: 'list-workspaces',
  inputSchema: ListWorkspacesInputSchema,
  outputSchema: ListWorkspacesOutputSchema,
};
