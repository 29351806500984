import type {
  GetUrlPreviewADto,
  MerchantADto,
  MerchantApiSettingsADto,
  PatchMerchantADto,
  UrlPreviewADto,
} from '@cohort/admin-schemas/merchant';
import {
  MerchantApiSettingsASchema,
  MerchantASchema,
  UrlPreviewSchema,
} from '@cohort/admin-schemas/merchant';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';

export async function patchMerchant(
  merchantId: string,
  data: PatchMerchantADto
): Promise<MerchantADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}`, {
    expect: HttpCodes.SUCCESS,
    parser: MerchantASchema.parse,
    body: data,
  });
}

export async function getMerchant(merchantId: string): Promise<MerchantADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}`, {
    expect: HttpCodes.SUCCESS,
    parser: MerchantASchema.parse,
  });
}

export async function getMerchantApiSettings(merchantId: string): Promise<MerchantApiSettingsADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/api-settings`, {
    expect: HttpCodes.SUCCESS,
    parser: MerchantApiSettingsASchema.parse,
  });
}

export async function generateUrlPreview(
  merchantId: string,
  data: GetUrlPreviewADto
): Promise<UrlPreviewADto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/generate-url-preview`, {
    expect: HttpCodes.CREATED,
    parser: UrlPreviewSchema.parse,
    body: data,
  });
}
