import {cn} from '@cohort/shared-frontend/utils/classNames';
import {forwardRef} from 'react';

type RichTextProps = {
  html: string;
  className?: string;
};

const RichText = forwardRef<HTMLDivElement, RichTextProps>((props, ref) => {
  const {html, className} = props;

  return (
    <p
      className={cn('prose-sm text-left', className)}
      dangerouslySetInnerHTML={{__html: html}}
      ref={ref}
    />
  );
});

export default RichText;
