import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {BaseResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {z} from 'zod';

export const CohortCohortResourceSchema = BaseResourcePropertiesSchema.extend({
  id: z.string().uuid(),
});

export type CohortCohortResourceType = z.infer<typeof CohortCohortResourceSchema>;

export type CohortCohortResourceStruct = ResourceStruct<'cohort.cohort', CohortCohortResourceType>;

export const CohortCohortResourceSpec: ResourceSpec<CohortCohortResourceStruct> = {
  id: 'cohort.cohort',
  schema: CohortCohortResourceSchema,
};
