import type {BaseProfileProperties, ConnectorSpec, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials} from '@cohort/shared/apps/oauth';
import {OAuth2AppCredentialsSchema, OAuth2CredentialsSchema} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const SALESFORCE_CONNECTOR_ID = 'salesforce' as const;

export const SalesforceEnvironmentSchema = z.enum(['sandbox', 'production']);
export type SalesforceEnvironment = z.infer<typeof SalesforceEnvironmentSchema>;

export const SalesforceCredentialsSchema = OAuth2CredentialsSchema.extend({
  instanceUrl: z.string().url(),
  environment: SalesforceEnvironmentSchema,
});
export type SalesforceCredentials = z.infer<typeof SalesforceCredentialsSchema>;

export type SalesforceConnectorStruct = ConnectorStruct<
  'salesforce',
  OAuth2AppCredentials,
  SalesforceCredentials,
  BaseProfileProperties,
  null
>;

export const SalesforceConnectorSpec = {
  id: SALESFORCE_CONNECTOR_ID,
  type: 'oauth2',
  appCredentialsSchema: OAuth2AppCredentialsSchema,
  credentialsSchema: SalesforceCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<SalesforceConnectorStruct>;
