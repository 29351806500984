import {z} from 'zod';

export type TokenId = number;
export type Address = string;

export const NetworkSchema = z.enum(['polygon', 'polygon-mumbai', 'polygon-amoy', 'local']);
export type Network = z.infer<typeof NetworkSchema>;

export const DeprecatedNetworks: Array<Network> = ['polygon-mumbai'];

export const BlockchainNameSchema = z.enum(['Local', 'Mumbai', 'Amoy', 'Polygon']);
export type BlockchainNameDto = z.infer<typeof BlockchainNameSchema>;

export const TokenStandardSchema = z.enum(['ERC-1155']);
export type TokenStandardDto = z.infer<typeof TokenStandardSchema>;

export const TokenMetadataAttributeSchema = z.object({
  trait_type: z.string(),
  value: z.string(),
});
export type TokenMetadataAttributeDto = z.infer<typeof TokenMetadataAttributeSchema>;

export const TokenMetadataSchema = z.object({
  name: z.string(),
  description: z.string(),
  image: z.string().url(),
  animation_url: z.string().url().nullable(),
  external_url: z.string().url().optional(),
  attributes: z.array(TokenMetadataAttributeSchema),
});
export type TokenMetadataDto = z.infer<typeof TokenMetadataSchema>;

export const CollectionMetadataSchema = z.object({
  name: z.string(),
  image: z.string().url(),
  description: z.string(),
  external_link: z.string().url(),
});
export type CollectionMetadataDto = z.infer<typeof CollectionMetadataSchema>;

export const SmartContractSpecs = [
  {
    version: 'CohortNft1155',
    transfer: true,
    batch: false,
  },
  {
    version: 'CohortNft1155V2',
    transfer: true,
    batch: true,
  },
  {
    version: 'CohortNonTransferableNftV1',
    transfer: false,
    batch: true,
  },
] as const;

export type SmartContractSpec = (typeof SmartContractSpecs)[number];
export type SmartContractVersion = (typeof SmartContractSpecs)[number]['version'];

export const getSmartContractSpec = (version: SmartContractVersion): SmartContractSpec => {
  return SmartContractSpecs.find(spec => spec.version === version) as SmartContractSpec;
};
