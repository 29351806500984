import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getBadgeRoute} from '@cohort/merchants/lib/Pages';
import type {CohortBadgeLostEventStruct} from '@cohort/shared/apps/cohort/userEvents/badge/badgeLost';
import {CohortBadgeLostEventSpec} from '@cohort/shared/apps/cohort/userEvents/badge/badgeLost';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const badgeId = (userEvent.properties as CohortBadgeLostEventStruct['Properties']).badgeId;
  const badgeInternalName = userEvent.badgeInternalName;
  const navigate = useNavigate();

  return (
    <Trans
      i18nKey="userEvents.badge.lost.detail"
      ns="app-cohort"
      values={{
        badgeInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () => navigate(getBadgeRoute(badgeId).path)),
      }}
    />
  );
};

export const CohortBadgeLostUserEvent: UserEvent<CohortBadgeLostEventStruct> = {
  spec: CohortBadgeLostEventSpec,
  detailComponent: DetailComponent,
};
