import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {
  getYoutubeCommentUrl,
  getYoutubeProfileUrl,
  getYoutubeVideoUrl,
} from '@cohort/shared/apps/youtube/common';
import type {YoutubeCommentVerificationAttemptData} from '@cohort/shared/apps/youtube/triggers/comment';
import {Trans, useTranslation} from 'react-i18next';

const YoutubeCommentTriggerStepCompletionTitleComponent: React.FC<{
  data: YoutubeCommentVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {channelId, channelTitle, channelHandle, comment} = data;
  const {t} = useTranslation('app-youtube');

  if (!completionSuccess && channelId !== null && channelTitle !== null) {
    return (
      <Trans
        i18nKey="triggerIntegrations.comment.stepCompletionTitleComponent.errorWithChannel"
        ns="app-youtube"
        values={{username: channelHandle ?? channelTitle}}
        components={{
          userLink: <StepCompletionLink url={getYoutubeProfileUrl(channelId)} />,
        }}
      />
    );
  }

  if (!completionSuccess || comment === null) {
    return t('triggerIntegrations.comment.stepCompletionTitleComponent.error');
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.comment.stepCompletionTitleComponent.success"
      ns="app-youtube"
      values={{username: channelHandle ?? channelTitle, videoTitle: comment.videoTitle}}
      components={{
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userLink: <StepCompletionLink url={getYoutubeProfileUrl(channelId!)} />,
        commentLink: <StepCompletionLink url={getYoutubeCommentUrl(comment.videoId, comment.id)} />,
        videoLink: <StepCompletionLink url={getYoutubeVideoUrl(comment.videoId)} />,
      }}
    />
  );
};

export default YoutubeCommentTriggerStepCompletionTitleComponent;
