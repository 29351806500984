import {CurrencySpecs} from '@cohort/shared/schema/common/currency';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {convert} from 'html-to-text';
import slugify from 'slugify';

dayjs.extend(duration);

export function formatAmount(
  amount: number,
  currency: string,
  options?: Intl.NumberFormatOptions
): string {
  const formatter = new Intl.NumberFormat(currency === 'eur' ? 'fr-FR' : 'en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    ...options,
  });
  return formatter.format(CurrencySpecs.toDecimal(currency, amount));
}

export function formatBlockchainAddress(address: string): string {
  return `${address.slice(0, 5)}...${address.slice(-3)}`;
}

export function formatDate(date: Date): string {
  return dayjs(date).format('lll');
}

export function formatDuration(seconds: number): string {
  return dayjs.duration(1000 * seconds).format('HH[h] mm[m] ss[s]');
}

export function htmlToText(html: string): string {
  return convert(html);
}

export const redeemCodeToRedeemLink = (shopUrl: string | null, code: string): string => {
  return `${shopUrl}?redeemCode=${code}`;
};

export function toSlug(name: string): string {
  return slugify(name, {lower: true, strict: true});
}
