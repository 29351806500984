type RowProps = {
  name: string;
  value: string | React.ReactNode;
};

const Row: React.FC<RowProps> = ({name, value}) => {
  return (
    <li
      key={name}
      className="grid grid-cols-5 bg-slate-50 px-6 py-4 text-slate-700 last:rounded-b-md"
    >
      <div className="col-span-1 text-slate-500">{name}</div>
      <div className="col-span-4 text-right font-medium text-slate-700">{value}</div>
    </li>
  );
};

type GridProps = {
  children: React.ReactElement<RowProps>[] | React.ReactElement<RowProps>;
};

const Grid: React.FC<GridProps> = ({children}) => {
  return <ul className="divide-y divide-slate-200">{children}</ul>;
};

export const PerkUsageLayout = {
  Grid,
  Row,
};
