import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortAppConnectedPropertiesSchema = z.object({
  appId: z.string(),
  connectionId: z.string().uuid(),
});
export type CohortAppConnectedProperties = z.infer<typeof CohortAppConnectedPropertiesSchema>;

export type CohortAppConnectedEventStruct = UserEventStruct<
  'cohort.app.connected',
  keyof CohortAppConnectedProperties,
  CohortAppConnectedProperties,
  CohortAppConnectedProperties
>;

export const CohortAppConnectedEventSpec: UserEventSpec<CohortAppConnectedEventStruct> = {
  id: 'cohort.app.connected',
  name: 'Connected an App',
  description: 'Event Triggerred when a user connects an app in their Experience Space',
  propertiesSchema: CohortAppConnectedPropertiesSchema,
  exposedPropertiesSchema: CohortAppConnectedPropertiesSchema,
  resources: {
    appId: 'cohort.app',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['appId'],
  },
};
