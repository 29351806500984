/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import type {ListConnectedPagesActionStruct} from '@cohort/shared/apps/instagram/actions/listConnectedPages';

export const instagramAppKeys = {
  instagramApp: ['instagramApp'] as const,
  listConnectedPages: (merchantId: string) =>
    [...instagramAppKeys.instagramApp, merchantId, 'list-connected-pages'] as const,
};

export const useConnectedFacebookPages = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
) => {
  return useCohortQuery({
    queryKey: instagramAppKeys.listConnectedPages(merchantId),
    queryFn: async () => {
      return executeAction<ListConnectedPagesActionStruct>(merchantId, {
        appId: 'instagram',
        actionId: 'list-connected-pages',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};
