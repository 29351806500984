import type {PatchDigitalAssetCollectionRequestADto} from '@cohort/admin-schemas/digitalAssetCollection';
import {
  Breadcrumb,
  BreadcrumbBadges,
  BreadcrumbContent,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
} from '@cohort/merchants/components/Breadcrumb';
import Button from '@cohort/merchants/components/buttons/Button';
import DigitalAssetCollectionAirdropModal from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionAirdropModal';
import {DigitalAssetCollectionStatusBadge} from '@cohort/merchants/components/digital-assets/DigitalAssetCollectionStatusBadge';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentStandaloneAirdrop} from '@cohort/merchants/hooks/api/Airdrops';
import {digitalAssetCollectionsKeys} from '@cohort/merchants/hooks/api/DigitalAssetCollections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentDigitalAssetCollection} from '@cohort/merchants/hooks/contexts/currentDigitalAssetCollection';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {patchDigitalAssetCollection} from '@cohort/merchants/lib/api/DigitalAssetCollections';
import {getDigitalAssetsRoute} from '@cohort/merchants/lib/Pages';
import DigitalAssetCollectionDropdown from '@cohort/merchants/pages/digital-asset-collections/overview/DigitalAssetCollectionDropdown';
import {PaperPlaneRight} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const PatchDigitalAssetCollectionFormSchema = z.object({
  internalName: z.string().min(3, {message: 'errorTooShort3'}),
});

const DigitalAssetCollectionPageBreadcrumb = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {t} = useTranslation('pages', {
    keyPrefix:
      'digitalAssetCollections.digitalAssetCollection.digitalAssetCollectionPageBreadcrumb',
  });
  const queryClient = useQueryClient();
  const digitalAssetCollection = useCurrentDigitalAssetCollection();
  const [airdropModalOpen, setAirdropModalOpen] = useState(false);

  const {data: currentAirdrop} = useCurrentStandaloneAirdrop(merchantId);
  const airdropInProgress = currentAirdrop?.status === 'in-progress';

  const {mutate: updateDigitalAssetCollection} = useCohortMutation({
    mutationFn: async (digitalAssetCollectionData: PatchDigitalAssetCollectionRequestADto) =>
      patchDigitalAssetCollection(
        merchantId,
        digitalAssetCollection.id,
        digitalAssetCollectionData
      ),
    notifyErrorMessage: t('notificationErrorUpdate'),
    notifySuccessMessage: t('notificationSuccess'),
    onSuccess: () => {
      queryClient.invalidateQueries(
        digitalAssetCollectionsKeys.getById(merchantId, digitalAssetCollection.id)
      );
      queryClient.invalidateQueries(digitalAssetCollectionsKeys.list(merchantId));
    },
  });

  return (
    <Fragment>
      {airdropModalOpen && (
        <DigitalAssetCollectionAirdropModal onClose={() => setAirdropModalOpen(false)} />
      )}
      <Breadcrumb>
        <BreadcrumbContent>
          <BreadcrumbLink name={t('name')} path={getDigitalAssetsRoute().path} />
          <BreadcrumbEditableTitle
            value={digitalAssetCollection.internalName}
            onChange={(name: string) => updateDigitalAssetCollection({internalName: name})}
            validator={(name: string) =>
              PatchDigitalAssetCollectionFormSchema.safeParse({internalName: name}).success
            }
          />
          <BreadcrumbBadges>
            <DigitalAssetCollectionStatusBadge
              campaignsCount={digitalAssetCollection.campaignsCount}
              size="small"
            />
          </BreadcrumbBadges>
        </BreadcrumbContent>
        <div className="flex items-center gap-4">
          <Button
            disabled={airdropInProgress}
            variant="secondary"
            onClick={() => setAirdropModalOpen(true)}
          >
            <div className="mr-1">
              {airdropInProgress ? (
                <Loader size={14} color="gray" />
              ) : (
                <PaperPlaneRight className="-rotate-90 text-primary" />
              )}
            </div>
            {t('labelAirdrop')}
          </Button>
          <DigitalAssetCollectionDropdown digitalAssetCollection={digitalAssetCollection} />
        </div>
      </Breadcrumb>
    </Fragment>
  );
};

export default DigitalAssetCollectionPageBreadcrumb;
