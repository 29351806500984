import type {MerchantADto} from '@cohort/admin-schemas/merchant';
import {useMerchant} from '@cohort/merchants/hooks/api/Merchants';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {useLDClient} from 'launchdarkly-react-client-sdk';
import {createContext, Fragment} from 'react';
import {shallow} from 'zustand/shallow';

type CurrentMerchantProviderProps = {
  children: React.ReactNode;
};

export const CurrentMerchantContext = createContext<MerchantADto | null>(null);

export const CurrentMerchantProvider: React.FC<CurrentMerchantProviderProps> = ({children}) => {
  const {merchantId, organization} = useUserSessionStore(
    store => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      merchantId: store.merchantId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organization: store.organization!,
    }),
    shallow
  );
  const {data: merchant} = useMerchant(merchantId);
  const ldClient = useLDClient();

  if (merchant === undefined) {
    return <Fragment />;
  }

  if (ldClient !== undefined) {
    ldClient.identify({
      key: organization.slug,
      kind: 'organization',
    });
  }

  return (
    <CurrentMerchantContext.Provider value={merchant}>{children}</CurrentMerchantContext.Provider>
  );
};
