import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {FacebookPostSchema} from '@cohort/shared/apps/facebook/common';
import {z} from 'zod';

export const ListPostsInputSchema = z.object({});
export type ListPostsInput = z.infer<typeof ListPostsInputSchema>;

export const ListPostsOutputSchema = z.object({
  posts: z.array(FacebookPostSchema),
});
export type ListPostsOutput = z.infer<typeof ListPostsOutputSchema>;

export type ListPostsActionStruct = ActionStruct<'list-posts', ListPostsInput, ListPostsOutput>;
export const ListPostsActionSpec: ActionSpec<ListPostsActionStruct> = {
  id: 'list-posts',
  inputSchema: ListPostsInputSchema,
  outputSchema: ListPostsOutputSchema,
};
