import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useAppPageStore} from '@cohort/merchants/hooks/stores/appsPage';
import PageLayout from '@cohort/merchants/layouts/PageLayout';
import PageOutlet from '@cohort/merchants/layouts/PageOutlet';
import {CurrentAppProvider} from '@cohort/merchants/pages/apps/app/CurrentAppContext';
import React from 'react';

const AppPageSkeleton: React.FC = () => {
  const {appSpec} = useCurrentApp();
  const store = useAppPageStore(store => ({
    footer: store.footer,
    breadcrumb: store.breadcrumb,
    menu: store.menu,
  }));

  return (
    <PageLayout
      title={appSpec.name}
      breadcrumb={store.breadcrumb}
      menu={store.menu}
      footer={store.footer}
    >
      <PageOutlet />
    </PageLayout>
  );
};

const AppPageLayout = (): JSX.Element => (
  <CurrentAppProvider>
    <AppPageSkeleton />
  </CurrentAppProvider>
);

export default AppPageLayout;
