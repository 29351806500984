/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getCurrentStandaloneAirdrop} from '@cohort/merchants/lib/api/Airdrops';

export const standaloneAirdropKeys = {
  airdrops: ['airdrops'] as const,
};

export const useCurrentStandaloneAirdrop = (merchantId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: standaloneAirdropKeys.airdrops,
    queryFn: async () => getCurrentStandaloneAirdrop(merchantId),
    ...options,
    refetchInterval: data => (data?.status === 'in-progress' ? 3000 : false),
  });
