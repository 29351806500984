import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCohortMembersRoute} from '@cohort/merchants/lib/Pages';
import type {CohortCohortLeftEventStruct} from '@cohort/shared/apps/cohort/userEvents/cohort/cohortLeft';
import {CohortCohortLeftEventSpec} from '@cohort/shared/apps/cohort/userEvents/cohort/cohortLeft';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const navigate = useNavigate();
  const cohortName = userEvent.cohortName;
  const cohortId = (userEvent.properties as CohortCohortLeftEventStruct['Properties']).cohortId;
  return (
    <Trans
      i18nKey="userEvents.cohort.left.detail"
      ns="app-cohort"
      values={{
        cohortName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCohortMembersRoute(cohortId).path)
        ),
      }}
    />
  );
};

export const CohortCohortLeftUserEvent: UserEvent<CohortCohortLeftEventStruct> = {
  spec: CohortCohortLeftEventSpec,
  detailComponent: DetailComponent,
};
