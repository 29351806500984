/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {notify} from '@cohort/merchants/hooks/toast';
import {defaultNetworkErrorMessage} from '@cohort/shared/models';
import type {UseMutationOptions, UseQueryOptions} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import {useCallback} from 'react';

export interface QueryOptions {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  autoRefresh?: boolean;
}

type UseCohortQueryOptions<TData, TError, TQueryFnData> = UseQueryOptions<
  TData,
  TError,
  TQueryFnData
>;

type UseCohortMutationOptions<TData, TError, TVariables> = UseMutationOptions<
  TData,
  TError,
  TVariables
> & {
  notifySuccessMessage?: string;
  notifyErrorMessage?: string;
};

export const useCohortQuery = <TData = unknown, TError = unknown, TQueryFnData = TData>(
  options: UseCohortQueryOptions<TData, TError, TQueryFnData>
) => {
  return useQuery({
    retry: false,
    ...options,
  });
};

export const useCohortMutation = <TData = unknown, TError = unknown, TVariables = void>(
  options: UseCohortMutationOptions<TData, TError, TVariables>
) => {
  const onSuccess = useCallback(
    (data: TData, variables: TVariables, context: unknown) => {
      if (options.notifySuccessMessage !== undefined) {
        notify('success', options.notifySuccessMessage);
      }

      if (options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    [options]
  );

  return useMutation({
    onError: (err: unknown) => {
      let errorMessage;

      if (err instanceof Error) {
        errorMessage = err.message;
      }
      notify('error', options.notifyErrorMessage ?? errorMessage ?? defaultNetworkErrorMessage);
    },
    ...options,
    onSuccess,
  });
};
