import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

type SuggestionsProps = {
  items: string[];
  command: (props: {id: string}) => void;
};

export type SuggestionsRefProps = {
  onKeyDown: (props: {event: KeyboardEvent}) => boolean;
};

const Suggestions = forwardRef<SuggestionsRefProps, SuggestionsProps>(({items, command}, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const itemRefs = useRef<Array<HTMLLIElement | null>>([]);

  useEffect(() => {
    setSelectedIndex(0);
    itemRefs.current[0]?.scrollIntoView({block: 'nearest'});
  }, [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({event}) => {
      switch (event.key) {
        case 'ArrowUp': {
          const newIndex = (selectedIndex + items.length - 1) % items.length;

          setSelectedIndex(newIndex);
          itemRefs.current[newIndex]?.scrollIntoView({block: 'nearest'});
          return true;
        }
        case 'ArrowDown': {
          const newIndex = (selectedIndex + 1) % items.length;

          setSelectedIndex(newIndex);
          itemRefs.current[newIndex]?.scrollIntoView({block: 'nearest'});
          return true;
        }
        case 'Enter':
          selectItem(selectedIndex);
          return true;
        default:
          return false;
      }
    },
  }));

  function selectItem(index: number): void {
    const item = items[index];

    if (item) {
      command({id: item});
    }
  }

  return (
    <ul className="relative flex max-h-[150px] flex-col gap-1 overflow-scroll rounded-md border bg-white *:border-b">
      {items.length ? (
        items.map((item, index) => (
          <li
            id={`suggestion-${index}`}
            className={`w-full px-4 py-1 text-center text-sm ${
              index === selectedIndex ? 'text-primary' : ''
            }`}
            key={`suggestion-${index}`}
            onClick={() => selectItem(index)}
            ref={el => (itemRefs.current[index] = el)}
          >
            {item}
          </li>
        ))
      ) : (
        <li className="px-2 py-1 text-sm">No result</li>
      )}
    </ul>
  );
});

export default Suggestions;
