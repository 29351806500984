import {OrganizationASchema} from '@cohort/admin-schemas/organization';
import {EmailSchema} from '@cohort/shared/schema/common';
import {OrganizationMemberStatusSchema} from '@cohort/shared/schema/common/organizationMember';
import {z} from 'zod';

export const OrganizationMemberASchema = z.object({
  id: z.string().uuid(),
  status: OrganizationMemberStatusSchema,
  email: EmailSchema,
  organization: OrganizationASchema,
});
export type OrganizationMemberADto = z.infer<typeof OrganizationMemberASchema>;

// Activate

export const ActivateOrganizationMemberASchema = z.object({
  invitationCode: z.string(),
});
export type ActivateOrganizationMemberADto = z.infer<typeof ActivateOrganizationMemberASchema>;

export const ActivateOrganizationMemberResponseASchema = z.object({
  link: z.string(),
});

export type ActivateOrganizationMemberResponseADto = z.infer<
  typeof ActivateOrganizationMemberResponseASchema
>;
