import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortDigitalAssetsCountUserPropertyStruct = UserPropertyStruct<
  'cohort.digital-assets-count',
  number
>;

export const CohortDigitalAssetsCountUserPropertySpec: UserPropertySpec<CohortDigitalAssetsCountUserPropertyStruct> =
  {
    id: 'cohort.digital-assets-count',
    name: 'Number of Digital Assets',
    dataType: 'number',
    scope: 'global',
    defaultValue: 0,
  };
