/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import {TALON_ONE_APP_ID} from '@cohort/shared/apps/talon-one';
import type {ListCampaignsActionStruct} from '@cohort/shared/apps/talon-one/actions/listCampaigns';

export const talonOneAppKeys = {
  talonOneApp: ['talonOneApp'] as const,
  listRoles: (merchantId: string, merchantConnectionId: string) =>
    [...talonOneAppKeys.talonOneApp, merchantId, merchantConnectionId, 'list-roles'] as const,
};

export const useTalonOneCampaigns = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
) => {
  return useCohortQuery({
    queryKey: talonOneAppKeys.listRoles(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<ListCampaignsActionStruct>(merchantId, {
        appId: TALON_ONE_APP_ID,
        actionId: 'list-campaigns',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};
