import {useConnectedFacebookPages} from '@cohort/merchants/apps/instagram/api';
import FacebookPagePicker from '@cohort/merchants/apps/instagram/connector/FacebookPagePicker';
import type {PostConnectionConfigComponentProps} from '@cohort/merchants/components/connections/OAuthEditConnectionComponent';
import ErrorState from '@cohort/merchants/components/ErrorState';
import Loader from '@cohort/merchants/components/Loader';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {configureConnection} from '@cohort/merchants/lib/api/Connections';
import type {FacebookMerchantConnectorStruct} from '@cohort/shared/apps/facebook/merchantConnector';
import type {FacebookPage} from '@cohort/shared/apps/instagram/common';
import {useQueryClient} from '@tanstack/react-query';

export const SetupConnectionAccount: React.FC<PostConnectionConfigComponentProps> = ({
  connection,
  onConfigFinished,
}) => {
  const queryClient = useQueryClient();
  const merchant = useCurrentMerchant();

  const {
    data: pages,
    isLoading: isFacebookPagesLoading,
    isError,
  } = useConnectedFacebookPages(merchant.id, connection.id);

  const {mutate: onPageSelected, isLoading: submitInProgress} = useCohortMutation({
    mutationFn: async (page: FacebookPage) =>
      await configureConnection<FacebookMerchantConnectorStruct>(merchant.id, connection.id, {
        facebookPageId: page.id,
      }),
    onSuccess: connection => {
      queryClient.invalidateQueries(connectionsKeys.list(merchant.id));
      onConfigFinished(connection);
    },
  });

  if (isFacebookPagesLoading || pages === undefined) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorState />;
  }

  return (
    <FacebookPagePicker
      pages={pages.output.pages}
      onPageSelected={(page: FacebookPage) => onPageSelected(page)}
      submitInProgress={submitInProgress}
    />
  );
};
