import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentBadge} from '@cohort/merchants/hooks/contexts/currentBadge';
import {useBadgePageStore} from '@cohort/merchants/hooks/stores/badgePage';
import {
  getBadgeAppearanceRoute,
  getBadgeCohortRoute,
  getBadgeNotificationsRoute,
  getBadgeVisibilityRoute,
} from '@cohort/merchants/lib/Pages';
import {Bell, ChartPieSlice, Eye, Gear} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

const BadgePageMenu: React.FC = () => {
  const badge = useCurrentBadge();
  const {t} = useTranslation('pages', {keyPrefix: 'users.badges.badge.badgePageMenu'});
  const menu = useBadgePageStore(state => state.menu);

  if (menu) {
    return <Fragment>{menu}</Fragment>;
  }

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('appearance'),
          path: getBadgeAppearanceRoute(badge.id).path,
          icon: <Gear size={20} />,
          testId: 'badges-appearance-link',
        },
        {
          name: t('cohort'),
          path: getBadgeCohortRoute(badge.id).path,
          icon: <ChartPieSlice size={20} />,
          testId: 'badges-cohort-link',
        },
        {
          name: t('visibility'),
          path: getBadgeVisibilityRoute(badge.id).path,
          icon: <Eye size={20} />,
          testId: 'badges-visibility-link',
        },
        // Show notifications link only if badge is published.
        ...(badge.status === 'published'
          ? [
              {
                name: t('notifications'),
                path: getBadgeNotificationsRoute(badge.id).path,
                icon: <Bell size={20} />,
                testId: 'badges-notifications-link',
              },
            ]
          : []),
      ]}
    />
  );
};

export default BadgePageMenu;
