import SpotifyPlayer from '@cohort/components-xps/components/contents/apps/spotify/SpotifyPlayer';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {
  buildSpotifyUrl,
  SPOTIFY_RESOURCE_URL_SCHEMAS,
  SpotifyResourceTypesSchema,
} from '@cohort/shared-frontend/common/apps/spotify';
import {zodResolver} from '@hookform/resolvers/zod';
import type {ChangeEvent} from 'react';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isEmpty, isString} from 'remeda';
import {z, ZodError} from 'zod';

const SpotifyPlaylistUrlSchema = z.object({
  playlistUrl: z.string(),
});

type SpotifyPlaylistUrlValues = z.infer<typeof SpotifyPlaylistUrlSchema>;

const SpotifyPlaylistMediaConfigComponent: React.FC = () => {
  const {watch, setValue} = useCohortForm<ContentSettingsStepValues>();
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'medias.playlist.configComponent',
  });

  const playlistId = watch('media.config.playlistId');

  const {
    register,
    control,
    setError: setPlaylistUrlError,
    clearErrors: clearPlaylistUrlErrors,
  } = useForm<SpotifyPlaylistUrlValues>({
    defaultValues: {
      playlistUrl: isString(playlistId) ? buildSpotifyUrl('playlist', playlistId) : '',
    },
    resolver: zodResolver(SpotifyPlaylistUrlSchema),
  });

  const updateFormValues = useCallback(
    (playlistUrl: string): void => {
      const playlistId = playlistUrl.split('playlist/')[1]?.split('?')[0];

      setValue('media.config.playlistId', playlistId);
      setValue('media.config.playlistUrl', playlistUrl);
      setValue('media.config.playlistThumbnailUrl', null);
    },
    [setValue]
  );

  const handlePlaylistUrlChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newPlaylistUrl = e.target.value;
    try {
      if (isEmpty(newPlaylistUrl)) {
        return;
      }

      if (!newPlaylistUrl.startsWith(SPOTIFY_RESOURCE_URL_SCHEMAS.playlist)) {
        // For better UX: throw with a precise message if the URL is from Spotify BUT not a playlist.
        for (const [type, schema] of Object.entries(SPOTIFY_RESOURCE_URL_SCHEMAS)) {
          if (newPlaylistUrl.startsWith(schema)) {
            throw new z.ZodError([
              {
                code: 'custom',
                message: type,
                path: [],
              },
            ]);
          }
        }

        // Throw with a generic message if the URL is not from Spotify.
        throw new z.ZodError([
          {
            code: 'custom',
            message: 'default',
            path: [],
          },
        ]);
      }

      clearPlaylistUrlErrors('playlistUrl');
      return updateFormValues(newPlaylistUrl);
    } catch (errors: unknown) {
      if (errors instanceof ZodError) {
        const message = errors.issues[0]?.message;
        if (SpotifyResourceTypesSchema.safeParse(message).success) {
          setPlaylistUrlError('playlistUrl', {
            type: 'manual',
            // i18nOwl-ignore [albumErrorPlaylistUrl, artistErrorPlaylistUrl, episodeErrorPlaylistUrl, showErrorPlaylistUrl, trackErrorPlaylistUrl]
            message: t(`${message}ErrorPlaylistUrl`),
          });
        } else {
          setPlaylistUrlError('playlistUrl', {
            type: 'manual',
            message: t('defaultPlaylistUrlError'),
          });
        }
      }
      setValue('media.config.playlistUrl', undefined);
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <UrlInput
        name="playlistUrl"
        register={register}
        control={control}
        placeholder={t('spotifyPlaylistUrlPlaceholder')}
        onChange={handlePlaylistUrlChange}
      />
      {isString(playlistId) && (
        <SpotifyPlayer
          resourceId={playlistId}
          resourceType="playlist"
          onError={() =>
            setPlaylistUrlError('playlistUrl', {
              type: 'manual',
              message: t('defaultPlaylistUrlError'),
            })
          }
        />
      )}
    </div>
  );
};

export default SpotifyPlaylistMediaConfigComponent;
