import Button from '@cohort/merchants/components/buttons/Button';
import ErrorSectionTemplate from '@cohort/merchants/components/error-pages/ErrorSectionTemplate';
import {WarningOctagon} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const NotFoundSection: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation('components', {keyPrefix: 'errorPages.notFoundPage'});

  return (
    <ErrorSectionTemplate
      title={t('title')}
      description={t('description')}
      Icon={WarningOctagon}
      cta={
        <Button className="w-full" onClick={() => navigate(-1)}>
          {t('btnBack')}
        </Button>
      }
    />
  );
};

export default NotFoundSection;
