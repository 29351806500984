import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {TypeformFormSchema} from '@cohort/shared/apps/typeform/actions/listForms';
import {z} from 'zod';

export const GetFormInputSchema = z.object({
  formId: z.string(),
});
type GetFormInput = z.infer<typeof GetFormInputSchema>;

export const GetFormOutputSchema = z.object({
  form: TypeformFormSchema,
});
export type GetFormOutput = z.infer<typeof GetFormOutputSchema>;

export type GetFormActionStruct = ActionStruct<'get-form', GetFormInput, GetFormOutput>;

export const GetFormActionSpec: ActionSpec<GetFormActionStruct> = {
  id: 'get-form',
  inputSchema: GetFormInputSchema,
  outputSchema: GetFormOutputSchema,
};
