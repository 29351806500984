import TriggerMediaCardWrapper from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {SpotifyLogo} from '@phosphor-icons/react/dist/ssr';
import {useTranslation} from 'react-i18next';

type SpotifyPlaylistCardProps = {
  media?: {
    name: string;
    thumbnailUrl: string | null;
    tracksCount: number;
  };
  isSelected?: boolean;
  onClick?: () => void;
};

const SpotifyPlaylistCard: React.FC<SpotifyPlaylistCardProps> = ({
  media: playlist,
  isSelected = false,
  onClick,
}) => {
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'components.spotifyPlaylistCard',
  });

  return (
    <TriggerMediaCardWrapper
      hasMedia={playlist !== undefined}
      onClick={onClick}
      isSelected={isSelected}
    >
      <div
        className={cn(
          'flex aspect-square items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-slate-50 shadow-sm',
          isSelected && 'opacity-50'
        )}
      >
        {playlist !== undefined && playlist.thumbnailUrl !== null ? (
          <img src={playlist.thumbnailUrl} alt="Playlist thumbnail" />
        ) : (
          <SpotifyLogo size={40} weight="fill" className="text-slate-200" />
        )}
      </div>

      {playlist && (
        <div className="flex grow flex-col gap-y-3">
          <p className="line-clamp-2 text-sm text-black">{playlist.name}</p>
          <p className="flex flex-grow flex-col justify-end text-xs text-slate-500">
            {t('tracksCount', {count: playlist.tracksCount})}
          </p>
        </div>
      )}
    </TriggerMediaCardWrapper>
  );
};

export default SpotifyPlaylistCard;
