import {
  FileInput,
  FileInputUploader,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {ContentSettingsStepValues} from '@cohort/merchants/pages/contents/content/formSchemas';
import {useTranslation} from 'react-i18next';

const CohortVideoMediaConfigComponent: React.FC = () => {
  const {control, register} = useCohortForm<ContentSettingsStepValues>();

  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'medias.video.configComponent',
  });

  return (
    <FileInput
      acceptHint={t('instructionFileInput')}
      assetKind="contentVideo"
      name="media.config.videoFileKey"
      videoFileConfig={{
        thumbnailOptions: {
          name: 'media.config.thumbnailFileKey',
          assetKind: 'contentVideoThumbnail',
        },
      }}
      control={control}
      register={register}
    >
      <FileInputUploader />
    </FileInput>
  );
};

export default CohortVideoMediaConfigComponent;
