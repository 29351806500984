import type {ConnectorSpec, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials} from '@cohort/shared/apps/oauth';
import {OAuth2AppCredentialsSchema, OAuth2CredentialsSchema} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const InstagramMerchantProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  username: z.string().nullable(),
});
export type InstagramMerchantProfileProperties = z.infer<
  typeof InstagramMerchantProfilePropertiesSchema
>;

export const InstagramMerchantUserCredentialsSchema = OAuth2CredentialsSchema.extend({
  pagesAccessTokens: z.record(z.string()),
});
export type InstagramMerchantUserCredentials = z.infer<
  typeof InstagramMerchantUserCredentialsSchema
>;

export const InstagramMerchantConnectionConfigSchema = z.object({
  facebookPageId: z.string(),
  instagramBusinessAccountId: z.string(),
});
export type InstagramMerchantConnectionConfig = z.infer<
  typeof InstagramMerchantConnectionConfigSchema
>;

export type InstagramMerchantConnectorStruct = ConnectorStruct<
  'instagram-merchant',
  OAuth2AppCredentials,
  InstagramMerchantUserCredentials,
  InstagramMerchantProfileProperties,
  InstagramMerchantConnectionConfig
>;

export const InstagramMerchantConnectorSpec = {
  id: 'instagram-merchant' as const,
  type: 'oauth2',
  appCredentialsSchema: OAuth2AppCredentialsSchema,
  credentialsSchema: InstagramMerchantUserCredentialsSchema,
  profilePropertiesSchema: InstagramMerchantProfilePropertiesSchema,
  connectionConfigSchema: InstagramMerchantConnectionConfigSchema,
} satisfies ConnectorSpec<InstagramMerchantConnectorStruct>;
