import Spinner from '@cohort/shared-frontend/components/Spinner';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import React, {useEffect, useRef, useState, useSyncExternalStore} from 'react';
import type {Options, YouTubePlayer} from 'youtube-player/dist/types';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: (() => void) | null;
    YT: {
      Player: new (elementId: string | HTMLElement, options: Options) => YouTubePlayer;
    };
  }
}

type YoutubeVideoPlayerProps = {
  className?: string;
  onError?: () => void;
  videoId: string;
};

// Global variables to manage API readiness
let youtubeApiReadyPromise: Promise<void> | null = null;
let isYoutubeApiReady = false;
let listeners: Array<() => void> = [];

// Ensures YouTube API is loaded and ready
const ensureYoutubeApiReady = (): Promise<void> => {
  if (!youtubeApiReadyPromise) {
    youtubeApiReadyPromise = new Promise(resolve => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (window.YT) {
        isYoutubeApiReady = true;
        listeners.forEach(listener => listener());
        resolve();
      } else {
        window.onYouTubeIframeAPIReady = () => {
          isYoutubeApiReady = true;
          listeners.forEach(listener => listener());
          resolve();
        };
      }
    });
  }
  return youtubeApiReadyPromise;
};

// Subscribe function for useSyncExternalStore
const subscribe = (callback: () => void): (() => void) => {
  listeners.push(callback);
  return () => {
    listeners = listeners.filter(listener => listener !== callback);
  };
};

// Snapshot functions for useSyncExternalStore
const getSnapshot = (): boolean => isYoutubeApiReady;
const getServerSnapshot = (): boolean => false;

// Custom hook to manage YouTube player
const useYouTubePlayer = (
  containerRef: React.RefObject<HTMLDivElement>,
  videoId: string,
  onError?: () => void
): {isLoading: boolean} => {
  const playerRef = useRef<YouTubePlayer | null>(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isApiReady = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);

  useEffect(() => {
    ensureYoutubeApiReady().catch(error => {
      // eslint-disable-next-line no-console
      console.error('Error waiting for YouTube API readiness', error);
      onError?.();
    });
  }, [onError]);

  // Initialize player when API is ready
  useEffect(() => {
    if (isApiReady && containerRef.current && !playerRef.current) {
      try {
        playerRef.current = new window.YT.Player(containerRef.current, {
          width: '100%',
          videoId,
          playerVars: {
            playsinline: 1,
            autoplay: 0,
          },
          events: {
            ready: () => {
              setIsPlayerReady(true);
              setIsLoading(false);
            },
            stateChange: e => {
              // -1 (unstarted), 0 (ended), 1 (playing), 2 (paused), 3 (buffering), 5 (video cued)
              if (e.detail?.data === 5) {
                setIsLoading(false);
              }
            },
            error: () => {
              onError?.();
              setIsLoading(false);
            },
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error creating YouTube player', error);
        onError?.();
        setIsLoading(false);
      }
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
    };
  }, [isApiReady, videoId, onError, containerRef]);

  // Handle video ID changes
  useEffect(() => {
    if (playerRef.current && isPlayerReady) {
      setIsLoading(true);
      try {
        playerRef.current.cueVideoById({
          videoId,
          startSeconds: 0,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading video', error);
        onError?.();
      }
    }
  }, [videoId, isPlayerReady, onError]);

  return {isLoading};
};

const YoutubeVideoPlayer: React.FC<YoutubeVideoPlayerProps> = ({className, onError, videoId}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {isLoading} = useYouTubePlayer(containerRef, videoId, onError);

  return (
    <div className={cn('relative aspect-video', className)}>
      <div ref={containerRef} className="relative z-10 h-full" />
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner size={24} color="gray" />
        </div>
      )}
    </div>
  );
};

export default YoutubeVideoPlayer;
