import type {CohortFillFormVerificationAttemptData} from '@cohort/shared/apps/cohort/triggers/fillForm';
import {Trans} from 'react-i18next';

const CohortFillFormTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortFillFormVerificationAttemptData;
  completionSuccess: boolean;
}> = () => (
  <Trans
    // i18nOwl-ignore [app-cohort.triggerIntegrations.fill-form.stepCompletionTitleComponent.success]
    i18nKey="triggerIntegrations.fill-form.stepCompletionTitleComponent.success"
    ns="app-cohort"
  />
);

export default CohortFillFormTriggerIntegrationStepCompletionTitleComponent;
