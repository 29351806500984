import {cn} from '@cohort/shared-frontend/utils/classNames';
import {CheckCircle, Info, Warning, XCircle} from '@phosphor-icons/react';
import React from 'react';

interface HighlightTextProps {
  text: string | JSX.Element;
  type: 'info' | 'success' | 'warning' | 'error' | 'secondary';
}

const colorMapping = {
  info: {
    text: 'bg-blue-100 text-blue-800',
    icon: 'text-blue-500',
  },
  success: {
    text: 'bg-green-100 text-green-800',
    icon: 'text-green-500',
  },
  warning: {
    text: 'bg-orange-50 text-orange-800',
    icon: 'text-orange-500',
  },
  error: {
    text: 'bg-red-100 text-red-800',
    icon: 'text-red-500',
  },
  secondary: {
    text: 'bg-slate-100 text-slate-600',
    icon: 'text-slate-400',
  },
};

const iconMapping = {
  info: Info,
  success: CheckCircle,
  warning: Warning,
  error: XCircle,
  secondary: Info,
};

const HighlightText: React.FC<HighlightTextProps> = ({text, type}) => {
  const Icon = iconMapping[type];

  return (
    <div className={cn(colorMapping[type].text, 'flex items-center gap-3 rounded-md p-4')}>
      <Icon className={cn(colorMapping[type].icon, 'h-5 w-5')} />
      <div className="max-w-[calc(100%-2rem)] text-sm">{text}</div>
    </div>
  );
};

export default HighlightText;
