/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {
  ListUserPropertiesParamsADto,
  UserPropertyADto,
} from '@cohort/admin-schemas/userProperty';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getUserProperties, getUserProperty} from '@cohort/merchants/lib/api/UserProperties';
import {useInfiniteQuery} from '@tanstack/react-query';

type ListUserPropertiesInfiniteQueryParams = {
  search?: string;
  visible?: boolean;
  excludedReferenceIds?: Array<string>;
};

export const userPropertiesKeys = {
  userProperties: ['userProperties'] as const,
  getById: (merchantId: string, userPropertyId: string) =>
    [...userPropertiesKeys.userProperties, merchantId, userPropertyId] as const,
  list: (merchantId: string, params: ListUserPropertiesParamsADto) =>
    [...userPropertiesKeys.userProperties, merchantId, 'list', params] as const,
  listWithPagination: (merchantId: string, params: ListUserPropertiesParamsADto) =>
    [...userPropertiesKeys.userProperties, merchantId, 'listWithPagination', params] as const,
  listVisible: (merchantId: string) =>
    [...userPropertiesKeys.userProperties, merchantId, 'visible'] as const,
  listInfiniteUserProperties: (merchantId: string, params: ListUserPropertiesInfiniteQueryParams) =>
    [...userPropertiesKeys.userProperties, merchantId, 'infinite', params] as const,
};

export const usePaginatedUserProperties = (
  merchantId: string,
  params: ListUserPropertiesParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: userPropertiesKeys.listWithPagination(merchantId, params),
    queryFn: async () => getUserProperties(merchantId, params),
    ...options,
  });

export const useUserProperties = (
  merchantId: string,
  params: Omit<ListUserPropertiesParamsADto, 'page' | 'pageSize'>,
  options?: QueryOptions
) => {
  const PAGE_SIZE = 50;
  const DEFAULT_QUERY_PARAMS = {...params, page: 1, pageSize: PAGE_SIZE};
  const queryParams = DEFAULT_QUERY_PARAMS;

  return useCohortQuery({
    queryKey: userPropertiesKeys.list(merchantId, queryParams),
    queryFn: async () => {
      let fetchNextPage = true;
      const userProperties: Array<UserPropertyADto> = [];

      while (fetchNextPage) {
        const [pagination, userPropertiesData = []] = await getUserProperties(
          merchantId,
          queryParams
        );
        userProperties.push(...userPropertiesData);
        queryParams.page += 1;

        if (pagination.next === null) {
          queryParams.page = 1;
          fetchNextPage = false;
        }
      }

      return userProperties;
    },
    ...options,
  });
};

export const useUserProperty = (
  merchantId: string,
  userPropertyId: string,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: userPropertiesKeys.getById(merchantId, userPropertyId),
    queryFn: async () => getUserProperty(merchantId, userPropertyId),
    ...options,
  });

export const useUserPropertiesWithInfiniteQuery = (
  merchantId: string,
  pageSize: number,
  params: ListUserPropertiesInfiniteQueryParams
) =>
  useInfiniteQuery({
    queryKey: userPropertiesKeys.listInfiniteUserProperties(merchantId, params),
    queryFn: async ({pageParam = 1}) => {
      const {search, visible, excludedReferenceIds} = params;
      const [pagination, userProperties] = await getUserProperties(merchantId, {
        page: pageParam,
        pageSize,
        ...(search !== undefined && search.trim().length > 0 && {search}),
        ...(visible !== undefined && {visible}),
        ...(excludedReferenceIds !== undefined && {excludedReferenceIds}),
        orderBy: 'name',
      });

      return {data: userProperties, meta: pagination};
    },
    getNextPageParam: lastPage => {
      if (lastPage.meta.page === lastPage.meta.pages) {
        return undefined;
      }
      return lastPage.meta.page + 1;
    },
  });
