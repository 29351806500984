import {CohortError} from '@cohort/shared/schema/common/errors';
import {Big} from 'big.js';
import {z} from 'zod';

type CurrencySpec = {
  currency: string;
  symbol: string;
  name: string;
  minFeeAmount: number;
  amountSchema: z.ZodType<number>;
  decimals: number;
};

const amountSchema = (minAmount: number): z.ZodNumber =>
  z.number().int('errorInvalidDecimal').min(minAmount, 'errorPriceTooLow');

const specs = [
  {
    currency: 'eur',
    symbol: '€',
    name: 'Euro',
    minFeeAmount: 100,
    amountSchema: amountSchema(100),
    decimals: 2,
  },
  {
    currency: 'usd',
    symbol: '$',
    name: 'US Dollar',
    minFeeAmount: 100,
    amountSchema: amountSchema(100),
    decimals: 2,
  },
  {
    currency: 'jpy',
    symbol: '¥',
    name: 'Japanese Yen',
    minFeeAmount: 100,
    amountSchema: amountSchema(100),
    decimals: 0,
  },
  {
    currency: 'krw',
    symbol: '₩',
    name: 'South Korean Won',
    minFeeAmount: 1500,
    amountSchema: amountSchema(1500),
    decimals: 0,
  },
  {
    currency: 'gbp',
    symbol: '£',
    name: 'British Pound',
    minFeeAmount: 100,
    amountSchema: amountSchema(100),
    decimals: 2,
  },
  {
    currency: 'chf',
    symbol: '₣',
    name: 'Swiss Franc',
    minFeeAmount: 100,
    amountSchema: amountSchema(100),
    decimals: 2,
  },
] as const satisfies ReadonlyArray<CurrencySpec>;

export type Currency = (typeof specs)[number]['currency'];
// workaround to generate CurrencySchemas automatically
const currencies: [Currency, ...Currency[]] = [
  specs[0].currency,
  ...specs.slice(1).map(spec => spec.currency),
];
export const CurrencySchema = z.enum(currencies);

export const DEFAULT_CURRENCY = specs[0].currency;

export const CurrencySpecs = {
  specs,
  get(currency: string): CurrencySpec | null {
    const spec = this.specs.find(spec => spec.currency === currency);
    return spec ?? null;
  },
  getOrThrow(currency: string): CurrencySpec {
    const spec = this.get(currency);
    if (!spec) {
      throw new CohortError('currency-spec.not-found', {currency});
    }
    return spec;
  },
  toDecimal(currency: string, amount: number | Big): number {
    const spec = this.getOrThrow(currency);
    return Number(Big(amount).div(Big(10).pow(spec.decimals)).toString());
  },
  fromDecimal(currency: string, amount: number | Big): number {
    const spec = this.getOrThrow(currency);
    return Number(Big(amount).times(Big(10).pow(spec.decimals)).toString());
  },
};

export type Price = {
  currency: Currency;
  amount: number;
};

export const PriceSchema = z
  .object({
    currency: z.string(),
    amount: z.number(),
  })
  .superRefine((arg, ctx): arg is Price => {
    const {currency, amount} = arg;
    const spec = CurrencySpecs.get(currency);
    if (!spec) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid currency: ${currency}`,
        path: ['currency'],
      });
      return z.NEVER;
    }
    const parsed = spec.amountSchema.safeParse(amount);
    if (!parsed.success) {
      for (const error of parsed.error.errors) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: error.message,
          path: ['amount'],
        });
      }
    }
    return z.NEVER;
  });

export const PricesSchema = z.array(PriceSchema).superRefine((arg, ctx): arg is Price[] => {
  const currencies = arg.map(price => price.currency);
  const uniqueCurrencies = new Set(currencies);
  if (uniqueCurrencies.size !== currencies.length) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Duplicate currencies: ${currencies.join(', ')}`,
      path: [],
    });
  }
  return z.NEVER;
});
