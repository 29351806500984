import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {BrevoConnectorStruct} from '@cohort/shared/apps/brevo/connector';
import {BrevoConnectorSpec} from '@cohort/shared/apps/brevo/connector';
import type {BrevoSyncStruct} from '@cohort/shared/apps/brevo/sync';
import {BrevoSyncSpec} from '@cohort/shared/apps/brevo/sync';

export const BREVO_APP_ID = 'brevo';
export const BREVO_APP_NAME = 'Brevo';

export type BrevoAppStruct = AppStruct<
  'brevo',
  BrevoConnectorStruct,
  null,
  BrevoSyncStruct,
  never,
  never,
  never,
  never,
  never,
  never,
  never,
  never
>;

export const BrevoAppSpec: AppSpec<BrevoAppStruct> = {
  id: BREVO_APP_ID,
  name: BREVO_APP_NAME,
  merchantConnector: BrevoConnectorSpec,
  userConnector: null,
  syncSpec: BrevoSyncSpec,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: [],
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
