import {z} from 'zod';

export const FACEBOOK_URL = 'https://facebook.com';

export const FacebookPostSchema = z.object({
  id: z.string(),
  userId: z.string(),
  message: z.string().optional(),
  createdTime: z.date(),
  permalinkUrl: z.string(),
  thumbnailUrl: z.string().optional(),
  attachmentType: z.string().optional(),
  likeCount: z.number().optional(),
  commentCount: z.number().optional(),
  tags: z.array(z.string()),
  privacy: z.string().optional(),
});
export type FacebookPost = z.infer<typeof FacebookPostSchema>;

export const FacebookCommentSchema = z.object({
  id: z.string(),
  userId: z.string(),
  username: z.string(),
  message: z.string(),
  permalinkUrl: z.string(),
  createdAt: z.date(),
});
export type FacebookComment = z.infer<typeof FacebookCommentSchema>;

export const FacebookPostLikeSchema = z.object({
  id: z.string(),
  username: z.string(),
});
export type FacebookPostLike = z.infer<typeof FacebookPostLikeSchema>;
