/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import {DISCORD_APP_ID} from '@cohort/shared/apps/discord';
import type {ListRolesActionStruct} from '@cohort/shared/apps/discord/actions/listRoles';

export const discordAppKeys = {
  discordApp: ['discordApp'] as const,
  listRoles: (merchantId: string, merchantConnectionId: string) =>
    [...discordAppKeys.discordApp, merchantId, merchantConnectionId, 'list-roles'] as const,
};

export const useDiscordRoles = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
) => {
  return useCohortQuery({
    queryKey: discordAppKeys.listRoles(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<ListRolesActionStruct>(merchantId, {
        appId: DISCORD_APP_ID,
        actionId: 'list-roles',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};
