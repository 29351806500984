import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import {typeformAppKeys} from '@cohort/merchants/apps/typeform/api';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import type {ListMediasActionStruct} from '@cohort/shared/apps/instagram/actions/listMedias';
import type {QueryOptions, UseQueryResult} from '@tanstack/react-query';

export const instagramAppKeys = {
  instagramApp: ['instagramApp'] as const,
  listMedias: (merchantId: string, merchantConnectionId: string) =>
    [...typeformAppKeys.typeformApp, merchantId, merchantConnectionId, 'list-medias'] as const,
};

export const useInstagramMedias = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
): UseQueryResult<ExecuteActionResponseADto<ListMediasActionStruct>, unknown> => {
  return useCohortQuery({
    queryKey: instagramAppKeys.listMedias(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<ListMediasActionStruct>(merchantId, {
        appId: 'instagram',
        actionId: 'list-medias',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};
