/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {GetPerksParamsADto} from '@cohort/admin-schemas/perk';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getPerk, getPerks} from '@cohort/merchants/lib/api/Perks';
import {useInfiniteQuery} from '@tanstack/react-query';

export const perksKeys = {
  perks: ['perks'] as const,
  list: (merchantId: string) => [...perksKeys.perks, merchantId, 'list'] as const,
  listWithFilters: (merchantId: string, params: GetPerksParamsADto) =>
    [...perksKeys.list(merchantId), params] as const,
  listInfinitePerks: (merchantId: string, params: Omit<GetPerksParamsADto, 'page'>) =>
    [...perksKeys.list(merchantId), params, 'infinite'] as const,
  getById: (merchantId: string, perkId: string) =>
    [...perksKeys.perks, merchantId, perkId] as const,
  countById: (merchantId: string, perkId: string) =>
    [...perksKeys.getById(merchantId, perkId), 'count'] as const,
};

export const usePerk = (merchantId: string, perkId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: perksKeys.getById(merchantId, perkId),
    queryFn: async () => getPerk(merchantId, perkId),
    ...options,
  });

export const usePerks = (merchantId: string, params: GetPerksParamsADto, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: perksKeys.listWithFilters(merchantId, params),
    queryFn: async () => getPerks(merchantId, params),
    ...options,
  });

export const usePerkUsageCount = (merchantId: string, perkId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: perksKeys.countById(merchantId, perkId),
    queryFn: async () =>
      Promise.all([
        getPerks(merchantId, {
          perkId: [perkId],
          usageStatus: 'used',
          page: 1,
          pageSize: 1,
          orderBy: '-updatedAt',
        }),
        getPerks(merchantId, {
          perkId: [perkId],
          page: 1,
          pageSize: 1,
          orderBy: '-updatedAt',
        }),
      ]),
    select: ([used, all]) => ({
      used: used[0].total,
      total: all[0].total,
    }),
    ...options,
  });

export const usePerksWithInfiniteQuery = (
  merchantId: string,
  params: Omit<GetPerksParamsADto, 'page'>
) =>
  useInfiniteQuery({
    queryKey: perksKeys.listInfinitePerks(merchantId, params),
    queryFn: async ({pageParam = 1}) => {
      const [pagination, data] = await getPerks(merchantId, {
        ...params,
        page: pageParam,
      });
      return {data, meta: pagination};
    },
    getNextPageParam: lastPage => {
      if (lastPage.meta.page === lastPage.meta.pages) {
        return undefined;
      }
      return lastPage.meta.page + 1;
    },
  });
