import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {getYoutubeProfileUrl} from '@cohort/shared/apps/youtube/common';
import type {YoutubeSubscribeVerificationAttemptData} from '@cohort/shared/apps/youtube/triggers/subscribe';
import {Trans, useTranslation} from 'react-i18next';

const YoutubeSubscribeTriggerStepCompletionTitleComponent: React.FC<{
  data: YoutubeSubscribeVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {channelId, channelTitle, channelHandle} = data;
  const {t} = useTranslation('app-youtube');

  if (!completionSuccess && channelId !== null && channelTitle !== null) {
    return (
      <Trans
        i18nKey="triggerIntegrations.subscribe.stepCompletionTitleComponent.errorWithChannel"
        ns="app-youtube"
        values={{username: channelHandle ?? channelTitle}}
        components={{
          a: <StepCompletionLink url={getYoutubeProfileUrl(channelId)} />,
        }}
      />
    );
  }

  if (!completionSuccess) {
    return t('triggerIntegrations.subscribe.stepCompletionTitleComponent.error');
  }

  if (channelId !== null && channelTitle !== null) {
    return (
      <Trans
        i18nKey="triggerIntegrations.subscribe.stepCompletionTitleComponent.successWithChannel"
        ns="app-youtube"
        values={{username: channelHandle ?? channelTitle}}
        components={{
          a: <StepCompletionLink url={getYoutubeProfileUrl(channelId)} />,
        }}
      />
    );
  }

  return t('triggerIntegrations.subscribe.stepCompletionTitleComponent.success');
};

export default YoutubeSubscribeTriggerStepCompletionTitleComponent;
