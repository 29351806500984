import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortChallengeCompletedPropertiesSchema = z.object({
  campaignId: z.string().uuid(),
  challengeParticipationId: z.string().uuid(),
});
export type CohortChallengeCompletedProperties = z.infer<
  typeof CohortChallengeCompletedPropertiesSchema
>;

export type CohortChallengeCompletedEventStruct = UserEventStruct<
  'cohort.challenge.completed',
  keyof CohortChallengeCompletedProperties,
  CohortChallengeCompletedProperties,
  CohortChallengeCompletedProperties
>;

export const CohortChallengeCompletedEventSpec: UserEventSpec<CohortChallengeCompletedEventStruct> =
  {
    id: 'cohort.challenge.completed',
    name: 'Completed a Challenge',
    description: 'Event Triggerred when a user completes a Challenge',
    propertiesSchema: CohortChallengeCompletedPropertiesSchema,
    exposedPropertiesSchema: CohortChallengeCompletedPropertiesSchema,
    resources: {
      campaignId: 'cohort.challenge',
    },
    rulesEngineConfig: {
      isVisible: true,
      exposedFields: ['campaignId'],
    },
  };
