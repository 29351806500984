import type {ConnectorSpec} from '@cohort/shared/apps/app';
import type {BaseProfileProperties, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const BrevoCredentialsSchema = z.object({
  apiKey: z.string(),
});
export type BrevoCredentials = z.infer<typeof BrevoCredentialsSchema>;

export type BrevoConnectorStruct = ConnectorStruct<
  'brevo',
  OAuth2AppCredentials,
  BrevoCredentials,
  BaseProfileProperties,
  null
>;

export const BrevoConnectorSpec = {
  id: 'brevo',
  type: 'custom',
  appCredentialsSchema: null,
  credentialsSchema: BrevoCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<BrevoConnectorStruct>;
