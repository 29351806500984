import type {BadgeADto} from '@cohort/admin-schemas/badge';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useBadge} from '@cohort/merchants/hooks/api/Badges';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import SkeletonLoader from '@cohort/merchants/layouts/SkeletonLoader';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentBadgeProviderProps = {
  children: React.ReactNode;
};

export const CurrentBadgeContext = createContext<BadgeADto | null>(null);

export const CurrentBadgeProvider: React.FC<CurrentBadgeProviderProps> = ({children}) => {
  const merchant = useCurrentMerchant();
  const {badgeId} = useParams();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {data: badge, isFetched} = useBadge(merchant.id, badgeId!);

  if (badgeId === undefined) {
    return <NotFoundSection />;
  }

  if (isFetched && !badge) {
    return <NotFoundSection />;
  }

  if (badge === undefined) {
    return <SkeletonLoader />;
  }

  return <CurrentBadgeContext.Provider value={badge}>{children}</CurrentBadgeContext.Provider>;
};
