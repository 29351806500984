export function isFile(file: unknown): file is File {
  return file instanceof File;
}

export function isFileList(file: unknown): file is FileList {
  return file instanceof FileList;
}

export const isEmptyFileList = (file: unknown): file is FileList => {
  return isFileList(file) && file.length === 0;
};
