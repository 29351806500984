import Button from '@cohort/merchants/components/buttons/Button';
import type {EditableTextProps} from '@cohort/merchants/components/form/EditableText';
import EditableText from '@cohort/merchants/components/form/EditableText';
import useBackNavigationStore from '@cohort/merchants/hooks/stores/backNavigation';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {ArrowLeft} from '@phosphor-icons/react';
import React, {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';

type BreadcrumbProps = {
  children: React.ReactNode;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({children}) => (
  <div className="w-full border-b bg-white">
    <div className="m-auto max-w-[var(--content-max-width)] px-8">
      <div className="flex h-[4rem] w-full items-center justify-between">{children}</div>
    </div>
  </div>
);

type BreadcrumbContentProps = {
  children: React.ReactNode;
  backPath?: string;
};

const BreadcrumbContent: React.FC<BreadcrumbContentProps> = ({children, backPath}) => {
  const navigate = useNavigate();
  const backUrl = useBackNavigationStore(state => state.backPath);

  return (
    <div className="flex items-center gap-4">
      <Button onClick={() => navigate(backPath ?? backUrl)} variant="secondary" size="icon">
        <ArrowLeft size={20} className="text-slate-400" />
      </Button>
      <div className="flex items-center gap-2">{children}</div>
    </div>
  );
};

type BreadcrumbTitleProps = JSX.IntrinsicElements['div'];

const BreadcrumbTitle: React.FC<BreadcrumbTitleProps> = ({className, ...props}) => (
  <span className={cn('p-2 font-semibold', className)} {...props} />
);

type BreadcrumbEditableTitleProps = EditableTextProps;

const BreadcrumbEditableTitle: React.FC<BreadcrumbEditableTitleProps> = props => (
  <div className="flex items-center gap-2">
    <EditableText {...props} />
  </div>
);

type BreadcrumbLinkProps = {
  name: React.ReactNode;
  path: string;
};

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({name, path}) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Button
        onClick={() => navigate(path)}
        variant="ghostGray"
        size="sm"
        className="px-2 text-base"
      >
        {name}
      </Button>
      <span className="text-slate-400">/</span>
    </Fragment>
  );
};

type BreadcrumbBadgesProps = {
  children: React.ReactNode;
};

const BreadcrumbBadges: React.FC<BreadcrumbBadgesProps> = ({children}) => (
  <div className="flex flex-wrap items-center gap-2">{children}</div>
);

type BreadcrumbCtasProps = {
  children: React.ReactNode;
};

const BreadcrumbCtas: React.FC<BreadcrumbCtasProps> = ({children}) => (
  <div className="flex gap-2">{children}</div>
);

export {
  Breadcrumb,
  BreadcrumbContent,
  BreadcrumbTitle,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
  BreadcrumbBadges,
  BreadcrumbCtas,
};
