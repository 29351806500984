import type {TriggerIntegration} from '@cohort/merchants/apps';
import TikTokKeywordInDescriptionTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/ConfigComponent';
import TikTokKeywordInDescriptionTriggerStepCompletionContextComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/StepCompletionContextComponent';
import TikTokKeywordInDescriptionTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/keywordInDescription/StepCompletionTitleComponent';
import type {TikTokKeywordInDescriptionTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {TikTokKeywordInDescriptionTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';

export const TikTokKeywordInDescriptionTriggerIntegration: TriggerIntegration<TikTokKeywordInDescriptionTriggerStruct> =
  {
    spec: TikTokKeywordInDescriptionTriggerSpec,
    configComponent: TikTokKeywordInDescriptionTriggerConfigComponent,
    stepCompletionTitleComponent: TikTokKeywordInDescriptionTriggerStepCompletionTitleComponent,
    stepCompletionContextComponent: TikTokKeywordInDescriptionTriggerStepCompletionContextComponent,
    getTitle: t => t('triggers.keyword-in-description.title', {ns: 'app-tiktok'}),
  };
