import type {TriggerIntegration} from '@cohort/merchants/apps';
import YoutubeSubscribeTriggerIntegrationConfigComponent from '@cohort/merchants/apps/youtube/triggers/subscribe/ConfigComponent';
import YoutubeSubscribeTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/youtube/triggers/subscribe/StepCompletionTitleComponent';
import type {YoutubeSubscribeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/subscribe';
import {YoutubeSubscribeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/subscribe';

export const YoutubeSubscribeTriggerIntegration: TriggerIntegration<YoutubeSubscribeTriggerStruct> =
  {
    spec: YoutubeSubscribeTriggerSpec,
    configComponent: YoutubeSubscribeTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: YoutubeSubscribeTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.subscribe.title', {ns: 'app-youtube'}),
  };
