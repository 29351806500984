/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 11
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/cohort/Merchants/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'prod' | 'dev';

export const ApiKey: Record<Environment, string> = {
  prod: '',
  dev: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '11',
    branch: 'main',
    source: 'web',
    versionId: 'd81b593e-354f-48f7-ad1c-6a6cf11ad863'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  traceId?: string;
}

export interface AccordionClickedProperties {
  accordionName: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface ApiSettingsPageViewedProperties {
  merchantId?: string;
  merchantSlug?: string;
  mode?: string;
  organizationId?: string;
  organizationName?: string;
}

export interface BrandSettingsPageViewedProperties {
  organizationId: string;
  organizationName: string;
}

export interface BrandSettingsSaveButtonClickedProperties {
  organizationId: string;
  organizationName: string;
}

export interface BreadcrumbBackButtonClickedProperties {
  pageName: string;
  resourceId?: string;
}

export interface CampaignAirdropModalCloseButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignAirdropModalCsvImportedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignAirdropModalOpenButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignAirdropSubmittedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  emailsCount: number;
  fromCsv: boolean;
  organizationId: string;
  organizationName: string;
}

export interface CampaignCardClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignCreateButtonClickedProperties {
  organizationId: string;
  organizationName: string;
}

export interface CampaignCreateSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignDropdownArchiveClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignDropdownClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignDropdownUnarchiveClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignDropdownVisitPageClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignEditDominantBgColorButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignEditImportBrandSpaceColorsButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignEditPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignListItemClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignOrdersPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPerksAssociationsModalClosedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPerksAssociationsModalFilteringChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filters: string[];
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPerksAssociationsModalOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPerksAssociationsModalSortingChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  orderBy: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPerksAssociationsModalSubmitProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPerksPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPerksReorderedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPerksSaveButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPreviewModalClosedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPreviewModalOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPreviewModeButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  previewMode?: any;
}

export interface CampaignPublishButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignRedeemLinksPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface CampaignReviewAndPublishButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignReviewAndPublishModalClosedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignReviewAndPublishModalOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignReviewAndPublishPreviewModeButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  previewMode?: any;
}

export interface CampaignSaveButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignsDisplayChangedProperties {
  display: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignsOverviewPageViewedProperties {
  organizationId: string;
  organizationName: string;
}

export interface CampaignsSortingChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  orderBy: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignsStatusFilterChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filters: string[];
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignsTypeFilterChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filters: string[];
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface ChangeOrganizationButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CopyRedeemCodeBtnClickedProperties {
  campaignId: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  redeemCodeCode: string;
  redeemCodeId: string;
  redeemCodeStatus: string;
}

export interface DeleteRedeemCodeBtnClickedProperties {
  campaignId: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  redeemCodeCode: string;
  redeemCodeId: string;
  redeemCodeStatus: string;
}

export interface DeleteRedeemCodeSuccessProperties {
  campaignId: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  redeemCodeCode: string;
  redeemCodeId: string;
  redeemCodeStatus: string;
}

export interface DigitalAssetCollectionCreateButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionCreateSuccessProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionDeleteSuccessProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionDetailsPageViewedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionDetailsSaveButtonClickedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionDropdownClickedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionDropdownDeleteClickedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionDropdownDuplicateClickedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionDuplicateSuccessProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerkAddedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface DigitalAssetCollectionPerkRemovedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface DigitalAssetCollectionPerksAssociationsModalAppFilterChangedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filters: string[];
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerksAssociationsModalClosedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerksAssociationsModalOpenedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerksAssociationsModalSortingChangedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  orderBy: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerksAssociationsModalSubmitProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerksAssociationsModalTypeFilterChangedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filters: string[];
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerksPageViewedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerksReorderedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPerksSaveButtonClickedProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionsDisplayChangedProperties {
  display: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionsOverviewPageViewedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionsSortingChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  orderBy: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionsStatusFilterChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  selectedStatuses: string;
}

export interface ExportOrdersErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  errorCode?: any;
  errorReason?: string;
  organizationId: string;
  organizationName: string;
}

export interface ExportOrdersSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface ExportPerkAnalyticsSuccessProperties {
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface ExportRedeemCodesErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  organizationId: string;
  organizationName: string;
}

export interface ExportRedeemCodesSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface InvitationActivationErrorProperties {
  errorCode?: any;
  errorReason?: string;
}

export interface MembershipPassConfigurationSettingsSaveButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface MembershipPassPreviewModalClosedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface MembershipPassPreviewModalOpenedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface MembershipPassSettingsBgColorBtnClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  selectedBackgroundColorOption: string;
}

export interface MembersSettingsPageViewedProperties {
  organizationId: string;
  organizationName: string;
}

export interface NavbarLinkButtonClickedProperties {
  pageName: string;
  resourceId?: string;
}

export interface OnboardingCreateOrganizationSuccessProperties {
  organizationId: string;
  organizationName: string;
}

export interface OrganizationMembershipInviteButtonClickedProperties {
  organizationId: string;
  organizationName: string;
}

export interface OrganizationMembershipRemoveButtonClickedProperties {
  organizationId: string;
  organizationMembershipId: string;
  organizationName: string;
}

export interface OrganizationMembershipReSendInvitationButtonClickedProperties {
  organizationId: string;
  organizationName: string;
}

export interface PaymentSettingsPageViewedProperties {
  merchantId: any;
  merchantSlug: any;
  mode: any;
  organizationId: any;
  organizationName: any;
}

export interface PerkAnalyticsCampaignFilterChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  selectedCampaignsLabels: string[];
}

export interface PerkAnalyticsUsageFilterChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  selectedUsage: string;
}

export interface PerkCampaignsAssociationsModalClosedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkCampaignsAssociationsModalFilteringChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filters: string[];
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkCampaignsAssociationsModalOpenedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkCampaignsAssociationsModalSortingChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  orderBy: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkCampaignsAssociationsModalSubmitProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkCampaignsPageViewedProperties {
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkCampaignsSaveButtonClickedProperties {
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkCardClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkCreateAppFilterChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkApp?: string;
  perkIntegration?: string;
}

export interface PerkCreateButtonClickedProperties {
  organizationId: string;
  organizationName: string;
}

export interface PerkCreatePageViewedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface PerkCreateSuccessProperties {
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkDropdownArchiveClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkDropdownClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkDropdownDeleteClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkDropdownDuplicateClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkDropdownUnarchiveClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkEditPageViewedProperties {
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkListItemClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerkMetadataSettingsAddFieldBtnClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkMetadataSettingsRemoveFieldBtnClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkMetadataSettingsSeeDocLinkClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkPreviewButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkPreviewModalClosedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkPreviewModalOpenedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkPublishButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface PerkReviewAndPublishButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkReviewAndPublishModalClosedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkReviewAndPublishModalOpenedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerkSaveButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface PerksDisplayChangedProperties {
  display: string;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface PerksListPageViewedProperties {
  organizationId: string;
  organizationName: string;
}

export interface PerksListTableAppsFilterChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  selectedApps: string[];
}

export interface PerksListTablePerkClickedProperties {
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface PerksListTableStatusFilterChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  selectedPerkStatuses: string[];
}

export interface PerksListTableTypesFilterChangedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  selectedTypes: string[];
}

export interface PerkUsagesPageViewedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface QrReaderDeletedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
  qrReaderId: string;
}

export interface QrReaderExportSuccessProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface QrReadersCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  count: number;
  fromCsv: boolean;
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface QrReadersSettingsPageViewedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface SigninErrorProperties {
  errorCode?: any;
  errorReason?: string;
  signinErrorType?: string;
  signinMethod?: any;
}

export interface SigninFormRedirectProperties {
  signinMethod?: any;
}

export interface SigninSuccessProperties {
  signinMethod?: any;
}

export interface TestModeBannerModalButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface TestModeToggleClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface TestModeToggleInfoButtonClickedProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface CampaignPropertiesProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  campaignAvailableSupply?: number;
  campaignId: string;
  campaignSlug?: string;
  campaignStatus: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1 |
   */
  campaignTotalSupply?: number;
}

export interface DefaultPropertiesProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
  organizationId: string;
  organizationName: string;
}

export interface DigitalAssetCollectionPropertiesProperties {
  digitalAssetCollectionId: string;
  digitalAssetCollectionTitle: string;
}

export interface ErrorPropertiesProperties {
  errorCode?: any;
  errorReason?: string;
}

export interface MerchantPropertiesProperties {
  merchantId: string;
  merchantSlug: string;
  mode: string;
}

export interface OptionalPerkPropertiesProperties {
  perkId?: string;
  perkIntegration?: string;
  perkName?: string;
  perkStatus?: string;
  perkType?: string;
}

export interface OrganizationMembershipPropertiesProperties {
  organizationMembershipId: string;
}

export interface OrganizationPropertiesProperties {
  organizationId: string;
  organizationName: string;
}

export interface PerkPropertiesProperties {
  perkId: string;
  perkIntegration?: string;
  perkName: string;
  perkStatus: string;
  perkType: string;
}

export interface QrReaderPropertiesProperties {
  qrReaderId: string;
}

export interface RedeemCodePropertiesProperties {
  campaignId: string;
  redeemCodeCode: string;
  redeemCodeId: string;
  redeemCodeStatus: string;
}

export interface SigninPropertiesProperties {
  signinMethod?: any;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccordionClicked implements BaseEvent {
  event_type = 'accordionClicked';

  constructor(
    public event_properties: AccordionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApiSettingsPageViewed implements BaseEvent {
  event_type = 'apiSettingsPageViewed';

  constructor(
    public event_properties?: ApiSettingsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BrandSettingsPageViewed implements BaseEvent {
  event_type = 'brandSettingsPageViewed';

  constructor(
    public event_properties: BrandSettingsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BrandSettingsSaveButtonClicked implements BaseEvent {
  event_type = 'brandSettingsSaveButtonClicked';

  constructor(
    public event_properties: BrandSettingsSaveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BreadcrumbBackButtonClicked implements BaseEvent {
  event_type = 'breadcrumbBackButtonClicked';

  constructor(
    public event_properties: BreadcrumbBackButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignAirdropModalCloseButtonClicked implements BaseEvent {
  event_type = 'campaignAirdropModalCloseButtonClicked';

  constructor(
    public event_properties: CampaignAirdropModalCloseButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignAirdropModalCsvImported implements BaseEvent {
  event_type = 'campaignAirdropModalCsvImported';

  constructor(
    public event_properties: CampaignAirdropModalCsvImportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignAirdropModalOpenButtonClicked implements BaseEvent {
  event_type = 'campaignAirdropModalOpenButtonClicked';

  constructor(
    public event_properties: CampaignAirdropModalOpenButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignAirdropSubmitted implements BaseEvent {
  event_type = 'campaignAirdropSubmitted';

  constructor(
    public event_properties: CampaignAirdropSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignCardClicked implements BaseEvent {
  event_type = 'campaignCardClicked';

  constructor(
    public event_properties: CampaignCardClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignCreateButtonClicked implements BaseEvent {
  event_type = 'campaignCreateButtonClicked';

  constructor(
    public event_properties: CampaignCreateButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignCreateSuccess implements BaseEvent {
  event_type = 'campaignCreateSuccess';

  constructor(
    public event_properties: CampaignCreateSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignDropdownArchiveClicked implements BaseEvent {
  event_type = 'campaignDropdownArchiveClicked';

  constructor(
    public event_properties: CampaignDropdownArchiveClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignDropdownClicked implements BaseEvent {
  event_type = 'campaignDropdownClicked';

  constructor(
    public event_properties: CampaignDropdownClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignDropdownUnarchiveClicked implements BaseEvent {
  event_type = 'campaignDropdownUnarchiveClicked';

  constructor(
    public event_properties: CampaignDropdownUnarchiveClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignDropdownVisitPageClicked implements BaseEvent {
  event_type = 'campaignDropdownVisitPageClicked';

  constructor(
    public event_properties: CampaignDropdownVisitPageClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignEditDominantBgColorButtonClicked implements BaseEvent {
  event_type = 'campaignEditDominantBgColorButtonClicked';

  constructor(
    public event_properties: CampaignEditDominantBgColorButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignEditImportBrandSpaceColorsButtonClicked implements BaseEvent {
  event_type = 'campaignEditImportBrandSpaceColorsButtonClicked';

  constructor(
    public event_properties: CampaignEditImportBrandSpaceColorsButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignEditPageViewed implements BaseEvent {
  event_type = 'campaignEditPageViewed';

  constructor(
    public event_properties: CampaignEditPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignListItemClicked implements BaseEvent {
  event_type = 'campaignListItemClicked';

  constructor(
    public event_properties: CampaignListItemClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignOrdersPageViewed implements BaseEvent {
  event_type = 'campaignOrdersPageViewed';

  constructor(
    public event_properties: CampaignOrdersPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPerksAssociationsModalClosed implements BaseEvent {
  event_type = 'campaignPerksAssociationsModalClosed';

  constructor(
    public event_properties: CampaignPerksAssociationsModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPerksAssociationsModalFilteringChanged implements BaseEvent {
  event_type = 'campaignPerksAssociationsModalFilteringChanged';

  constructor(
    public event_properties: CampaignPerksAssociationsModalFilteringChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPerksAssociationsModalOpened implements BaseEvent {
  event_type = 'campaignPerksAssociationsModalOpened';

  constructor(
    public event_properties: CampaignPerksAssociationsModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPerksAssociationsModalSortingChanged implements BaseEvent {
  event_type = 'campaignPerksAssociationsModalSortingChanged';

  constructor(
    public event_properties: CampaignPerksAssociationsModalSortingChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPerksAssociationsModalSubmit implements BaseEvent {
  event_type = 'campaignPerksAssociationsModalSubmit';

  constructor(
    public event_properties: CampaignPerksAssociationsModalSubmitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPerksPageViewed implements BaseEvent {
  event_type = 'campaignPerksPageViewed';

  constructor(
    public event_properties: CampaignPerksPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPerksReordered implements BaseEvent {
  event_type = 'campaignPerksReordered';

  constructor(
    public event_properties: CampaignPerksReorderedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPerksSaveButtonClicked implements BaseEvent {
  event_type = 'campaignPerksSaveButtonClicked';

  constructor(
    public event_properties: CampaignPerksSaveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPreviewModalClosed implements BaseEvent {
  event_type = 'campaignPreviewModalClosed';

  constructor(
    public event_properties: CampaignPreviewModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPreviewModalOpened implements BaseEvent {
  event_type = 'campaignPreviewModalOpened';

  constructor(
    public event_properties: CampaignPreviewModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPreviewModeButtonClicked implements BaseEvent {
  event_type = 'campaignPreviewModeButtonClicked';

  constructor(
    public event_properties: CampaignPreviewModeButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignPublishButtonClicked implements BaseEvent {
  event_type = 'campaignPublishButtonClicked';

  constructor(
    public event_properties: CampaignPublishButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignRedeemLinksPageViewed implements BaseEvent {
  event_type = 'campaignRedeemLinksPageViewed';

  constructor(
    public event_properties: CampaignRedeemLinksPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignReviewAndPublishButtonClicked implements BaseEvent {
  event_type = 'campaignReviewAndPublishButtonClicked';

  constructor(
    public event_properties: CampaignReviewAndPublishButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignReviewAndPublishModalClosed implements BaseEvent {
  event_type = 'campaignReviewAndPublishModalClosed';

  constructor(
    public event_properties: CampaignReviewAndPublishModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignReviewAndPublishModalOpened implements BaseEvent {
  event_type = 'campaignReviewAndPublishModalOpened';

  constructor(
    public event_properties: CampaignReviewAndPublishModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignReviewAndPublishPreviewModeButtonClicked implements BaseEvent {
  event_type = 'campaignReviewAndPublishPreviewModeButtonClicked';

  constructor(
    public event_properties: CampaignReviewAndPublishPreviewModeButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignSaveButtonClicked implements BaseEvent {
  event_type = 'campaignSaveButtonClicked';

  constructor(
    public event_properties: CampaignSaveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignsDisplayChanged implements BaseEvent {
  event_type = 'campaignsDisplayChanged';

  constructor(
    public event_properties: CampaignsDisplayChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignsOverviewPageViewed implements BaseEvent {
  event_type = 'campaignsOverviewPageViewed';

  constructor(
    public event_properties: CampaignsOverviewPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignsSortingChanged implements BaseEvent {
  event_type = 'campaignsSortingChanged';

  constructor(
    public event_properties: CampaignsSortingChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignsStatusFilterChanged implements BaseEvent {
  event_type = 'campaignsStatusFilterChanged';

  constructor(
    public event_properties: CampaignsStatusFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignsTypeFilterChanged implements BaseEvent {
  event_type = 'campaignsTypeFilterChanged';

  constructor(
    public event_properties: CampaignsTypeFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeOrganizationButtonClicked implements BaseEvent {
  event_type = 'changeOrganizationButtonClicked';

  constructor(
    public event_properties: ChangeOrganizationButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyRedeemCodeBtnClicked implements BaseEvent {
  event_type = 'copyRedeemCodeBtnClicked';

  constructor(
    public event_properties: CopyRedeemCodeBtnClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteRedeemCodeBtnClicked implements BaseEvent {
  event_type = 'deleteRedeemCodeBtnClicked';

  constructor(
    public event_properties: DeleteRedeemCodeBtnClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteRedeemCodeSuccess implements BaseEvent {
  event_type = 'deleteRedeemCodeSuccess';

  constructor(
    public event_properties: DeleteRedeemCodeSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionCreateButtonClicked implements BaseEvent {
  event_type = 'digitalAssetCollectionCreateButtonClicked';

  constructor(
    public event_properties: DigitalAssetCollectionCreateButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionCreateSuccess implements BaseEvent {
  event_type = 'digitalAssetCollectionCreateSuccess';

  constructor(
    public event_properties: DigitalAssetCollectionCreateSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionDeleteSuccess implements BaseEvent {
  event_type = 'digitalAssetCollectionDeleteSuccess';

  constructor(
    public event_properties: DigitalAssetCollectionDeleteSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionDetailsPageViewed implements BaseEvent {
  event_type = 'digitalAssetCollectionDetailsPageViewed';

  constructor(
    public event_properties: DigitalAssetCollectionDetailsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionDetailsSaveButtonClicked implements BaseEvent {
  event_type = 'digitalAssetCollectionDetailsSaveButtonClicked';

  constructor(
    public event_properties: DigitalAssetCollectionDetailsSaveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionDropdownClicked implements BaseEvent {
  event_type = 'digitalAssetCollectionDropdownClicked';

  constructor(
    public event_properties: DigitalAssetCollectionDropdownClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionDropdownDeleteClicked implements BaseEvent {
  event_type = 'digitalAssetCollectionDropdownDeleteClicked';

  constructor(
    public event_properties: DigitalAssetCollectionDropdownDeleteClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionDropdownDuplicateClicked implements BaseEvent {
  event_type = 'digitalAssetCollectionDropdownDuplicateClicked';

  constructor(
    public event_properties: DigitalAssetCollectionDropdownDuplicateClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionDuplicateSuccess implements BaseEvent {
  event_type = 'digitalAssetCollectionDuplicateSuccess';

  constructor(
    public event_properties: DigitalAssetCollectionDuplicateSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerkAdded implements BaseEvent {
  event_type = 'digitalAssetCollectionPerkAdded';

  constructor(
    public event_properties: DigitalAssetCollectionPerkAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerkRemoved implements BaseEvent {
  event_type = 'digitalAssetCollectionPerkRemoved';

  constructor(
    public event_properties: DigitalAssetCollectionPerkRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksAssociationsModalAppFilterChanged implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksAssociationsModalAppFilterChanged';

  constructor(
    public event_properties: DigitalAssetCollectionPerksAssociationsModalAppFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksAssociationsModalClosed implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksAssociationsModalClosed';

  constructor(
    public event_properties: DigitalAssetCollectionPerksAssociationsModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksAssociationsModalOpened implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksAssociationsModalOpened';

  constructor(
    public event_properties: DigitalAssetCollectionPerksAssociationsModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksAssociationsModalSortingChanged implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksAssociationsModalSortingChanged';

  constructor(
    public event_properties: DigitalAssetCollectionPerksAssociationsModalSortingChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksAssociationsModalSubmit implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksAssociationsModalSubmit';

  constructor(
    public event_properties: DigitalAssetCollectionPerksAssociationsModalSubmitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksAssociationsModalTypeFilterChanged implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksAssociationsModalTypeFilterChanged';

  constructor(
    public event_properties: DigitalAssetCollectionPerksAssociationsModalTypeFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksPageViewed implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksPageViewed';

  constructor(
    public event_properties: DigitalAssetCollectionPerksPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksReordered implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksReordered';

  constructor(
    public event_properties: DigitalAssetCollectionPerksReorderedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionPerksSaveButtonClicked implements BaseEvent {
  event_type = 'digitalAssetCollectionPerksSaveButtonClicked';

  constructor(
    public event_properties: DigitalAssetCollectionPerksSaveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionsDisplayChanged implements BaseEvent {
  event_type = 'digitalAssetCollectionsDisplayChanged';

  constructor(
    public event_properties: DigitalAssetCollectionsDisplayChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionsOverviewPageViewed implements BaseEvent {
  event_type = 'digitalAssetCollectionsOverviewPageViewed';

  constructor(
    public event_properties: DigitalAssetCollectionsOverviewPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionsSortingChanged implements BaseEvent {
  event_type = 'digitalAssetCollectionsSortingChanged';

  constructor(
    public event_properties: DigitalAssetCollectionsSortingChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalAssetCollectionsStatusFilterChanged implements BaseEvent {
  event_type = 'digitalAssetCollectionsStatusFilterChanged';

  constructor(
    public event_properties: DigitalAssetCollectionsStatusFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportOrdersError implements BaseEvent {
  event_type = 'exportOrdersError';

  constructor(
    public event_properties: ExportOrdersErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportOrdersSuccess implements BaseEvent {
  event_type = 'exportOrdersSuccess';

  constructor(
    public event_properties: ExportOrdersSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportPerkAnalyticsSuccess implements BaseEvent {
  event_type = 'exportPerkAnalyticsSuccess';

  constructor(
    public event_properties: ExportPerkAnalyticsSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportRedeemCodesError implements BaseEvent {
  event_type = 'exportRedeemCodesError';

  constructor(
    public event_properties: ExportRedeemCodesErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportRedeemCodesSuccess implements BaseEvent {
  event_type = 'exportRedeemCodesSuccess';

  constructor(
    public event_properties: ExportRedeemCodesSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InvitationActivationError implements BaseEvent {
  event_type = 'invitationActivationError';

  constructor(
    public event_properties?: InvitationActivationErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InvitationActivationSuccess implements BaseEvent {
  event_type = 'invitationActivationSuccess';
}

export class InvitationPageViewed implements BaseEvent {
  event_type = 'invitationPageViewed';
}

export class MembershipPassConfigurationSettingsSaveButtonClicked implements BaseEvent {
  event_type = 'membershipPassConfigurationSettingsSaveButtonClicked';

  constructor(
    public event_properties: MembershipPassConfigurationSettingsSaveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MembershipPassPreviewModalClosed implements BaseEvent {
  event_type = 'membershipPassPreviewModalClosed';

  constructor(
    public event_properties: MembershipPassPreviewModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MembershipPassPreviewModalOpened implements BaseEvent {
  event_type = 'membershipPassPreviewModalOpened';

  constructor(
    public event_properties: MembershipPassPreviewModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MembershipPassSettingsBgColorBtnClicked implements BaseEvent {
  event_type = 'membershipPassSettingsBgColorBtnClicked';

  constructor(
    public event_properties: MembershipPassSettingsBgColorBtnClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MembersSettingsPageViewed implements BaseEvent {
  event_type = 'membersSettingsPageViewed';

  constructor(
    public event_properties: MembersSettingsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NavbarLinkButtonClicked implements BaseEvent {
  event_type = 'navbarLinkButtonClicked';

  constructor(
    public event_properties: NavbarLinkButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingCreateOrganizationSuccess implements BaseEvent {
  event_type = 'onboardingCreateOrganizationSuccess';

  constructor(
    public event_properties: OnboardingCreateOrganizationSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingPageViewed implements BaseEvent {
  event_type = 'onboardingPageViewed';
}

export class OrganizationMembershipInviteButtonClicked implements BaseEvent {
  event_type = 'organizationMembershipInviteButtonClicked';

  constructor(
    public event_properties: OrganizationMembershipInviteButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrganizationMembershipRemoveButtonClicked implements BaseEvent {
  event_type = 'organizationMembershipRemoveButtonClicked';

  constructor(
    public event_properties: OrganizationMembershipRemoveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrganizationMembershipReSendInvitationButtonClicked implements BaseEvent {
  event_type = 'organizationMembershipReSendInvitationButtonClicked';

  constructor(
    public event_properties: OrganizationMembershipReSendInvitationButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PaymentSettingsPageViewed implements BaseEvent {
  event_type = 'paymentSettingsPageViewed';

  constructor(
    public event_properties: PaymentSettingsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkAnalyticsCampaignFilterChanged implements BaseEvent {
  event_type = 'perkAnalyticsCampaignFilterChanged';

  constructor(
    public event_properties: PerkAnalyticsCampaignFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkAnalyticsUsageFilterChanged implements BaseEvent {
  event_type = 'perkAnalyticsUsageFilterChanged';

  constructor(
    public event_properties: PerkAnalyticsUsageFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCampaignsAssociationsModalClosed implements BaseEvent {
  event_type = 'perkCampaignsAssociationsModalClosed';

  constructor(
    public event_properties: PerkCampaignsAssociationsModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCampaignsAssociationsModalFilteringChanged implements BaseEvent {
  event_type = 'perkCampaignsAssociationsModalFilteringChanged';

  constructor(
    public event_properties: PerkCampaignsAssociationsModalFilteringChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCampaignsAssociationsModalOpened implements BaseEvent {
  event_type = 'perkCampaignsAssociationsModalOpened';

  constructor(
    public event_properties: PerkCampaignsAssociationsModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCampaignsAssociationsModalSortingChanged implements BaseEvent {
  event_type = 'perkCampaignsAssociationsModalSortingChanged';

  constructor(
    public event_properties: PerkCampaignsAssociationsModalSortingChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCampaignsAssociationsModalSubmit implements BaseEvent {
  event_type = 'perkCampaignsAssociationsModalSubmit';

  constructor(
    public event_properties: PerkCampaignsAssociationsModalSubmitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCampaignsPageViewed implements BaseEvent {
  event_type = 'perkCampaignsPageViewed';

  constructor(
    public event_properties: PerkCampaignsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCampaignsSaveButtonClicked implements BaseEvent {
  event_type = 'perkCampaignsSaveButtonClicked';

  constructor(
    public event_properties: PerkCampaignsSaveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCardClicked implements BaseEvent {
  event_type = 'perkCardClicked';

  constructor(
    public event_properties: PerkCardClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCreateAppFilterChanged implements BaseEvent {
  event_type = 'perkCreateAppFilterChanged';

  constructor(
    public event_properties: PerkCreateAppFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCreateButtonClicked implements BaseEvent {
  event_type = 'perkCreateButtonClicked';

  constructor(
    public event_properties: PerkCreateButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCreatePageViewed implements BaseEvent {
  event_type = 'perkCreatePageViewed';

  constructor(
    public event_properties: PerkCreatePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkCreateSuccess implements BaseEvent {
  event_type = 'perkCreateSuccess';

  constructor(
    public event_properties: PerkCreateSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkDropdownArchiveClicked implements BaseEvent {
  event_type = 'perkDropdownArchiveClicked';

  constructor(
    public event_properties: PerkDropdownArchiveClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkDropdownClicked implements BaseEvent {
  event_type = 'perkDropdownClicked';

  constructor(
    public event_properties: PerkDropdownClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkDropdownDeleteClicked implements BaseEvent {
  event_type = 'perkDropdownDeleteClicked';

  constructor(
    public event_properties: PerkDropdownDeleteClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkDropdownDuplicateClicked implements BaseEvent {
  event_type = 'perkDropdownDuplicateClicked';

  constructor(
    public event_properties: PerkDropdownDuplicateClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkDropdownUnarchiveClicked implements BaseEvent {
  event_type = 'perkDropdownUnarchiveClicked';

  constructor(
    public event_properties: PerkDropdownUnarchiveClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkEditPageViewed implements BaseEvent {
  event_type = 'perkEditPageViewed';

  constructor(
    public event_properties: PerkEditPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkListItemClicked implements BaseEvent {
  event_type = 'perkListItemClicked';

  constructor(
    public event_properties: PerkListItemClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkMetadataSettingsAddFieldBtnClicked implements BaseEvent {
  event_type = 'perkMetadataSettingsAddFieldBtnClicked';

  constructor(
    public event_properties: PerkMetadataSettingsAddFieldBtnClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkMetadataSettingsRemoveFieldBtnClicked implements BaseEvent {
  event_type = 'perkMetadataSettingsRemoveFieldBtnClicked';

  constructor(
    public event_properties: PerkMetadataSettingsRemoveFieldBtnClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkMetadataSettingsSeeDocLinkClicked implements BaseEvent {
  event_type = 'perkMetadataSettingsSeeDocLinkClicked';

  constructor(
    public event_properties: PerkMetadataSettingsSeeDocLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkPreviewButtonClicked implements BaseEvent {
  event_type = 'perkPreviewButtonClicked';

  constructor(
    public event_properties: PerkPreviewButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkPreviewModalClosed implements BaseEvent {
  event_type = 'perkPreviewModalClosed';

  constructor(
    public event_properties: PerkPreviewModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkPreviewModalOpened implements BaseEvent {
  event_type = 'perkPreviewModalOpened';

  constructor(
    public event_properties: PerkPreviewModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkPublishButtonClicked implements BaseEvent {
  event_type = 'perkPublishButtonClicked';

  constructor(
    public event_properties: PerkPublishButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkReviewAndPublishButtonClicked implements BaseEvent {
  event_type = 'perkReviewAndPublishButtonClicked';

  constructor(
    public event_properties: PerkReviewAndPublishButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkReviewAndPublishModalClosed implements BaseEvent {
  event_type = 'perkReviewAndPublishModalClosed';

  constructor(
    public event_properties: PerkReviewAndPublishModalClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkReviewAndPublishModalOpened implements BaseEvent {
  event_type = 'perkReviewAndPublishModalOpened';

  constructor(
    public event_properties: PerkReviewAndPublishModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkSaveButtonClicked implements BaseEvent {
  event_type = 'perkSaveButtonClicked';

  constructor(
    public event_properties: PerkSaveButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerksDisplayChanged implements BaseEvent {
  event_type = 'perksDisplayChanged';

  constructor(
    public event_properties: PerksDisplayChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerksListPageViewed implements BaseEvent {
  event_type = 'perksListPageViewed';

  constructor(
    public event_properties: PerksListPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerksListTableAppsFilterChanged implements BaseEvent {
  event_type = 'perksListTableAppsFilterChanged';

  constructor(
    public event_properties: PerksListTableAppsFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerksListTablePerkClicked implements BaseEvent {
  event_type = 'perksListTablePerkClicked';

  constructor(
    public event_properties: PerksListTablePerkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerksListTableStatusFilterChanged implements BaseEvent {
  event_type = 'perksListTableStatusFilterChanged';

  constructor(
    public event_properties: PerksListTableStatusFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerksListTableTypesFilterChanged implements BaseEvent {
  event_type = 'perksListTableTypesFilterChanged';

  constructor(
    public event_properties: PerksListTableTypesFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerkUsagesPageViewed implements BaseEvent {
  event_type = 'perkUsagesPageViewed';

  constructor(
    public event_properties: PerkUsagesPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QrReaderDeleted implements BaseEvent {
  event_type = 'qrReaderDeleted';

  constructor(
    public event_properties: QrReaderDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QrReaderExportSuccess implements BaseEvent {
  event_type = 'qrReaderExportSuccess';

  constructor(
    public event_properties: QrReaderExportSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QrReadersCreated implements BaseEvent {
  event_type = 'qrReadersCreated';

  constructor(
    public event_properties: QrReadersCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QrReadersSettingsPageViewed implements BaseEvent {
  event_type = 'qrReadersSettingsPageViewed';

  constructor(
    public event_properties: QrReadersSettingsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SigninError implements BaseEvent {
  event_type = 'signinError';

  constructor(
    public event_properties?: SigninErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SigninFormRedirect implements BaseEvent {
  event_type = 'signinFormRedirect';

  constructor(
    public event_properties?: SigninFormRedirectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SigninPageViewed implements BaseEvent {
  event_type = 'signinPageViewed';
}

export class SigninSendLinkSuccess implements BaseEvent {
  event_type = 'signinSendLinkSuccess';
}

export class SigninSuccess implements BaseEvent {
  event_type = 'signinSuccess';

  constructor(
    public event_properties?: SigninSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignoutSuccess implements BaseEvent {
  event_type = 'signoutSuccess';
}

export class TestModeBannerModalButtonClicked implements BaseEvent {
  event_type = 'testModeBannerModalButtonClicked';

  constructor(
    public event_properties: TestModeBannerModalButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TestModeToggleClicked implements BaseEvent {
  event_type = 'testModeToggleClicked';

  constructor(
    public event_properties: TestModeToggleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TestModeToggleInfoButtonClicked implements BaseEvent {
  event_type = 'testModeToggleInfoButtonClicked';

  constructor(
    public event_properties: TestModeToggleInfoButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * accordionClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/accordionClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. accordionName)
   * @param options Amplitude event options.
   */
  accordionClicked(
    properties: AccordionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccordionClicked(properties), options);
  }

  /**
   * apiSettingsPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/apiSettingsPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  apiSettingsPageViewed(
    properties?: ApiSettingsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApiSettingsPageViewed(properties), options);
  }

  /**
   * brandSettingsPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/brandSettingsPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  brandSettingsPageViewed(
    properties: BrandSettingsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrandSettingsPageViewed(properties), options);
  }

  /**
   * brandSettingsSaveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/brandSettingsSaveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  brandSettingsSaveButtonClicked(
    properties: BrandSettingsSaveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BrandSettingsSaveButtonClicked(properties), options);
  }

  /**
   * breadcrumbBackButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/breadcrumbBackButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pageName)
   * @param options Amplitude event options.
   */
  breadcrumbBackButtonClicked(
    properties: BreadcrumbBackButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BreadcrumbBackButtonClicked(properties), options);
  }

  /**
   * campaignAirdropModalCloseButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignAirdropModalCloseButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignAirdropModalCloseButtonClicked(
    properties: CampaignAirdropModalCloseButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignAirdropModalCloseButtonClicked(properties), options);
  }

  /**
   * campaignAirdropModalCsvImported
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignAirdropModalCsvImported)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignAirdropModalCsvImported(
    properties: CampaignAirdropModalCsvImportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignAirdropModalCsvImported(properties), options);
  }

  /**
   * campaignAirdropModalOpenButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignAirdropModalOpenButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignAirdropModalOpenButtonClicked(
    properties: CampaignAirdropModalOpenButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignAirdropModalOpenButtonClicked(properties), options);
  }

  /**
   * campaignAirdropSubmitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignAirdropSubmitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignAirdropSubmitted(
    properties: CampaignAirdropSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignAirdropSubmitted(properties), options);
  }

  /**
   * campaignCardClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignCardClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignCardClicked(
    properties: CampaignCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignCardClicked(properties), options);
  }

  /**
   * campaignCreateButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignCreateButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  campaignCreateButtonClicked(
    properties: CampaignCreateButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignCreateButtonClicked(properties), options);
  }

  /**
   * campaignCreateSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignCreateSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignCreateSuccess(
    properties: CampaignCreateSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignCreateSuccess(properties), options);
  }

  /**
   * campaignDropdownArchiveClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignDropdownArchiveClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignDropdownArchiveClicked(
    properties: CampaignDropdownArchiveClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignDropdownArchiveClicked(properties), options);
  }

  /**
   * campaignDropdownClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignDropdownClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignDropdownClicked(
    properties: CampaignDropdownClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignDropdownClicked(properties), options);
  }

  /**
   * campaignDropdownUnarchiveClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignDropdownUnarchiveClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignDropdownUnarchiveClicked(
    properties: CampaignDropdownUnarchiveClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignDropdownUnarchiveClicked(properties), options);
  }

  /**
   * campaignDropdownVisitPageClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignDropdownVisitPageClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignDropdownVisitPageClicked(
    properties: CampaignDropdownVisitPageClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignDropdownVisitPageClicked(properties), options);
  }

  /**
   * campaignEditDominantBgColorButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignEditDominantBgColorButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignEditDominantBgColorButtonClicked(
    properties: CampaignEditDominantBgColorButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignEditDominantBgColorButtonClicked(properties), options);
  }

  /**
   * campaignEditImportBrandSpaceColorsButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignEditImportBrandSpaceColorsButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignEditImportBrandSpaceColorsButtonClicked(
    properties: CampaignEditImportBrandSpaceColorsButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignEditImportBrandSpaceColorsButtonClicked(properties), options);
  }

  /**
   * campaignEditPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignEditPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignEditPageViewed(
    properties: CampaignEditPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignEditPageViewed(properties), options);
  }

  /**
   * campaignListItemClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignListItemClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignListItemClicked(
    properties: CampaignListItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignListItemClicked(properties), options);
  }

  /**
   * campaignOrdersPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignOrdersPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignOrdersPageViewed(
    properties: CampaignOrdersPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignOrdersPageViewed(properties), options);
  }

  /**
   * campaignPerksAssociationsModalClosed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPerksAssociationsModalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPerksAssociationsModalClosed(
    properties: CampaignPerksAssociationsModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPerksAssociationsModalClosed(properties), options);
  }

  /**
   * campaignPerksAssociationsModalFilteringChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPerksAssociationsModalFilteringChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPerksAssociationsModalFilteringChanged(
    properties: CampaignPerksAssociationsModalFilteringChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPerksAssociationsModalFilteringChanged(properties), options);
  }

  /**
   * campaignPerksAssociationsModalOpened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPerksAssociationsModalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPerksAssociationsModalOpened(
    properties: CampaignPerksAssociationsModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPerksAssociationsModalOpened(properties), options);
  }

  /**
   * campaignPerksAssociationsModalSortingChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPerksAssociationsModalSortingChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPerksAssociationsModalSortingChanged(
    properties: CampaignPerksAssociationsModalSortingChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPerksAssociationsModalSortingChanged(properties), options);
  }

  /**
   * campaignPerksAssociationsModalSubmit
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPerksAssociationsModalSubmit)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPerksAssociationsModalSubmit(
    properties: CampaignPerksAssociationsModalSubmitProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPerksAssociationsModalSubmit(properties), options);
  }

  /**
   * campaignPerksPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPerksPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPerksPageViewed(
    properties: CampaignPerksPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPerksPageViewed(properties), options);
  }

  /**
   * campaignPerksReordered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPerksReordered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPerksReordered(
    properties: CampaignPerksReorderedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPerksReordered(properties), options);
  }

  /**
   * campaignPerksSaveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPerksSaveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPerksSaveButtonClicked(
    properties: CampaignPerksSaveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPerksSaveButtonClicked(properties), options);
  }

  /**
   * campaignPreviewModalClosed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPreviewModalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPreviewModalClosed(
    properties: CampaignPreviewModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPreviewModalClosed(properties), options);
  }

  /**
   * campaignPreviewModalOpened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPreviewModalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPreviewModalOpened(
    properties: CampaignPreviewModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPreviewModalOpened(properties), options);
  }

  /**
   * campaignPreviewModeButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPreviewModeButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPreviewModeButtonClicked(
    properties: CampaignPreviewModeButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPreviewModeButtonClicked(properties), options);
  }

  /**
   * campaignPublishButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignPublishButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignPublishButtonClicked(
    properties: CampaignPublishButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignPublishButtonClicked(properties), options);
  }

  /**
   * campaignRedeemLinksPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignRedeemLinksPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignRedeemLinksPageViewed(
    properties: CampaignRedeemLinksPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignRedeemLinksPageViewed(properties), options);
  }

  /**
   * campaignReviewAndPublishButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignReviewAndPublishButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignReviewAndPublishButtonClicked(
    properties: CampaignReviewAndPublishButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignReviewAndPublishButtonClicked(properties), options);
  }

  /**
   * campaignReviewAndPublishModalClosed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignReviewAndPublishModalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignReviewAndPublishModalClosed(
    properties: CampaignReviewAndPublishModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignReviewAndPublishModalClosed(properties), options);
  }

  /**
   * campaignReviewAndPublishModalOpened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignReviewAndPublishModalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignReviewAndPublishModalOpened(
    properties: CampaignReviewAndPublishModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignReviewAndPublishModalOpened(properties), options);
  }

  /**
   * campaignReviewAndPublishPreviewModeButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignReviewAndPublishPreviewModeButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignReviewAndPublishPreviewModeButtonClicked(
    properties: CampaignReviewAndPublishPreviewModeButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignReviewAndPublishPreviewModeButtonClicked(properties), options);
  }

  /**
   * campaignSaveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignSaveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  campaignSaveButtonClicked(
    properties: CampaignSaveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignSaveButtonClicked(properties), options);
  }

  /**
   * campaignsDisplayChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignsDisplayChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. display)
   * @param options Amplitude event options.
   */
  campaignsDisplayChanged(
    properties: CampaignsDisplayChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignsDisplayChanged(properties), options);
  }

  /**
   * campaignsOverviewPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignsOverviewPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  campaignsOverviewPageViewed(
    properties: CampaignsOverviewPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignsOverviewPageViewed(properties), options);
  }

  /**
   * campaignsSortingChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignsSortingChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  campaignsSortingChanged(
    properties: CampaignsSortingChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignsSortingChanged(properties), options);
  }

  /**
   * campaignsStatusFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignsStatusFilterChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filters)
   * @param options Amplitude event options.
   */
  campaignsStatusFilterChanged(
    properties: CampaignsStatusFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignsStatusFilterChanged(properties), options);
  }

  /**
   * campaignsTypeFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/campaignsTypeFilterChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filters)
   * @param options Amplitude event options.
   */
  campaignsTypeFilterChanged(
    properties: CampaignsTypeFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignsTypeFilterChanged(properties), options);
  }

  /**
   * changeOrganizationButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/changeOrganizationButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  changeOrganizationButtonClicked(
    properties: ChangeOrganizationButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeOrganizationButtonClicked(properties), options);
  }

  /**
   * copyRedeemCodeBtnClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/copyRedeemCodeBtnClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  copyRedeemCodeBtnClicked(
    properties: CopyRedeemCodeBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyRedeemCodeBtnClicked(properties), options);
  }

  /**
   * deleteRedeemCodeBtnClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/deleteRedeemCodeBtnClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  deleteRedeemCodeBtnClicked(
    properties: DeleteRedeemCodeBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteRedeemCodeBtnClicked(properties), options);
  }

  /**
   * deleteRedeemCodeSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/deleteRedeemCodeSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  deleteRedeemCodeSuccess(
    properties: DeleteRedeemCodeSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteRedeemCodeSuccess(properties), options);
  }

  /**
   * digitalAssetCollectionCreateButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionCreateButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionCreateButtonClicked(
    properties: DigitalAssetCollectionCreateButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionCreateButtonClicked(properties), options);
  }

  /**
   * digitalAssetCollectionCreateSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionCreateSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionCreateSuccess(
    properties: DigitalAssetCollectionCreateSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionCreateSuccess(properties), options);
  }

  /**
   * digitalAssetCollectionDeleteSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionDeleteSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionDeleteSuccess(
    properties: DigitalAssetCollectionDeleteSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionDeleteSuccess(properties), options);
  }

  /**
   * digitalAssetCollectionDetailsPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionDetailsPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionDetailsPageViewed(
    properties: DigitalAssetCollectionDetailsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionDetailsPageViewed(properties), options);
  }

  /**
   * digitalAssetCollectionDetailsSaveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionDetailsSaveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionDetailsSaveButtonClicked(
    properties: DigitalAssetCollectionDetailsSaveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionDetailsSaveButtonClicked(properties), options);
  }

  /**
   * digitalAssetCollectionDropdownClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionDropdownClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionDropdownClicked(
    properties: DigitalAssetCollectionDropdownClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionDropdownClicked(properties), options);
  }

  /**
   * digitalAssetCollectionDropdownDeleteClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionDropdownDeleteClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionDropdownDeleteClicked(
    properties: DigitalAssetCollectionDropdownDeleteClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionDropdownDeleteClicked(properties), options);
  }

  /**
   * digitalAssetCollectionDropdownDuplicateClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionDropdownDuplicateClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionDropdownDuplicateClicked(
    properties: DigitalAssetCollectionDropdownDuplicateClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionDropdownDuplicateClicked(properties), options);
  }

  /**
   * digitalAssetCollectionDuplicateSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionDuplicateSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionDuplicateSuccess(
    properties: DigitalAssetCollectionDuplicateSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionDuplicateSuccess(properties), options);
  }

  /**
   * digitalAssetCollectionPerkAdded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerkAdded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerkAdded(
    properties: DigitalAssetCollectionPerkAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerkAdded(properties), options);
  }

  /**
   * digitalAssetCollectionPerkRemoved
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerkRemoved)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerkRemoved(
    properties: DigitalAssetCollectionPerkRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerkRemoved(properties), options);
  }

  /**
   * digitalAssetCollectionPerksAssociationsModalAppFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksAssociationsModalAppFilterChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksAssociationsModalAppFilterChanged(
    properties: DigitalAssetCollectionPerksAssociationsModalAppFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksAssociationsModalAppFilterChanged(properties), options);
  }

  /**
   * digitalAssetCollectionPerksAssociationsModalClosed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksAssociationsModalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksAssociationsModalClosed(
    properties: DigitalAssetCollectionPerksAssociationsModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksAssociationsModalClosed(properties), options);
  }

  /**
   * digitalAssetCollectionPerksAssociationsModalOpened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksAssociationsModalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksAssociationsModalOpened(
    properties: DigitalAssetCollectionPerksAssociationsModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksAssociationsModalOpened(properties), options);
  }

  /**
   * digitalAssetCollectionPerksAssociationsModalSortingChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksAssociationsModalSortingChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksAssociationsModalSortingChanged(
    properties: DigitalAssetCollectionPerksAssociationsModalSortingChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksAssociationsModalSortingChanged(properties), options);
  }

  /**
   * digitalAssetCollectionPerksAssociationsModalSubmit
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksAssociationsModalSubmit)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksAssociationsModalSubmit(
    properties: DigitalAssetCollectionPerksAssociationsModalSubmitProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksAssociationsModalSubmit(properties), options);
  }

  /**
   * digitalAssetCollectionPerksAssociationsModalTypeFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksAssociationsModalTypeFilterChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksAssociationsModalTypeFilterChanged(
    properties: DigitalAssetCollectionPerksAssociationsModalTypeFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksAssociationsModalTypeFilterChanged(properties), options);
  }

  /**
   * digitalAssetCollectionPerksPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksPageViewed(
    properties: DigitalAssetCollectionPerksPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksPageViewed(properties), options);
  }

  /**
   * digitalAssetCollectionPerksReordered
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksReordered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksReordered(
    properties: DigitalAssetCollectionPerksReorderedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksReordered(properties), options);
  }

  /**
   * digitalAssetCollectionPerksSaveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionPerksSaveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. digitalAssetCollectionId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionPerksSaveButtonClicked(
    properties: DigitalAssetCollectionPerksSaveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionPerksSaveButtonClicked(properties), options);
  }

  /**
   * digitalAssetCollectionsDisplayChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionsDisplayChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. display)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionsDisplayChanged(
    properties: DigitalAssetCollectionsDisplayChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionsDisplayChanged(properties), options);
  }

  /**
   * digitalAssetCollectionsOverviewPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionsOverviewPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionsOverviewPageViewed(
    properties: DigitalAssetCollectionsOverviewPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionsOverviewPageViewed(properties), options);
  }

  /**
   * digitalAssetCollectionsSortingChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionsSortingChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionsSortingChanged(
    properties: DigitalAssetCollectionsSortingChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionsSortingChanged(properties), options);
  }

  /**
   * digitalAssetCollectionsStatusFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/digitalAssetCollectionsStatusFilterChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  digitalAssetCollectionsStatusFilterChanged(
    properties: DigitalAssetCollectionsStatusFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalAssetCollectionsStatusFilterChanged(properties), options);
  }

  /**
   * exportOrdersError
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/exportOrdersError)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  exportOrdersError(
    properties: ExportOrdersErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportOrdersError(properties), options);
  }

  /**
   * exportOrdersSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/exportOrdersSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  exportOrdersSuccess(
    properties: ExportOrdersSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportOrdersSuccess(properties), options);
  }

  /**
   * exportPerkAnalyticsSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/exportPerkAnalyticsSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  exportPerkAnalyticsSuccess(
    properties: ExportPerkAnalyticsSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportPerkAnalyticsSuccess(properties), options);
  }

  /**
   * exportRedeemCodesError
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/exportRedeemCodesError)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  exportRedeemCodesError(
    properties: ExportRedeemCodesErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportRedeemCodesError(properties), options);
  }

  /**
   * exportRedeemCodesSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/exportRedeemCodesSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignAvailableSupply)
   * @param options Amplitude event options.
   */
  exportRedeemCodesSuccess(
    properties: ExportRedeemCodesSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportRedeemCodesSuccess(properties), options);
  }

  /**
   * invitationActivationError
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/invitationActivationError)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. errorCode)
   * @param options Amplitude event options.
   */
  invitationActivationError(
    properties?: InvitationActivationErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvitationActivationError(properties), options);
  }

  /**
   * invitationActivationSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/invitationActivationSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  invitationActivationSuccess(
    options?: EventOptions,
  ) {
    return this.track(new InvitationActivationSuccess(), options);
  }

  /**
   * invitationPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/invitationPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  invitationPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new InvitationPageViewed(), options);
  }

  /**
   * membershipPassConfigurationSettingsSaveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/membershipPassConfigurationSettingsSaveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  membershipPassConfigurationSettingsSaveButtonClicked(
    properties: MembershipPassConfigurationSettingsSaveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MembershipPassConfigurationSettingsSaveButtonClicked(properties), options);
  }

  /**
   * membershipPassPreviewModalClosed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/membershipPassPreviewModalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  membershipPassPreviewModalClosed(
    properties: MembershipPassPreviewModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MembershipPassPreviewModalClosed(properties), options);
  }

  /**
   * membershipPassPreviewModalOpened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/membershipPassPreviewModalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  membershipPassPreviewModalOpened(
    properties: MembershipPassPreviewModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MembershipPassPreviewModalOpened(properties), options);
  }

  /**
   * membershipPassSettingsBgColorBtnClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/membershipPassSettingsBgColorBtnClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  membershipPassSettingsBgColorBtnClicked(
    properties: MembershipPassSettingsBgColorBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MembershipPassSettingsBgColorBtnClicked(properties), options);
  }

  /**
   * membersSettingsPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/membersSettingsPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  membersSettingsPageViewed(
    properties: MembersSettingsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MembersSettingsPageViewed(properties), options);
  }

  /**
   * navbarLinkButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/navbarLinkButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pageName)
   * @param options Amplitude event options.
   */
  navbarLinkButtonClicked(
    properties: NavbarLinkButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavbarLinkButtonClicked(properties), options);
  }

  /**
   * onboardingCreateOrganizationSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/onboardingCreateOrganizationSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  onboardingCreateOrganizationSuccess(
    properties: OnboardingCreateOrganizationSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingCreateOrganizationSuccess(properties), options);
  }

  /**
   * onboardingPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/onboardingPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingPageViewed(), options);
  }

  /**
   * organizationMembershipInviteButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/organizationMembershipInviteButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  organizationMembershipInviteButtonClicked(
    properties: OrganizationMembershipInviteButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrganizationMembershipInviteButtonClicked(properties), options);
  }

  /**
   * organizationMembershipRemoveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/organizationMembershipRemoveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  organizationMembershipRemoveButtonClicked(
    properties: OrganizationMembershipRemoveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrganizationMembershipRemoveButtonClicked(properties), options);
  }

  /**
   * organizationMembershipReSendInvitationButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/organizationMembershipReSendInvitationButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  organizationMembershipReSendInvitationButtonClicked(
    properties: OrganizationMembershipReSendInvitationButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrganizationMembershipReSendInvitationButtonClicked(properties), options);
  }

  /**
   * paymentSettingsPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/paymentSettingsPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  paymentSettingsPageViewed(
    properties: PaymentSettingsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentSettingsPageViewed(properties), options);
  }

  /**
   * perkAnalyticsCampaignFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkAnalyticsCampaignFilterChanged)
   *
   * Fired when merchant changes the value of the ***campaign*** filter on the perk analytics page
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkAnalyticsCampaignFilterChanged(
    properties: PerkAnalyticsCampaignFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkAnalyticsCampaignFilterChanged(properties), options);
  }

  /**
   * perkAnalyticsUsageFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkAnalyticsUsageFilterChanged)
   *
   * Fired when user changes the value of the ***Usage*** filter on the perk analytics page
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkAnalyticsUsageFilterChanged(
    properties: PerkAnalyticsUsageFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkAnalyticsUsageFilterChanged(properties), options);
  }

  /**
   * perkCampaignsAssociationsModalClosed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCampaignsAssociationsModalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkCampaignsAssociationsModalClosed(
    properties: PerkCampaignsAssociationsModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCampaignsAssociationsModalClosed(properties), options);
  }

  /**
   * perkCampaignsAssociationsModalFilteringChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCampaignsAssociationsModalFilteringChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filters)
   * @param options Amplitude event options.
   */
  perkCampaignsAssociationsModalFilteringChanged(
    properties: PerkCampaignsAssociationsModalFilteringChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCampaignsAssociationsModalFilteringChanged(properties), options);
  }

  /**
   * perkCampaignsAssociationsModalOpened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCampaignsAssociationsModalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkCampaignsAssociationsModalOpened(
    properties: PerkCampaignsAssociationsModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCampaignsAssociationsModalOpened(properties), options);
  }

  /**
   * perkCampaignsAssociationsModalSortingChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCampaignsAssociationsModalSortingChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkCampaignsAssociationsModalSortingChanged(
    properties: PerkCampaignsAssociationsModalSortingChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCampaignsAssociationsModalSortingChanged(properties), options);
  }

  /**
   * perkCampaignsAssociationsModalSubmit
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCampaignsAssociationsModalSubmit)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkCampaignsAssociationsModalSubmit(
    properties: PerkCampaignsAssociationsModalSubmitProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCampaignsAssociationsModalSubmit(properties), options);
  }

  /**
   * perkCampaignsPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCampaignsPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  perkCampaignsPageViewed(
    properties: PerkCampaignsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCampaignsPageViewed(properties), options);
  }

  /**
   * perkCampaignsSaveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCampaignsSaveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  perkCampaignsSaveButtonClicked(
    properties: PerkCampaignsSaveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCampaignsSaveButtonClicked(properties), options);
  }

  /**
   * perkCardClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCardClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkCardClicked(
    properties: PerkCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCardClicked(properties), options);
  }

  /**
   * perkCreateAppFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCreateAppFilterChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkCreateAppFilterChanged(
    properties: PerkCreateAppFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCreateAppFilterChanged(properties), options);
  }

  /**
   * perkCreateButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCreateButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  perkCreateButtonClicked(
    properties: PerkCreateButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCreateButtonClicked(properties), options);
  }

  /**
   * perkCreatePageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCreatePageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkCreatePageViewed(
    properties: PerkCreatePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCreatePageViewed(properties), options);
  }

  /**
   * perkCreateSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkCreateSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  perkCreateSuccess(
    properties: PerkCreateSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkCreateSuccess(properties), options);
  }

  /**
   * perkDropdownArchiveClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkDropdownArchiveClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkDropdownArchiveClicked(
    properties: PerkDropdownArchiveClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkDropdownArchiveClicked(properties), options);
  }

  /**
   * perkDropdownClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkDropdownClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkDropdownClicked(
    properties: PerkDropdownClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkDropdownClicked(properties), options);
  }

  /**
   * perkDropdownDeleteClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkDropdownDeleteClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkDropdownDeleteClicked(
    properties: PerkDropdownDeleteClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkDropdownDeleteClicked(properties), options);
  }

  /**
   * perkDropdownDuplicateClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkDropdownDuplicateClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkDropdownDuplicateClicked(
    properties: PerkDropdownDuplicateClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkDropdownDuplicateClicked(properties), options);
  }

  /**
   * perkDropdownUnarchiveClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkDropdownUnarchiveClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkDropdownUnarchiveClicked(
    properties: PerkDropdownUnarchiveClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkDropdownUnarchiveClicked(properties), options);
  }

  /**
   * perkEditPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkEditPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  perkEditPageViewed(
    properties: PerkEditPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkEditPageViewed(properties), options);
  }

  /**
   * perkListItemClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkListItemClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkListItemClicked(
    properties: PerkListItemClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkListItemClicked(properties), options);
  }

  /**
   * perkMetadataSettingsAddFieldBtnClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkMetadataSettingsAddFieldBtnClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkMetadataSettingsAddFieldBtnClicked(
    properties: PerkMetadataSettingsAddFieldBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkMetadataSettingsAddFieldBtnClicked(properties), options);
  }

  /**
   * perkMetadataSettingsRemoveFieldBtnClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkMetadataSettingsRemoveFieldBtnClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkMetadataSettingsRemoveFieldBtnClicked(
    properties: PerkMetadataSettingsRemoveFieldBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkMetadataSettingsRemoveFieldBtnClicked(properties), options);
  }

  /**
   * perkMetadataSettingsSeeDocLinkClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkMetadataSettingsSeeDocLinkClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkMetadataSettingsSeeDocLinkClicked(
    properties: PerkMetadataSettingsSeeDocLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkMetadataSettingsSeeDocLinkClicked(properties), options);
  }

  /**
   * perkPreviewButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkPreviewButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkPreviewButtonClicked(
    properties: PerkPreviewButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkPreviewButtonClicked(properties), options);
  }

  /**
   * perkPreviewModalClosed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkPreviewModalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkPreviewModalClosed(
    properties: PerkPreviewModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkPreviewModalClosed(properties), options);
  }

  /**
   * perkPreviewModalOpened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkPreviewModalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkPreviewModalOpened(
    properties: PerkPreviewModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkPreviewModalOpened(properties), options);
  }

  /**
   * perkPublishButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkPublishButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkPublishButtonClicked(
    properties: PerkPublishButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkPublishButtonClicked(properties), options);
  }

  /**
   * perkReviewAndPublishButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkReviewAndPublishButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkReviewAndPublishButtonClicked(
    properties: PerkReviewAndPublishButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkReviewAndPublishButtonClicked(properties), options);
  }

  /**
   * perkReviewAndPublishModalClosed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkReviewAndPublishModalClosed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkReviewAndPublishModalClosed(
    properties: PerkReviewAndPublishModalClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkReviewAndPublishModalClosed(properties), options);
  }

  /**
   * perkReviewAndPublishModalOpened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkReviewAndPublishModalOpened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkReviewAndPublishModalOpened(
    properties: PerkReviewAndPublishModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkReviewAndPublishModalOpened(properties), options);
  }

  /**
   * perkSaveButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkSaveButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkSaveButtonClicked(
    properties: PerkSaveButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkSaveButtonClicked(properties), options);
  }

  /**
   * perksDisplayChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perksDisplayChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. display)
   * @param options Amplitude event options.
   */
  perksDisplayChanged(
    properties: PerksDisplayChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerksDisplayChanged(properties), options);
  }

  /**
   * perksListPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perksListPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  perksListPageViewed(
    properties: PerksListPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerksListPageViewed(properties), options);
  }

  /**
   * perksListTableAppsFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perksListTableAppsFilterChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perksListTableAppsFilterChanged(
    properties: PerksListTableAppsFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerksListTableAppsFilterChanged(properties), options);
  }

  /**
   * perksListTablePerkClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perksListTablePerkClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. organizationId)
   * @param options Amplitude event options.
   */
  perksListTablePerkClicked(
    properties: PerksListTablePerkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerksListTablePerkClicked(properties), options);
  }

  /**
   * perksListTableStatusFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perksListTableStatusFilterChanged)
   *
   * Fired when a merchant changes the value of the ***Status*** filter on the perks overview table.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perksListTableStatusFilterChanged(
    properties: PerksListTableStatusFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerksListTableStatusFilterChanged(properties), options);
  }

  /**
   * perksListTableTypesFilterChanged
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perksListTableTypesFilterChanged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perksListTableTypesFilterChanged(
    properties: PerksListTableTypesFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerksListTableTypesFilterChanged(properties), options);
  }

  /**
   * perkUsagesPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/perkUsagesPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  perkUsagesPageViewed(
    properties: PerkUsagesPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerkUsagesPageViewed(properties), options);
  }

  /**
   * qrReaderDeleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/qrReaderDeleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  qrReaderDeleted(
    properties: QrReaderDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QrReaderDeleted(properties), options);
  }

  /**
   * qrReaderExportSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/qrReaderExportSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  qrReaderExportSuccess(
    properties: QrReaderExportSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new QrReaderExportSuccess(properties), options);
  }

  /**
   * qrReadersCreated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/qrReadersCreated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. count)
   * @param options Amplitude event options.
   */
  qrReadersCreated(
    properties: QrReadersCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QrReadersCreated(properties), options);
  }

  /**
   * qrReadersSettingsPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/qrReadersSettingsPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  qrReadersSettingsPageViewed(
    properties: QrReadersSettingsPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QrReadersSettingsPageViewed(properties), options);
  }

  /**
   * signinError
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/signinError)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. errorCode)
   * @param options Amplitude event options.
   */
  signinError(
    properties?: SigninErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new SigninError(properties), options);
  }

  /**
   * signinFormRedirect
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/signinFormRedirect)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. signinMethod)
   * @param options Amplitude event options.
   */
  signinFormRedirect(
    properties?: SigninFormRedirectProperties,
    options?: EventOptions,
  ) {
    return this.track(new SigninFormRedirect(properties), options);
  }

  /**
   * signinPageViewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/signinPageViewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signinPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new SigninPageViewed(), options);
  }

  /**
   * signinSendLinkSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/signinSendLinkSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signinSendLinkSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SigninSendLinkSuccess(), options);
  }

  /**
   * signinSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/signinSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. signinMethod)
   * @param options Amplitude event options.
   */
  signinSuccess(
    properties?: SigninSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new SigninSuccess(properties), options);
  }

  /**
   * signoutSuccess
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/signoutSuccess)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signoutSuccess(
    options?: EventOptions,
  ) {
    return this.track(new SignoutSuccess(), options);
  }

  /**
   * testModeBannerModalButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/testModeBannerModalButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  testModeBannerModalButtonClicked(
    properties: TestModeBannerModalButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TestModeBannerModalButtonClicked(properties), options);
  }

  /**
   * testModeToggleClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/testModeToggleClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  testModeToggleClicked(
    properties: TestModeToggleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TestModeToggleClicked(properties), options);
  }

  /**
   * testModeToggleInfoButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/cohort/Merchants/events/main/latest/testModeToggleInfoButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. merchantId)
   * @param options Amplitude event options.
   */
  testModeToggleInfoButtonClicked(
    properties: TestModeToggleInfoButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TestModeToggleInfoButtonClicked(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
