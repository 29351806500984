import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import CohortFormConfig from '@cohort/merchants/components/form/cohortForm/CohortFormConfig';
import {useController} from 'react-hook-form';

const CohortFillFormTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {register, control}}) => {
  const {field} = useController({control, name: 'triggerIntegrationConfig'});

  return (
    <CohortFormConfig
      register={register}
      control={control}
      fieldPaths={{
        questions: 'triggerIntegrationConfig.questions',
        description: 'triggerIntegrationConfig.description',
      }}
      existingConfig={field.value}
    />
  );
};

export default CohortFillFormTriggerIntegrationConfigComponent;
