import SpotifyPlaylistCard from '@cohort/merchants/apps/spotify/triggers/SpotifyPlaylistCard';
import type {SpotifyFollowPlaylistVerificationAttemptData} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import {Fragment} from 'react';

const SpotifyFollowPlaylistTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: SpotifyFollowPlaylistVerificationAttemptData;
}> = ({data}) => {
  const {follows} = data;

  if (!follows) {
    return <Fragment />;
  }

  return (
    <div className="flex justify-center gap-y-2 bg-slate-50 p-4">
      <div className="w-[185px] rounded-lg border border-slate-200">
        <SpotifyPlaylistCard
          media={{
            name: data.playlistName,
            thumbnailUrl: data.playlistThumbnailUrl,
            tracksCount: data.playlistTracksCount,
          }}
        />
      </div>
    </div>
  );
};

export default SpotifyFollowPlaylistTriggerIntegrationStepCompletionContextComponent;
