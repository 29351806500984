import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useDiscordRoles} from '@cohort/merchants/apps/discord/api';
import ErrorState from '@cohort/merchants/components/ErrorState';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {DiscordRoleTriggerConfigSchema} from '@cohort/shared/apps/discord/triggers/hasRole';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import type {MultiValue} from 'react-select';
import {match} from 'ts-pattern';
import {z} from 'zod';

const DiscordHasRoleTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {control}, connectionId}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {field: rolesField} = useController({name: 'triggerIntegrationConfig.roles', control});
  const {t} = useTranslation('app-discord', {
    keyPrefix: 'triggerIntegrations.has-role.configComponent',
  });

  const {data: discordRolesRes, isLoading, isFetched} = useDiscordRoles(merchant.id, connectionId);
  const discordRoles = discordRolesRes?.output.roles ?? [];

  return match({
    discordRoles,
    isLoading,
    isFetched,
  })
    .with({isLoading: true}, () => <Loader size={30} color="gray" />)
    .with({discordRoles: []}, () => <p>{t('roleSelect.noRolesMessage')}</p>)
    .with({isFetched: true}, () => {
      const parsedRolesField = z
        .array(DiscordRoleTriggerConfigSchema)
        .parse(rolesField.value ?? []);
      const selectedRoleIds = parsedRolesField.map(role => role.roleId);

      const roleOptions = discordRoles.map(role => ({
        value: role.id,
        label: role.name,
      }));
      const selectedRoles = roleOptions.filter(option => selectedRoleIds.includes(option.value));

      return (
        <SelectPicker
          name="triggerIntegrationConfig.roles"
          labelPosition="top"
          label={t('roleSelect.labelRoleSelect')}
          options={roleOptions}
          value={selectedRoles}
          onChange={(options: MultiValue<SelectOption>) =>
            rolesField.onChange(
              options.map(option => ({
                roleId: option.value,
                roleName: option.label,
              }))
            )
          }
          isMulti
        />
      );
    })
    .otherwise(() => <ErrorState />);
};

export default DiscordHasRoleTriggerIntegrationConfigComponent;
