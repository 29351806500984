import type {TriggerIntegration} from '@cohort/merchants/apps';
import FacebookCommentPostTriggerIntegrationConfigComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/ConfigComponent';
import FacebookCommentPostTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/StepCompletionContextComponent';
import FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/StepCompletionTitleComponent';
import type {FacebookCommentPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {FacebookCommentPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/commentPost';

export const FacebookCommentPostTriggerIntegration: TriggerIntegration<FacebookCommentPostTriggerStruct> =
  {
    spec: FacebookCommentPostTriggerSpec,
    configComponent: FacebookCommentPostTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      FacebookCommentPostTriggerIntegrationStepCompletionContextComponent,
    getTitle: t => t('triggers.comment-post.title', {ns: 'app-facebook'}),
  };
