/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {GetDigitalAssetCollectionsParamsADto} from '@cohort/admin-schemas/digitalAssetCollection';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {
  getDigitalAssetCollection,
  getDigitalAssetCollections,
} from '@cohort/merchants/lib/api/DigitalAssetCollections';
import {useInfiniteQuery} from '@tanstack/react-query';

export const digitalAssetCollectionsKeys = {
  digitalAssetCollections: ['digitalAssetCollections'] as const,
  list: (merchantId: string) =>
    [...digitalAssetCollectionsKeys.digitalAssetCollections, merchantId, 'list'] as const,
  listWithFilters: (merchantId: string, params: GetDigitalAssetCollectionsParamsADto) =>
    [...digitalAssetCollectionsKeys.list(merchantId), params] as const,
  listInfiniteDigitalAssetCollections: (
    merchantId: string,
    params: Omit<GetDigitalAssetCollectionsParamsADto, 'page'>
  ) => [...digitalAssetCollectionsKeys.list(merchantId), params, 'infinite'] as const,
  getById: (merchantId: string, digitalAssetCollectionId: string) =>
    [
      ...digitalAssetCollectionsKeys.digitalAssetCollections,
      merchantId,
      digitalAssetCollectionId,
    ] as const,
};

export const useDigitalAssetCollection = (
  merchantId: string,
  digitalAssetCollectionId: string,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: digitalAssetCollectionsKeys.getById(merchantId, digitalAssetCollectionId),
    queryFn: async () => getDigitalAssetCollection(merchantId, digitalAssetCollectionId),
    ...options,
    refetchInterval: options?.autoRefresh === true ? 5000 : false,
  });

export const useDigitalAssetCollections = (
  merchantId: string,
  params: GetDigitalAssetCollectionsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: digitalAssetCollectionsKeys.listWithFilters(merchantId, params),
    queryFn: async () => getDigitalAssetCollections(merchantId, params),
    ...options,
    refetchInterval: options?.autoRefresh === true ? 5000 : false,
  });

export const useDigitalAssetCollectionsWithInfiniteQuery = (
  merchantId: string,
  params: Omit<GetDigitalAssetCollectionsParamsADto, 'page'>
) =>
  useInfiniteQuery({
    queryKey: digitalAssetCollectionsKeys.listInfiniteDigitalAssetCollections(merchantId, params),
    queryFn: async ({pageParam = 1}) => {
      const [pagination, data] = await getDigitalAssetCollections(merchantId, {
        ...params,
        page: pageParam,
      });
      return {data, meta: pagination};
    },
    getNextPageParam: lastPage => {
      if (lastPage.meta.page === lastPage.meta.pages) {
        return undefined;
      }
      return lastPage.meta.page + 1;
    },
  });
