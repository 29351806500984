import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import {FACEBOOK_APP_ID} from '@cohort/shared/apps/facebook';
import type {ListPostsActionStruct} from '@cohort/shared/apps/facebook/actions/listPosts';
import {ListPostsActionSpec} from '@cohort/shared/apps/facebook/actions/listPosts';
import type {QueryOptions, UseQueryResult} from '@tanstack/react-query';

export const facebookAppKeys = {
  facebookApp: ['facebookApp'] as const,
  listPosts: (merchantId: string, merchantConnectionId: string) =>
    [...facebookAppKeys.facebookApp, merchantId, merchantConnectionId, 'list-medias'] as const,
};

export const useFacebookPosts = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
): UseQueryResult<ExecuteActionResponseADto<ListPostsActionStruct>, unknown> => {
  return useCohortQuery({
    queryKey: facebookAppKeys.listPosts(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<ListPostsActionStruct>(merchantId, {
        appId: FACEBOOK_APP_ID,
        actionId: ListPostsActionSpec.id,
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};
