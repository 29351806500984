import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import {useDigitalAsset} from '@cohort/merchants/hooks/api/DigitalAssets';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortDigitalAssetOwnedVerificationAttemptData} from '@cohort/shared/apps/cohort/triggers/digitalAssetOwned';
import {Trans} from 'react-i18next';

const CohortDigitalAssetOwnedTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortDigitalAssetOwnedVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const merchant = useCurrentMerchant();

  const firstDigitalAsset = data.ownerships[0];
  const {data: digitalAsset, isLoading} = useDigitalAsset(
    merchant.id,
    firstDigitalAsset?.digitalAssetId ?? '',
    {enabled: firstDigitalAsset !== undefined}
  );

  return completionSuccess ? (
    <StepCompletionCard
      isLoading={isLoading}
      translationConfig={
        digitalAsset !== undefined
          ? {
              // i18nOwl-ignore [app-cohort.triggerIntegrations.digital-asset-owned.stepCompletionTitleComponent.success]
              key: 'triggerIntegrations.digital-asset-owned.stepCompletionTitleComponent.success',
              ns: 'app-cohort',
              value: digitalAsset.digitalAssetCollection.internalName,
            }
          : null
      }
    />
  ) : (
    <Trans
      i18nKey="triggerIntegrations.digital-asset-owned.stepCompletionTitleComponent.error"
      ns="app-cohort"
    />
  );
};

export default CohortDigitalAssetOwnedTriggerIntegrationStepCompletionTitleComponent;
