import {Apps} from '@cohort/merchants/apps';
import {usePerkIntegrations} from '@cohort/merchants/apps/usePerkIntegrations';
import {IconBadge} from '@cohort/merchants/components/badges/IconBadge';
import type {AppId, PerkIntegrationId} from '@cohort/shared/apps';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import React, {Fragment} from 'react';

interface Props {
  perkIntegrationId: PerkIntegrationId | null;
  size?: 'large' | 'small';
}
export const PerkAppBadge: React.FC<Props> = ({perkIntegrationId, size = 'small'}): JSX.Element => {
  const {getPerkIntegration, getPerkIntegrationTitle} = usePerkIntegrations();

  if (perkIntegrationId === null) {
    return <Fragment />;
  }
  const appId = perkIntegrationId.split('.')[0];
  const app = Apps.find(app => app.spec.id === appId);
  if (app === undefined || appId === undefined) {
    throw new Error(`Never happens: No App found for id: ${appId}`);
  }

  const perkIntegration = getPerkIntegration(perkIntegrationId);

  return (
    <IconBadge
      size={size}
      icon={<SvgAppIcon className="mr-1" name={appId as AppId} height={14} width={14} />}
      text={getPerkIntegrationTitle(perkIntegration)}
      backgroundColor="bg-slate-100"
    />
  );
};
