import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const FacebookLikePostTriggerConfigSchema = z.object({
  postId: z.string(),
  postPermalink: z.string().url(),
});
type FacebookLikePostTriggerConfig = z.infer<typeof FacebookLikePostTriggerConfigSchema>;

const FacebookLikePostVerificationAttemptDataSchema = z.object({
  username: z.string().nullable(),
  userLink: z.string().nullable(),
});
export type FacebookLikePostVerificationAttemptData = z.infer<
  typeof FacebookLikePostVerificationAttemptDataSchema
>;

export type FacebookLikePostTriggerStruct = TriggerIntegrationStruct<
  'facebook.like-post',
  FacebookLikePostTriggerConfig,
  FacebookLikePostVerificationAttemptData
>;
export const FacebookLikePostTriggerSpec: TriggerIntegrationSpec<FacebookLikePostTriggerStruct> = {
  id: 'facebook.like-post' as const,
  type: 'verification',
  configurableMaxAttempts: false,
  configSchema: FacebookLikePostTriggerConfigSchema,
  verificationAttemptDataSchema: FacebookLikePostVerificationAttemptDataSchema,
  verificationAttemptInputSchema: z.object({}),
  requiresMerchantConnection: true,
};
