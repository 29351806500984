import type {FileInputContextType} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import {FileInputContext} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import {useContext} from 'react';
import type {FieldValues} from 'react-hook-form';

const useFileInputContext = <T extends FieldValues>(): FileInputContextType<T> => {
  const context = useContext(FileInputContext);
  if (context === undefined) {
    throw new Error('useFileInputContext must be used within a FileInputContextProvider');
  }
  return context as FileInputContextType<T>;
};

export default useFileInputContext;
