import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortMemberHandleUserPropertyStruct = UserPropertyStruct<
  'cohort.member-handle',
  string
>;

export const CohortMemberHandleUserPropertySpec: UserPropertySpec<CohortMemberHandleUserPropertyStruct> =
  {
    id: 'cohort.member-handle',
    name: 'Member Handle',
    dataType: 'string',
    scope: 'global',
  };
