import type {CampaignADto} from '@cohort/admin-schemas/campaign';
import Dropdown, {
  DropdownButton,
  DropdownEntry,
  DropdownPanel,
} from '@cohort/merchants/components/buttons/Dropdown';
import {ConfirmationModal} from '@cohort/merchants/components/modals/ConfirmationModal';
import DeletionModal from '@cohort/merchants/components/modals/DeletetionModal';
import {campaignsKeys} from '@cohort/merchants/hooks/api/Campaigns';
import {ChallengeParticipationKeys} from '@cohort/merchants/hooks/api/ChallengeParticipations';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {userEventsKeys} from '@cohort/merchants/hooks/api/UserEvents';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {
  archiveCampaign,
  deleteCampaign,
  endCampaign,
  reopenCampaign,
  unarchiveCampaign,
} from '@cohort/merchants/lib/api/Campaigns';
import {getCampaignsRoute} from '@cohort/merchants/lib/Pages';
import {
  trackCampaignDropdownArchiveClicked,
  trackCampaignDropdownClicked,
  trackCampaignDropdownUnarchiveClicked,
  trackCampaignDropdownVisitPageClicked,
} from '@cohort/merchants/lib/Tracking';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {
  Archive,
  ArrowSquareOut,
  DotsThreeVertical,
  LockSimple,
  LockSimpleOpen,
  Trash,
} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

interface CampaignDropdownProps {
  campaign: CampaignADto;
}

const CampaignDropdown: React.FC<CampaignDropdownProps> = ({campaign}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('pages', {keyPrefix: 'campaigns.overview.campaignDropdown'});
  const location = useLocation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  async function onMutationSuccess(): Promise<void> {
    await queryClient.invalidateQueries(campaignsKeys.campaigns);
  }

  const {mutate: handleCampaignArchive} = useCohortMutation({
    mutationFn: async () => archiveCampaign(merchant.id, campaign.id),
    onSuccess: () => {
      onMutationSuccess();
      setShowArchiveModal(false);
    },
    notifySuccessMessage: t('notificationCampaignUpdateSuccess'),
  });

  const {mutate: handleCampaignUnarchive} = useCohortMutation({
    mutationFn: async () => unarchiveCampaign(merchant.id, campaign.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationCampaignUpdateSuccess'),
  });

  const {mutate: handleCampaignEnd} = useCohortMutation({
    mutationFn: async () => endCampaign(merchant.id, campaign.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationCampaignUpdateSuccess'),
  });

  const {mutate: handleCampaignDelete} = useCohortMutation({
    mutationFn: async () => deleteCampaign(merchant.id, campaign.id),
    onSuccess: async () => {
      setShowDeleteModal(false);
      if (location.pathname.includes(campaign.id)) {
        navigate(getCampaignsRoute().path);
      }
      queryClient.invalidateQueries(campaignsKeys.list(merchant.id));
      queryClient.invalidateQueries(userEventsKeys.userEvents);
      if (campaign.type === 'challenge') {
        queryClient.invalidateQueries(ChallengeParticipationKeys.participations);
      }
    },
    notifySuccessMessage: t('notificationCampaignDeleteSuccess'),
  });

  const {mutate: handleCampaignReopen} = useCohortMutation({
    mutationFn: async () => reopenCampaign(merchant.id, campaign.id),
    onSuccess: onMutationSuccess,
    notifySuccessMessage: t('notificationCampaignUpdateSuccess'),
    onError: err => {
      if (isCohortError(err, 'campaign.not-enough-tokens')) {
        return notify('error', t('notificationCampaignNotEnoughTokens'));
      }
      return notify('error', t('notificationCampaignUpdateError'));
    },
  });

  return (
    <Fragment>
      <Dropdown>
        <DropdownButton
          className="p-0 [height:36px] [width:36px]"
          onClick={() => trackCampaignDropdownClicked(campaign)}
        >
          <DotsThreeVertical size={20} color="gray" />
        </DropdownButton>
        <DropdownPanel>
          {campaign.status !== 'draft' && campaign.type !== 'airdrop' && (
            <DropdownEntry
              label={t('buttonVisitPage')}
              icon={<ArrowSquareOut className="h-4 w-4 text-slate-400" />}
              handleClick={() => {
                trackCampaignDropdownVisitPageClicked(campaign);
                if (campaign.store?.shopUrl) {
                  return window.open(campaign.store.shopUrl, '_blank');
                }
                if (campaign.challenge?.standaloneUrl) {
                  return window.open(campaign.challenge.standaloneUrl, '_blank');
                }
              }}
            />
          )}
          {campaign.status !== 'draft' && (
            <DropdownEntry
              label={campaign.status === 'archived' ? t('buttonUnarchive') : t('buttonArchive')}
              icon={<Archive className="h-4 w-4 text-slate-400" />}
              handleClick={() => {
                if (campaign.status === 'archived') {
                  trackCampaignDropdownUnarchiveClicked(campaign);
                  handleCampaignUnarchive();
                } else {
                  trackCampaignDropdownArchiveClicked(campaign);
                  // show confirmation modal before archiving if campaign is not ended
                  if (campaign.status !== 'ended') {
                    setShowArchiveModal(true);
                  } else {
                    handleCampaignArchive();
                  }
                }
              }}
            />
          )}
          {campaign.status !== 'draft' && campaign.status !== 'archived' && (
            <DropdownEntry
              label={campaign.status === 'ended' ? t('buttonReopen') : t('buttonEnd')}
              icon={
                campaign.status === 'ended' ? (
                  <LockSimpleOpen className="h-4 w-4 text-slate-400" />
                ) : (
                  <LockSimple className="h-4 w-4 text-slate-400" />
                )
              }
              handleClick={() => {
                if (campaign.status !== 'ended') {
                  handleCampaignEnd();
                } else {
                  handleCampaignReopen();
                }
              }}
            />
          )}
          <DropdownEntry
            label={t('buttonDelete')}
            icon={<Trash className="h-4 w-4 text-red-500" />}
            handleClick={() => {
              setShowDeleteModal(true);
            }}
          />
        </DropdownPanel>
      </Dropdown>
      {showDeleteModal && (
        <DeletionModal
          title={t('deletionModalTitle')}
          subtitle={t('deletionModalSubtitle')}
          show={true}
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleCampaignDelete}
        />
      )}
      {showArchiveModal && (
        <ConfirmationModal
          title={t('archiveModalTitle')}
          content={t('archiveModalSubtitle')}
          submitButtonText={t('archiveModalSubmit')}
          show={true}
          onClose={() => setShowArchiveModal(false)}
          onSubmit={handleCampaignArchive}
        />
      )}
    </Fragment>
  );
};

export default CampaignDropdown;
