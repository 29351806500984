import type {BadgeADto} from '@cohort/admin-schemas/badge';
import {CurrentBadgeContext} from '@cohort/merchants/pages/users/cohort/CurrentBadgeContext';
import {useContext} from 'react';

export const useCurrentBadge = (): BadgeADto => {
  const context = useContext(CurrentBadgeContext);

  if (context === null) {
    throw new Error('useCurrentBadge must be used within a CurrentBadgeProvider');
  }
  return context;
};
