import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortFillFormTriggerIntegrationConfigComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/fillForm/ConfigComponent';
import CohortFillFormTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/fillForm/StepCompletionContextComponent';
import CohortFillFormTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/fillForm/StepCompletionTitleComponent';
import {
  removeLanguagesFromQuestions,
  removeUndefinedLanguages,
} from '@cohort/merchants/lib/form/localization';
import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort/triggers/fillForm';
import {CohortFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/fillForm';
import type {Language} from '@cohort/shared/schema/common';
import type {CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {CohortFormConfigSchema} from '@cohort/shared/schema/common/cohortForm';

export const CohortFillFormTriggerIntegration: TriggerIntegration<CohortFillFormTriggerStruct> = {
  spec: CohortFillFormTriggerIntegrationSpec,
  configComponent: CohortFillFormTriggerIntegrationConfigComponent,
  configForm: {
    schema: CohortFormConfigSchema,
    toForm,
    fromForm,
  },
  stepCompletionTitleComponent: CohortFillFormTriggerIntegrationStepCompletionTitleComponent,
  stepCompletionContextComponent: CohortFillFormTriggerIntegrationStepCompletionContextComponent,
  getTitle: t => t('triggerIntegrations.fill-form.title', {ns: 'app-cohort'}),
};

function fromForm(
  formValues: CohortFormConfig,
  definedLanguages: Array<Language>
): CohortFormConfig {
  const questions = removeLanguagesFromQuestions(formValues.questions, definedLanguages);
  const description = removeUndefinedLanguages(formValues.description, definedLanguages);
  return {
    ...formValues,
    description,
    questions,
  };
}

function toForm(config: CohortFormConfig): CohortFormConfig {
  return config;
}
