import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortMemberSinceUserPropertyStruct = UserPropertyStruct<'cohort.member-since', Date>;

export const CohortMemberSinceUserPropertySpec: UserPropertySpec<CohortMemberSinceUserPropertyStruct> =
  {
    id: 'cohort.member-since',
    name: 'Member Since',
    dataType: 'date',
    scope: 'global',
  };
