import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortEmailUserPropertyStruct = UserPropertyStruct<'cohort.email', string>;

export const CohortEmailUserPropertySpec: UserPropertySpec<CohortEmailUserPropertyStruct> = {
  id: 'cohort.email',
  name: 'Email',
  dataType: 'string',
  scope: 'global',
};
