import type {
  UserEvent,
  UserEventDetailComponentProps,
  UserEventDetailModalComponentProps,
} from '@cohort/merchants/apps';
import {LabelValueTable} from '@cohort/merchants/components/LabelValueTable';
import type {GarminConnectActivityCreatedEventStruct} from '@cohort/shared/apps/garmin-connect/userEvents/activityCreated';
import {GarminConnectActivityCreatedEventSpec} from '@cohort/shared/apps/garmin-connect/userEvents/activityCreated';
import {toKilometers, toKilometersPerHour} from '@cohort/shared/utils/convert';
import {formatDuration} from '@cohort/shared/utils/format';
import {Trans, useTranslation} from 'react-i18next';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as GarminConnectActivityCreatedEventStruct['Properties'];
  const activityName = properties.activityName;

  return (
    <Trans
      i18nKey="userEvents.activity.created.detail"
      ns="app-garmin-connect"
      values={{
        activityName,
      }}
      components={{
        bold: <span className="font-semibold text-gray-900" />,
      }}
    />
  );
};

const DetailModalComponent: React.FC<UserEventDetailModalComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as GarminConnectActivityCreatedEventStruct['Properties'];
  const {t} = useTranslation('app-garmin-connect', {keyPrefix: 'userEvents.activity.created'});

  const averageSpeedInKmPerHour =
    properties.averageSpeedInMetersPerSecond !== undefined
      ? toKilometersPerHour(properties.averageSpeedInMetersPerSecond).toFixed(2).toString()
      : undefined;

  const distanceInKmh =
    properties.distanceInMeters !== undefined
      ? toKilometers(properties.distanceInMeters).toFixed(2).toString()
      : undefined;

  const duration =
    properties.durationInSeconds !== undefined
      ? formatDuration(properties.durationInSeconds)
      : undefined;

  const tableData = [
    {label: t('labelType'), value: properties.activityType.toString()},
    {label: t('labelDuration'), value: duration},
    {label: t('labelAverageSpeed'), value: averageSpeedInKmPerHour},
    {label: t('labelDistance'), value: distanceInKmh},
    {label: t('labelElevation'), value: properties.totalElevationGainInMeters?.toString()},
  ];

  return <LabelValueTable data={tableData} />;
};

export const GarminConnectActivityCreatedUserEvent: UserEvent<GarminConnectActivityCreatedEventStruct> =
  {
    spec: GarminConnectActivityCreatedEventSpec,
    detailComponent: DetailComponent,
    detailModalComponent: DetailModalComponent,
  };
