import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

const TalonOneCouponConfigSchema = z.object({
  campaignId: z.number().min(1, {message: 'errorRequired'}),
  usageLimit: z.number().min(0, {message: 'errorRequired'}),
  storeUrl: z.string().url({message: 'errorInvalidUrl'}),
});
export type TalonOneCouponConfig = z.infer<typeof TalonOneCouponConfigSchema>;

const TalonOneCouponStateSchema = z.object({});
export type TalonOneCouponState = z.infer<typeof TalonOneCouponStateSchema>;

export const ActivatedTalonOneCouponAccessDataSchema = z.object({
  couponId: z.number().int().positive(),
  couponCode: z.string(),
});
export type ActivatedTalonOneCouponAccessData = z.infer<
  typeof ActivatedTalonOneCouponAccessDataSchema
>;

export const TalonOneCouponAccessDataSchema = z.union([
  ActivatedTalonOneCouponAccessDataSchema,
  z.object({}),
]);
export type TalonOneCouponAccessData = z.infer<typeof TalonOneCouponAccessDataSchema>;

const TalonOneCouponUsageInputSchema = z.object({});
export type TalonOneCouponUsageInput = z.infer<typeof TalonOneCouponUsageInputSchema>;

const TalonOneCouponUsageDataSchema = z.object({
  couponId: z.number().int().positive(),
  couponCode: z.string(),
});
export type TalonOneCouponUsageData = z.infer<typeof TalonOneCouponUsageDataSchema>;

const TalonOneCouponExposedUsageDataSchema = z.object({});
export type TalonOneCouponExposedUsageData = z.infer<typeof TalonOneCouponExposedUsageDataSchema>;

export type TalonOneCouponPerkIntegrationStruct = PerkIntegrationStruct<
  'talon-one.coupon',
  TalonOneCouponConfig,
  TalonOneCouponState,
  TalonOneCouponAccessData,
  TalonOneCouponUsageInput,
  TalonOneCouponUsageData,
  TalonOneCouponExposedUsageData
>;
export const TalonOneCouponPerkIntegrationSpec: PerkIntegrationSpec<TalonOneCouponPerkIntegrationStruct> =
  {
    id: 'talon-one.coupon' as const,
    configSchema: TalonOneCouponConfigSchema,
    stateSchema: TalonOneCouponStateSchema,
    accessDataSchema: TalonOneCouponAccessDataSchema,
    usageInputDataSchema: TalonOneCouponUsageInputSchema,
    internalUsageDataSchema: TalonOneCouponUsageDataSchema,
    exposedUsageDataSchema: TalonOneCouponExposedUsageDataSchema,
    defaultMaxUsagesPerToken: null,
    requiresMerchantConnection: true,
  };
