import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {StravaCompleteActivityVerificationAttemptData} from '@cohort/shared/apps/strava/triggers/completeActivity';

const StravaCompleteActivityTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: StravaCompleteActivityVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => (
  <StepCompletionCard
    translationConfig={{
      // i18nOwl-ignore [app-strava.triggerIntegrations.complete-activity.stepCompletionTitleComponent.success]
      // i18nOwl-ignore [app-strava.triggerIntegrations.complete-activity.stepCompletionTitleComponent.error]
      key: `triggerIntegrations.complete-activity.stepCompletionTitleComponent.${
        completionSuccess ? 'success' : 'error'
      }`,
      ns: 'app-strava',
      value: data === null ? 'N/A' : data.stravaUsername,
    }}
  />
);

export default StravaCompleteActivityTriggerIntegrationStepCompletionTitleComponent;
