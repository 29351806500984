import type {TriggerIntegration} from '@cohort/merchants/apps';
import InstagramTagInPostTriggerIntegrationConfigComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/tagInPost/ConfigComponent';
import InstagramTagInPostTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/tagInPost/StepCompletionTitleComponent';
import type {InstagramTagInPostTriggerStruct} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import {InstagramTagInPostTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/tagInPost';

export const InstagramTagInPostTriggerIntegration: TriggerIntegration<InstagramTagInPostTriggerStruct> =
  {
    spec: InstagramTagInPostTriggerIntegrationSpec,
    configComponent: InstagramTagInPostTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: InstagramTagInPostTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.tag-in-post.title', {ns: 'app-instagram'}),
  };
