import {ToastAction} from '@cohort/merchants/components/toasts/Toast';
import {useAppVersion} from '@cohort/merchants/hooks/api/Version';
import {useToast} from '@cohort/merchants/hooks/toast';
import {ArrowsClockwise} from '@phosphor-icons/react';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';

export default function useVersionChecker(): void {
  const currentVersion = GIT_COMMIT_SHA;
  const toastDisplayed = useRef<boolean>(false);
  const {data: latestVersion, isFetched} = useAppVersion(
    import.meta.env.COHORT_ENV !== 'development' && toastDisplayed.current === false
  );
  const {toast} = useToast();
  const {t} = useTranslation('components', {keyPrefix: 'toasts.newVersionToast'});

  // It will return false if there is an error, therefore we don't want to show the toast
  if (latestVersion === false) {
    return;
  }

  if (
    isFetched &&
    toastDisplayed.current === false &&
    (latestVersion === null || latestVersion !== currentVersion)
  ) {
    toast({
      title: t('title'),
      duration: Infinity,
      action: (
        <ToastAction onClick={() => window.location.reload()} altText={t('updateBtn')}>
          <ArrowsClockwise className="mr-2 h-4 w-4" />
          {t('updateBtn')}
        </ToastAction>
      ),
    });
    toastDisplayed.current = true;
  }
}
