import type {ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const TikTokMerchantProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  username: z.string(),
});

export type TikTokMerchantProfileProperties = z.infer<typeof TikTokMerchantProfilePropertiesSchema>;

export type TikTokMerchantConnectorStruct = ConnectorStruct<
  'tiktok-merchant',
  OAuth2AppCredentials,
  OAuth2Credentials,
  TikTokMerchantProfileProperties,
  null
>;

export const TikTokMerchantConnectorSpec = basicOauth2ConnectorSpec<TikTokMerchantConnectorStruct>(
  'tiktok-merchant',
  TikTokMerchantProfilePropertiesSchema
);
