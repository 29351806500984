import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import type {CohortAppDisconnectedEventStruct} from '@cohort/shared/apps/cohort/userEvents/appDisconnected';
import {CohortAppDisconnectedEventSpec} from '@cohort/shared/apps/cohort/userEvents/appDisconnected';
import {Trans} from 'react-i18next';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as CohortAppDisconnectedEventStruct['Properties'];
  const appName = properties.appId;
  return (
    <Trans
      i18nKey="userEvents.app.disconnected.detail"
      ns="app-cohort"
      values={{
        appName,
      }}
      components={{
        bold: <span className="font-semibold text-gray-900" />,
      }}
    />
  );
};

export const CohortAppDisconnectedUserEvent: UserEvent<CohortAppDisconnectedEventStruct> = {
  spec: CohortAppDisconnectedEventSpec,
  detailComponent: DetailComponent,
};
