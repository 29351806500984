import type {BadgeADto} from '@cohort/admin-schemas/badge';
import {BadgeASchema} from '@cohort/admin-schemas/badge';
import type {
  GetUserBadgesParamsADto,
  GetUserCohortsParamsADto,
  ImportUsersADto,
  UpdateUserDataADto,
  UserAnalyticsADto,
  UserCohortADto,
} from '@cohort/admin-schemas/userAnalytics';
import {UserAnalyticsASchema, UserCohortASchema} from '@cohort/admin-schemas/userAnalytics';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto, PaginationParamsDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getUser(merchantId: string, userId: string): Promise<UserAnalyticsADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/users/${userId}`, {
    expect: HttpCodes.SUCCESS,
    parser: UserAnalyticsASchema.parse,
  });
}

export async function updateUser(
  merchantId: string,
  userId: string,
  data: UpdateUserDataADto
): Promise<UserAnalyticsADto> {
  return apiCall('PATCH', `/v1/merchants/${merchantId}/users/${userId}`, {
    expect: HttpCodes.SUCCESS,
    parser: UserAnalyticsASchema.parse,
    body: data,
  });
}

export async function getUsers(
  merchantId: string,
  params: PaginationParamsDto
): Promise<[PaginationDto, UserAnalyticsADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/users`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(UserAnalyticsASchema),
    params,
  });
}

export async function exportUsers(merchantId: string): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/users/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
    params: {
      format: 'csv',
    },
  });
}

export async function importUsers(merchantId: string, data: ImportUsersADto): Promise<object> {
  return apiCall('POST', `/v1/merchants/${merchantId}/users/import`, {
    expect: HttpCodes.CREATED,
    parser: () => ({}),
    body: data,
  });
}

export async function getUserCohorts(
  merchantId: string,
  userId: string,
  params: GetUserCohortsParamsADto
): Promise<[PaginationDto, UserCohortADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/users/${userId}/cohorts`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(UserCohortASchema),
    params,
  });
}

export const getUserBadges = async (
  merchantId: string,
  userId: string,
  params: GetUserBadgesParamsADto
): Promise<[PaginationDto, BadgeADto[]]> => {
  return apiCall('GET', `/v1/merchants/${merchantId}/users/${userId}/badges`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(BadgeASchema),
    params,
  });
};
