import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import GarminConnectActivitySelectPicker from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/ActivitySelectPicker';
import GarminConnectMetricSelectPicker from '@cohort/merchants/apps/garmin-connect/triggerIntegratons/beActive/MetricSelectPicker';
import Input from '@cohort/merchants/components/form/input/Input';
import TriggerIntegrationDateRangeInput from '@cohort/merchants/components/trigger-integration/TriggerIntegrationDateRangeInput';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const GarminBeActiveTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = props => {
  const {
    formReturn: {register, control},
  } = props;
  const {t} = useTranslation('app-garmin-connect', {
    keyPrefix: 'triggerIntegrations.be-active.configComponent',
  });

  const {field: metric} = useController({
    control,
    name: 'triggerIntegrationConfig.metric',
  });

  const valueLabel = match(metric.value)
    .with('distance', () => t('labelActivityDistance'))
    .with('duration', () => t('labelActivityDuration'))
    .with('elevation', () => t('labelActivityElevation'))
    .otherwise(() => t('labelActivityValue'));

  const valueDescription = match(metric.value)
    .with('distance', () => t('descriptionInKilometers'))
    .with('duration', () => t('descriptionInMinutes'))
    .with('elevation', () => t('descriptionInMeters'))
    .otherwise(() => '');

  return (
    <div className="flex flex-col gap-4">
      <TriggerIntegrationDateRangeInput
        {...props}
        startDateName="triggerIntegrationConfig.startDate"
        endDateName="triggerIntegrationConfig.endDate"
      />

      <div className="flex flex-row gap-4">
        <GarminConnectMetricSelectPicker {...props} />

        {!!metric.value && (
          <Input
            name="triggerIntegrationConfig.value"
            label={valueLabel}
            description={valueDescription}
            register={register}
            control={control}
            type="number"
            min={0}
          />
        )}
      </div>

      <GarminConnectActivitySelectPicker
        {...props}
        fieldName="triggerIntegrationConfig.activityGroups"
        hasFieldName="triggerIntegrationConfig.hasActivityTypes"
      />
    </div>
  );
};

export default GarminBeActiveTriggerIntegrationConfigComponent;
