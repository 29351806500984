import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import {CurrentConnectionContext} from '@cohort/merchants/pages/campaigns/campaign/CurrentConnectionContext';
import {useContext} from 'react';

export const useCurrentConnection = (): ConnectionADto => {
  const context = useContext(CurrentConnectionContext);

  if (context === null) {
    throw new Error('useCurrentConnection must be used within a CurrentConnectionProvider');
  }
  return context;
};
