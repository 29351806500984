import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getDigitalAssetRoute} from '@cohort/merchants/lib/Pages';
import type {CohortDigitalAssetReceivedEventStruct} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetReceived';
import {CohortDigitalAssetReceivedEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetReceived';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const collectionId = (userEvent.properties as CohortDigitalAssetReceivedEventStruct['Properties'])
    .collectionId;
  const digitalAssetCollectionInternalName = userEvent.digitalAssetCollectionInternalName;
  const navigate = useNavigate();

  return (
    <Trans
      i18nKey="userEvents.digital-asset.received.detail"
      ns="app-cohort"
      values={{
        digitalAssetCollectionInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getDigitalAssetRoute(collectionId).path)
        ),
      }}
    />
  );
};

export const CohortDigitalAssetReceivedUserEvent: UserEvent<CohortDigitalAssetReceivedEventStruct> =
  {
    spec: CohortDigitalAssetReceivedEventSpec,
    detailComponent: DetailComponent,
  };
