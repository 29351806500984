import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import BaseTikTokSelectPostTriggerIntegrationConfig from '@cohort/merchants/apps/tiktok/triggers/BaseTikTokSelectPostTriggerIntegrationConfig';
import {useTranslation} from 'react-i18next';

const TikTokCommentTiktokTriggerConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.comment-tiktok.configComponent',
  });

  return (
    <BaseTikTokSelectPostTriggerIntegrationConfig
      connectionId={connectionId}
      formReturn={formReturn}
      description={t('descriptionVideoSelect')}
      title={t('titleVideoSelect')}
      noVideosMessage={t('noVideosMessage')}
      unverifiedInfo={t('unverifiedInfo')}
    />
  );
};

export default TikTokCommentTiktokTriggerConfigComponent;
