import type {OrganizationMemberADto} from '@cohort/admin-schemas/organizationMember';
import {OrganizationMemberASchema} from '@cohort/admin-schemas/organizationMember';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import {parseJwtToken} from '@cohort/merchants/lib/Parsers';

export async function getCurrentMember(idToken: string): Promise<OrganizationMemberADto> {
  return apiCall('GET', `/v1/auth/current-member`, {
    expect: HttpCodes.SUCCESS,
    parser: OrganizationMemberASchema.parse,
    idToken,
  });
}

export async function sendSigninOtp(
  email: string,
  turnstileToken: string,
  destinationPath?: string
): Promise<void> {
  return apiCall('POST', `/v1/auth/send-signin-otp`, {
    expect: HttpCodes.CREATED,
    parser: () => {},
    headers: {
      'x-turnstile-token': turnstileToken,
    },
    body: {
      email,
      destinationPath,
    },
  });
}

export async function verifySigninOtp(email: string, code: string): Promise<string> {
  return apiCall('POST', `/v1/auth/verify-signin-otp`, {
    expect: HttpCodes.CREATED,
    parser: parseJwtToken,
    body: {
      email,
      code,
    },
  });
}

export async function requestChangePassword(email: string, turnstileToken: string): Promise<void> {
  return apiCall('POST', `/v1/auth/request-change-password`, {
    expect: HttpCodes.CREATED,
    parser: () => {},
    headers: {
      'x-turnstile-token': turnstileToken,
    },
    body: {
      email,
    },
  });
}

export async function changePassword(
  email: string,
  code: string,
  password: string,
  turnstileToken: string
): Promise<void> {
  return apiCall('POST', `/v1/auth/change-password`, {
    expect: HttpCodes.CREATED,
    parser: () => {},
    headers: {
      'x-turnstile-token': turnstileToken,
    },
    body: {
      email,
      code,
      password,
    },
  });
}
