// i18nOwl-ignore [app-cohort.notificationIntegrations.email.title]
import type {NotificationIntegration} from '@cohort/merchants/apps';
import CohortEmailNotificationIntegrationConfigComponent from '@cohort/merchants/apps/cohort/notificationIntegrations/email/ConfigComponent';
import type {CohortEmailNotificationIntegrationStruct} from '@cohort/shared/apps/cohort/notifications/email';
import {CohortEmailNotificationIntegrationSpec} from '@cohort/shared/apps/cohort/notifications/email';

export const CohortEmailNotificationIntegration: NotificationIntegration<CohortEmailNotificationIntegrationStruct> =
  {
    spec: CohortEmailNotificationIntegrationSpec,
    configComponent: CohortEmailNotificationIntegrationConfigComponent,
  };
