import {generateMock} from '@anatine/zod-mock';
import type {DeepPartial} from '@cohort/shared/typeUtils';
import {mergeWith} from 'lodash';
import type {ZodNullable, ZodSchema, ZodTypeAny} from 'zod';
import {z} from 'zod';

// Generate a records of all fields in a Zod schema (not working for nested schemas)
export function getZodFlatSchemaFields(schema: ZodSchema): Record<string, true> {
  const fields: Record<string, true> = {};
  const proxy = new Proxy(fields, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    get(_, key) {
      if (key === 'then' || typeof key !== 'string') {
        return;
      }
      fields[key] = true;
    },
  });
  schema.safeParse(proxy);
  return fields;
}

export function getShapeFromSchema<T>(schema: ZodSchema<T>): z.ZodRawShape {
  // TODO: I think we should try to have apps define Zod Shapes instead of schemas to avoid doing that kind of stuff..
  if (schema instanceof z.ZodObject) {
    return schema.shape;
  }
  throw new Error('Invalid zod Schema type');
}

export function getPropertiesPathFromZodSchema(schema: ZodTypeAny, prefix = ''): string[] {
  if (schema.isNullable()) {
    schema = (schema as ZodNullable<ZodTypeAny>).unwrap();
  }

  if (schema instanceof z.ZodObject) {
    const shape = schema.shape;

    const paths = Object.keys(shape).flatMap(key => {
      const nestedPrefix = prefix ? `${prefix}.${key}` : key;

      return getPropertiesPathFromZodSchema(shape[key], nestedPrefix);
    });

    return prefix ? [prefix, ...paths] : paths;
  }
  return [prefix];
}

export function generateMockObjectFromSchema<T>(schema: z.ZodSchema, seedData?: DeepPartial<T>): T {
  const mock = generateMock(schema);

  return mergeWith({}, mock, seedData, (objValue, srcValue) => {
    if (Array.isArray(srcValue)) {
      return srcValue;
    }
    return undefined;
  });
}
