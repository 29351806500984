import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import type {TwitterFollowVerificationAttemptData} from '@cohort/shared/apps/twitter/triggers/follow';
import {Trans} from 'react-i18next';

const TWITTER_URL = 'https://x.com';

const TwitterFollowTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: TwitterFollowVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggerIntegrations.follow.stepCompletionTitleComponent.error"
        ns="app-twitter"
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const memberHandle = data.memberHandle!;

  return (
    <Trans
      i18nKey="triggerIntegrations.follow.stepCompletionTitleComponent.success"
      ns="app-twitter"
      values={{username: memberHandle}}
      components={{
        a: <StepCompletionLink url={`${TWITTER_URL}/${memberHandle}`} />,
      }}
    />
  );
};

export default TwitterFollowTriggerIntegrationStepCompletionTitleComponent;
