import {z} from 'zod';

export type TriggerIntegrationStruct<
  Id extends string = string,
  Config = unknown,
  VerificationAttemptData = unknown,
  VerificationAttemptInput = unknown,
> = {
  Id: Id;
  Config: Config;
  VerificationAttemptData: VerificationAttemptData;
  VerificationAttemptInput: VerificationAttemptInput;
};

export const UnverifiedTriggerBaseConfigSchema = z.object({
  url: z.string().url(),
});

const UnverifiedTriggerConfigSchema = UnverifiedTriggerBaseConfigSchema.passthrough();
type UnverifiedTriggerConfig = z.infer<typeof UnverifiedTriggerConfigSchema>;
export type UnverifiedTriggerStruct<
  Id extends string = string,
  Config extends UnverifiedTriggerConfig = UnverifiedTriggerConfig,
  VerificationAttemptData = unknown,
  VerificationAttemptInput = unknown,
> = {
  Id: Id;
  Config: Config;
  VerificationAttemptData: VerificationAttemptData;
  VerificationAttemptInput: VerificationAttemptInput;
};

export type TriggerIntegrationType = 'action' | 'verification';

export type TriggerIntegrationSpec<T extends TriggerIntegrationStruct = TriggerIntegrationStruct> =
  {
    id: T['Id'];
    type: TriggerIntegrationType;
    configurableMaxAttempts: boolean;
    configSchema: z.ZodSchema<T['Config']>;
    verificationAttemptDataSchema: z.ZodSchema<T['VerificationAttemptData']>;
    verificationAttemptInputSchema: z.ZodSchema<T['VerificationAttemptInput']>;
    requiresMerchantConnection: boolean;
  };

// Connect Account Trigger Integration

export const ConnectAccountConfigSchema = z.object({});
export type ConnectAccountConfig = z.infer<typeof ConnectAccountConfigSchema>;

export const ConnectAccountVerificationAttemptDataSchema = z.object({
  id: z.string().nullable(),
  displayName: z.string().nullable(),
});
export type ConnectAccountVerificationAttemptData = z.infer<
  typeof ConnectAccountVerificationAttemptDataSchema
>;
export type ConnectAccountTriggerIntegrationStruct<Id extends string> = TriggerIntegrationStruct<
  Id,
  ConnectAccountConfig,
  ConnectAccountVerificationAttemptData
>;

export function createConnectAccountTriggerIntegrationSpec<Id extends string>(
  appId: Id
): TriggerIntegrationSpec<ConnectAccountTriggerIntegrationStruct<`${Id}.connect-account`>> {
  return {
    id: `${appId}.connect-account`,
    type: 'verification',
    configurableMaxAttempts: false,
    configSchema: ConnectAccountConfigSchema,
    verificationAttemptDataSchema: ConnectAccountVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: false,
  };
}
