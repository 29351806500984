import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {Fragment} from 'react';

const DiscordPrivateAccessPerkIntegrationConfigComponent: React.FC<
  PerkIntegrationConfigComponentProps
> = () => {
  return <Fragment />;
};

export default DiscordPrivateAccessPerkIntegrationConfigComponent;
