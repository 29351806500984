import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type SpotifyIsFollowerUserPropertyStruct = UserPropertyStruct<
  'spotify.is-subscriber',
  boolean
>;

export const SpotifyIsFollowerUserPropertySpec: UserPropertySpec<SpotifyIsFollowerUserPropertyStruct> =
  {
    id: 'spotify.is-subscriber',
    name: 'Spotify Is Channel Follower',
    dataType: 'boolean',
    scope: 'merchant_connection',
  };
