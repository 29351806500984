import type {MediaCardProps} from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import TriggerMediaCardWrapper from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import type {YoutubeVideo} from '@cohort/shared/apps/youtube/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {YoutubeLogo} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import {Fragment} from 'react';

type YoutubeVideoCardProps = MediaCardProps<YoutubeVideo>;

const YoutubeVideoCard: React.FC<YoutubeVideoCardProps> = ({
  media: video,
  isSelected = false,
  onClick,
}) => (
  <TriggerMediaCardWrapper hasMedia={video !== undefined} onClick={onClick} isSelected={isSelected}>
    {!video ? (
      <YoutubeLogo size={24} weight="fill" className="text-slate-200" />
    ) : (
      <Fragment>
        <img
          src={video.thumbnailUrl}
          className={cn('h-[145px] w-full rounded-lg object-cover', isSelected && 'opacity-50')}
        />
        <div className="flex flex-grow flex-col justify-between gap-y-3">
          <div className="line-clamp-2 text-sm">{video.title}</div>

          <div className="text-xs font-medium text-slate-500">
            {dayjs(video.publishedAt).format('ll')}
          </div>
        </div>
      </Fragment>
    )}
  </TriggerMediaCardWrapper>
);

export default YoutubeVideoCard;
