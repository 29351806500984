/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {trackError} from '@cohort/merchants/lib/Tracking';

export const versionKeys = {
  version: ['version'] as const,
};

export const useAppVersion = (enabled?: boolean) =>
  useCohortQuery({
    queryKey: versionKeys.version,
    queryFn: async () => {
      const res = await fetch(`${import.meta.env.COHORT_ASSETS_URL}/version.json`, {
        mode: 'cors',
      });

      if (!res.ok) {
        // 404 is expected if it's the first upload
        if (res.status === 404) {
          return null;
        }
        trackError(new Error(`Failed to fetch version.json file`));
        return false;
      }
      try {
        const data = await res.json();

        return data.version ?? false;
      } catch (err: unknown) {
        void err;
        trackError(new Error(`Failed to parse version.json file`));
        return false;
      }
    },
    refetchInterval: 1000 * 60, // every minutes
    enabled,
  });
