// i18nOwl-ignore [app-cohort.perkIntegrations.form.title, app-cohort.perkIntegrations.form.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import CohortFormPerkIntegrationConfigComponent from '@cohort/merchants/apps/cohort/perkIntegrations/form/ConfigComponent';
import CohortFormPerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/cohort/perkIntegrations/form/UsageViewComponent';
import type {CohortFormPerkIntegrationStruct} from '@cohort/shared/apps/cohort/perks/form';
import {CohortFormPerkIntegrationSpec} from '@cohort/shared/apps/cohort/perks/form';

export const CohortFormPerkIntegration: PerkIntegration<CohortFormPerkIntegrationStruct> = {
  spec: CohortFormPerkIntegrationSpec,
  configComponent: CohortFormPerkIntegrationConfigComponent,
  usageViewComponent: CohortFormPerkIntegrationsUsageViewComponent,
  iconColor: 'text-primary',
  backgroundColor: 'bg-primary/10',
};
