import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useConnection} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import SkeletonLoader from '@cohort/merchants/layouts/SkeletonLoader';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentConnectionProviderProps = {
  children: React.ReactNode;
};

export const CurrentConnectionContext = createContext<ConnectionADto | null>(null);

export const CurrentConnectionProvider: React.FC<CurrentConnectionProviderProps> = ({children}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {appId} = useCurrentApp();
  const {connectionId} = useParams();

  const {data: connection, isFetched} = useConnection(
    merchantId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    connectionId!,
    {enabled: Boolean(connectionId), refetchOnWindowFocus: false}
  );

  if (connectionId === undefined) {
    return <NotFoundSection />;
  }

  if (isFetched && !connection) {
    return <NotFoundSection />;
  }

  if (connection && connection.appId !== appId) {
    return <NotFoundSection />;
  }

  if (connection === undefined) {
    return <SkeletonLoader />;
  }

  return (
    <CurrentConnectionContext.Provider value={connection}>
      {children}
    </CurrentConnectionContext.Provider>
  );
};
