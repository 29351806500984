// i18nOwl-ignore [app-klaviyo.description, app-klaviyo.sync.description, app-klaviyo.sync.title]
import type {App} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import translationEn from '@cohort/merchants/apps/klaviyo/locales/en.json';
import translationFr from '@cohort/merchants/apps/klaviyo/locales/fr.json';
import {KlaviyoSyncIntegration} from '@cohort/merchants/apps/klaviyo/sync/SyncIntegration';
import type {KlaviyoAppStruct} from '@cohort/shared/apps/klaviyo';
import {KlaviyoAppSpec} from '@cohort/shared/apps/klaviyo';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const KlaviyoApp: App<KlaviyoAppStruct> = {
  spec: KlaviyoAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('klaviyo'),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="klaviyo" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [],
  userEvents: [],
  sync: KlaviyoSyncIntegration,
};

export default KlaviyoApp;
