import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import type {CustomOAuthErrorMessage} from '@cohort/merchants/components/connections/OAuthEditConnectionComponent';
import {connectionsKeys, useOauthAuthorizationUrl} from '@cohort/merchants/hooks/api/Connections';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {notify} from '@cohort/merchants/hooks/toast';
import {getConnectionById} from '@cohort/merchants/lib/api/Connections';
import {getCookieValue, OAUTH_COOKIE_NAME, resetCookie} from '@cohort/merchants/lib/Cookies';
import type {OAuthErrorMessage, OAuthSuccessMessage} from '@cohort/merchants/lib/Messages';
import {useMessageListener} from '@cohort/merchants/lib/Messages';
import {getOauthRedirectUrl} from '@cohort/merchants/lib/Utils';
import type {ConnectorId} from '@cohort/shared/apps';
import type {AppSpec} from '@cohort/shared/apps/app';
import {useQueryClient} from '@tanstack/react-query';
import React, {Fragment, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const generateOauthPopupFeatures = (): string => {
  const props = {
    popup: 'yes',
    toolbar: 'no',
    menubar: 'no',
    width: 600,
    height: 700,
  };
  const {width, height} = props;
  const {outerHeight, outerWidth} = window;
  const top = Math.round(outerHeight / 2 - height / 2);
  const left = Math.round(outerWidth / 2 - width / 2);
  return Object.entries({top, left, ...props})
    .map(([k, v]) => `${k}=${v}`)
    .join(',');
};

type OAuthFlowHandlerProps = {
  appSpec: AppSpec;
  existingConnectionId?: string;
  preConfig?: Record<string, unknown>;
  onCompleted: (connection: ConnectionADto | null) => void;
  customOauthErrorMessage?: CustomOAuthErrorMessage;
  popup: React.MutableRefObject<WindowProxy | null>;
};

const OAuthFlowHandler: React.FC<OAuthFlowHandlerProps> = ({
  appSpec,
  existingConnectionId,
  preConfig,
  onCompleted,
  customOauthErrorMessage,
  popup,
}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();
  const {t} = useTranslation('components', {
    keyPrefix: 'connections.oauthFlowHandler',
  });

  const {
    data: authorizationUrlResponse,
    isError,
    error,
  } = useOauthAuthorizationUrl(merchant.id, {
    connectorId: appSpec.merchantConnector.id as ConnectorId,
    adminRedirectUrl: getOauthRedirectUrl(),
    existingConnectionId,
    context: preConfig,
  });

  useEffect(() => {
    if (!popup.current && authorizationUrlResponse) {
      popup.current = window.open(
        authorizationUrlResponse.authorizationUrl,
        '_blank',
        generateOauthPopupFeatures()
      );
    }
    if (isError) {
      notify('error', t('connectionError', {appName: appSpec.name}));
      onCompleted(null);
    }
  }, [appSpec.name, authorizationUrlResponse, error, isError, onCompleted, popup, t]);

  const handleOAuthError = useCallback(
    (message: OAuthErrorMessage) => {
      onCompleted(null);

      let errorMessage: string | undefined;

      if (message.cause && customOauthErrorMessage) {
        errorMessage = customOauthErrorMessage(message.cause);
      }

      switch (message.errorCode) {
        case 'oauth.access-denied':
          errorMessage = t('accessDeniedError', {appName: appSpec.name});
          break;
        case 'connector.insufficient-permissions':
          errorMessage = t('insufficientPermissionsError', {appName: appSpec.name});
          break;
        default:
          errorMessage = t('connectionError', {appName: appSpec.name});
          break;
      }

      notify('error', errorMessage);
    },
    [appSpec.name, customOauthErrorMessage, onCompleted, t]
  );

  const onMessageReceived = useCallback(
    async (message: OAuthSuccessMessage | OAuthErrorMessage): Promise<void> => {
      switch (message.code) {
        case 'oauth-success': {
          queryClient.invalidateQueries(connectionsKeys.list(merchant.id));
          if (existingConnectionId) {
            queryClient.invalidateQueries(
              connectionsKeys.getById(merchant.id, existingConnectionId)
            );
          }
          const connection = await getConnectionById(merchant.id, message.connectionId);
          onCompleted(connection);
          break;
        }
        case 'oauth-error': {
          handleOAuthError(message);
          break;
        }
      }
    },
    [existingConnectionId, handleOAuthError, merchant.id, onCompleted, queryClient]
  );

  useMessageListener(['oauth-success', 'oauth-error'], onMessageReceived);

  // Some OAuth flows (Twitter) require using a cookie to pass the result back to the main window
  const checkOauthResult = useCallback(() => {
    const oAuthMessage = getCookieValue(OAUTH_COOKIE_NAME);
    if (oAuthMessage === undefined) {
      return;
    }
    onMessageReceived(JSON.parse(oAuthMessage));
    resetCookie(OAUTH_COOKIE_NAME);
  }, [onMessageReceived]);

  useEffect(() => {
    const intervalId = setInterval(checkOauthResult, 1000); // Poll every second
    return () => clearInterval(intervalId);
  }, [checkOauthResult]);

  return <Fragment />;
};

export default OAuthFlowHandler;
