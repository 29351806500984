import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import type {ResourceListDataType} from '@cohort/shared/schema/common/userProperty';

export type CohortChallengesUserPropertyStruct = UserPropertyStruct<
  'cohort.challenges',
  ResourceListDataType
>;

export const CohortChallengesUserPropertySpec: UserPropertySpec<CohortChallengesUserPropertyStruct> =
  {
    id: 'cohort.challenges',
    name: 'Challenges',
    dataType: 'resource_list',
    scope: 'global',
    resource: 'cohort.challenge',
    defaultValue: [],
  };
