import type {ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const YoutubeUserProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  channelId: z.string().nullable(),
  channelHandle: z.string().nullable(),
  channelTitle: z.string().nullable(),
  subscribersCount: z.number().nullable(),
  videosCount: z.number().nullable(),
  viewsCount: z.number().nullable(),
  isVerified: z.boolean().nullable(),
  isMonetized: z.boolean().nullable(),
});
export type YoutubeUserProfileProperties = z.infer<typeof YoutubeUserProfilePropertiesSchema>;

export type YoutubeUserConnectorStruct = ConnectorStruct<
  'youtube',
  OAuth2AppCredentials,
  OAuth2Credentials,
  YoutubeUserProfileProperties,
  null
>;

export const YoutubeUserConnectorSpec = basicOauth2ConnectorSpec<YoutubeUserConnectorStruct>(
  'youtube',
  YoutubeUserProfilePropertiesSchema
);
