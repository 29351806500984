import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortChallengeCompletedEventStruct} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeCompleted';
import {CohortChallengeCompletedEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeCompleted';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const campaignId = (userEvent.properties as CohortChallengeCompletedEventStruct['Properties'])
    .campaignId;
  const campaignInternalName = userEvent.campaignInternalName;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.challenge.completed.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCampaignRoute(campaignId).path)
        ),
      }}
    />
  );
};

export const CohortChallengeCompletedUserEvent: UserEvent<CohortChallengeCompletedEventStruct> = {
  spec: CohortChallengeCompletedEventSpec,
  detailComponent: DetailComponent,
};
