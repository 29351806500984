// i18nOwl-ignore [app-cohort.perkIntegrations.qr-code.title, app-cohort.perkIntegrations.qr-code.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import CohortQrCodePerkIntegrationConfigComponent from '@cohort/merchants/apps/cohort/perkIntegrations/qrCode/ConfigComponent';
import CohortQrCodePerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/cohort/perkIntegrations/qrCode/UsageViewComponent';
import type {CohortQrCodePerkIntegrationStruct} from '@cohort/shared/apps/cohort/perks/qrCode';
import {CohortQrCodePerkIntegrationSpec} from '@cohort/shared/apps/cohort/perks/qrCode';

export const CohortQrCodePerkIntegration: PerkIntegration<CohortQrCodePerkIntegrationStruct> = {
  spec: CohortQrCodePerkIntegrationSpec,
  configComponent: CohortQrCodePerkIntegrationConfigComponent,
  usageViewComponent: CohortQrCodePerkIntegrationsUsageViewComponent,
  iconColor: 'text-cyan-500',
  backgroundColor: 'bg-cyan-100',
};
