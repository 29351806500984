import type {TooltipSides} from '@cohort/shared-frontend/components/Tooltip';
import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Info} from '@phosphor-icons/react';
type InformationTooltipProps = {
  content: JSX.Element | string;
  side?: TooltipSides;
  className?: string;
  disabled?: boolean;
};

export const InformationTooltip: React.FC<InformationTooltipProps> = ({
  content,
  side,
  className,
  disabled = false,
}) => {
  return (
    <Tooltip content={content} side={side} disabled={disabled}>
      <Info className={cn('h-5 w-5 cursor-pointer text-slate-400', className)} />
    </Tooltip>
  );
};
