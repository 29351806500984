import type {TikTokKeywordInDescriptionVerificationAttemptData} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {Fragment} from 'react';

const TikTokKeywordInDescriptionTriggerStepCompletionContextComponent: React.FC<{
  data: TikTokKeywordInDescriptionVerificationAttemptData;
}> = ({data}) => {
  const {embedLink} = data;

  if (embedLink === undefined) {
    return <Fragment />;
  }

  return (
    <div className="rounded-lg border border-slate-200 bg-slate-50 p-4">
      <iframe
        className="w-full rounded-xl"
        height="450"
        src={embedLink}
        title="TikTok post embed"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </div>
  );
};

export default TikTokKeywordInDescriptionTriggerStepCompletionContextComponent;
