import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {PaginationSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export type Parser<Res> = (data: Record<string, unknown>) => Res;

export function paginatedParser<T extends z.ZodTypeAny>(
  schema: T
): Parser<[PaginationDto, z.infer<T>[]]> {
  const parser = (data: Record<string, unknown>): [PaginationDto, z.infer<T>[]] => {
    return [PaginationSchema.parse(data.meta), z.array(schema).parse(data.data)];
  };
  return parser;
}
