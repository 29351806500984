import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {FacebookPageSchema} from '@cohort/shared/apps/instagram/common';
import {z} from 'zod';

export const ListConnectedPagesInputSchema = z.object({});
export type ListConnectedPagesInput = z.infer<typeof ListConnectedPagesInputSchema>;

export const ListConnectedPagesOutputSchema = z.object({
  pages: z.array(FacebookPageSchema),
});
export type ListConnectedPagesOutput = z.infer<typeof ListConnectedPagesOutputSchema>;

export type ListConnectedPagesActionStruct = ActionStruct<
  'list-connected-pages',
  ListConnectedPagesInput,
  ListConnectedPagesOutput
>;

export const ListConnectedPagesActionSpec: ActionSpec<ListConnectedPagesActionStruct> = {
  id: 'list-connected-pages',
  inputSchema: ListConnectedPagesInputSchema,
  outputSchema: ListConnectedPagesOutputSchema,
};
