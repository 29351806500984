import {cn} from '@cohort/shared-frontend/utils/classNames';
// eslint-disable-next-line import/no-namespace
import * as SwitchPrimitives from '@radix-ui/react-switch';
import React from 'react';

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  applyWalletStyle?: boolean;
}

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
  ({className, applyWalletStyle, ...props}, ref) => {
    return (
      <SwitchPrimitives.Root
        className={cn(
          'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-40',
          !applyWalletStyle && 'data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
          applyWalletStyle &&
            'data-[state=checked]:bg-[--xps-accent-color] data-[state=unchecked]:bg-[--xps-secondary-btn-background-color]',
          className
        )}
        {...props}
        ref={ref}
      >
        <SwitchPrimitives.Thumb className="pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0" />
      </SwitchPrimitives.Root>
    );
  }
);
Switch.displayName = SwitchPrimitives.Root.displayName;

export default Switch;
