import TestModeModal from '@cohort/merchants/components/test-mode/TestModeModal';
import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import {
  trackTestModeToggleClicked,
  trackTestModeToggleInfoButtonClicked,
} from '@cohort/merchants/lib/Tracking';
import Switch from '@cohort/shared-frontend/components/Switch';
import {Info} from '@phosphor-icons/react';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {matchPath, useLocation, useNavigate} from 'react-router-dom';

// When test mode is toggled, a redirection is needed for some routes with entity ids or routes
// not accessibles in test mode
const routesRedirectsOnTestModeToggle = [
  {
    path: '/users/:userId/*',
    redirectsTo: '/users',
  },
  {
    path: '/campaigns/:campaignId/*',
    redirectsTo: '/campaigns',
  },
  {
    path: '/digital-assets/:digitalAssetid/*',
    redirectsTo: '/digital-assets',
  },
  {
    path: '/perks/:perkId/*',
    redirectsTo: '/perks',
  },
  {
    path: '/settings/payments',
    redirectsTo: '/settings',
  },
  {
    path: '/settings/team',
    redirectsTo: '/settings',
  },
];

const TestModeToggle: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation('components', {keyPrefix: 'testMode.testModeToggle'});
  const testModeEnabled = useTestModeStore(store => store.testModeEnabled);
  const [openTestModeModal, setOpenTestModeModal] = useState(false);

  function generateRedirectPath(): string {
    // Check if the current path is one of the routes that has an entity id
    for (const route of routesRedirectsOnTestModeToggle) {
      const match = matchPath(route.path, location.pathname);
      const testMatch = matchPath(`/test${route.path}`, location.pathname);

      if (match || testMatch) {
        return match ? `/test${route.redirectsTo}` : route.redirectsTo;
      }
    }

    if (!testModeEnabled) {
      return `/test${location.pathname}`;
    }
    const liveModePath = location.pathname.replace('/test', '');

    return liveModePath;
  }

  return (
    <Fragment>
      <div className="flex items-center gap-2">
        <label htmlFor="test-mode" className="text-sm font-medium text-muted-foreground">
          {t('testMode')}
        </label>
        <Switch
          id="test-mode"
          checked={testModeEnabled}
          onCheckedChange={() => {
            const redirectPath = generateRedirectPath();

            trackTestModeToggleClicked();
            navigate(redirectPath);
          }}
        />
        <Info
          onClick={() => {
            trackTestModeToggleInfoButtonClicked();
            setOpenTestModeModal(true);
          }}
          className="inline-block h-5 w-5 cursor-pointer text-muted-foreground"
        />
      </div>
      <TestModeModal show={openTestModeModal} onClose={() => setOpenTestModeModal(false)} />
    </Fragment>
  );
};

export default TestModeToggle;
