import type {TurnstileContextProps} from '@cohort/shared-frontend/contexts/TurnstileContext';
import {TurnstileContext} from '@cohort/shared-frontend/contexts/TurnstileContext';
import {useContext} from 'react';

export const useTurnstile = (): TurnstileContextProps => {
  const context = useContext(TurnstileContext);

  if (context === null) {
    throw new Error('useTurnstile must be used within a TurnstileProvider');
  }
  return context;
};
