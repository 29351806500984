import type {MediaCardProps} from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import TriggerMediaCardWrapper from '@cohort/merchants/apps/common/triggers/TriggerMediaCardWrapper';
import type {TikTokVideo} from '@cohort/shared/apps/tiktok/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {ChatCircleDots, ShareFat, ThumbsUp, TiktokLogo} from '@phosphor-icons/react';
import dayjs from 'dayjs';

type TikTokVideoCardProps = MediaCardProps<TikTokVideo>;

const TikTokVideoCard: React.FC<TikTokVideoCardProps> = ({
  media: video,
  isSelected = false,
  onClick,
}) => (
  <TriggerMediaCardWrapper hasMedia={video !== undefined} onClick={onClick} isSelected={isSelected}>
    {!video ? (
      <div className="flex h-[120px] w-[120px] items-center justify-center">
        <TiktokLogo size={24} weight="fill" className="text-slate-200" />
      </div>
    ) : (
      <div className="flex gap-4">
        <div
          className={cn(
            'flex aspect-square h-[136px] w-[136px] flex-shrink-0 items-center justify-center overflow-hidden rounded-lg border border-slate-200 bg-slate-50 shadow-sm',
            isSelected && 'opacity-50'
          )}
        >
          <img src={video.coverImageUrl} alt="Video thumbnail" />
        </div>
        <div className="flex flex-grow flex-col justify-between gap-3">
          <div className="line-clamp-5 whitespace-pre-wrap text-sm font-normal">
            {video.videoDescription.length > 0 ? video.videoDescription : video.title}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex-grow text-xs font-medium text-slate-500">
              {dayjs(video.createdAt).format('ll')}
            </div>
            <div className="flex items-center gap-1 text-xs font-medium text-slate-500">
              <ThumbsUp size={16} />
              {video.likeCount}
            </div>
            <div className="flex items-center gap-1 text-xs font-medium text-slate-500">
              <ChatCircleDots size={16} />
              {video.commentCount}
            </div>
            <div className="flex items-center gap-1 text-xs font-medium text-slate-500">
              <ShareFat size={16} />
              {video.shareCount}
            </div>
          </div>
        </div>
      </div>
    )}
  </TriggerMediaCardWrapper>
);

export default TikTokVideoCard;
