// i18nOwl-ignore [app-typeform.perkIntegrations.form.title, app-typeform.perkIntegrations.form.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import TypeformFormPerkIntegrationConfigComponent from '@cohort/merchants/apps/typeform/perkIntegrations/form/ConfigComponent';
import TypeformFormPerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/typeform/perkIntegrations/form/UsageViewComponent';
import type {TypeformFormPerkIntegrationStruct} from '@cohort/shared/apps/typeform/perks/form';
import {TypeformFormPerkIntegrationSpec} from '@cohort/shared/apps/typeform/perks/form';

export const TypeformFormPerkIntegration: PerkIntegration<TypeformFormPerkIntegrationStruct> = {
  spec: TypeformFormPerkIntegrationSpec,
  configComponent: TypeformFormPerkIntegrationConfigComponent,
  usageViewComponent: TypeformFormPerkIntegrationsUsageViewComponent,
  iconColor: 'text-slate-500',
  backgroundColor: 'bg-slate-100',
};
