import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import Input from '@cohort/merchants/components/form/input/Input';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {GarminConnectTriggerIntegrationActivityGroupsType} from '@cohort/shared/apps/garmin-connect/activity';
import {
  GarminConnectTriggerIntegrationActivityGroups,
  getGarminConnectActivityGroupLabel,
} from '@cohort/shared/apps/garmin-connect/activity';
import React from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const GarminConnectActivitySelectPicker: React.FC<
  {
    fieldName: `triggerIntegrationConfig.${string}`;
    hasFieldName: `triggerIntegrationConfig.${string}`;
  } & TriggerIntegrationConfigComponentProps
> = ({fieldName, hasFieldName, formReturn: {control, register}}) => {
  const {defaultLanguage} = useCurrentMerchant();
  const {t} = useTranslation('components', {
    keyPrefix: 'triggerIntegrations',
  });

  const formattedAvailableActivityGroups = GarminConnectTriggerIntegrationActivityGroups.map(
    activityGroup => ({
      value: activityGroup,
      label: getGarminConnectActivityGroupLabel(activityGroup, defaultLanguage),
    })
  );

  const {field: hasActivityTypes} = useController({
    control,
    name: hasFieldName,
  });

  const {field: activityGroups, fieldState: activityGroupsState} = useController({
    control,
    name: fieldName,
  });

  return (
    <div className="flex flex-col gap-4">
      <Input
        name={hasFieldName}
        label={t('labelShowActivityTypes')}
        register={register}
        control={control}
        type="checkbox"
        labelPosition="right"
      />

      {hasActivityTypes.value && (
        <SelectPicker
          isMulti
          label={t('labelActivityType')}
          options={formattedAvailableActivityGroups}
          onChange={options => activityGroups.onChange(options.map(opt => opt.value))}
          value={activityGroups.value?.map(
            (activityGroup: GarminConnectTriggerIntegrationActivityGroupsType) => {
              return {
                value: activityGroup,
                label: getGarminConnectActivityGroupLabel(activityGroup, defaultLanguage),
              };
            }
          )}
          name={fieldName}
          error={activityGroupsState.error?.message}
        />
      )}
    </div>
  );
};

export default GarminConnectActivitySelectPicker;
