import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import Loader from '@cohort/merchants/components/Loader';
import {usePerks} from '@cohort/merchants/hooks/api/Perks';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useEffect} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const CohortPerkUsedTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {control, register}}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.perk-used.configComponent',
  });
  const {data: perksList, isFetched} = usePerks(merchant.id, {
    page: 1,
    pageSize: 100,
    orderBy: 'internalName',
  });
  const [, perks] = perksList ?? [];
  const {field: perkId} = useController({
    control,
    name: 'triggerIntegrationConfig.perkId',
  });

  useEffect(() => {
    if (perkId.value === undefined && isFetched && perksList && perksList[0].total > 0) {
      const [, perks] = perksList;

      perkId.onChange(perks[0]?.id);
    }
  }, [perksList, perkId, isFetched]);

  if (!isFetched) {
    return <Loader size={30} color="gray" />;
  }

  return (
    <SelectInput
      name="triggerIntegrationConfig.perkId"
      label={t('labelPerk')}
      register={register}
      control={control}
      options={
        perks?.map(({id, internalName}) => ({
          value: id,
          label: internalName,
        })) ?? []
      }
    />
  );
};

export default CohortPerkUsedTriggerIntegrationConfigComponent;
