import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import {capitalize} from '@cohort/merchants/lib/Utils';
import {GoogleFitBeActiveTriggerConfigMetricSchema} from '@cohort/shared/apps/google-fit/triggers/beActive';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const GoogleFitMetricSelectPicker: React.FC<TriggerIntegrationConfigComponentProps> = ({
  formReturn: {control},
}) => {
  const {t} = useTranslation('app-google-fit', {
    keyPrefix: 'triggerIntegrations.be-active.configComponent',
  });

  const availableMetrics = GoogleFitBeActiveTriggerConfigMetricSchema.options.map(metric => ({
    value: metric,
    label: capitalize(metric),
  }));
  const {field: metric, fieldState: metricState} = useController({
    control,
    name: 'triggerIntegrationConfig.metric',
  });

  return (
    <SelectPicker
      value={{value: metric.value, label: metric.value ? capitalize(metric.value) : null}}
      label={t('labelActivityMetric')}
      options={availableMetrics}
      onChange={option => metric.onChange(option?.value ?? '')}
      name="triggerIntegrationConfig.metric"
      error={metricState.error?.message}
    />
  );
};

export default GoogleFitMetricSelectPicker;
