import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

export const TwitterFollowTriggerConfigSchema = z.object({});
export type TwitterFollowTriggerConfig = z.infer<typeof TwitterFollowTriggerConfigSchema>;

export const TwitterFollowVerificationAttemptDataSchema = z.object({
  memberHandle: z.string().nullable(),
});
export type TwitterFollowVerificationAttemptData = z.infer<
  typeof TwitterFollowVerificationAttemptDataSchema
>;

export type TwitterFollowTriggerStruct = TriggerIntegrationStruct<
  'twitter.follow',
  TwitterFollowTriggerConfig,
  TwitterFollowVerificationAttemptData
>;
export const TwitterFollowTriggerIntegrationSpec: TriggerIntegrationSpec<TwitterFollowTriggerStruct> =
  {
    id: 'twitter.follow' as const,
    type: 'verification',
    configurableMaxAttempts: false,
    configSchema: TwitterFollowTriggerConfigSchema,
    verificationAttemptDataSchema: TwitterFollowVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
