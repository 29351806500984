import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import type {TalonOneCouponUsageData} from '@cohort/shared/apps/talon-one/perks/coupon';
import {useTranslation} from 'react-i18next';

const TalonOneCouponPerkIntegrationsUsageViewComponent: React.FC<PerkUsageViewComponentProps> = ({
  usage,
}) => {
  const {t} = useTranslation('app-talon-one', {
    keyPrefix: 'perkIntegrations.coupon.usageViewComponent',
  });
  const data = usage.data as TalonOneCouponUsageData;

  return (
    <PerkUsageLayout.Grid>
      <PerkUsageLayout.Row name={t('labelCouponId')} value={data.couponId} />
      <PerkUsageLayout.Row name={t('labelCouponCode')} value={data.couponCode} />
    </PerkUsageLayout.Grid>
  );
};

export default TalonOneCouponPerkIntegrationsUsageViewComponent;
