import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {InstagramMediaSchema} from '@cohort/shared/apps/instagram/common';
import {z} from 'zod';

export const ListMediasInputSchema = z.object({});
export type ListMediasInput = z.infer<typeof ListMediasInputSchema>;

export const ListMediasOutputSchema = z.object({
  medias: z.array(InstagramMediaSchema),
});
export type ListMediasOutput = z.infer<typeof ListMediasOutputSchema>;

export type ListMediasActionStruct = ActionStruct<'list-medias', ListMediasInput, ListMediasOutput>;
export const ListMediasActionSpec: ActionSpec<ListMediasActionStruct> = {
  id: 'list-medias',
  inputSchema: ListMediasInputSchema,
  outputSchema: ListMediasOutputSchema,
};
