import type {Language, LocalizationConfig, LocalizedString} from '@cohort/shared/schema/common';
import {LanguageSchema} from '@cohort/shared/schema/common';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {CohortError} from '@cohort/shared/schema/common/errors';

type LocalizedObject<T> = {
  [key: string]: T | undefined | null;
};

export function getLocalizedContent<T>(
  localizedObject: LocalizedObject<T>,
  localizationConfig: LocalizationConfig
): T | null {
  const desiredTranslation = localizedObject[localizationConfig.desiredLanguage];
  if (
    desiredTranslation !== undefined &&
    localizationConfig.supportedLanguages.includes(localizationConfig.desiredLanguage)
  ) {
    return desiredTranslation;
  }
  if (localizationConfig.fallbackLanguage !== undefined) {
    const fallbackTranslation = localizedObject[localizationConfig.fallbackLanguage];
    if (
      fallbackTranslation !== undefined &&
      localizationConfig.supportedLanguages.includes(localizationConfig.fallbackLanguage)
    ) {
      return fallbackTranslation;
    }
  }
  return null;
}

export function getLocalizedContentOrThrow<T>(
  localizedContent: LocalizedObject<T>,
  localizationConfig: LocalizationConfig
): T {
  const translation = getLocalizedContent(localizedContent, localizationConfig);
  if (translation === null) {
    throw new Error(
      `Translation not found for ${localizationConfig.desiredLanguage} or ${localizationConfig.fallbackLanguage}`
    );
  }
  return translation;
}

export function ensureLanguageIsSet(
  dictionnary: Array<LocalizedString> | LocalizedString,
  language: Language,
  customCohortError?: CohortErrorCode
): void {
  const localizedStrings = Array.isArray(dictionnary) ? dictionnary : [dictionnary];

  for (const localizedString of localizedStrings) {
    if (localizedString[language] === undefined || localizedString[language] === null) {
      throw new CohortError(customCohortError ?? 'generic.invalid-request', {
        language: language,
      });
    }
  }
}

export function buildLocalizationConfig(
  desiredLanguage: Language,
  supportedLanguages: Array<Language>,
  fallbackLanguage?: Language
): LocalizationConfig {
  return {desiredLanguage, supportedLanguages, fallbackLanguage};
}

export function loadLocalizedStringContent<T>(
  localizedContent: LocalizedObject<T>,
  neededLanguages: Language[]
): LocalizedObject<T> {
  const localizedDictionary: LocalizedObject<T> = {};
  for (const language of neededLanguages) {
    localizedDictionary[language] = (localizedContent[language] ?? null) as T;
  }
  return localizedDictionary;
}

export function deleteNullLocales<T>(localizedContent: LocalizedObject<T>): LocalizedObject<T> {
  const updatedContent: LocalizedObject<T> = {};
  for (const language in localizedContent) {
    if (localizedContent[language] !== null) {
      updatedContent[language] = localizedContent[language];
    }
  }
  return updatedContent;
}

export function formatI18nLanguage(language: string): Language {
  const normalizedLanguage = LanguageSchema.safeParse(language.toLowerCase().slice(0, 2));
  if (normalizedLanguage.success) {
    return normalizedLanguage.data;
  }
  return 'en';
}
