import type {ActionSpec, AppSpec, AppStruct, PerkIntegrationSpec} from '@cohort/shared/apps/app';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';
import {GetFormActionSpec} from '@cohort/shared/apps/typeform/actions/getForm';
import {ListFormsActionSpec} from '@cohort/shared/apps/typeform/actions/listForms';
import {ListWorkspacesActionSpec} from '@cohort/shared/apps/typeform/actions/listWorkspaces';
import type {TypeformMerchantConnectorStruct} from '@cohort/shared/apps/typeform/merchantConnector';
import {TypeformMerchantConnectorSpec} from '@cohort/shared/apps/typeform/merchantConnector';
import {TypeformFormPerkIntegrationSpec} from '@cohort/shared/apps/typeform/perks/form';
import {TypeformFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/fillForm';
import {TypeformTakeQuizTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/takeQuiz';

export const TYPEFORM_APP_ID = 'typeform' as const;

export const TypeformTriggerIntegrationSpecs = [
  TypeformFillFormTriggerIntegrationSpec,
  TypeformTakeQuizTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type TypeformTriggerIntegrationIds = (typeof TypeformTriggerIntegrationSpecs)[number]['id'];

export const TypeformActionSpecs = [
  GetFormActionSpec,
  ListFormsActionSpec,
  ListWorkspacesActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type TypeformActionIds = (typeof TypeformActionSpecs)[number]['id'];

export const TypeformPerkIntegrationSpecs = [
  TypeformFormPerkIntegrationSpec,
] as const satisfies ReadonlyArray<PerkIntegrationSpec>;
type TypeformPerkIntegrationIds = (typeof TypeformPerkIntegrationSpecs)[number]['id'];

export type TypeformAppStruct = AppStruct<
  'typeform',
  TypeformMerchantConnectorStruct,
  null,
  null,
  TypeformActionIds,
  never,
  TypeformPerkIntegrationIds,
  TypeformTriggerIntegrationIds,
  never,
  never,
  never,
  never
>;

export const TypeformAppSpec: AppSpec<TypeformAppStruct> = {
  id: TYPEFORM_APP_ID,
  name: 'Typeform',
  merchantConnector: TypeformMerchantConnectorSpec,
  userConnector: null,
  syncSpec: null,
  actionSpecs: TypeformActionSpecs,
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: TypeformPerkIntegrationSpecs,
  triggerIntegrationSpecs: TypeformTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
