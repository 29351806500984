import type {
  DigitalAssetAnalyticsADto,
  GetDigitalAssetsParamsADto,
} from '@cohort/admin-schemas/digitalAsset';
import {DigitalAssetAnalyticsASchema} from '@cohort/admin-schemas/digitalAsset';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getDigitalAsset(
  merchantId: string,
  digitalAssetId: string
): Promise<DigitalAssetAnalyticsADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/digital-assets/${digitalAssetId}`, {
    expect: HttpCodes.SUCCESS,
    parser: DigitalAssetAnalyticsASchema.parse,
  });
}

export async function getDigitalAssets(
  merchantId: string,
  params: GetDigitalAssetsParamsADto
): Promise<[PaginationDto, Array<DigitalAssetAnalyticsADto>]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/digital-assets/`, {
    expect: HttpCodes.SUCCESS,
    params,
    parser: paginatedParser(DigitalAssetAnalyticsASchema),
  });
}

export async function exportDigitalAssets(
  merchantId: string,
  digitalAssetCollectionId: string
): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/digital-assets/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
    params: {
      digitalAssetCollectionId,
      format: 'csv',
    },
  });
}
