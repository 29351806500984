import type {Language, LocalizedString} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const GOOGLE_FIT_UNKNOWN_ACTIVITY_TYPE = 4;

export const GoogleFitTriggerIntegrationActivityGroups = [
  'RUNNING',
  'CYCLING',
  'WALKING',
  'SWIMMING',
] as const;
export type GoogleFitIntegrationActivityGroupsType =
  (typeof GoogleFitTriggerIntegrationActivityGroups)[number];

export const GoogleFitActivityGroupsSchema = z.enum(GoogleFitTriggerIntegrationActivityGroups);
export type GoogleFitActivityGroupsType = z.infer<typeof GoogleFitActivityGroupsSchema>;

export const GoogleFitActivityTypeSchema = z.number();
export type GoogleFitActivityType = z.infer<typeof GoogleFitActivityTypeSchema>;

export const GoogleFitIntegrationActivityTypesMappedByGroups: Record<
  GoogleFitIntegrationActivityGroupsType,
  GoogleFitActivityType[]
> = {
  RUNNING: [8, 56, 57, 58, 88, 98],
  CYCLING: [1, 14, 15, 16, 17, 18, 19, 25],
  WALKING: [7, 75, 88, 93, 94, 95, 98, 116],
  SWIMMING: [82, 83, 84],
};

export const GoogleFitActivityGroupsTranslations: Record<
  GoogleFitIntegrationActivityGroupsType,
  LocalizedString
> = {
  RUNNING: {en: 'Running', fr: 'Course à pied', es: 'Correr'},
  CYCLING: {en: 'Cycling', fr: 'Cyclisme', es: 'Ciclismo'},
  WALKING: {en: 'Walking', fr: 'Marche à pied', es: 'Caminata'},
  SWIMMING: {en: 'Swimming', fr: 'Natation', es: 'Natación'},
};

export const GoogleFitActivityTypesTranslation: Record<GoogleFitActivityType, LocalizedString> = {
  9: {en: 'Aerobics', fr: 'Aérobic', es: 'Aeróbica'},
  119: {en: 'Archery', fr: "Tir à l'arc", es: 'Tiro con arco'},
  10: {en: 'Badminton', fr: 'Badminton', es: 'Bádminton'},
  11: {en: 'Baseball', fr: 'Base-ball', es: 'Béisbol'},
  12: {en: 'Basketball', fr: 'Basket-ball', es: 'Básquetbol'},
  13: {en: 'Biathlon', fr: 'Biathlon', es: 'Biatlón'},
  1: {en: 'Biking', fr: 'Vélo', es: 'Bicicleta'},
  14: {en: 'Handbiking', fr: 'Vélo', es: 'Ciclismo manual'},
  15: {en: 'Mountain biking', fr: 'Vélo tout terrain', es: 'Ciclismo de montaña'},
  16: {en: 'Road biking', fr: 'Vélo de route', es: 'Ciclismo en carretera'},
  17: {en: 'Spinning', fr: 'Spinning', es: 'Hilado'},
  18: {en: 'Stationary biking', fr: 'Vélo stationnaire', es: 'Bicicleta fija'},
  19: {en: 'Utility biking', fr: 'Vélo utilitaire', es: 'Ciclismo urbano'},
  20: {en: 'Boxing', fr: 'Boxe', es: 'Boxeo'},
  21: {en: 'Calisthenics', fr: 'Gymnastique suédoise', es: 'Calistenia'},
  22: {en: 'Circuit training', fr: 'Circuit training', es: 'Circuito de entrenamiento'},
  23: {en: 'Cricket', fr: 'Cricket', es: 'Cricket'},
  113: {en: 'Crossfit', fr: 'CrossFit', es: 'CrossFit'},
  106: {en: 'Curling', fr: 'Curling', es: 'Curling'},
  24: {en: 'Dancing', fr: 'Danse', es: 'Baile'},
  102: {en: 'Diving', fr: 'Plongée', es: 'Buceo'},
  117: {en: 'Elevator', fr: 'Ascenseur', es: 'Ascensor'},
  25: {en: 'Elliptical', fr: 'Vélo elliptique', es: 'Elíptico'},
  103: {en: 'Ergometer', fr: 'Ergomètre', es: 'Ergómetro'},
  118: {en: 'Escalator', fr: 'Escalier roulant', es: 'Escalera mecánica'},
  26: {en: 'Fencing', fr: 'Escrime', es: 'Esgrima'},
  27: {en: 'Football (American)', fr: 'Football américain', es: 'Fútbol americano'},
  28: {en: 'Football (Australian)', fr: 'Football australien', es: 'Fútbol (Australia)'},
  29: {en: 'Football (Soccer)', fr: 'Football', es: 'Fútbol'},
  30: {en: 'Frisbee', fr: 'Frisbee', es: 'Frisbee'},
  31: {en: 'Gardening', fr: 'Jardinage', es: 'Jardinería'},
  32: {en: 'Golf', fr: 'Golf', es: 'Golf'},
  122: {en: 'Guided Breathing', fr: 'Respiration guidée', es: 'Respiración guiada'},
  33: {en: 'Gymnastics', fr: 'Gymnastique', es: 'Gimnasia'},
  34: {en: 'Handball', fr: 'Handball', es: 'Balonmano'},
  114: {en: 'HIIT', fr: 'HIIT', es: 'HIIT'},
  35: {en: 'Hiking', fr: 'Randonnée', es: 'Hacer senderismo'},
  36: {en: 'Hockey', fr: 'Hockey', es: 'Hockey'},
  37: {en: 'Horseback riding', fr: 'À cheval', es: 'Equitación'},
  38: {en: 'Housework', fr: 'Ménage', es: 'Tareas del hogar'},
  104: {en: 'Ice skating', fr: 'Patin à glace', es: 'Patinaje sobre hielo'},
  0: {en: 'In vehicle', fr: 'Dans un véhicule', es: 'En un vehículo'},
  115: {
    en: 'Interval Training',
    fr: 'Entraînement fractionné',
    es: 'Entrenamiento por intervalos',
  },
  39: {en: 'Jumping rope', fr: 'Corde à sauter', es: 'Saltar la cuerda'},
  40: {en: 'Kayaking', fr: 'Canoë-kayak', es: 'Kayakismo'},
  41: {
    en: 'Kettlebell training',
    fr: 'Entraînement avec kettlebell',
    es: 'Entrenamiento con pesas rusas',
  },
  42: {en: 'Kickboxing', fr: 'Kick-boxing', es: 'Kickboxing'},
  43: {en: 'Kitesurfing', fr: 'Kitesurf', es: 'Kitesurf'},
  44: {en: 'Martial arts', fr: 'Arts martiaux', es: 'Artes marciales'},
  45: {en: 'Meditation', fr: 'Méditation', es: 'Meditación'},
  46: {en: 'Mixed martial arts', fr: 'Arts martiaux mixtes', es: 'Artes marciales mixtas'},
  108: {
    en: 'Other (unclassified fitness activity)',
    fr: 'Autre (activité de remise en forme non classée)',
    es: 'Otro (actividad de entrenamiento no clasificada)',
  },
  47: {en: 'P90X exercises', fr: 'Exercices P90X', es: 'Ejercicios de P90X'},
  48: {en: 'Paragliding', fr: 'Parapente', es: 'Parapente'},
  49: {en: 'Pilates', fr: 'Pilates', es: 'Pilates'},
  50: {en: 'Polo', fr: 'Polo', es: 'Polo'},
  51: {en: 'Racquetball', fr: 'Racketball', es: 'Ráquetbol'},
  52: {en: 'Rock climbing', fr: 'Escalade', es: 'Escalada'},
  53: {en: 'Rowing', fr: 'Aviron', es: 'Remo'},
  54: {en: 'Rowing machine', fr: 'Rameur', es: 'Máquina de remo'},
  55: {en: 'Rugby', fr: 'Rugby', es: 'Rugby'},
  8: {en: 'Running', fr: 'Running', es: 'Activo'},
  56: {en: 'Jogging', fr: 'Jogging', es: 'Trote'},
  57: {en: 'Running on sand', fr: 'Course sur sable', es: 'Correr en la arena'},
  58: {en: 'Running (treadmill)', fr: 'Course à pied (tapis de course)', es: 'Correr (correr)'},
  59: {en: 'Sailing', fr: 'Voile', es: 'Navegar'},
  60: {en: 'Scuba diving', fr: 'Plongée sous-marine', es: 'Buceo'},
  61: {en: 'Skateboarding', fr: 'Skateboard', es: 'Patineta'},
  62: {en: 'Skating', fr: 'Patinage', es: 'Patinaje'},
  63: {en: 'Cross skating', fr: 'Cross-skating', es: 'Patinaje nórdico'},
  105: {en: 'Indoor skating', fr: 'Patinage en salle', es: 'Patinaje en pista cubierta'},
  64: {
    en: 'Inline skating (rollerblading)',
    fr: 'Roller en ligne',
    es: 'Patinaje en línea (patinaje)',
  },
  65: {en: 'Skiing', fr: 'Ski', es: 'Esquí'},
  66: {en: 'Back-country skiing', fr: 'Ski de fond', es: 'Esquí de fondo'},
  67: {en: 'Cross-country skiing', fr: 'Ski de fond', es: 'Esquí de fondo'},
  68: {en: 'Downhill skiing', fr: 'Ski alpin', es: 'Esquí cuesta abajo'},
  69: {en: 'Kite skiing', fr: 'Snowkite', es: 'Kite esquí'},
  70: {en: 'Roller skiing', fr: 'Ski à roulettes', es: 'Skiroll'},
  71: {en: 'Sledding', fr: 'Luge', es: 'Carrera en trineo'},
  73: {en: 'Snowboarding', fr: 'Snowboard', es: 'Snowboarding'},
  74: {en: 'Snowmobile', fr: 'Motoneige', es: 'Moto de nieve'},
  75: {en: 'Snowshoeing', fr: 'Randonnée en raquettes', es: 'Caminata con raquetas para nieve'},
  120: {en: 'Softball', fr: 'Softball', es: 'Sóftbol'},
  76: {en: 'Squash', fr: 'Squash', es: 'Squash'},
  77: {en: 'Stair climbing', fr: 'Escaliers', es: 'Subir escaleras'},
  78: {
    en: 'Stair-climbing machine',
    fr: "Machine de montée d'escaliers",
    es: 'Máquina escaladora',
  },
  79: {en: 'Stand-up paddleboarding', fr: 'Stand up paddle', es: 'Surf de remo'},
  3: {en: 'Still (not moving)', fr: 'Toujours (immobile)', es: 'Todavía (no se mueve)'},
  80: {en: 'Strength training', fr: 'Musculation', es: 'Entrenamiento de fuerza'},
  81: {en: 'Surfing', fr: 'Surf', es: 'Surf'},
  82: {en: 'Swimming', fr: 'Natation', es: 'Natación'},
  84: {en: 'Swimming (open water)', fr: 'Natation (eau libre)', es: 'Natación (agua abierta)'},
  83: {en: 'Swimming (swimming pool)', fr: 'Natation (piscine)', es: 'Natación (piscina)'},
  85: {
    en: 'Table tennis (ping pong)',
    fr: 'Tables de ping-pong',
    es: 'Tenis de mesa (ping pong)',
  },
  86: {en: 'Team sports', fr: "Sports d'équipe", es: 'Deportes en equipo'},
  87: {en: 'Tennis', fr: 'Tennis', es: 'Tenis'},
  5: {
    en: 'Tilting (sudden device gravity change)',
    fr: 'Inclinaison (changement de gravité des appareils soudains)',
    es: 'Inclinación (cambio repentino de gravedad del dispositivo)',
  },
  88: {
    en: 'Treadmill (walking or running)',
    fr: 'Tapis de course (marche ou course)',
    es: 'Correr en cinta (caminar o correr)',
  },
  4: {
    en: 'Unknown (unable to detect activity)',
    fr: "Inconnu (impossible de détecter l'activité)",
    es: 'Desconocido (no se puede detectar la actividad)',
  },
  89: {en: 'Volleyball', fr: 'Volley-ball', es: 'Voleibol'},
  90: {en: 'Volleyball (beach)', fr: 'Volley-ball (plage)', es: 'Voleibol (playa)'},
  91: {
    en: 'Volleyball (indoor)',
    fr: 'Volley-ball (intérieur)',
    es: 'Voleibol (para interiores)',
  },
  92: {en: 'Wakeboarding', fr: 'Wakeboard', es: 'Wakeboarding'},
  7: {en: 'Walking', fr: 'Marche', es: 'A pie'},
  93: {en: 'Walking (fitness)', fr: 'Marche (fitness)', es: 'A pie (deporte)'},
  94: {en: 'Nording walking', fr: 'Marche nordique', es: 'Caminata hacia el norte'},
  95: {en: 'Walking (treadmill)', fr: 'À pied (tapis de course)', es: 'Caminar (correr)'},
  116: {en: 'Walking (stroller)', fr: 'À pied (poussette)', es: 'A pie (stroller)'},
  96: {en: 'Waterpolo', fr: 'Waterpolo', es: 'Waterpolo'},
  97: {en: 'Weightlifting', fr: 'Haltérophilie', es: 'Levantamiento de pesas'},
  98: {en: 'Wheelchair', fr: 'Fauteuil roulant', es: 'Silla de ruedas'},
  99: {en: 'Windsurfing', fr: 'Planche à voile', es: 'Windsurf'},
  100: {en: 'Yoga', fr: 'Yoga', es: 'Yoga'},
  101: {en: 'Zumba', fr: 'Zumba', es: 'Zumba'},
};

export function getGoogleFitActivityGroupLabel(
  activityGroup: GoogleFitIntegrationActivityGroupsType,
  language: Language
): string {
  return (
    GoogleFitActivityGroupsTranslations[activityGroup][language] ??
    GoogleFitActivityGroupsTranslations[activityGroup].en ??
    'Unknown'
  );
}
export function getGoogleFitActivityTypeLabel(
  activityType: GoogleFitActivityType,
  language: Language
): string {
  return (
    GoogleFitActivityTypesTranslation[activityType]?.[language] ??
    GoogleFitActivityTypesTranslation[activityType]?.en ??
    'Unknown'
  );
}

export const GoogleFitActivitySchema = z.object({
  id: z.string(),
  distanceInMeters: z.number(),
  durationInSeconds: z.number(),
  startDate: z.date(),
  steps: z.number(),
  speedInMetersPerSecond: z.number(),
  type: GoogleFitActivityTypeSchema,
});

export type GoogleFitActivity = z.infer<typeof GoogleFitActivitySchema>;
