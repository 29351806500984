// i18nOwl-ignore [app-instagram.perkIntegrations.close-friends-list.title, app-instagram.perkIntegrations.close-friends-list.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import InstagramCloseFriendsListPerkIntegrationConfigComponent from '@cohort/merchants/apps/instagram/perkIntegrations/closeFriendsList/ConfigComponent';
import InstagramCloseFriendsListPerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/instagram/perkIntegrations/closeFriendsList/UsageViewComponent';
import type {InstagramCloseFriendsListPerkIntegrationStruct} from '@cohort/shared/apps/instagram/perks/closeFriendsList';
import {InstagramCloseFriendsListPerkIntegrationSpec} from '@cohort/shared/apps/instagram/perks/closeFriendsList';

export const InstagramCloseFriendsListPerkIntegration: PerkIntegration<InstagramCloseFriendsListPerkIntegrationStruct> =
  {
    spec: InstagramCloseFriendsListPerkIntegrationSpec,
    configComponent: InstagramCloseFriendsListPerkIntegrationConfigComponent,
    usageViewComponent: InstagramCloseFriendsListPerkIntegrationsUsageViewComponent,
    iconColor: 'text-purple-500',
    backgroundColor: 'bg-purple-100',
  };
