import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type FacebookHasLikedPageUserPropertyStruct = UserPropertyStruct<
  'facebook.has-liked-page',
  boolean
>;

export const FacebookHasLikedPageUserPropertySpec: UserPropertySpec<FacebookHasLikedPageUserPropertyStruct> =
  {
    id: 'facebook.has-liked-page',
    name: 'Facebook Has Liked Page',
    dataType: 'boolean',
    scope: 'merchant_connection',
  };
