// i18nOwl-ignore [app-typeform.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import {makeOauthEditConnectionComponent} from '@cohort/merchants/apps/appUtils';
import SetupConnectionWorkspace from '@cohort/merchants/apps/typeform/connector/SetupConnectionWorkspace';
import translationEn from '@cohort/merchants/apps/typeform/locales/en.json';
import translationFr from '@cohort/merchants/apps/typeform/locales/fr.json';
import {TypeformFormPerkIntegration} from '@cohort/merchants/apps/typeform/perkIntegrations/form/PerkIntegration';
import {TypeformFillFormTriggerIntegration} from '@cohort/merchants/apps/typeform/triggerIntegrations/fillForm/TriggerIntegration';
import {TypeformTakeQuizTriggerIntegration} from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/TriggerIntegration';
import type {TypeformAppStruct} from '@cohort/shared/apps/typeform';
import {TypeformAppSpec} from '@cohort/shared/apps/typeform';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const TypeformApp: App<TypeformAppStruct> = {
  spec: TypeformAppSpec,
  ConnectionEditComponent: makeOauthEditConnectionComponent('typeform', {
    postConnectionConfigComponent: SetupConnectionWorkspace,
  }),
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="typeform" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [TypeformFormPerkIntegration],
  triggerIntegrations: [
    TypeformFillFormTriggerIntegration,
    TypeformTakeQuizTriggerIntegration,
  ] as TriggerIntegration[],
  userEvents: [],
};

export default TypeformApp;
