import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const SpotifyProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  followersCount: z.number(),
  name: z.string(),
  country: z.string(),
  subscription: z.string(),
  profileUri: z.string(),
  profileUrl: z.string().url(),
});
export type SpotifyProfileProperties = z.infer<typeof SpotifyProfilePropertiesSchema>;

export const SpotifyPlaylistSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  public: z.boolean(),
  url: z.string().url(),
  uri: z.string(),
  thumbnailUrl: z.string().nullable(),
  theme: z.string().optional(),
  tracksCount: z.number(),
});
export type SpotifyPlaylist = z.infer<typeof SpotifyPlaylistSchema>;
