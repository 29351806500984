import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useTypeformForms} from '@cohort/merchants/apps/typeform/api';
import ErrorState from '@cohort/merchants/components/ErrorState';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const TypeformFormPerkIntegrationConfigComponent: React.FC<PerkIntegrationConfigComponentProps> = ({
  connectionId,
}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-typeform', {keyPrefix: 'perkIntegrations.form.configComponent'});
  const {register, control} = useCohortForm<PerkFormValues>();

  const {
    data: typeformFormsRes,
    isLoading: isTypeformFormsLoading,
    isError: isTypeformFormsError,
    isFetched: isTypeformFormsFetched,
  } = useTypeformForms(merchant.id, connectionId);

  const typeformForms = typeformFormsRes?.output.forms ?? [];
  const sortedTypeformForms = typeformForms.sort(formA => (formA.settings.is_public ? -1 : 1));

  return match({
    typeformForms,
    isTypeformFormsLoading,
    isTypeformFormsError,
    isTypeformFormsFetched,
  })
    .with({isTypeformFormsLoading: true}, () => <Loader size={30} color="gray" />)
    .with({isTypeformFormsError: true}, () => <ErrorState />)
    .with({typeformForms: []}, () => <p>{t('noFormsMessage')}</p>)
    .with({isTypeformFormsFetched: true}, () => (
      <SelectInput
        labelPosition="top"
        label={t('labelFormSelect')}
        description={t('descriptionFormSelect')}
        options={sortedTypeformForms.map(
          form =>
            ({
              value: form.id,
              label: form.settings.is_public
                ? form.title
                : t('privateFormLabel', {title: form.title}),
              isDisabled: !form.settings.is_public,
            }) satisfies SelectOption
        )}
        name="integration.config.formId"
        register={register}
        control={control}
      />
    ))
    .otherwise(() => <ErrorState />);
};

export default TypeformFormPerkIntegrationConfigComponent;
