import type {BaseProfileProperties, ConnectorSpec, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials} from '@cohort/shared/apps/oauth';
import {OAuth2AppCredentialsSchema, OAuth2CredentialsSchema} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const SHOPIFY_MERCHANT_CONNECTOR_ID = 'shopify' as const;

export const ShopifyShopIdSchema = z
  .string()
  .regex(/^[a-zA-Z0-9][a-zA-Z0-9-]*.myshopify.com$/u, 'errorValue');

export const ShopifyShopIdShortSchema = ShopifyShopIdSchema.transform(value =>
  value.replace('.myshopify.com', '')
);

export const ShopifyAppCredentialsSchema = OAuth2CredentialsSchema.extend({
  shopId: ShopifyShopIdSchema,
});
export type ShopifyAppCredentials = z.infer<typeof ShopifyAppCredentialsSchema>;

export type ShopifyMerchantConnectorStruct = ConnectorStruct<
  'shopify',
  OAuth2AppCredentials,
  ShopifyAppCredentials,
  BaseProfileProperties,
  null
>;

export const ShopifyMerchantConnectorSpec = {
  id: SHOPIFY_MERCHANT_CONNECTOR_ID,
  type: 'oauth2',
  appCredentialsSchema: OAuth2AppCredentialsSchema,
  credentialsSchema: ShopifyAppCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<ShopifyMerchantConnectorStruct>;
