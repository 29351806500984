import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortChallengeOptedInEventStruct} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeOptedIn';
import {CohortChallengeOptedInEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/challengeOptedIn';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const campaignId = (userEvent.properties as CohortChallengeOptedInEventStruct['Properties'])
    .campaignId;
  const campaignInternalName = userEvent.campaignInternalName;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.challenge.opted-in.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCampaignRoute(campaignId).path)
        ),
      }}
    />
  );
};

export const CohortChallengeOptedInUserEvent: UserEvent<CohortChallengeOptedInEventStruct> = {
  spec: CohortChallengeOptedInEventSpec,
  detailComponent: DetailComponent,
};
