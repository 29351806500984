import {DatetimeSchema} from '@cohort/shared/schema/common';
import {ApiKeyPermissionSchema} from '@cohort/shared/schema/common/apiKeyPermissions';
import {z} from 'zod';

export const ApiKeyASchema = z.object({
  id: z.string().uuid(),
  createdAt: DatetimeSchema,
  name: z.string(),
  redacted: z.string(),
  permissions: z.array(ApiKeyPermissionSchema),
});
export type ApiKeyADto = z.infer<typeof ApiKeyASchema>;

// CreateApiKey
export const CreateApiKeyDataASchema = z.object({
  name: z.string().min(3).max(100),
  permissions: z.array(ApiKeyPermissionSchema),
});
export type CreateApiKeyDataADto = z.infer<typeof CreateApiKeyDataASchema>;

export const CreateApiKeyResponseASchema = ApiKeyASchema.extend({
  plaintextKey: z.string(),
});
export type CreateApiKeyResponseADto = z.infer<typeof CreateApiKeyResponseASchema>;

// UdateApiKey
export const UpdateApiKeyDataASchema = z.object({
  name: z.string().min(3).max(100),
  permissions: z.array(ApiKeyPermissionSchema),
});
export type UpdateApiKeyDataADto = z.infer<typeof UpdateApiKeyDataASchema>;

export const UpdateApiKeyResponseASchema = ApiKeyASchema;
export type UpdateApiKeyResponseADto = z.infer<typeof UpdateApiKeyResponseASchema>;
