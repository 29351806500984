import Spinner from '@cohort/shared-frontend/components/Spinner';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {isEmptyFileList} from '@cohort/shared-frontend/utils/isFile';
import {Play} from '@phosphor-icons/react';
import {useRef, useState} from 'react';
import {isDefined} from 'remeda';

type VideoPlayerOverlayProps = {
  handlePlayPause: () => void;
  thumbnailSrc?: string | null;
};

const VideoPlayerOverlay: React.FC<VideoPlayerOverlayProps> = ({handlePlayPause, thumbnailSrc}) => {
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(isDefined(thumbnailSrc));

  return (
    <div
      className="absolute inset-0 flex cursor-pointer items-center justify-center rounded-lg"
      onClick={handlePlayPause}
    >
      {isDefined(thumbnailSrc) && (
        <img
          src={thumbnailSrc}
          alt="Custom Thumbnail"
          className="h-full w-full rounded-lg object-cover brightness-50"
          onLoad={() => setIsThumbnailLoading(false)}
        />
      )}
      <div className="absolute inset-0 flex items-center justify-center">
        {isThumbnailLoading ? (
          <Spinner size={26} />
        ) : (
          <div className="flex size-10 items-center justify-center rounded-full bg-white/30">
            <Play size={16} weight="fill" className="text-white" />
          </div>
        )}
      </div>
    </div>
  );
};

type VideoPlayerProps = {
  aspectRatio?: 'aspect-video' | 'aspect-square' | 'aspect-auto';
  autoPlay?: boolean;
  layout?: 'row' | 'column';
  showControls?: boolean;
  style?: React.CSSProperties;
  thumbnailSrc?: string | null;
  videoSrc: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  aspectRatio = 'aspect-auto',
  autoPlay = false,
  layout = 'row',
  showControls = true,
  style,
  thumbnailSrc,
  videoSrc,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoLoaded = (): void => {
    setIsLoading(false);
  };

  // Toggle play/pause on button click
  const handlePlayPause = (): void => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="relative h-full">
      {/* Show loader while video is loading */}
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center brightness-50">
          <Spinner size={24} />
        </div>
      )}
      <video
        autoPlay={autoPlay}
        className={cn(
          'w-full rounded-lg object-cover',
          aspectRatio,
          !isPlaying && (!thumbnailSrc || isEmptyFileList(thumbnailSrc)) && 'brightness-50',
          layout === 'row' && 'h-full'
        )}
        controlsList="nodownload"
        disablePictureInPicture
        height="150"
        id="video"
        loop
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onLoadedData={handleVideoLoaded}
        ref={videoRef}
        src={videoSrc}
        style={style}
        {...(showControls ? {controls: isPlaying} : {})} // Show controls only when video is playing
      />

      {!isLoading && !isPlaying && (
        <VideoPlayerOverlay handlePlayPause={handlePlayPause} thumbnailSrc={thumbnailSrc} />
      )}
    </div>
  );
};

export default VideoPlayer;
