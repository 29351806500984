import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {UserPropertyDataTypeSchema} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export const PostgresqlUserPropertyColumnSchema = z.object({
  columnName: z.string(),
  referenceId: z.string(),
  propertyName: z.string(),
  dataType: UserPropertyDataTypeSchema,
});
export type PostgresqlUserPropertyColumn = z.infer<typeof PostgresqlUserPropertyColumnSchema>;

export const PostgresqlSyncConfigSchema = z.object({
  query: z.string().min(1),
  userPropertyColumns: z.array(PostgresqlUserPropertyColumnSchema),
  emailColumn: z.string().min(1),
});
export type PostgresqlSyncConfig = z.infer<typeof PostgresqlSyncConfigSchema>;

export type PostgresqlSyncStruct = SyncIntegrationStruct<
  PostgresqlSyncConfig,
  PostgresqlSyncState,
  never,
  never,
  never,
  never
>;

export const PostgresqlSyncStateSchema = z.object({
  maxUpdatedAt: z.string().nullable(),
});
export type PostgresqlSyncState = z.infer<typeof PostgresqlSyncStateSchema>;

export const PostgresqlSyncIntegrationSpec: SyncIntegrationSpec<PostgresqlSyncStruct> = {
  userSyncsConfigSchema: PostgresqlSyncConfigSchema,
  userSyncsStateSchema: PostgresqlSyncStateSchema,
  userEventExportConfigSchema: null, // We could imagine exporting user events to Postgres in the future
  contentSyncConfigSchema: null,
  userExportConfigSchema: null,
  userExportStateSchema: null,
};
