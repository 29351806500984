import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {BaseResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {z} from 'zod';

export const CohortDigitalAssetResourceSchema = BaseResourcePropertiesSchema.extend({
  id: z.string().uuid(),
  tokenId: z.number().int().nullable(),
  imageUrl: z.string().url(),
  animationUrl: z.string().url().nullable(),
});

export type CohortDigitalAssetResourceType = z.infer<typeof CohortDigitalAssetResourceSchema>;

export type CohortDigitalAssetResourceStruct = ResourceStruct<
  'cohort.digital-asset',
  CohortDigitalAssetResourceType
>;

export const CohortDigitalAssetResourceSpec: ResourceSpec<CohortDigitalAssetResourceStruct> = {
  id: 'cohort.digital-asset',
  schema: CohortDigitalAssetResourceSchema,
};
