import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {GoogleFitBeActiveVerificationAttemptData} from '@cohort/shared/apps/google-fit/triggers/beActive';

const GoogleFitBeActiveTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: GoogleFitBeActiveVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => (
  <StepCompletionCard
    translationConfig={{
      // i18nOwl-ignore [app-google-fit.triggerIntegrations.be-active.stepCompletionTitleComponent.success]
      // i18nOwl-ignore [app-google-fit.triggerIntegrations.be-active.stepCompletionTitleComponent.error]
      key: `triggerIntegrations.be-active.stepCompletionTitleComponent.${
        completionSuccess ? 'success' : 'error'
      }`,
      ns: 'app-google-fit',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: data === null ? 'N/A' : data.googleUsername!,
    }}
  />
);

export default GoogleFitBeActiveTriggerIntegrationStepCompletionTitleComponent;
