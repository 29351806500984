/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {
  getCurrentOrganization,
  getUserOrganizations,
} from '@cohort/merchants/lib/api/Organizations';

export const organizationsKeys = {
  organizations: ['organizations'] as const,
  current: () => [...organizationsKeys.organizations, 'current'] as const,
  list: () => [...organizationsKeys.organizations, 'list'] as const,
};

export function useOrganizations(options?: QueryOptions) {
  return useCohortQuery({
    queryKey: organizationsKeys.list(),
    queryFn: async () => getUserOrganizations(),
    ...options,
  });
}

export function useCurrentOrganization(options?: QueryOptions) {
  return useCohortQuery({
    queryKey: organizationsKeys.current(),
    queryFn: async () => getCurrentOrganization(),
    ...options,
    staleTime: Infinity,
  });
}
