import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import type {ShopifyDiscountUsageData} from '@cohort/shared/apps/shopify/perks/discount';
import {useTranslation} from 'react-i18next';

const ShopifyDiscountPerkIntegrationsUsageViewComponent: React.FC<PerkUsageViewComponentProps> = ({
  usage,
}) => {
  const {t} = useTranslation('app-shopify', {
    keyPrefix: 'perkIntegrations.discount.usageViewComponent',
  });
  const data = usage.data as ShopifyDiscountUsageData;

  return (
    <PerkUsageLayout.Grid>
      <PerkUsageLayout.Row
        name={t('labelOrderId')}
        value={
          <a
            href={data.shopifyOrderUrl}
            target="_blank"
            className="col-span-4 flex flex-row hover:underline"
            rel="noreferrer"
          >
            {data.shopifyOrderId}
          </a>
        }
      />
    </PerkUsageLayout.Grid>
  );
};

export default ShopifyDiscountPerkIntegrationsUsageViewComponent;
