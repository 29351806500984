import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getPerkRoute} from '@cohort/merchants/lib/Pages';
import type {CohortPerkRestoredEventStruct} from '@cohort/shared/apps/cohort/userEvents/perk/perkRestored';
import {CohortPerkRestoredEventSpec} from '@cohort/shared/apps/cohort/userEvents/perk/perkRestored';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const perkId = (userEvent.properties as CohortPerkRestoredEventStruct['Properties']).perkId;
  const perkInternalName = userEvent.perkInternalName;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.perk.restored.detail"
      ns="app-cohort"
      values={{
        perkInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () => navigate(getPerkRoute(perkId).path)),
      }}
    />
  );
};

export const CohortPerkRestoredUserEvent: UserEvent<CohortPerkRestoredEventStruct> = {
  spec: CohortPerkRestoredEventSpec,
  detailComponent: DetailComponent,
};
