import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type YoutubeIsSubscriberUserPropertyStruct = UserPropertyStruct<
  'youtube.is-subscriber',
  boolean
>;

export const YoutubeIsSubscriberUserPropertySpec: UserPropertySpec<YoutubeIsSubscriberUserPropertyStruct> =
  {
    id: 'youtube.is-subscriber',
    name: 'Youtube Is Channel Subscriber',
    dataType: 'boolean',
    scope: 'merchant_connection',
  };
