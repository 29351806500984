import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {ComarchConnectorStruct} from '@cohort/shared/apps/comarch/connector';
import {ComarchConnectorSpec} from '@cohort/shared/apps/comarch/connector';

export const COMARCH_APP_ID = 'comarch';
export const COMARCH_APP_NAME = 'Comarch';

export type ComarchAppStruct = AppStruct<
  'comarch',
  ComarchConnectorStruct,
  null,
  never,
  never,
  never,
  never,
  never,
  never,
  never,
  never,
  never
>;

export const ComarchAppSpec: AppSpec<ComarchAppStruct> = {
  id: COMARCH_APP_ID,
  name: COMARCH_APP_NAME,
  documentationUrl: 'https://docs.getcohort.com/integrations/apps/comarch',
  merchantConnector: ComarchConnectorSpec,
  userConnector: null,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: [],
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
