import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import type {SpotifyFollowPlaylistVerificationAttemptData} from '@cohort/shared/apps/spotify/triggers/followPlaylist';
import {Trans} from 'react-i18next';

const SpotifyFollowPlaylistTriggerStepCompletionTitleComponent: React.FC<{
  data: SpotifyFollowPlaylistVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggerIntegrations.follow-playlist.stepCompletionTitleComponent.error"
        ns="app-spotify"
        values={{username: data.userName}}
        components={{
          a: <StepCompletionLink url={data.userUrl} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggerIntegrations.follow-playlist.stepCompletionTitleComponent.success"
      ns="app-spotify"
      values={{username: data.userName}}
      components={{
        a: <StepCompletionLink url={data.userUrl} />,
      }}
    />
  );
};

export default SpotifyFollowPlaylistTriggerStepCompletionTitleComponent;
