import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {TIKTOK_URL} from '@cohort/shared/apps/tiktok/common';
import type {TikTokFollowVerificationAttemptData} from '@cohort/shared/apps/tiktok/triggers/follow';
import {Trans} from 'react-i18next';

const TikTokFollowTriggerStepCompletionTitleComponent: React.FC<{
  data: TikTokFollowVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data}) => {
  const userLink = `${TIKTOK_URL}/@${data.username}`;

  return (
    <Trans
      i18nKey="triggers.follow.stepCompletionTitleComponent.success"
      ns="app-tiktok"
      values={{username: data.username}}
      components={{
        userLink: <StepCompletionLink url={userLink} />,
        tiktokLink: <StepCompletionLink url={data.url} />,
      }}
    />
  );
};

export default TikTokFollowTriggerStepCompletionTitleComponent;
