import {create} from 'zustand';

export interface TestModeStore {
  testModeEnabled: boolean;
  toggleTestMode: (testModeEnabled: boolean) => void;
}

export const useTestModeStore = create<TestModeStore>(set => ({
  testModeEnabled: false,
  toggleTestMode: (testModeEnabled: boolean) => set({testModeEnabled}),
}));
