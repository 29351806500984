import {z} from 'zod';

export const TalonOneAttributeTypeSchema = z.enum(['string', 'number', 'boolean', 'time']);
export type TalonOneAttributeType = z.infer<typeof TalonOneAttributeTypeSchema>;

export const TalonOneCampaignStateSchema = z.enum(['enabled', 'disabled', 'archived']);
export const TalonOneCampaignFeatureSchema = z.enum([
  'coupons',
  'referrals',
  'loyalty',
  'giveaways',
  'strikethrough',
  'achievements',
]);

export const TalonOneCampaignSchema = z.object({
  id: z.number(),
  name: z.string(),
  state: TalonOneCampaignStateSchema,
  features: z.array(TalonOneCampaignFeatureSchema),
});
export type TalonOneCampaign = z.infer<typeof TalonOneCampaignSchema>;
