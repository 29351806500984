import Button from '@cohort/merchants/components/buttons/Button';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import HighlightText from '@cohort/merchants/components/HighlightText';
import type {TypeformWorkspace} from '@cohort/shared/apps/typeform/actions/listWorkspaces';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type WorkspacePickerProps = {
  workspaces: Array<TypeformWorkspace>;
  onWorkspaceSelected: (workspaceId: string) => Promise<void>;
};
const WorkspacePicker: React.FC<WorkspacePickerProps> = ({workspaces, onWorkspaceSelected}) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<SelectOption | undefined>(undefined);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'workspacePicker',
  });

  const handleSubmit = async (): Promise<void> => {
    if (selectedWorkspace === undefined) {
      return;
    }
    setSubmitInProgress(true);
    try {
      await onWorkspaceSelected(selectedWorkspace.value as string);
    } finally {
      setSubmitInProgress(false);
    }
  };

  if (workspaces.length === 0) {
    return <div>{t('noWorkspacesLabel')}</div>;
  }

  const sortedWorkspaces = workspaces.sort(a => (a.shared ? -1 : 1));

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mt-1 flex w-full gap-x-3">
        <SelectPicker
          value={selectedWorkspace}
          options={sortedWorkspaces.map(
            workspace =>
              ({
                value: workspace.id,
                label: !workspace.shared
                  ? t('privateWorkSpaceName', {name: workspace.name})
                  : workspace.name,
                isDisabled: !workspace.shared,
              }) satisfies SelectOption
          )}
          onChange={selected => setSelectedWorkspace(selected ?? undefined)}
          labelPosition="left"
          name="workspace"
          className="w-full"
        />
      </div>
      <div className="mt-2">
        <HighlightText type="info" text={<label>{t('workspaceSelectInfoMessage')}</label>} />
      </div>

      <div className="mt-4">
        <Button
          className="self-end"
          onClick={handleSubmit}
          disabled={selectedWorkspace === undefined || submitInProgress}
        >
          {t('connectWorkspaceButton')}
        </Button>
      </div>
    </div>
  );
};

export default WorkspacePicker;
