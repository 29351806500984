import type {Language} from '@cohort/shared/schema/common';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

const useTranslateLanguage = (): ((key: Language) => string) => {
  const {t} = useTranslation('common', {keyPrefix: 'languages'});
  // i18nOwl-ignore [ar, cs, de, el, en, es, fa, fr, id, it]
  // i18nOwl-ignore [ja, ko, nl, pl, pt, ru, tr, uk, vi, zh]

  const getLanguageTranslation = useCallback(
    (key: Language): string => {
      return t(key);
    },
    [t]
  );

  return getLanguageTranslation;
};

export default useTranslateLanguage;
