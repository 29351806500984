import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentCohort} from '@cohort/merchants/hooks/contexts/currentCohort';
import {getCohortMembersRoute, getCohortRuleRoute} from '@cohort/merchants/lib/Pages';
import {Sliders, Users} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const SegmentCohortPageMenu = (): JSX.Element => {
  const {t} = useTranslation('pages', {
    keyPrefix: 'users.cohort.segmentCohortPageMenu',
  });
  const cohort = useCurrentCohort();

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('members'),
          path: getCohortMembersRoute(cohort.id).path,
          icon: <Users size={20} />,
          testId: 'cohort-members-link',
        },
        {
          name: t('conditions'),
          path: getCohortRuleRoute(cohort.id).path,
          icon: <Sliders size={20} />,
          testId: 'cohort-rule-link',
        },
      ]}
    />
  );
};

export default SegmentCohortPageMenu;
