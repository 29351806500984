import StepCompletionLink from '@cohort/merchants/apps/common/triggers/StepCompletionLink';
import {TIKTOK_URL} from '@cohort/shared/apps/tiktok/common';
import type {TikTokKeywordInDescriptionVerificationAttemptData} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {Trans} from 'react-i18next';

const TikTokKeywordInDescriptionTriggerStepCompletionTitleComponent: React.FC<{
  data: TikTokKeywordInDescriptionVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const userLink = `${TIKTOK_URL}/@${data.username}`;

  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggers.keyword-in-description.stepCompletionTitleComponent.error"
        ns="app-tiktok"
        values={{username: data.username, keyword: data.keyword}}
        components={{
          userLink: <StepCompletionLink url={userLink} />,
        }}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const shareUrl = data.shareUrl!;

  return (
    <Trans
      i18nKey="triggers.keyword-in-description.stepCompletionTitleComponent.success"
      ns="app-tiktok"
      values={{username: data.username, keyword: data.keyword}}
      components={{
        userLink: <StepCompletionLink url={userLink} />,
        postLink: <StepCompletionLink url={shareUrl} />,
      }}
    />
  );
};

export default TikTokKeywordInDescriptionTriggerStepCompletionTitleComponent;
