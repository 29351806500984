import {cn} from '@cohort/shared-frontend/utils/classNames';
// eslint-disable-next-line import/no-namespace
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';

export type TooltipSides = 'top' | 'bottom' | 'left' | 'right';

const TooltipProvider = TooltipPrimitive.Provider;
const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({className, sideOffset = 4, ...props}, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    {...props}
    side="top"
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95',
      'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
      'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

type TooltipProps = {
  contentAsChild?: boolean;
  content: JSX.Element | string | undefined;
  children: JSX.Element | string;
  side?: TooltipSides;
  disabled?: boolean;
};

const Tooltip: React.FC<TooltipProps> = ({
  contentAsChild = false,
  content,
  children,
  side = 'top',
  disabled = false,
}: TooltipProps) => {
  if (disabled || content === undefined) {
    return <>{children}</>;
  }

  return (
    <TooltipProvider delayDuration={50}>
      <TooltipRoot>
        <TooltipTrigger asChild={contentAsChild} type="button">
          {contentAsChild ? <div>{children}</div> : children}
        </TooltipTrigger>
        <TooltipContent side={side} className="bg-white text-xs font-normal text-slate-700">
          {content}
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  );
};

export default Tooltip;
