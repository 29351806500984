import TestModeModal from '@cohort/merchants/components/test-mode/TestModeModal';
import {useTestModeStore} from '@cohort/merchants/hooks/stores/testMode';
import {trackTestModeBannerModalButtonClicked} from '@cohort/merchants/lib/Tracking';
import {Info} from '@phosphor-icons/react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const TestModeBanner: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'testMode.testModeBanner'});
  const [openTestModeModal, setOpenTestModeModal] = useState(false);
  const testModeEnabled = useTestModeStore(store => store.testModeEnabled);

  return (
    <div>
      {testModeEnabled === true && (
        <div
          data-testid="test-mode-banner"
          className="flex h-[2rem] flex-col justify-center bg-primary/10 text-sm text-primary"
        >
          <div className="flex flex-row items-center justify-center">
            <span className="font-semibold">{t('title')}</span>&nbsp;
            <Info
              onClick={() => {
                trackTestModeBannerModalButtonClicked();
                setOpenTestModeModal(true);
              }}
              className="ml-1 inline-block h-5 w-5 cursor-pointer"
            />
          </div>
        </div>
      )}
      <TestModeModal show={openTestModeModal} onClose={() => setOpenTestModeModal(false)} />
    </div>
  );
};

export default TestModeBanner;
