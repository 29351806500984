import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortNewChallengeAvailablePropertiesSchema = z.object({
  campaignId: z.string().uuid(),
});
export type CohortNewChallengeAvailableProperties = z.infer<
  typeof CohortNewChallengeAvailablePropertiesSchema
>;

export type CohortNewChallengeAvailableEventStruct = UserEventStruct<
  'cohort.challenge.new-challenge-available',
  keyof CohortNewChallengeAvailableProperties,
  CohortNewChallengeAvailableProperties,
  CohortNewChallengeAvailableProperties
>;

export const CohortNewChallengeAvailableEventSpec: UserEventSpec<CohortNewChallengeAvailableEventStruct> =
  {
    id: 'cohort.challenge.new-challenge-available',
    name: 'A new Challenge available',
    description: 'Event Triggerred when a new Challenge is available to a user',
    propertiesSchema: CohortNewChallengeAvailablePropertiesSchema,
    exposedPropertiesSchema: CohortNewChallengeAvailablePropertiesSchema,
    resources: {
      campaignId: 'cohort.challenge',
    },
    rulesEngineConfig: {
      isVisible: false,
      exposedFields: [],
    },
  };
