// i18nOwl-ignore [userPropertyDataType.boolean, userPropertyDataType.date, userPropertyDataType.number, userPropertyDataType.string]
import type {
  SalesforceUserObject,
  SalesforceUserObjectField,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import {
  buildDefaultUserPropertyColumn,
  getDataTypeFromSalesforceFieldType,
  getSalesforceObjectByName,
  SALESFORCE_USER_PROPERTY_PREFIX,
} from '@cohort/merchants/apps/salesforce/sync/utils';
import {Badge} from '@cohort/merchants/components/Badge';
import Button from '@cohort/merchants/components/buttons/Button';
import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {ArrowRight, Trash} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

interface ImportUserFieldSelectProps {
  index: number;
  salesforceUserObjects: SalesforceUserObject[];
  selectedField: SalesforceUserObjectField | null;
}

const ImportUserFieldSelect: React.FC<ImportUserFieldSelectProps> = ({
  index,
  salesforceUserObjects,
  selectedField,
}) => {
  const {t} = useTranslation('app-salesforce', {
    keyPrefix: 'sync.importUsersFieldSelect',
  });

  const {control, watch} = useCohortForm<Extract<SyncConfigFormValues, {appId: 'salesforce'}>>();

  const isImportEnabled = watch('userImportEnabled');
  const userSyncConfig = watch('userSyncConfig');

  const selectedObject = getSalesforceObjectByName(
    salesforceUserObjects,
    userSyncConfig?.salesforceObjectName ?? ''
  );

  const {remove: removeUserPropertyColumn, replace: replaceUserPropertyColumns} = useFieldArray({
    control,
    name: 'userSyncConfig.userPropertyColumns',
  });
  const selectedFieldNames = userSyncConfig?.userPropertyColumns.map(column => column.columnName);

  const selectedFieldType =
    selectedField !== null && selectedObject !== undefined
      ? selectedObject.fields.find(field => field.name === selectedField.name)?.type
      : undefined;

  const handleDeleteField = (index: number): void => {
    if (userSyncConfig?.salesforceObjectName.length === 1) {
      // When deleting the last field, we reinitialize it to always have at least one field
      replaceUserPropertyColumns([buildDefaultUserPropertyColumn()]);
      return;
    }

    removeUserPropertyColumn(index);
  };

  const handleFieldChange = (index: number, field: SalesforceUserObjectField | null): void => {
    const updatedUserPropertyColumns = [...(userSyncConfig?.userPropertyColumns ?? [])];

    if (updatedUserPropertyColumns[index] !== undefined && field !== null) {
      updatedUserPropertyColumns[index] = {
        columnName: field.name,
        referenceId: field.name,
        propertyName: `${SALESFORCE_USER_PROPERTY_PREFIX} ${field.label}`,
        dataType: getDataTypeFromSalesforceFieldType(field.type),
      };
      replaceUserPropertyColumns(updatedUserPropertyColumns);
    }
  };

  const fieldOptions =
    selectedObject !== undefined
      ? selectedObject.fields.map(field => ({
          label: field.label,
          value: field.name,
          isDisabled: selectedFieldNames?.includes(field.name),
        }))
      : [];
  const value =
    selectedField !== null ? {value: selectedField.name, label: selectedField.label} : null;
  const dataType =
    selectedFieldType !== undefined
      ? getDataTypeFromSalesforceFieldType(selectedFieldType)
      : 'string';
  const isLoneEmptyField =
    userSyncConfig?.userPropertyColumns.length === 1 &&
    userSyncConfig.userPropertyColumns[0]?.referenceId === '';

  return (
    <div key={index} className="flex items-center gap-6">
      <div className="w-[276px]">
        <SelectPicker
          name={`field-${index}`}
          placeholder={t('placeholder')}
          options={fieldOptions}
          value={value}
          isDisabled={!isImportEnabled || selectedObject === undefined}
          onChange={option => {
            handleFieldChange(
              index,
              selectedObject?.fields.find(field => field.name === option?.value) ?? null
            );
          }}
        />
      </div>
      <ArrowRight size={20} className="text-slate-400" />
      <div className="flex flex-1 items-center justify-between">
        {selectedField !== null ? (
          <Fragment>
            <div>
              <Badge
                text={`${SALESFORCE_USER_PROPERTY_PREFIX} ${selectedField.label}`}
                textColor="text-slate-700"
                backgroundColor="bg-slate-100"
              />
            </div>
            <div className="flex items-center gap-x-1.5">
              <DataTypeIcon dataType={dataType} />
              <div className="text-sm font-normal text-slate-700">
                {t(`userPropertyDataType.${dataType}`)}
              </div>
            </div>
          </Fragment>
        ) : (
          <p className="text-slate-400">{t('noFieldSelected')}</p>
        )}
      </div>
      <Button
        variant="secondary"
        size="icon"
        onClick={() => handleDeleteField(index)}
        disabled={isLoneEmptyField}
      >
        <Trash size={20} className="text-red-400" />
      </Button>
    </div>
  );
};

export default ImportUserFieldSelect;
