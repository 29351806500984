import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useInstagramMedias} from '@cohort/merchants/apps/instagram/actions';
import InstagramMediaSelector from '@cohort/merchants/apps/instagram/triggerIntegrations/components/InstagramMediaSelector';
import ErrorState from '@cohort/merchants/components/ErrorState';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {Fragment, useEffect} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const InstagramLikeMediaTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({connectionId, formReturn}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {setValue, control} = formReturn;
  const {field: mediaIdField} = useController({name: 'triggerIntegrationConfig.mediaId', control});
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.like-media.configComponent',
  });

  const {
    data: mediasResponse,
    isLoading: isMediasLoading,
    isFetched: isMediasFetched,
  } = useInstagramMedias(merchant.id, connectionId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const medias = mediasResponse?.output.medias ?? [];

  // Set the value of the url to match the mediaId
  useEffect(() => {
    const media = medias.find(media => media.id === mediaIdField.value);
    if (media === undefined) {
      return;
    }
    setValue('triggerIntegrationConfig.url', media.permalink);
  }, [mediaIdField, medias, setValue]);

  return match({
    medias,
    isMediasLoading,
    isMediasFetched,
    formIdFieldValue: mediaIdField.value,
  })
    .with({isMediasLoading: true}, () => <Loader size={30} color="gray" />)
    .with({medias: []}, () => <p>{t('noMediasMessage')}</p>)
    .with({isMediasFetched: true}, () => {
      const selectedMedia = medias.find(media => media.id === mediaIdField.value);

      return (
        <Fragment>
          <InstagramMediaSelector
            description={t('descriptionMediaSelect')}
            setValue={setValue}
            title={t('titleMediaSelect')}
            selectedMedia={selectedMedia}
            medias={medias}
          />
          <HighlightText type="info" text={t('unverifiedInfo')} />
        </Fragment>
      );
    })
    .otherwise(() => <ErrorState />);
};

export default InstagramLikeMediaTriggerIntegrationConfigComponent;
