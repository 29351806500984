import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {z} from 'zod';

export const YoutubeContentSyncConfigSchema = z.object({});
export type YoutubeContentSyncConfig = z.infer<typeof YoutubeContentSyncConfigSchema>;

export type YoutubeSyncStruct = SyncIntegrationStruct<
  never,
  never,
  YoutubeContentSyncConfig,
  never,
  never,
  never
>;

export const YoutubeSyncSpec: SyncIntegrationSpec<YoutubeSyncStruct> = {
  contentSyncConfigSchema: YoutubeContentSyncConfigSchema,
  userSyncsConfigSchema: null,
  userSyncsStateSchema: null,
  userEventExportConfigSchema: null,
  userExportConfigSchema: null,
  userExportStateSchema: null,
};
