import {z} from 'zod';

export const UserEventsOrderBySchema = z.enum([
  'eventDate',
  '-eventDate',
  'createdAt',
  '-createdAt',
  'updatedAt',
  '-updatedAt',
]);

export type UserEventsOrderBy = z.infer<typeof UserEventsOrderBySchema>;
