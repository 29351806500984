import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortCohortJoinedPropertiesSchema = z.object({
  cohortId: z.string().uuid(),
});
export type CohortCohortJoinedProperties = z.infer<typeof CohortCohortJoinedPropertiesSchema>;

export type CohortCohortJoinedEventStruct = UserEventStruct<
  'cohort.cohort.joined',
  keyof CohortCohortJoinedProperties,
  CohortCohortJoinedProperties,
  CohortCohortJoinedProperties
>;

export const CohortCohortJoinedEventSpec: UserEventSpec<CohortCohortJoinedEventStruct> = {
  id: 'cohort.cohort.joined',
  name: 'Joined a Cohort',
  description: 'Event Triggerred when a user joins a cohort',
  propertiesSchema: CohortCohortJoinedPropertiesSchema,
  exposedPropertiesSchema: CohortCohortJoinedPropertiesSchema,
  resources: {
    cohortId: 'cohort.cohort',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['cohortId'],
  },
};
