import type {BaseProfileProperties, ConnectorSpec, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {OAuth2AppCredentialsSchema, OAuth2CredentialsSchema} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const TYPEFORM_MERCHANT_CONNECTOR_ID = 'typeform' as const;

export const TypeformConnectionConfigSchema = z.object({
  workspaceId: z.string(),
});
export type TypeformConnectionConfig = z.infer<typeof TypeformConnectionConfigSchema>;

export type TypeformMerchantConnectorStruct = ConnectorStruct<
  'typeform',
  OAuth2AppCredentials,
  OAuth2Credentials,
  BaseProfileProperties,
  TypeformConnectionConfig
>;

export const TypeformMerchantConnectorSpec: ConnectorSpec<TypeformMerchantConnectorStruct> = {
  id: 'typeform' as const,
  type: 'oauth2',
  appCredentialsSchema: OAuth2AppCredentialsSchema,
  credentialsSchema: OAuth2CredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: TypeformConnectionConfigSchema,
};
