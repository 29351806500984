import Button from '@cohort/merchants/components/buttons/Button';
import type {SelectOption} from '@cohort/merchants/components/form/select/SelectPicker';
import {SelectPicker} from '@cohort/merchants/components/form/select/SelectPicker';
import HighlightText from '@cohort/merchants/components/HighlightText';
import type {FacebookPage} from '@cohort/shared/apps/instagram/common';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type FacebookPagePickerProps = {
  submitInProgress: boolean;
  pages: Array<FacebookPage>;
  onPageSelected: (page: FacebookPage) => void;
};
const FacebookPagePicker: React.FC<FacebookPagePickerProps> = ({
  pages,
  onPageSelected,
  submitInProgress,
}) => {
  const [selectedPage, setSelectedPage] = useState<SelectOption | undefined>(undefined);
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'facebookPagePicker',
  });

  const handleSubmit = (): void => {
    if (selectedPage === undefined) {
      return;
    }
    onPageSelected(selectedPage.value as FacebookPage);
  };

  if (pages.length === 0) {
    return <div>{t('noPagesLabel')}</div>;
  }

  const sortedPages = pages.sort(page => (page.instagramBusinessAccountId === null ? 1 : -1));

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mt-1 flex w-full gap-x-3">
        <SelectPicker
          value={selectedPage}
          options={sortedPages.map(
            page =>
              ({
                value: page,
                label: page.name,
                isDisabled: page.instagramBusinessAccountId === null,
              }) satisfies SelectOption
          )}
          onChange={selected => setSelectedPage(selected ?? undefined)}
          labelPosition="left"
          name="page"
          className="w-full"
        />
      </div>
      <div className="mt-2">
        <HighlightText type="info" text={<label>{t('pageSelectInfoMessage')}</label>} />
      </div>

      <div className="mt-4">
        <Button
          className="self-end"
          onClick={handleSubmit}
          disabled={selectedPage === undefined || submitInProgress}
        >
          {t('connectPageButton')}
        </Button>
      </div>
    </div>
  );
};

export default FacebookPagePicker;
