import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortLastActiveUserPropertyStruct = UserPropertyStruct<'cohort.last-active', Date>;

export const CohortLastActiveUserPropertySpec: UserPropertySpec<CohortLastActiveUserPropertyStruct> =
  {
    id: 'cohort.last-active',
    name: 'Last Active',
    dataType: 'date',
    scope: 'global',
  };
