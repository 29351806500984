import Loader from '@cohort/merchants/components/Loader';
import {Trans} from 'react-i18next';

type StepCompletionCardTranslationConfig = {
  key: string;
  ns: string;
  value: string | null;
};

const StepCompletionCard: React.FC<{
  translationConfig: StepCompletionCardTranslationConfig | null;
  isLoading?: boolean;
}> = ({translationConfig, isLoading}) => (
  <div>
    {isLoading === true ? (
      <Loader size={30} color="gray" />
    ) : (
      translationConfig !== null && (
        <Trans
          i18nKey={translationConfig.key}
          ns={translationConfig.ns}
          values={{
            content: translationConfig.value,
          }}
          components={{
            bold: <span className="font-semibold text-gray-900" />,
            a: (
              <a
                className="font-semibold text-gray-900"
                target="_blank"
                rel="noopener noreferrer nofollow"
                href={translationConfig.value ?? undefined}
              />
            ),
          }}
        />
      )
    )}
  </div>
);

export default StepCompletionCard;
