import {FacebookPostSchema} from '@cohort/shared/apps/facebook/common';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const FacebookTagInPostTriggerConfigSchema = z.object({
  pageId: z.string(),
  pageName: z.string(),
});
type FacebookTagInPostTriggerConfig = z.infer<typeof FacebookTagInPostTriggerConfigSchema>;

const FacebookTagInPostVerificationAttemptDataSchema = z.object({
  userId: z.string(),
  username: z.string(),
  userPermalink: z.string().optional(),
  post: FacebookPostSchema.optional(),
});
export type FacebookTagInPostVerificationAttemptData = z.infer<
  typeof FacebookTagInPostVerificationAttemptDataSchema
>;

export type FacebookTagInPostTriggerStruct = TriggerIntegrationStruct<
  'facebook.tag-in-post',
  FacebookTagInPostTriggerConfig,
  FacebookTagInPostVerificationAttemptData
>;
export const FacebookTagInPostTriggerSpec: TriggerIntegrationSpec<FacebookTagInPostTriggerStruct> =
  {
    id: 'facebook.tag-in-post' as const,
    type: 'verification',
    configurableMaxAttempts: false,
    configSchema: FacebookTagInPostTriggerConfigSchema,
    verificationAttemptDataSchema: FacebookTagInPostVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
