import type {TriggerIntegration} from '@cohort/merchants/apps';
import TypeformTriggerIntegrationConfigComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/ConfigComponent';
import TypeformFillFormTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/fillForm/StepCompletionContextComponent';
import TypeformFillFormTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/typeform/triggerIntegrations/fillForm/StepCompletionTitleComponent';
import type {TypeformFillFormTriggerStruct} from '@cohort/shared/apps/typeform/triggers/fillForm';
import {TypeformFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/typeform/triggers/fillForm';

export const TypeformFillFormTriggerIntegration: TriggerIntegration<TypeformFillFormTriggerStruct> =
  {
    spec: TypeformFillFormTriggerIntegrationSpec,
    configComponent: TypeformTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: TypeformFillFormTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      TypeformFillFormTriggerIntegrationStepCompletionContextComponent,
    getTitle: t => t('triggerIntegrations.fill-form.title', {ns: 'app-typeform'}),
  };
