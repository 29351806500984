import {z} from 'zod';

export const RESOURCES = [
  'campaigns',
  'users',
  'cohorts',
  'digital-assets',
  'perks',
  'orders',
  'user-properties',
] as const;

export type Resource = (typeof RESOURCES)[number];

export type Permission = `${Resource}:read` | `${Resource}:write` | 'basic';
export const allPermissions: Permission[] = RESOURCES.flatMap<Permission>(resource => [
  `${resource}:read`,
  `${resource}:write`,
]).concat('basic');

export const ApiKeyPermissionSchema = z.enum(allPermissions as [Permission, ...Permission[]]);
