import LoadingScreen from '@cohort/merchants/layouts/LoadingScreen';
import {useEffect, useState} from 'react';
import {useNavigation} from 'react-router-dom';

export default function useLazyRouteLoader(timeout = 200): JSX.Element | null {
  const navigation = useNavigation();
  const [timeoutReached, setTimeoutReached] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (navigation.state === 'loading') {
      timer = setTimeout(() => {
        setTimeoutReached(true);
      }, timeout);
    } else {
      setTimeoutReached(false);
    }
    return () => clearTimeout(timer);
  }, [timeout, navigation.state]);

  if (timeoutReached && navigation.state === 'loading') {
    return <LoadingScreen />;
  }

  return null;
}
