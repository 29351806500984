import type {
  NotificationIntegrationSpec,
  NotificationIntegrationStruct,
} from '@cohort/shared/apps/app';
import {requestBodyTransformer, urlTransformer} from '@cohort/shared/utils/validators';
import {z} from 'zod';

export const CohortCustomRequestNotificationHttpMethodSchema = z.enum([
  'GET',
  'PATCH',
  'PUT',
  'POST',
  'DELETE',
]);
export const CohortCustomRequestNotificationConfigSchema = z.object({
  httpMethod: CohortCustomRequestNotificationHttpMethodSchema,
  url: z
    .string()
    .url()
    .transform(url => urlTransformer(url, {encodeUrl: true})),
  body: z
    .string()
    .nullable()
    .transform(body => requestBodyTransformer(body)),
  headers: z
    .array(
      z.object({
        key: z.string(),
        value: z.string(),
      })
    )
    .nullable(),
});
export type CohortCustomRequestNotificationConfig = z.infer<
  typeof CohortCustomRequestNotificationConfigSchema
>;

export const CustomRequestNotificationExecutionDataSchema = z.object({
  httpStatusCode: z.number().nullable(),
  payload: z.string().nullable(),
  reason: z.string().nullable(),
});
export type CustomRequestNotificationExecutionData = z.infer<
  typeof CustomRequestNotificationExecutionDataSchema
>;

export type CohortCustomRequestNotificationIntegrationStruct = NotificationIntegrationStruct<
  'cohort.custom-request',
  CohortCustomRequestNotificationConfig
>;

export const CohortCustomRequestNotificationIntegrationSpec: NotificationIntegrationSpec<CohortCustomRequestNotificationIntegrationStruct> =
  {
    id: 'cohort.custom-request' as const,
    configSchema: CohortCustomRequestNotificationConfigSchema,
    notificationDataSchema: z.object({}),
    notificationInputSchema: z.object({}),
    notificationExecutionDataSchema: CustomRequestNotificationExecutionDataSchema,
  };
