import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import type {DiscordPrivateAccessUsageData} from '@cohort/shared/apps/discord/perks/privateAccess';
import {useTranslation} from 'react-i18next';

const DiscordPrivateAccessPerkIntegrationsUsageViewComponent: React.FC<
  PerkUsageViewComponentProps
> = ({usage}) => {
  const {t} = useTranslation('app-discord', {
    keyPrefix: 'perkIntegrations.private-access.usageViewComponent',
  });
  const data = usage.data as DiscordPrivateAccessUsageData;
  const username = `${data.discordUserName}#${data.discordDiscriminator}`;

  return (
    <PerkUsageLayout.Grid>
      <PerkUsageLayout.Row name={t('labelId')} value={data.discordUserId} />
      <PerkUsageLayout.Row name={t('labelUser')} value={username} />
    </PerkUsageLayout.Grid>
  );
};

export default DiscordPrivateAccessPerkIntegrationsUsageViewComponent;
