import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {CohortBeInCohortVerificationAttemptData} from '@cohort/shared/apps/cohort/triggers/beInCohort';

const CohortBeInCohortTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortBeInCohortVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  return (
    <StepCompletionCard
      translationConfig={{
        // i18nOwl-ignore [app-cohort.triggerIntegrations.be-in-cohort.stepCompletionTitleComponent.success]
        // i18nOwl-ignore [app-cohort.triggerIntegrations.be-in-cohort.stepCompletionTitleComponent.error]
        key: `triggerIntegrations.be-in-cohort.stepCompletionTitleComponent.${
          completionSuccess ? 'success' : 'error'
        }`,
        ns: 'app-cohort',
        value: data.cohortName,
      }}
    />
  );
};

export default CohortBeInCohortTriggerIntegrationStepCompletionTitleComponent;
