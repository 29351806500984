import type {AssetKind} from '@cohort/shared/schema/common/assets';
import type {SizedAssetKind} from '@cohort/shared/utils/fileUploads';
import {ASSETS_MIN_DIMENSIONS, isLandscapeVisual} from '@cohort/shared/utils/fileUploads';
import type {PercentCrop, PixelCrop} from 'react-image-crop';
import {centerCrop, makeAspectCrop} from 'react-image-crop';

export const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  assetKind: AssetKind
): PercentCrop => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      isLandscapeVisual(assetKind) ? 16 / 9 : 1,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

export const drawCanvasPreview = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop
): void => {
  const ctx = canvas.getContext('2d', {willReadFrequently: true});

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
};

export interface ImageToCrop {
  name: string;
  src: string;
}

export const ASSETS_TO_CROP = new Set(['digitalAssetVisual', 'logo', 'banner', 'perkBanner']);

// Adapt the minimal crop size to the size of the images on the page.
// Detailed here: https://github.com/DominicTobias/react-image-crop/issues/446
export const scaleMinDimensions = (
  imgRef: HTMLImageElement,
  assetKind: SizedAssetKind
): {minWidth: number; minHeight: number} => {
  const naturalWidth = imgRef.naturalWidth;
  const clientWidth = imgRef.width;
  const scaleRatio = naturalWidth / clientWidth;
  return {
    minWidth: ASSETS_MIN_DIMENSIONS[assetKind].width / scaleRatio / 2,
    minHeight: ASSETS_MIN_DIMENSIONS[assetKind].height / scaleRatio / 2,
  };
};
