import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getDigitalAssetRoute} from '@cohort/merchants/lib/Pages';
import type {CohortDigitalAssetTransferredEventStruct} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetTransferred';
import {CohortDigitalAssetTransferredEventSpec} from '@cohort/shared/apps/cohort/userEvents/digitalAsset/digitalAssetTransferred';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const collectionId = (
    userEvent.properties as CohortDigitalAssetTransferredEventStruct['Properties']
  ).collectionId;
  const digitalAssetCollectionInternalName = userEvent.digitalAssetCollectionInternalName;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.digital-asset.transferred.detail"
      ns="app-cohort"
      values={{
        digitalAssetCollectionInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getDigitalAssetRoute(collectionId).path)
        ),
      }}
    />
  );
};

export const CohortDigitalAssetTransferredUserEvent: UserEvent<CohortDigitalAssetTransferredEventStruct> =
  {
    spec: CohortDigitalAssetTransferredEventSpec,
    detailComponent: DetailComponent,
  };
