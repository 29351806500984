import type {ContentADto} from '@cohort/admin-schemas/content';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {useContent} from '@cohort/merchants/hooks/api/Contents';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import SkeletonLoader from '@cohort/merchants/layouts/SkeletonLoader';
import React from 'react';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentContentProviderProps = {
  children: React.ReactNode;
};

export const CurrentContentContext = createContext<ContentADto | null>(null);

export const CurrentContentProvider: React.FC<CurrentContentProviderProps> = ({children}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {contentId} = useParams();

  const {data: content, isFetched} = useContent(
    merchantId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    contentId!,
    {enabled: Boolean(contentId), refetchOnWindowFocus: false}
  );

  if (contentId === undefined) {
    return <NotFoundSection />;
  }

  if (isFetched && !content) {
    return <NotFoundSection />;
  }

  if (content === undefined) {
    return <SkeletonLoader />;
  }

  return (
    <CurrentContentContext.Provider value={content}>{children}</CurrentContentContext.Provider>
  );
};
