import type {YoutubeCommentVerificationAttemptData} from '@cohort/shared/apps/youtube/triggers/comment';
import {Quotes} from '@phosphor-icons/react';
import {Fragment} from 'react';

const YoutubeCommentTriggerIntegrationStepCompletionContextComponent: React.FC<{
  data: YoutubeCommentVerificationAttemptData;
}> = ({data}) => {
  const {comment} = data;

  if (comment === null) {
    return <Fragment />;
  }

  return (
    <div className="flex flex-col gap-y-2 rounded-lg border border-slate-200 bg-slate-50 p-4">
      <Quotes size={16} className="text-slate-400" />
      <p className="text-sm text-slate-700">{comment.text}</p>
    </div>
  );
};

export default YoutubeCommentTriggerIntegrationStepCompletionContextComponent;
