import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortBeInCohortTriggerkIntegrationConfigComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/beInCohort/ConfigComponent';
import CohortBeInCohortTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort/triggerIntegrations/beInCohort/StepCompletionTitleComponent';
import type {CohortBeInCohortTriggerStruct} from '@cohort/shared/apps/cohort/triggers/beInCohort';
import {CohortBeInCohortTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/beInCohort';

export const CohortBeInCohortTriggerIntegration: TriggerIntegration<CohortBeInCohortTriggerStruct> =
  {
    spec: CohortBeInCohortTriggerIntegrationSpec,
    configComponent: CohortBeInCohortTriggerkIntegrationConfigComponent,
    stepCompletionTitleComponent: CohortBeInCohortTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggerIntegrations.be-in-cohort.title', {ns: 'app-cohort'}),
  };
