import {Card} from '@cohort/components-xps/components/cards/Card';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentInfos from '@cohort/components-xps/components/contents/MediaContentInfos';
import type {CohortArticleMediaStruct} from '@cohort/shared/apps/cohort/medias/article';
import RichText from '@cohort/shared-frontend/components/RichText';
import {isDefined, isString} from 'remeda';

const MediaContentCohortArticle: React.FC<MediaContentProps<CohortArticleMediaStruct>> = ({
  config,
  title,
}) => (
  <Card className="gap-y-2">
    <MediaContentInfos title={title} />
    {isDefined(config?.content) && isString(config.content) && <RichText html={config.content} />}
  </Card>
);

export default MediaContentCohortArticle;
