import type {SyncConfigComponentProps} from '@cohort/merchants/apps';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import UserEventsPicker from '@cohort/merchants/components/UserEventsPicker';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {SyncConfigFormValues} from '@cohort/merchants/pages/apps/app/utils';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const ExportUserEventsConfig: React.FC<SyncConfigComponentProps> = () => {
  const {setValue, register, control, watch} =
    useCohortForm<Extract<SyncConfigFormValues, {appId: 'brevo'}>>();

  const {t} = useTranslation('app-brevo', {
    keyPrefix: 'sync.exportUserEventsConfig',
  });

  const isEnabled = watch('userEventExportEnabled');
  const {allUserEventIds, userEventIds} = watch('userEventExportConfig') ?? {};

  useEffect(() => {
    if (allUserEventIds === true && userEventIds === undefined) {
      setValue('userEventExportConfig.userEventIds', []);
    }
  }, [allUserEventIds, setValue, userEventIds]);

  return (
    <div className="flex flex-col space-y-4">
      <RadioCards
        name="userEventExportConfig.allUserEventIds"
        label={t('labelEventIds')}
        register={register}
        control={control}
        disabled={!isEnabled}
        options={[
          {label: t('optionAllUserEvents'), value: 'true'},
          {label: t('optionSelectedUserEvents'), value: 'false'},
        ]}
        onChange={e => {
          setValue('userEventExportConfig.allUserEventIds', e.target.value === 'true');
        }}
        preventFieldOnChange
      />
      {allUserEventIds === false && (
        <UserEventsPicker
          name="userEventExportConfig.userEventIds"
          label={t('labelSelectUserEvents')}
          control={control}
          register={register}
        />
      )}
    </div>
  );
};

export default ExportUserEventsConfig;
