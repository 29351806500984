import postgresEmptyIcon from '@cohort/merchants/apps/postgresql/sync/postgresEmptyIcon.svg';
import DataTypeIcon from '@cohort/merchants/components/DataTypeIcon';
import {
  EmptyState,
  EmptyStateContainer,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@cohort/merchants/components/EmptyState';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@cohort/merchants/components/tables/Table';
import type {PostgresqlUserPropertyColumn} from '@cohort/shared/apps/postgresql/sync';
import {useTranslation} from 'react-i18next';

type UserPropertyColumnsTableProps = {
  userPropertyColumns: (PostgresqlUserPropertyColumn & {sampleData?: string[]})[];
  isQueryConfigured?: boolean;
  hideSampleData?: boolean;
};

const UserPropertyColumnsTable: React.FC<UserPropertyColumnsTableProps> = ({
  userPropertyColumns,
  isQueryConfigured = false,
  hideSampleData = false,
}) => {
  const {t} = useTranslation('app-postgresql', {
    keyPrefix: 'sync.importUsersConfig.userPropertyColumnsTable',
  });

  if (userPropertyColumns.length === 0) {
    return (
      <EmptyStateContainer>
        <EmptyState>
          <img width={150} src={postgresEmptyIcon} alt="Postgresql empty state" />
          <EmptyStateTitle>
            {isQueryConfigured ? t('emptyDataNoPropertiesTitle') : t('emptyDataNoQueryTitle')}
          </EmptyStateTitle>
          <EmptyStateDescription>
            {isQueryConfigured
              ? t('emptyDataNoPropertiesDescription')
              : t('emptyDataNoQueryDescription')}
          </EmptyStateDescription>
        </EmptyState>
      </EmptyStateContainer>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('tableHeaderColumn')}</TableHead>
          <TableHead>{t('tableHeaderUserProperty')}</TableHead>
          {!hideSampleData && <TableHead>{t('tableHeaderSampleData')}</TableHead>}
        </TableRow>
      </TableHeader>
      <TableBody>
        {userPropertyColumns.map(userPropertyColumn => (
          <TableRow key={userPropertyColumn.referenceId}>
            <TableCell className="text-sm font-medium text-slate-700">
              {userPropertyColumn.columnName}
            </TableCell>
            <TableCell>
              <div className="flex flex-row items-center gap-2">
                <DataTypeIcon dataType={userPropertyColumn.dataType} size={16} />
                {userPropertyColumn.propertyName}
              </div>
            </TableCell>
            {!hideSampleData && (
              <TableCell>
                <ul className="mt-0.5 max-w-48 text-xs text-muted-foreground">
                  {userPropertyColumn.sampleData !== undefined &&
                    userPropertyColumn.sampleData.map((line, index) => (
                      <p
                        key={`sample-${index}`}
                        className="overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {line}
                      </p>
                    ))}
                </ul>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default UserPropertyColumnsTable;
