import {cn} from '@cohort/shared-frontend/utils/classNames';
import React, {Fragment} from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';

export interface Anchors {
  name: string;
  value: string;
}

export interface Entries {
  name: string;
  path: string;
  icon: JSX.Element;
  testId?: string;
  disabled?: boolean;
  anchors?: Array<Anchors>;
  addOn?: JSX.Element;
}

interface AsidePageMenuProps {
  entries?: Array<Entries>;
}

const AsidePageMenu: React.FC<AsidePageMenuProps> = ({entries}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  return (
    <div className="flex flex-col">
      {entries?.map(item => (
        <Fragment key={item.testId || item.name}>
          <Link
            className="row flex cursor-pointer items-center"
            to={item.disabled ? '' : item.path}
          >
            <div
              className={cn(
                'flex flex-row items-center space-x-2 py-2 text-sm font-medium',
                item.disabled && 'cursor-not-allowed opacity-50'
              )}
            >
              <div
                className={cn(
                  location.pathname.startsWith(item.path) ? 'text-primary' : 'text-slate-400'
                )}
                data-testid={item.testId}
              >
                {item.icon}
              </div>
              <div
                className={cn(
                  location.pathname.startsWith(item.path) ? 'text-primary-darker' : 'text-slate-500'
                )}
              >
                {item.name}
              </div>
            </div>
            {item.addOn}
          </Link>
          {item.anchors && (
            <ul className="ml-2 flex flex-col gap-1 border-l px-6">
              {item.anchors.map(anchor => (
                <li key={anchor.name}>
                  <button
                    className="cursor-pointer text-left text-xs font-medium text-slate-500 hover:text-primary hover:underline"
                    onClick={() => {
                      if (location.pathname !== item.path) {
                        navigate(`${item.path}?section=${anchor.value}`);
                      } else {
                        setSearchParams({section: anchor.value});
                      }
                    }}
                  >
                    {anchor.name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default AsidePageMenu;
