import type {TriggerIntegration} from '@cohort/merchants/apps';
import InstagramFollowTriggerConfigComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/follow/ConfigComponent';
import InstagramFollowTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/follow/StepCompletionTitleComponent';
import type {InstagramFollowTriggerStruct} from '@cohort/shared/apps/instagram/triggers/follow';
import {InstagramFollowTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/follow';

export const InstagramFollowTriggerIntegration: TriggerIntegration<InstagramFollowTriggerStruct> = {
  spec: InstagramFollowTriggerIntegrationSpec,
  configComponent: InstagramFollowTriggerConfigComponent,
  stepCompletionTitleComponent: InstagramFollowTriggerStepCompletionTitleComponent,
  getTitle: t => t('triggerIntegrations.follow.title', {ns: 'app-instagram'}),
};
