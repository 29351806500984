import CohortLogo from '@cohort/merchants/components/CohortLogo';
import type {ErrorSectionTemplateProps} from '@cohort/merchants/components/error-pages/ErrorSectionTemplate';
import ErrorSectionTemplate from '@cohort/merchants/components/error-pages/ErrorSectionTemplate';
import {Link} from 'react-router-dom';

const ErrorPageTemplate: React.FC<ErrorSectionTemplateProps> = props => (
  <div className="h-screen">
    <Link
      className="absolute h-16"
      to="/"
      style={{
        left: '2rem',
        top: '1rem',
      }}
    >
      <CohortLogo />
    </Link>
    <ErrorSectionTemplate {...props} />
  </div>
);

export default ErrorPageTemplate;
