import type {OrganizationADto} from '@cohort/admin-schemas/organization';
import {OrganizationASchema} from '@cohort/admin-schemas/organization';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import {z} from 'zod';

export async function getUserOrganizations(): Promise<OrganizationADto[]> {
  return apiCall('GET', `/v1/organizations`, {
    expect: HttpCodes.SUCCESS,
    parser: z.array(OrganizationASchema).parse,
  });
}

export async function getCurrentOrganization(): Promise<OrganizationADto> {
  return apiCall('GET', `/v1/organizations/current`, {
    expect: HttpCodes.SUCCESS,
    parser: OrganizationASchema.parse,
  });
}
