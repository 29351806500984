import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {CohortVisitLinkVerificationAttemptData} from '@cohort/shared/apps/cohort/triggers/visitLink';

const CohortVisitLinkTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: CohortVisitLinkVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data}) => (
  // allow new lines on long URLs
  <div className="break-all">
    <StepCompletionCard
      translationConfig={{
        // i18nOwl-ignore [app-cohort.triggerIntegrations.visit-link.stepCompletionTitleComponent.success]
        key: `triggerIntegrations.visit-link.stepCompletionTitleComponent.success`,
        ns: 'app-cohort',
        value: data.url,
      }}
    />
  </div>
);

export default CohortVisitLinkTriggerIntegrationStepCompletionTitleComponent;
