import type {PerkIntegrationSpec, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {z} from 'zod';

export const ShopifyDiscountPerkTypeSchema = z.enum(['percentage', 'fixed-amount']);
export type ShopifyDiscountPerkType = z.infer<typeof ShopifyDiscountPerkTypeSchema>;

export const ShopifyDiscountConfigSchema = z
  .object({
    name: z.string().min(1, {message: 'errorRequired'}),
    type: ShopifyDiscountPerkTypeSchema,
    value: z.number().min(1, {message: 'errorRequired'}),
    singleUse: z.boolean(),
  })
  .superRefine(({type, value}, ctx) => {
    if (type === 'percentage' && value > 100) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'errorValue',
        path: ['value'],
      });
    }
  });
export type ShopifyDiscountConfig = z.infer<typeof ShopifyDiscountConfigSchema>;

export const ShopifyDiscountStateSchema = z.object({
  segmentId: z.string(),
  segmentCode: z.string(),
  discountId: z.string(),
});
export type ShopifyDiscountState = z.infer<typeof ShopifyDiscountStateSchema>;

export const ActivatedShopifyDiscountAccessDataSchema = z.object({
  redeemCode: z.string(),
  redeemCodeId: z.string(),
  discountUrl: z.string(),
  discountId: z.string(),
  userId: z.string(),
});
export type ActivatedShopifyDiscountAccessData = z.infer<
  typeof ActivatedShopifyDiscountAccessDataSchema
>;
export const ShopifyDiscountAccessDataSchema = z.union([
  ActivatedShopifyDiscountAccessDataSchema,
  z.object({}),
]);
export type ShopifyDiscountAccessData = z.infer<typeof ShopifyDiscountAccessDataSchema>;

export const ShopifyDiscountUsageDataSchema = z.object({
  shopifyOrderId: z.string(),
  shopifyOrderUrl: z.string(),
});
export type ShopifyDiscountUsageData = z.infer<typeof ShopifyDiscountUsageDataSchema>;

export const ExposedShopifyDiscountUsageDataSchema = z.object({
  shopifyOrderId: z.string(),
});
export type ExposedShopifyDiscountUsageData = z.infer<typeof ExposedShopifyDiscountUsageDataSchema>;

export type ShopifyDiscountPerkIntegrationStruct = PerkIntegrationStruct<
  'shopify.discount',
  ShopifyDiscountConfig,
  ShopifyDiscountState,
  ShopifyDiscountAccessData,
  null,
  ShopifyDiscountUsageData,
  ExposedShopifyDiscountUsageData
>;

export const ShopifyDiscountPerkIntegrationSpec: PerkIntegrationSpec<ShopifyDiscountPerkIntegrationStruct> =
  {
    id: 'shopify.discount' as const,
    configSchema: ShopifyDiscountConfigSchema,
    stateSchema: ShopifyDiscountStateSchema,
    accessDataSchema: ShopifyDiscountAccessDataSchema,
    usageInputDataSchema: z.null(),
    internalUsageDataSchema: ShopifyDiscountUsageDataSchema,
    exposedUsageDataSchema: ExposedShopifyDiscountUsageDataSchema,
    defaultMaxUsagesPerToken: null,
    requiresMerchantConnection: true,
  };
