import type {PerkIntegration} from '@cohort/merchants/apps';
import {getPerkIntegrations} from '@cohort/merchants/apps';
import {useApps} from '@cohort/merchants/apps/useApps';
import type {AppId} from '@cohort/shared/apps';
import type {PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {camelCase} from 'lodash';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export type Output<T extends PerkIntegrationStruct> = {
  getPerkIntegration: (perkIntegrationId: T['Id']) => PerkIntegration<T>;
  getPerkIntegrationTitle: (integration: PerkIntegration<T>) => string;
  getPerkIntegrationDescription: (integration: PerkIntegration<T>) => string;
  getPerkIntegrationAppId: (integration: PerkIntegration<T>) => AppId;
  perkIntegrationDisabled: (integration: PerkIntegration<T>) => boolean;
};

export const usePerkIntegrations = <T extends PerkIntegrationStruct>(): Output<T> => {
  const {t} = useTranslation();
  const {appDisabled} = useApps();
  const flags = useFlags();

  const getPerkIntegration = useCallback((perkIntegrationId: T['Id']): PerkIntegration<T> => {
    const perkIntegrations = getPerkIntegrations();
    const perkIntegration = perkIntegrations.find(
      integration => integration.spec.id === perkIntegrationId
    );

    if (!perkIntegration) {
      throw new Error(`No PerkIntegration found for perkIntegrationId: ${perkIntegrationId}`);
    }
    return perkIntegration as unknown as PerkIntegration<T>;
  }, []);

  const getPerkIntegrationTitle = (integration: PerkIntegration<T>): string => {
    const [appId, integrationId] = integration.spec.id.split('.');
    return t(`perkIntegrations.${integrationId}.title`, {
      ns: `app-${appId}`,
    });
  };

  const getPerkIntegrationDescription = (integration: PerkIntegration<T>): string => {
    const [appId, integrationId] = integration.spec.id.split('.');
    return t(`perkIntegrations.${integrationId}.description`, {
      ns: `app-${appId}`,
    });
  };

  const getPerkIntegrationAppId = (integration: PerkIntegration<T>): AppId => {
    const [appId] = integration.spec.id.split('.');
    return appId as AppId;
  };

  const perkIntegrationDisabled = (integration: PerkIntegration<T>): boolean => {
    const appId = getPerkIntegrationAppId(integration);
    if (appDisabled(appId)) {
      return true;
    }
    const perkIntegrationId = integration.spec.id.split('.').pop();
    const flag = flags[camelCase(`apps.${appId}.perks.${perkIntegrationId}`)];
    return flag === false;
  };

  return {
    getPerkIntegration,
    getPerkIntegrationTitle,
    getPerkIntegrationDescription,
    getPerkIntegrationAppId,
    perkIntegrationDisabled,
  };
};
