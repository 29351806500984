import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortChallengesCountUserPropertyStruct = UserPropertyStruct<
  'cohort.challenges-count',
  number
>;

export const CohortChallengesCountUserPropertySpec: UserPropertySpec<CohortChallengesCountUserPropertyStruct> =
  {
    id: 'cohort.challenges-count',
    name: 'Number of Challenges',
    dataType: 'number',
    scope: 'global',
    defaultValue: 0,
  };
