import {z} from 'zod';

export const DiscordGuildSchema = z.object({
  id: z.string(),
  name: z.string(),
  system_channel_id: z.string().nullable(),
});
export type DiscordGuild = z.infer<typeof DiscordGuildSchema>;

export const DiscordRoleSchema = z.object({
  id: z.string(),
  name: z.string(),
  permissions: z.string(),
});
export type DiscordRole = z.infer<typeof DiscordRoleSchema>;
export const DiscordRolesSchema = z.array(DiscordRoleSchema);
export type DiscordRoles = z.infer<typeof DiscordRoleSchema>;
