import {create} from 'zustand';

export interface MembershipPassConfigurationPageStore {
  membershipPassConfigurationPagePreview?: React.ReactNode;
  setmembershipPassConfigurationPagePreview: (
    membershipPassConfigurationPagePreview: React.ReactNode
  ) => void;
}

export const useMembershipPassConfigurationPageStore = create<MembershipPassConfigurationPageStore>(
  set => ({
    membershipPassConfigurationPagePreview: undefined,
    setmembershipPassConfigurationPagePreview: membershipPassConfigurationPagePreview =>
      set({membershipPassConfigurationPagePreview}),
  })
);
