import type {Language, LocalizedString} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const GarminConnectTriggerIntegrationActivityGroups = [
  'RUNNING',
  'CYCLING',
  'WALKING',
  'SWIMMING',
] as const;
export type GarminConnectTriggerIntegrationActivityGroupsType =
  (typeof GarminConnectTriggerIntegrationActivityGroups)[number];

export const GarminConnectTriggerIntegrationActivityTypes = [
  'RUNNING',
  'INDOOR_RUNNING',
  'OBSTACLE_RUN',
  'STREET_RUNNING',
  'TRACK_RUNNING',
  'TRAIL_RUNNING',
  'TREADMILL_RUNNING',
  'ULTRA_RUN',
  'VIRTUAL_RUN',
  'CYCLING',
  'BMX',
  'CYCLOCROSS',
  'DOWNHILL_BIKING',
  'E_BIKE_FITNESS',
  'E_BIKE_MOUNTAIN',
  'GRAVEL_CYCLING',
  'HANDCYCLING',
  'INDOOR_CYCLING',
  'INDOOR_HANDCYCLING',
  'MOUNTAIN_BIKING',
  'RECUMBENT_CYCLING',
  'ROAD_BIKING',
  'TRACK_CYCLING',
  'VIRTUAL_RIDE',
  'FITNESS_EQUIPMENT',
  'BOULDERING',
  'ELLIPTICAL',
  'HIIT',
  'INDOOR_CARDIO',
  'INDOOR_CLIMBING',
  'INDOOR_ROWING',
  'ONSHORE_GRINDING',
  'PILATES',
  'STAIR_CLIMBING',
  'STRENGTH_TRAINING',
  'YOGA',
  'SWIMMING',
  'LAP_SWIMMING',
  'OPEN_WATER_SWIMMING',
  'WALKING',
  'CASUAL_WALKING',
  'SPEED_WALKING',
  'HIKING',
  'WINTER_SPORTS',
  'BACKCOUNTRY_SKIING',
  'BACKCOUNTRY_SNOWBOARDING',
  'CROSS_COUNTRY_SKIING_WS',
  'RESORT_SKIING_SNOWBOARDING_WS',
  'SKATE_SKIING_WS',
  'SKATING_WS',
  'SNOW_SHOE_WS',
  'SNOWMOBILING_WS',
  'WATER_SPORTS',
  'BOATING',
  'FISHING',
  'KAYAKING',
  'KITEBOARDING',
  'OFFSHORE_GRINDING',
  'ROWING',
  'SAILING',
  'SNORKELING',
  'STAND_UP_PADDLEBOARDING',
  'SURFING',
  'WAKEBOARDING',
  'WATERSKIING',
  'WHITEWATER_RAFTING',
  'WINDSURFING',
  'TEAM_SPORTS',
  'AMERICAN_FOOTBALL',
  'BASEBALL',
  'BASKETBALL',
  'CRICKET',
  'FIELD_HOCKEY',
  'ICE_HOCKEY',
  'LACROSSE',
  'RUGBY',
  'SOCCER',
  'SOFTBALL',
  'ULTIMATE_DISC',
  'VOLLEYBALL',
  'RACKET_SPORTS',
  'BADMINTON',
  'PADEL',
  'PICKLEBALL',
  'PLATFORM_TENNIS',
  'RACQUETBALL',
  'SQUASH',
  'TABLE_TENNIS',
  'TENNIS',
  'PARA_SPORTS',
  'WHEELCHAIR_PUSH_RUN',
  'WHEELCHAIR_PUSH_WALK',
  'OTHER',
  'BOXING',
  'BREATHWORK',
  'DISC_GOLF',
  'FLOOR_CLIMBING',
  'GOLF',
  'INLINE_SKATING',
  'MIXED_MARTIAL_ARTS',
  'MOUNTAINEERING',
  'ROCK_CLIMBING',
  'STOP_WATCH',

  // NOT IN DOCS
  'DIVING',
  'APNEA',
  'APNEA_HUNTING',
  'CGR_DIVING',
  'GAUGE_DIVING',
  'MULTI_GAS_DIVING',
  'SINGLE_GAS_DIVING',
  'CANOEING',
  'WAKESURFING',
  'TUBING',
  'ARCHERY',
  'ATV',
  'AUTO_RACING',
  'DRIVING',
  'GAMING',
  'FLYING',
  'HAND_GLIDING',
  'HORSEBACK_RIDING',
  'HUNTING',
  'MEDITATING',
  'MOTOCROSS',
  'MOTORCYCLING',
  'OVERLAND',
  'DRONE',
  'SKY_DIVING',
  'WINGSUIT_FLYING',
] as const;
export type GarminConnectTriggerIntegrationActivityTypes =
  (typeof GarminConnectTriggerIntegrationActivityTypes)[number];

export const GarminConnectTriggerIntegrationActivityTypesMappedByGroups: Record<
  GarminConnectTriggerIntegrationActivityGroupsType,
  GarminConnectTriggerIntegrationActivityTypes[]
> = {
  RUNNING: [
    'RUNNING',
    'INDOOR_RUNNING',
    'OBSTACLE_RUN',
    'STREET_RUNNING',
    'TRACK_RUNNING',
    'TRAIL_RUNNING',
    'TREADMILL_RUNNING',
    'ULTRA_RUN',
    'VIRTUAL_RUN',
    'WHEELCHAIR_PUSH_RUN',
  ],
  CYCLING: [
    'CYCLING',
    'BMX',
    'CYCLOCROSS',
    'DOWNHILL_BIKING',
    'E_BIKE_FITNESS',
    'E_BIKE_MOUNTAIN',
    'GRAVEL_CYCLING',
    'HANDCYCLING',
    'INDOOR_CYCLING',
    'INDOOR_HANDCYCLING',
    'MOUNTAIN_BIKING',
    'RECUMBENT_CYCLING',
    'ROAD_BIKING',
    'TRACK_CYCLING',
    'VIRTUAL_RIDE',
  ],
  WALKING: ['WALKING', 'CASUAL_WALKING', 'SPEED_WALKING', 'HIKING', 'WHEELCHAIR_PUSH_WALK'],
  SWIMMING: ['SWIMMING', 'LAP_SWIMMING', 'OPEN_WATER_SWIMMING'],
} as const;

export const GarminConnectTriggerIntegrationAvailableActivityGroupsSchema = z.enum(
  GarminConnectTriggerIntegrationActivityGroups
);
export const GarminConnectTriggerIntegrationAvailableActivityTypesSchema = z.enum(
  GarminConnectTriggerIntegrationActivityTypes
);

export const GarminConnectTriggerIntegrationActivityGroupsTranslation: Record<
  GarminConnectTriggerIntegrationActivityGroupsType,
  LocalizedString
> = {
  RUNNING: {en: 'Running', fr: 'Course à pied', es: 'Correr'},
  CYCLING: {en: 'Cycling', fr: 'Cyclisme', es: 'Ciclismo'},
  WALKING: {en: 'Walking', fr: 'Marche à pied', es: 'Caminata'},
  SWIMMING: {en: 'Swimming', fr: 'Natation', es: 'Natación'},
};

export const GarminConnectTriggerIntegrationActivityTypesTranslation: Record<
  GarminConnectTriggerIntegrationActivityTypes,
  LocalizedString
> = {
  RUNNING: {en: 'Running', fr: 'Course à pied', es: 'Correr'},
  INDOOR_RUNNING: {en: 'Indoor Running', fr: 'Course en salle', es: 'Correr en interiores'},
  OBSTACLE_RUN: {
    en: 'Obstacle Course Racing',
    fr: "Course d'obstacles",
    es: 'Carrera de obstáculos',
  },
  STREET_RUNNING: {en: 'Street Running', fr: 'Course à pied urbaine', es: 'Correr en la calle'},
  TRACK_RUNNING: {en: 'Track Running', fr: 'Course à pied sur piste', es: 'Correr en pista'},
  TRAIL_RUNNING: {en: 'Trail Running', fr: 'Trail', es: 'Correr en sendero'},
  TREADMILL_RUNNING: {
    en: 'Treadmill Running',
    fr: 'Course à pied sur tapis roulant',
    es: 'Correr en cinta de correr',
  },
  ULTRA_RUN: {en: 'Ultra Running', fr: 'Ultrafond', es: 'Ultra Running'},
  VIRTUAL_RUN: {en: 'Virtual Running', fr: 'Course virtuelle', es: 'Correr virtualmente'},
  CYCLING: {en: 'Cycling', fr: 'Cyclisme', es: 'Ciclismo'},
  BMX: {en: 'BMX', fr: 'BMX', es: 'BMX'},
  CYCLOCROSS: {en: 'Cyclocross', fr: 'Cyclocross', es: 'Ciclocross'},
  DOWNHILL_BIKING: {en: 'Downhill Biking', fr: 'Cyclisme de descente', es: 'Bicicleta de descenso'},
  E_BIKE_FITNESS: {en: 'eBiking', fr: 'Vélo électrique', es: 'Bicicleta eléctrica'},
  E_BIKE_MOUNTAIN: {
    en: 'eMountain Biking',
    fr: 'VTT électrique',
    es: 'Bicicleta de montaña eléctrica',
  },
  GRAVEL_CYCLING: {
    en: 'Gravel/Unpaved Cycling',
    fr: 'Gravel bike',
    es: 'Ciclismo en gravilla/sin pavimentar',
  },
  HANDCYCLING: {en: 'Handcycling', fr: 'Cyclisme à main', es: 'Handcycling'},
  INDOOR_CYCLING: {en: 'Indoor Cycling', fr: "Vélo d'intérieur", es: 'Ciclismo en interiores'},
  INDOOR_HANDCYCLING: {
    en: 'Indoor Handcycling',
    fr: "Cyclisme à main d'intérieur",
    es: 'Handcycling en interiores',
  },
  MOUNTAIN_BIKING: {en: 'Mountain Biking', fr: 'VTT', es: 'Bicicleta de montaña'},
  RECUMBENT_CYCLING: {en: 'Recumbent Cycling', fr: 'Vélo couché', es: 'Ciclismo reclinado'},
  ROAD_BIKING: {en: 'Road Cycling', fr: 'Cyclisme sur route', es: 'Ciclismo en carretera'},
  TRACK_CYCLING: {en: 'Track Cycling', fr: 'Cyclisme sur piste', es: 'Ciclismo en pista'},
  VIRTUAL_RIDE: {en: 'Virtual Cycling', fr: 'Cyclisme virtuel', es: 'Ciclismo virtual'},
  FITNESS_EQUIPMENT: {
    en: 'Gym & Fitness Equipment',
    fr: 'Appareil de musculation et de fitness',
    es: 'Equipo de gimnasio y fitness',
  },
  BOULDERING: {en: 'Bouldering', fr: 'Escalade sur bloc', es: 'Escalada en bloque'},
  ELLIPTICAL: {en: 'Elliptical', fr: 'Vélo elliptique', es: 'Elíptica'},
  HIIT: {en: 'HIIT', fr: 'HIIT', es: 'HIIT'},
  INDOOR_CARDIO: {en: 'Cardio', fr: 'Cardio', es: 'Cardio en interiores'},
  INDOOR_CLIMBING: {en: 'Indoor Climbing', fr: 'Escalade en salle', es: 'Escalada en interiores'},
  INDOOR_ROWING: {en: 'Indoor Rowing', fr: "Rameur d'intérieur", es: 'Remo en interiores'},
  ONSHORE_GRINDING: {
    en: 'Onshore Grinding',
    fr: 'Grinding en navigation',
    es: 'Molienda en tierra',
  },
  PILATES: {en: 'Pilates', fr: 'Pilates', es: 'Pilates'},
  STAIR_CLIMBING: {en: 'Stair Stepper', fr: 'Stepper', es: 'Escaladora'},
  STRENGTH_TRAINING: {en: 'Strength Training', fr: 'Musculation', es: 'Entrenamiento de fuerza'},
  YOGA: {en: 'Yoga', fr: 'Yoga', es: 'Yoga'},
  SWIMMING: {en: 'Swimming', fr: 'Natation', es: 'Natación'},
  LAP_SWIMMING: {en: 'Pool Swimming', fr: 'En piscine', es: 'Natación en piscina'},
  OPEN_WATER_SWIMMING: {
    en: 'Open Water Swimming',
    fr: 'Natation en eau libre',
    es: 'Natación en aguas abiertas',
  },
  WALKING: {en: 'Walking', fr: 'Marche à pied', es: 'Caminata'},
  CASUAL_WALKING: {en: 'Casual Walking', fr: 'Marche', es: 'Caminata casual'},
  SPEED_WALKING: {en: 'Speed Walking', fr: 'Marche rapide', es: 'Caminata rápida'},
  HIKING: {en: 'Hiking', fr: 'Randonnée', es: 'Senderismo'},
  WINTER_SPORTS: {en: 'Winter Sports', fr: "Sports d'hiver", es: 'Deportes de invierno'},
  BACKCOUNTRY_SKIING: {en: 'Backcountry Skiing', fr: 'Ski de randonnée', es: 'Esquí de travesía'},
  BACKCOUNTRY_SNOWBOARDING: {
    en: 'Backcountry Snowboarding',
    fr: 'Snowboard backcountry',
    es: 'Snowboard de travesía',
  },
  CROSS_COUNTRY_SKIING_WS: {
    en: 'Cross Country Classic Skiing',
    fr: 'Ski de fond classique',
    es: 'Esquí de fondo clásico',
  },
  RESORT_SKIING_SNOWBOARDING_WS: {
    en: 'Resort Skiing/Snowboarding',
    fr: 'Ski en station',
    es: 'Esquí/Snowboard en estación',
  },
  SKATE_SKIING_WS: {
    en: 'Cross Country Skate Skiing',
    fr: 'Ski de fond skating',
    es: 'Esquí de fondo estilo patinaje',
  },
  SKATING_WS: {en: 'Skating', fr: 'Patin à glace/à roulettes', es: 'Patinaje'},
  SNOW_SHOE_WS: {en: 'Snowshoeing', fr: 'Raquette à neige', es: 'Senderismo en nieve'},
  SNOWMOBILING_WS: {en: 'Snowmobiling', fr: 'Motoneige', es: 'Motos de nieve'},
  WATER_SPORTS: {en: 'Water Sports', fr: 'Sports nautiques', es: 'Deportes acuáticos'},
  BOATING: {en: 'Boating', fr: 'Bateau', es: 'Navegación'},
  FISHING: {en: 'Fishing', fr: 'Pêche', es: 'Pesca'},
  KAYAKING: {en: 'Kayaking', fr: 'Canoë kayak', es: 'Kayak'},
  KITEBOARDING: {en: 'Kiteboarding', fr: 'Kiteboard', es: 'Kiteboarding'},
  OFFSHORE_GRINDING: {
    en: 'Offshore Grinding',
    fr: 'Entraînement au Grinding',
    es: 'Entrenamiento en alta mar',
  },
  ROWING: {en: 'Rowing', fr: 'Aviron', es: 'Remo'},
  SAILING: {en: 'Sailing', fr: 'Navigation à la voile', es: 'Vela'},
  SNORKELING: {en: 'Snorkeling', fr: 'Plongée avec tuba', es: 'Buceo con tubo'},
  STAND_UP_PADDLEBOARDING: {
    en: 'Stand Up Paddleboarding',
    fr: 'Stand up paddle',
    es: 'Paddleboarding de pie',
  },
  SURFING: {en: 'Surfing', fr: 'Surf', es: 'Surf'},
  WAKEBOARDING: {en: 'Wakeboarding', fr: 'Wakeboard', es: 'Wakeboarding'},
  WATERSKIING: {en: 'Waterskiing', fr: 'Ski nautique', es: 'Esquí acuático'},
  WHITEWATER_RAFTING: {en: 'Whitewater', fr: 'Eaux vives', es: 'Rafting en aguas bravas'},
  WINDSURFING: {en: 'Windsurfing', fr: 'Planche à voile', es: 'Windsurf'},
  TEAM_SPORTS: {en: 'Team Sports', fr: 'Sports collectifs', es: 'Deportes de equipo'},
  AMERICAN_FOOTBALL: {en: 'American Football', fr: 'Football américain', es: 'Fútbol americano'},
  BASEBALL: {en: 'Baseball', fr: 'Baseball', es: 'Béisbol'},
  BASKETBALL: {en: 'Basketball', fr: 'Basket-ball', es: 'Baloncesto'},
  CRICKET: {en: 'Cricket', fr: 'Cricket', es: 'Críquet'},
  FIELD_HOCKEY: {en: 'Field Hockey', fr: 'Hockey sur gazon', es: 'Hockey sobre césped'},
  ICE_HOCKEY: {en: 'Ice Hockey', fr: 'Hockey sur glace', es: 'Hockey sobre hielo'},
  LACROSSE: {en: 'Lacrosse', fr: 'Crosse', es: 'Lacrosse'},
  RUGBY: {en: 'Rugby', fr: 'Rugby', es: 'Rugby'},
  SOCCER: {en: 'Soccer/Football', fr: 'Football', es: 'Fútbol'},
  SOFTBALL: {en: 'Softball', fr: 'Softball', es: 'Softbol'},
  ULTIMATE_DISC: {en: 'Ultimate Disc', fr: 'Ultimate', es: 'Disco Ultimate'},
  VOLLEYBALL: {en: 'Volleyball', fr: 'Volley-ball', es: 'Voleibol'},
  RACKET_SPORTS: {en: 'Racket Sports', fr: 'Sports de raquette', es: 'Deportes de raqueta'},
  BADMINTON: {en: 'Badminton', fr: 'Badminton', es: 'Bádminton'},
  PADEL: {en: 'Padel', fr: 'Padel', es: 'Pádel'},
  PICKLEBALL: {en: 'Pickleball', fr: 'Pickleball', es: 'Pickleball'},
  PLATFORM_TENNIS: {en: 'Platform Tennis', fr: 'Tennis sur plateforme', es: 'Tenis de plataforma'},
  RACQUETBALL: {en: 'Racquetball', fr: 'Racquetball', es: 'Racquetball'},
  SQUASH: {en: 'Squash', fr: 'Squash', es: 'Squash'},
  TABLE_TENNIS: {en: 'Table Tennis', fr: 'Tennis de table', es: 'Tenis de mesa'},
  TENNIS: {en: 'Tennis', fr: 'Tennis', es: 'Tenis'},
  PARA_SPORTS: {en: 'Parasports', fr: 'Parasports', es: 'Deportes para personas con discapacidad'},
  WHEELCHAIR_PUSH_RUN: {
    en: 'Pushing - Run Speed',
    fr: 'Poussée - Vitesse de course',
    es: 'Empujar - Velocidad de carrera',
  },
  WHEELCHAIR_PUSH_WALK: {
    en: 'Pushing - Walk Speed',
    fr: 'Poussée - Vitesse de marche',
    es: 'Empujar - Velocidad de caminata',
  },
  OTHER: {en: 'Other', fr: 'Autre', es: 'Otro'},
  BOXING: {en: 'Boxing', fr: 'Boxe', es: 'Boxeo'},
  BREATHWORK: {en: 'Breathwork', fr: 'Exercice de respiration', es: 'Respiración'},
  DISC_GOLF: {en: 'Disc Golf', fr: 'Disc Golf', es: 'Golf de disco'},
  FLOOR_CLIMBING: {en: 'Floor Climbing', fr: "Montée d'étages", es: 'Escalada de pisos'},
  GOLF: {en: 'Golf', fr: 'Golf', es: 'Golf'},
  INLINE_SKATING: {en: 'Inline Skating', fr: 'Roller', es: 'Patinaje en línea'},
  MIXED_MARTIAL_ARTS: {
    en: 'Mixed Martial Arts',
    fr: 'Arts martiaux mixtes',
    es: 'Artes marciales mixtas',
  },
  MOUNTAINEERING: {en: 'Mountaineering', fr: 'Alpinisme', es: 'Montañismo'},
  ROCK_CLIMBING: {en: 'Rock Climbing', fr: 'Escalade', es: 'Escalada en roca'},
  STOP_WATCH: {en: 'Stopwatch', fr: 'Chronomètre', es: 'Cronómetro'},

  // NOT IN DOCS
  DIVING: {en: 'Diving', fr: 'Plongée', es: 'Buceo'},
  APNEA: {en: 'Apnea', fr: 'Apnée', es: 'Apnea'},
  APNEA_HUNTING: {en: 'Apnea Hunt', fr: 'Chasse en apnée', es: 'Caza de apnea'},
  CGR_DIVING: {en: 'CCR Dive', fr: 'Plongée avec RCF', es: 'Buceo CCR'},
  GAUGE_DIVING: {
    en: 'Gauge Dive',
    fr: 'Plongée avec profondimètre',
    es: 'Buceo con medidor de profundidad',
  },
  MULTI_GAS_DIVING: {en: 'Multi-Gas Dive', fr: 'Plongée multigaz', es: 'Buceo con múltiples gases'},
  SINGLE_GAS_DIVING: {en: 'Single-Gas Dive', fr: 'Plongée monogaz', es: 'Buceo con un solo gas'},
  CANOEING: {en: 'Canoeing', fr: 'Canoë', es: 'Canoísmo'},
  WAKESURFING: {en: 'Wakesurfing', fr: 'Wakesurf', es: 'Wakesurf'},
  TUBING: {en: 'Tubing', fr: 'Bouée gonflable', es: 'Tubing'},
  ARCHERY: {en: 'Archery', fr: "Tir à l'arc", es: 'Tiro con arco'},
  ATV: {en: 'ATV', fr: 'Quad', es: 'ATV'},
  AUTO_RACING: {en: 'Auto Racing', fr: 'Course auto', es: 'Carreras de autos'},
  DRIVING: {en: 'Driving', fr: 'Conduite', es: 'Conducción'},
  GAMING: {en: 'Gaming', fr: 'Jeux', es: 'Videojuegos'},
  FLYING: {en: 'Flying', fr: 'Aviation', es: 'Vuelo'},
  HAND_GLIDING: {en: 'Hang Gliding', fr: 'Deltaplane', es: 'Vuelo en ala delta'},
  HORSEBACK_RIDING: {en: 'Horseback Riding', fr: 'Equitation', es: 'Equitación'},
  HUNTING: {en: 'Hunting', fr: 'Chasse', es: 'Caza'},
  MEDITATING: {en: 'Meditation', fr: 'Méditation', es: 'Meditación'},
  MOTOCROSS: {en: 'Motocross', fr: 'Motocross', es: 'Motocross'},
  MOTORCYCLING: {en: 'Motorcycling', fr: 'Moto-cross', es: 'Motociclismo'},
  OVERLAND: {en: 'Overland', fr: 'Overlanding', es: 'Viaje por tierra'},
  DRONE: {en: 'RC/Drone', fr: 'Télécommande Radio du Drone', es: 'RC/Drone'},
  SKY_DIVING: {en: 'Sky Diving', fr: 'Parachutisme', es: 'Paracaidismo'},
  WINGSUIT_FLYING: {en: 'Wingsuit Flying', fr: 'Vol en wingsuit', es: 'Vuelo en traje de alas'},
  // 191: {en: 'Bike to Run Transition', fr: 'Transition entre vélo et course'},
  // 192: {en: 'Run to Bike Transition', fr: 'Transition entre course et vélo'},
  // 190: {en: 'Swim to Bike Transition', fr: 'Transition entre natation et vélo'},
  // 189: {en: 'Transition', fr: 'Transition'},
};

export function getGarminConnectActivityGroupLabel(
  activityGroup: GarminConnectTriggerIntegrationActivityGroupsType,
  language: Language
): string {
  return (
    GarminConnectTriggerIntegrationActivityGroupsTranslation[activityGroup][language] ??
    GarminConnectTriggerIntegrationActivityGroupsTranslation[activityGroup].en ??
    activityGroup
  );
}

export function getGarminConnectActivityTypeLabel(
  activityType: GarminConnectTriggerIntegrationActivityTypes,
  language: Language
): string {
  return (
    GarminConnectTriggerIntegrationActivityTypesTranslation[activityType][language] ??
    GarminConnectTriggerIntegrationActivityTypesTranslation[activityType].en ??
    activityType
  );
}

export const GarminConnectActivitySchema = z.object({
  userId: z.string(),
  summaryId: z.string(),
  activityId: z.number(),
  activityName: z.string(),
  durationInSeconds: z.number().optional(),
  startTimeInSeconds: z.number(),
  averageSpeedInMetersPerSecond: z.number().optional(),
  distanceInMeters: z.number().optional(),
  totalElevationGainInMeters: z.number().optional(),
  activityType: GarminConnectTriggerIntegrationAvailableActivityTypesSchema,
});

export type GarminConnectActivity = z.infer<typeof GarminConnectActivitySchema>;
