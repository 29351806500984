import type {ConnectorStruct} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import type {TwitterProfileProperties} from '@cohort/shared/apps/twitter/common';
import {TwitterProfilePropertiesSchema} from '@cohort/shared/apps/twitter/common';

export type TwitterConnectorStruct = ConnectorStruct<
  'twitter',
  OAuth2AppCredentials,
  OAuth2Credentials,
  TwitterProfileProperties,
  null
>;

export const TwitterConnectorSpec = basicOauth2ConnectorSpec<TwitterConnectorStruct>(
  'twitter',
  TwitterProfilePropertiesSchema
);
