import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Image} from '@phosphor-icons/react';
import React, {Fragment} from 'react';

interface Props {
  perk: {
    bannerFileKey: string | null;
    thumbnailFileKey: string | null;
  };
  className?: string;
}

export const PerkVisual: React.FC<Props> = ({perk, className}) => (
  <Fragment>
    {perk.bannerFileKey !== null ? (
      <img
        className={cn('h-12 rounded-md border border-slate-100 object-cover', className)}
        src={getImageUrl(import.meta.env.COHORT_ENV, perk.bannerFileKey, {h: Sizes.S, w: Sizes.S})}
      />
    ) : perk.thumbnailFileKey !== null ? (
      <img
        className={cn('h-12 rounded-md border border-slate-100 object-cover', className)}
        src={getImageUrl(import.meta.env.COHORT_ENV, perk.thumbnailFileKey, {
          h: Sizes.S,
          w: Sizes.S,
        })}
      />
    ) : (
      <div
        className={cn(
          'flex h-12 w-20 flex-col items-center justify-center rounded-md border border-slate-100 bg-slate-100 text-slate-400',
          className
        )}
      >
        <Image size={28} className="text-slate-400" />
      </div>
    )}
  </Fragment>
);
