import Button from '@cohort/merchants/components/buttons/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {Newspaper} from '@phosphor-icons/react';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  title: string;
  content: string | JSX.Element;
  submitButtonText: string;
  submitButtonDisabled?: boolean;
  show: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  loading?: boolean;
  icon?: JSX.Element;
}

export const ConfirmationModal = ({
  title,
  content,
  submitButtonText,
  submitButtonDisabled,
  show,
  onClose,
  onSubmit,
  loading,
  icon,
}: Props): JSX.Element => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.confirmationModal'});
  const handleClose = useCallback(() => onClose(), [onClose]);

  return (
    <Dialog open={show} onOpenChange={handleClose}>
      <DialogContent displayCloseButton={false}>
        <div className="px-4 pb-4 sm:flex sm:items-start sm:p-6">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary/10 sm:mx-0 sm:h-10 sm:w-10">
            {icon ?? <Newspaper className="h-6 w-6 text-primary" aria-hidden="true" />}
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <DialogTitle className="text-lg font-medium leading-6">{title}</DialogTitle>
            <DialogDescription className="mt-2 text-sm">{content}</DialogDescription>
          </div>
        </div>
        <DialogFooter className="justify-normal px-4 py-2 sm:flex sm:flex-row-reverse">
          <Button
            type="button"
            className="ml-4"
            data-testid="confirmation-modal-submit"
            loading={loading}
            disabled={submitButtonDisabled}
            onClick={e => {
              e.preventDefault();
              onSubmit?.();
            }}
          >
            {submitButtonText}
          </Button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border bg-white px-4 py-2 text-base font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={e => {
              e.preventDefault();
              handleClose();
            }}
          >
            {t('buttonCancel')}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
ConfirmationModal.displayName = 'ConfirmationModal';
