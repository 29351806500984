import Button from '@cohort/merchants/components/buttons/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from '@cohort/merchants/components/modals/Dialog';
import {Warning} from '@phosphor-icons/react';
import {useIsMutating} from '@tanstack/react-query';
import type {MouseEvent} from 'react';
import {useTranslation} from 'react-i18next';

interface DeletionModalProps {
  title: string;
  subtitle: string;
  onClose: () => void;
  onDelete: () => void;
  show: boolean;
}

const DeletionModal: React.FC<DeletionModalProps> = ({
  title,
  subtitle,
  onClose,
  onDelete,
  show,
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'modals.deletionModal'});
  const isMutating = useIsMutating();
  const isLoading = isMutating > 0;

  return (
    <Dialog open={show} onOpenChange={onClose}>
      <DialogContent
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div data-testid="deletion-modal" className="flex flex-col items-center p-6 [width:500px]">
          <div className="rounded-full bg-red-100 p-2 text-red-600">
            <Warning className="h-8 w-8" />
          </div>
          <DialogTitle className="mt-5 text-lg font-medium">{title}</DialogTitle>
          <DialogDescription className="mt-2 text-center text-sm font-normal">
            {subtitle}
          </DialogDescription>
        </div>
        <DialogFooter className="flex w-full items-center gap-4">
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onClose();
            }}
            className="w-1/2"
          >
            {t('cancelBtn')}
          </Button>
          <Button
            data-testid="delete-button"
            loading={isLoading}
            variant="destructive"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              onDelete();
            }}
            className="w-1/2"
          >
            {t('deleteBtn')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeletionModal;
