import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {TypeformFillFormVerificationAttemptData} from '@cohort/shared/apps/typeform/triggers/fillForm';

const TypeformFillFormTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: TypeformFillFormVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const {formTitle} = data;

  return (
    <StepCompletionCard
      translationConfig={{
        // i18nOwl-ignore [app-typeform.triggerIntegrations.fill-form.stepCompletionTitleComponent.success]
        // i18nOwl-ignore [app-typeform.triggerIntegrations.fill-form.stepCompletionTitleComponent.error]
        key: `triggerIntegrations.fill-form.stepCompletionTitleComponent.${
          completionSuccess ? 'success' : 'error'
        }`,
        ns: 'app-typeform',
        value: formTitle ?? null,
      }}
    />
  );
};

export default TypeformFillFormTriggerIntegrationStepCompletionTitleComponent;
