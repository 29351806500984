import {EmailSchema} from '@cohort/shared/schema/common';

export const isEmailValid = (email: string): boolean => {
  try {
    EmailSchema.parse(email);
    return true;
  } catch {
    return false;
  }
};
