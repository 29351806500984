import type {ConnectionADto, PatchConnectionDataADto} from '@cohort/admin-schemas/connection';
import {PatchConnectionDataASchema} from '@cohort/admin-schemas/connection';
import {
  Breadcrumb,
  BreadcrumbContent,
  BreadcrumbEditableTitle,
  BreadcrumbLink,
  BreadcrumbTitle,
} from '@cohort/merchants/components/Breadcrumb';
import ConnectionDropdown from '@cohort/merchants/components/connections/ConnectionDropdown';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useCurrentApp} from '@cohort/merchants/hooks/contexts/currentApp';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {patchConnection} from '@cohort/merchants/lib/api/Connections';
import {getAppConnectionsRoute, getAppsRoute} from '@cohort/merchants/lib/Pages';
import {useQueryClient} from '@tanstack/react-query';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type ConnectionEditableTitleProps = {
  connection: ConnectionADto;
};

const ConnectionEditableTitle: React.FC<ConnectionEditableTitleProps> = ({connection}) => {
  const merchant = useCurrentMerchant();
  const queryClient = useQueryClient();

  const {mutate: updateConnection} = useCohortMutation({
    mutationFn: async (data: PatchConnectionDataADto) =>
      patchConnection(merchant.id, connection.id, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(connectionsKeys.getById(merchant.id, connection.id));
      await queryClient.invalidateQueries(connectionsKeys.list(merchant.id));
    },
  });

  return (
    <BreadcrumbEditableTitle
      value={connection.name}
      onChange={(name: string) => updateConnection({name})}
      validator={(name: string) => PatchConnectionDataASchema.safeParse({name}).success}
    />
  );
};

type Props = {
  connection?: ConnectionADto | null;
};

const ConnectionsBreadcrumb: React.FC<Props> = ({connection}) => {
  const {app, appId, appSpec} = useCurrentApp();
  const {t} = useTranslation('pages', {keyPrefix: 'apps.app.connections.breadcrumb'});
  const navigate = useNavigate();

  const appLink = (
    <div className="flex items-center gap-2">
      {React.cloneElement(app.logo, {height: 24, width: 24})}
      {appSpec.name}
    </div>
  );

  return (
    <Breadcrumb>
      <BreadcrumbContent
        backPath={
          connection === undefined ? getAppsRoute().path : getAppConnectionsRoute(appId).path
        }
      >
        <BreadcrumbLink name={t('labelApps')} path={getAppsRoute().path} />
        {connection === undefined && <BreadcrumbTitle>{appLink}</BreadcrumbTitle>}
        {connection !== undefined && (
          <Fragment>
            <BreadcrumbLink
              name={
                <div className="flex items-center gap-2">
                  {React.cloneElement(app.logo, {height: 24, width: 24})}
                  {appSpec.name}
                </div>
              }
              path={getAppConnectionsRoute(appId).path}
            />
            <BreadcrumbTitle className="flex items-center gap-2">
              {connection === null ? (
                t('titleNewConnection', {appName: appSpec.name})
              ) : (
                <ConnectionEditableTitle connection={connection} />
              )}
            </BreadcrumbTitle>
          </Fragment>
        )}
      </BreadcrumbContent>
      {connection && (
        <ConnectionDropdown
          connection={connection}
          onConnectionDeleted={() => navigate(getAppConnectionsRoute(appId).path)}
        />
      )}
    </Breadcrumb>
  );
};
export default ConnectionsBreadcrumb;
