import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {formatCohortFormResponse} from '@cohort/merchants/apps/cohort/utils/format';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortFormUsageData} from '@cohort/shared/apps/cohort/perks/form';
import type {CohortFormConfig, CohortFormQuestion} from '@cohort/shared/schema/common/cohortForm';

const CohortFormPerkIntegrationsUsageViewComponent: React.FC<PerkUsageViewComponentProps> = ({
  usage,
}) => {
  const merchant = useCurrentMerchant();
  const config = usage.integrationConfig as CohortFormConfig;

  const renderQuestion = (question: CohortFormQuestion): JSX.Element => {
    const data = usage.data as CohortFormUsageData;
    const name = question.name[merchant.defaultLanguage] ?? '';
    const response = formatCohortFormResponse(question.type, data.values[question.id]);
    return <PerkUsageLayout.Row name={name} value={String(response)} key={question.id} />;
  };

  return (
    <PerkUsageLayout.Grid>
      {config.questions.map(question => renderQuestion(question))}
    </PerkUsageLayout.Grid>
  );
};

export default CohortFormPerkIntegrationsUsageViewComponent;
