import type {ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

// Media count is nullable because old connections lack this permission
export const InstagramUserProfileSchema = BaseProfilePropertiesSchema.extend({
  id: z.string(),
  username: z.string().nullable(),
  mediaCount: z.number().nullable(),
});
export type InstagramUserProfile = z.infer<typeof InstagramUserProfileSchema>;

export type InstagramUserConnectorStruct = ConnectorStruct<
  'instagram',
  OAuth2AppCredentials,
  OAuth2Credentials,
  InstagramUserProfile,
  null
>;

export const InstagramUserConnectorSpec = basicOauth2ConnectorSpec<InstagramUserConnectorStruct>(
  'instagram',
  InstagramUserProfileSchema
);
