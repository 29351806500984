export const YOUTUBE_EMBED_URL = 'youtube.com/embed/';

export const YOUTUBE_URLS = [
  {
    url: 'youtube.com/watch',
    extractor: (url: string) => new URLSearchParams(new URL(url).search).get('v'),
  },
  {url: 'youtu.be/', extractor: (url: string) => url.split('youtu.be/')[1]},
  {url: 'youtube.com/shorts/', extractor: (url: string) => url.split('youtube.com/shorts/')[1]},
  {url: YOUTUBE_EMBED_URL, extractor: (url: string) => url.split(YOUTUBE_EMBED_URL)[1]},
];

export const buildYoutubeEmbedUrl = (videoId: string): string =>
  `https://www.${YOUTUBE_EMBED_URL}${videoId}`;
