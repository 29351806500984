import {IconBadge} from '@cohort/merchants/components/badges/IconBadge';
import {usePerkUtils} from '@cohort/merchants/hooks/usePerkUtils';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Tag} from '@phosphor-icons/react';
import React from 'react';
interface Props {
  perkType?: PerkType;
  size?: 'large' | 'small';
  iconColor?: string;
  backgroundColor?: string;
}
export const PerkTypeBadge: React.FC<Props> = ({
  perkType,
  size = 'small',
  iconColor,
  backgroundColor,
}) => {
  const {getPerkTypeData} = usePerkUtils();

  return perkType === undefined ? null : (
    <IconBadge
      size={size}
      text={getPerkTypeData(perkType).title}
      backgroundColor={backgroundColor} // bg-slate-100
      icon={
        <Tag
          height={14}
          width={14}
          className={cn(
            'mr-1 inline-block',
            iconColor ? iconColor : getPerkTypeData(perkType).iconColor
          )}
        />
      }
    />
  );
};
