import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortNewChallengeAvailableEventStruct} from '@cohort/shared/apps/cohort/userEvents/challenge/newChallengeAvailable';
import {CohortNewChallengeAvailableEventSpec} from '@cohort/shared/apps/cohort/userEvents/challenge/newChallengeAvailable';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const campaignId = (userEvent.properties as CohortNewChallengeAvailableEventStruct['Properties'])
    .campaignId;
  const campaignInternalName = userEvent.campaignInternalName;
  const navigate = useNavigate();

  return (
    <Trans
      i18nKey="userEvents.challenge.new-challenge-available.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCampaignRoute(campaignId).path)
        ),
      }}
    />
  );
};

export const CohortNewChallengeAvailableUserEvent: UserEvent<CohortNewChallengeAvailableEventStruct> =
  {
    spec: CohortNewChallengeAvailableEventSpec,
    detailComponent: DetailComponent,
  };
