import type {
  ExportUserEventsParamsInput,
  ListUserEventsParamsADto,
  UserEventADto,
} from '@cohort/admin-schemas/userEvents';
import {UserEventASchema} from '@cohort/admin-schemas/userEvents';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getUserEvents(
  merchantId: string,
  params: ListUserEventsParamsADto
): Promise<[PaginationDto, UserEventADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/user-events`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(UserEventASchema),
    params,
  });
}

export async function exportUserEvents(
  merchantId: string,
  params: ExportUserEventsParamsInput
): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/user-events/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
    params,
  });
}
