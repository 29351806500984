import {create} from 'zustand';

export interface BadgePageStore {
  footer?: React.ReactNode;
  menu?: React.ReactNode;
  setFooter: (footer: React.ReactNode) => void;
  setMenu: (menu: React.ReactNode) => void;
}

export const useBadgePageStore = create<BadgePageStore>(set => ({
  footer: undefined,
  menu: undefined,
  setFooter: footer => set({footer}),
  setMenu: menu => set({menu}),
}));
