import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCohortMembersRoute} from '@cohort/merchants/lib/Pages';
import type {CohortCohortJoinedEventStruct} from '@cohort/shared/apps/cohort/userEvents/cohort/cohortJoined';
import {CohortCohortJoinedEventSpec} from '@cohort/shared/apps/cohort/userEvents/cohort/cohortJoined';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const navigate = useNavigate();
  const cohortName = userEvent.cohortName;
  const cohortId = (userEvent.properties as CohortCohortJoinedEventStruct['Properties']).cohortId;
  return (
    <Trans
      i18nKey="userEvents.cohort.joined.detail"
      ns="app-cohort"
      values={{
        cohortName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () =>
          navigate(getCohortMembersRoute(cohortId).path)
        ),
      }}
    />
  );
};

export const CohortCohortJoinedUserEvent: UserEvent<CohortCohortJoinedEventStruct> = {
  spec: CohortCohortJoinedEventSpec,
  detailComponent: DetailComponent,
};
