import {AssetKindSchema} from '@cohort/shared/schema/common/assets';
import {z} from 'zod';

export const AssetASchema = z.object({
  fileKey: z.string(),
  previewFileKey: z.string().nullable(),
  mimeType: z.string(),
});
export type AssetADto = z.infer<typeof AssetASchema>;

export const CreateThumbnailRequestASchema = z.object({
  assetKind: AssetKindSchema,
  fileKey: z.string(),
});
export type CreateThumbnailRequestADto = z.infer<typeof CreateThumbnailRequestASchema>;

export const SignedUrlASchema = z.object({
  signedUrl: z.string(),
  fileKey: z.string(),
  maxFileSize: z.number(),
});
export type SignedUrlADto = z.infer<typeof SignedUrlASchema>;

export const ThumbnailASchema = z.object({
  thumbnailFileKey: z.string(),
});
export type ThumbnailADto = z.infer<typeof ThumbnailASchema>;
