/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import {TYPEFORM_APP_ID} from '@cohort/shared/apps/typeform';
import type {GetFormActionStruct} from '@cohort/shared/apps/typeform/actions/getForm';
import type {ListFormsActionStruct} from '@cohort/shared/apps/typeform/actions/listForms';
import type {ListWorkspacesActionStruct} from '@cohort/shared/apps/typeform/actions/listWorkspaces';

export const typeformAppKeys = {
  typeformApp: ['typeformApp'] as const,
  listWorkspaces: (merchantId: string) =>
    [...typeformAppKeys.typeformApp, merchantId, 'list-workspaces'] as const,
  listForms: (merchantId: string, merchantConnectionId: string) =>
    [...typeformAppKeys.typeformApp, merchantId, merchantConnectionId, 'list-forms'] as const,
  getForm: (merchantId: string, merchantConnectionId: string, formId: string) =>
    [...typeformAppKeys.typeformApp, merchantId, merchantConnectionId, formId, 'get-form'] as const,
};

export const useTypeformWorkspaces = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
) => {
  return useCohortQuery({
    queryKey: typeformAppKeys.listWorkspaces(merchantId),
    queryFn: async () => {
      return executeAction<ListWorkspacesActionStruct>(merchantId, {
        appId: TYPEFORM_APP_ID,
        actionId: 'list-workspaces',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};

export const useTypeformForms = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
) => {
  return useCohortQuery({
    queryKey: typeformAppKeys.listForms(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<ListFormsActionStruct>(merchantId, {
        appId: TYPEFORM_APP_ID,
        actionId: 'list-forms',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};

export const useTypeformForm = (
  merchantId: string,
  merchantConnectionId: string,
  formId: string,
  options?: QueryOptions
) => {
  return useCohortQuery({
    queryKey: typeformAppKeys.getForm(merchantId, merchantConnectionId, formId),
    queryFn: async () => {
      return executeAction<GetFormActionStruct>(merchantId, {
        appId: TYPEFORM_APP_ID,
        actionId: 'get-form',
        merchantConnectionId,
        input: {formId},
      });
    },
    ...options,
  });
};
