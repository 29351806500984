import useLazyRouteLoader from '@cohort/merchants/hooks/lazyRouteLoader';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import useDefaultRoute from '@cohort/merchants/hooks/useDefaultRoute';
import {Fragment} from 'react';
import {Navigate, Outlet, useSearchParams} from 'react-router-dom';

const NoAuthLayout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isLoggedIn = useUserSessionStore(store => store.isLoggedIn);
  const defaultRoute = useDefaultRoute();
  const loader = useLazyRouteLoader();

  if (isLoggedIn) {
    const destination = searchParams.get('destination');

    if (destination !== null) {
      return <Navigate to={destination} />;
    }
    return <Navigate to={defaultRoute} replace />;
  }
  return (
    <Fragment>
      {loader}
      <Outlet />
    </Fragment>
  );
};

export default NoAuthLayout;
