import {useTranslation} from 'react-i18next';

const DeletedBadge: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'badges.deletedBadge'});
  return (
    <div className="flex h-fit rounded-sm bg-red-100 px-2 py-0.5 text-xs font-medium text-red-500">
      {t('deleted')}
    </div>
  );
};

export default DeletedBadge;
