import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {useTranslation} from 'react-i18next';

const CohortVisitLinkTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {control, register}}) => {
  const {t} = useTranslation('app-cohort', {
    keyPrefix: 'triggerIntegrations.visit-link.configComponent',
  });

  return (
    <UrlInput
      name="triggerIntegrationConfig.url"
      label={t('labelLink')}
      register={register}
      control={control}
    />
  );
};

export default CohortVisitLinkTriggerIntegrationConfigComponent;
