/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {GetDigitalAssetsParamsADto} from '@cohort/admin-schemas/digitalAsset';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getDigitalAsset, getDigitalAssets} from '@cohort/merchants/lib/api/DigitalAssets';

export const digitalAssetsKeys = {
  digitalAssets: ['digital-assets'] as const,
  get: (merchantId: string, digitalAssetId: string) =>
    [...digitalAssetsKeys.digitalAssets, merchantId, digitalAssetId, 'get'] as const,
  list: (merchantId: string) => [...digitalAssetsKeys.digitalAssets, merchantId, 'list'] as const,
  listWithFilter: (merchantId: string, params?: GetDigitalAssetsParamsADto) =>
    [...digitalAssetsKeys.list(merchantId), params, 'list'] as const,
};

export const useDigitalAsset = (
  merchantId: string,
  digitalAssetId: string,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: digitalAssetsKeys.get(merchantId, digitalAssetId),
    queryFn: async () => getDigitalAsset(merchantId, digitalAssetId),
    ...options,
  });

export const useDigitalAssets = (
  merchantId: string,
  params: GetDigitalAssetsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: digitalAssetsKeys.listWithFilter(merchantId, params),
    queryFn: async () => getDigitalAssets(merchantId, params),
    ...options,
  });
