import {asNumberOrOriginal} from '@cohort/shared/typeUtils';
import {z} from 'zod';

export const PaginationSchema = z.object({
  page: z.number().int().min(1),
  pageSize: z.number().int().min(1),
  next: z.number().int().min(2).nullable(),
  prev: z.number().int().min(1).nullable(),
  pages: z.number().int().min(1),
  total: z.number().int().min(0),
});
export type PaginationDto = z.infer<typeof PaginationSchema>;

export const PaginationParamsSchema = z.object({
  page: z.preprocess(asNumberOrOriginal, z.number().int().min(1).default(1)),
  pageSize: z.preprocess(asNumberOrOriginal, z.number().int().min(1).max(100).default(50)),
});
export type PaginationParamsDto = z.infer<typeof PaginationParamsSchema>;

export function paginatedSchema<ItemType extends z.ZodTypeAny>(
  itemSchema: ItemType
): z.AnyZodObject {
  return z.object({
    meta: PaginationSchema,
    data: z.array(itemSchema),
  });
}
export type PaginatedResponseDto<T> = {
  meta: PaginationDto;
  data: T[];
};
