import type {ActionSpec, AppSpec, AppStruct} from '@cohort/shared/apps/app';
import {TestQueryActionSpec} from '@cohort/shared/apps/salesforce/actions/testQuery';
import type {SalesforceConnectorStruct} from '@cohort/shared/apps/salesforce/connector';
import {SalesforceConnectorSpec} from '@cohort/shared/apps/salesforce/connector';
import type {SalesforceSyncStruct} from '@cohort/shared/apps/salesforce/sync';
import {SalesforceSyncIntegrationSpec} from '@cohort/shared/apps/salesforce/sync';

export const SalesforceActionSpecs = [
  TestQueryActionSpec,
] as const satisfies ReadonlyArray<ActionSpec>;
type SalesforceActionIds = (typeof SalesforceActionSpecs)[number]['id'];

export type SalesforceAppStruct = AppStruct<
  'salesforce',
  SalesforceConnectorStruct,
  null,
  SalesforceSyncStruct,
  SalesforceActionIds,
  never,
  never,
  never,
  never,
  never,
  never,
  never
>;

export const SalesforceAppSpec: AppSpec<SalesforceAppStruct> = {
  id: 'salesforce',
  name: 'Salesforce',
  merchantConnector: SalesforceConnectorSpec,
  userConnector: null,
  syncSpec: SalesforceSyncIntegrationSpec,
  mediaSpecs: [],
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: [],
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
};
