import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {useOrganizationSlug} from '@cohort/merchants/hooks/useOrganizationSlug';
import {getHomeRoute, getSignInRoute} from '@cohort/merchants/lib/Pages';
import {Navigate, Outlet, useSearchParams} from 'react-router-dom';

const OrgPickerLayout: React.FC = () => {
  const organizationSlug = useOrganizationSlug();
  const isLoggedIn = useUserSessionStore(store => store.isLoggedIn);
  const [searchParams] = useSearchParams();
  const destinationPath = searchParams.get('destination');

  if (organizationSlug !== null) {
    return <Navigate to={destinationPath ?? getHomeRoute().path} replace />;
  }

  if (!isLoggedIn) {
    return <Navigate to={getSignInRoute().path} replace />;
  }

  return (
    <div className="h-full overflow-auto">
      <Outlet />
    </div>
  );
};

export default OrgPickerLayout;
