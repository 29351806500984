// Browsers that support localStorage will have a property on the window object named localStorage.
// However, for various reasons, just asserting that property exists may throw exceptions.
// If it does exist, that is still no guarantee that localStorage is actually available, as various browsers offer settings that disable localStorage.
// So a browser may support localStorage, but not make it available to the scripts on the page.
// One example of that is Safari, which in Private Browsing mode gives us an empty localStorage object with a quota of zero, effectively making it unusable
export default function storageAvailable(): boolean {
  try {
    const storage = window['localStorage'];
    const x = '__storage_test__';

    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    void e;
    return false;
  }
}
