import {z} from 'zod';

export const TIKTOK_URL = 'https://tiktok.com';

export const TikTokVideoSchema = z.object({
  id: z.string(),
  coverImageUrl: z.string(),
  title: z.string(),
  embedLink: z.string(),
  likeCount: z.number(),
  commentCount: z.number(),
  shareCount: z.number(),
  videoDescription: z.string(),
  shareUrl: z.string(),
  createdAt: z.date(),
});
export type TikTokVideo = z.infer<typeof TikTokVideoSchema>;
