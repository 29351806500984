export const SECONDS_IN_MINUTE = 60;
export const METERS_IN_KILOMETER = 1000;

export function toMinutes(seconds: number): number {
  return seconds / SECONDS_IN_MINUTE;
}

export function toSeconds(minutes: number): number {
  return minutes * SECONDS_IN_MINUTE;
}

export function toMetersPerSecond(kilometersPerHour: number): number {
  return kilometersPerHour / (SECONDS_IN_MINUTE ** 2 / METERS_IN_KILOMETER);
}

export function toKilometersPerHour(metersPerSecond: number): number {
  return metersPerSecond * (SECONDS_IN_MINUTE ** 2 / METERS_IN_KILOMETER);
}

export function toKilometers(meters: number): number {
  return meters / METERS_IN_KILOMETER;
}

export function toMeters(kilometers: number): number {
  return kilometers * METERS_IN_KILOMETER;
}
