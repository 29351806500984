import type {ActionSpec, ActionStruct} from '@cohort/shared/apps/app';
import {YoutubeVideoSchema} from '@cohort/shared/apps/youtube/common';
import {z} from 'zod';

export const ListVideosInputSchema = z.object({
  search: z.string().optional(),
});
export type ListVideosInput = z.infer<typeof ListVideosInputSchema>;

export const ListVideosOutputSchema = z.object({
  videos: z.array(YoutubeVideoSchema),
});
export type ListVideosOutput = z.infer<typeof ListVideosOutputSchema>;

export type ListVideosActionStruct = ActionStruct<'list-videos', ListVideosInput, ListVideosOutput>;
export const ListVideosActionSpec: ActionSpec<ListVideosActionStruct> = {
  id: 'list-videos',
  inputSchema: ListVideosInputSchema,
  outputSchema: ListVideosOutputSchema,
};
