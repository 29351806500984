import {CohortError} from '@cohort/shared/schema/common/errors';
import type {DataType} from '@cohort/shared/schema/common/rules/dataTypes';
import {z} from 'zod';

const operators = [
  {
    id: 'number.eq',
    inputDataType: 'number',
    valueDataType: 'number',
  },
  {
    id: 'number.ne',
    inputDataType: 'number',
    valueDataType: 'number',
  },
  {
    id: 'number.gt',
    inputDataType: 'number',
    valueDataType: 'number',
  },
  {
    id: 'number.gte',
    inputDataType: 'number',
    valueDataType: 'number',
  },
  {
    id: 'number.lt',
    inputDataType: 'number',
    valueDataType: 'number',
  },
  {
    id: 'number.lte',
    inputDataType: 'number',
    valueDataType: 'number',
  },
  {
    id: 'number.is_set',
    inputDataType: 'number',
    valueDataType: 'null',
  },
  {
    id: 'number.is_not_set',
    inputDataType: 'number',
    valueDataType: 'null',
  },
  {
    id: 'string.is',
    inputDataType: 'string',
    valueDataType: 'string',
  },
  {
    id: 'string.is_not',
    inputDataType: 'string',
    valueDataType: 'string',
  },
  {
    id: 'string.starts_with',
    inputDataType: 'string',
    valueDataType: 'string',
  },
  {
    id: 'string.ends_with',
    inputDataType: 'string',
    valueDataType: 'string',
  },
  {
    id: 'string.not_starts_with',
    inputDataType: 'string',
    valueDataType: 'string',
  },
  {
    id: 'string.not_ends_with',
    inputDataType: 'string',
    valueDataType: 'string',
  },
  {
    id: 'string.contains',
    inputDataType: 'string',
    valueDataType: 'string',
  },
  {
    id: 'string.not_contains',
    inputDataType: 'string',
    valueDataType: 'string',
  },
  {
    id: 'string.is_in',
    inputDataType: 'string',
    valueDataType: 'string_list',
  },
  {
    id: 'string.is_not_in',
    inputDataType: 'string',
    valueDataType: 'string_list',
  },
  {
    id: 'string.is_set',
    inputDataType: 'string',
    valueDataType: 'null',
  },
  {
    id: 'string.is_not_set',
    inputDataType: 'string',
    valueDataType: 'null',
  },
  {
    id: 'string_list.contains',
    inputDataType: 'string_list',
    valueDataType: 'string',
  },
  {
    id: 'string_list.not_contains',
    inputDataType: 'string_list',
    valueDataType: 'string',
  },
  {
    id: 'resource.is',
    inputDataType: 'resource',
    valueDataType: 'resource',
  },
  {
    id: 'resource.is_not',
    inputDataType: 'resource',
    valueDataType: 'resource',
  },
  {
    id: 'resource_list.contains',
    inputDataType: 'resource_list',
    valueDataType: 'string',
  },
  {
    id: 'resource_list.not_contains',
    inputDataType: 'resource_list',
    valueDataType: 'string',
  },
  {
    id: 'boolean.is_true',
    inputDataType: 'boolean',
    valueDataType: 'null',
  },
  {
    id: 'boolean.is_false',
    inputDataType: 'boolean',
    valueDataType: 'null',
  },
  {
    id: 'boolean.is_set',
    inputDataType: 'boolean',
    valueDataType: 'null',
  },
  {
    id: 'boolean.is_not_set',
    inputDataType: 'boolean',
    valueDataType: 'null',
  },
  {
    id: 'date.is',
    inputDataType: 'date',
    valueDataType: 'date',
  },
  {
    id: 'date.is_not',
    inputDataType: 'date',
    valueDataType: 'date',
  },
  {
    id: 'date.is_today',
    inputDataType: 'date',
    valueDataType: 'null',
  },
  {
    id: 'date.is_in_the_past',
    inputDataType: 'date',
    valueDataType: 'null',
  },
  {
    id: 'date.is_in_the_future',
    inputDataType: 'date',
    valueDataType: 'null',
  },
  {
    id: 'date.before_or_equal',
    inputDataType: 'date',
    valueDataType: 'date',
  },
  {
    id: 'date.after_or_equal',
    inputDataType: 'date',
    valueDataType: 'date',
  },
  {
    id: 'date.in_next_days',
    inputDataType: 'date',
    valueDataType: 'number',
  },
  {
    id: 'date.in_past_days',
    inputDataType: 'date',
    valueDataType: 'number',
  },
  {
    id: 'date.is_set',
    inputDataType: 'date',
    valueDataType: 'null',
  },
  {
    id: 'date.is_not_set',
    inputDataType: 'date',
    valueDataType: 'null',
  },
] as const;

export type OperatorId = (typeof operators)[number]['id'];
const operatorIds = operators.map(operator => operator.id) as unknown as readonly [
  OperatorId,
  ...OperatorId[],
];
const OperatorIdSchema = z.enum(operatorIds);

type Operator = {
  id: OperatorId;
  inputDataType: DataType;
  valueDataType: DataType;
};

export function getOperatorOptions(inputDataType: DataType): Array<Operator> {
  return operators.filter(operator => operator.inputDataType === inputDataType);
}

export function getOperatorById(id: OperatorId): Operator {
  const operator = operators.find(operator => operator.id === id);
  if (!operator) {
    throw new CohortError('rule.operator-not-found');
  }
  return operator;
}

// ZodEnum.exclude exists but doesn't seems to work, it returns never
export const UserPropertyCriterionOperatorSchema = z.enum(
  operatorIds.filter(
    id => !['resource.is', 'resource.is_not'].includes(id)
  ) as unknown as readonly [OperatorId, ...OperatorId[]]
);
export type UserPropertyCriterionOperator = z.infer<typeof UserPropertyCriterionOperatorSchema>;

export const UserEventCriterionOperatorSchema = OperatorIdSchema.extract([
  'number.eq',
  'number.ne',
  'number.gt',
  'number.gte',
  'number.lt',
  'number.lte',
]);
export type UserEventCriterionOperator = z.infer<typeof UserEventCriterionOperatorSchema>;

export const UserEventCriterionFilterOperatorSchema = OperatorIdSchema;
export type UserEventCriterionFilterOperator = z.infer<
  typeof UserEventCriterionFilterOperatorSchema
>;

export const CohortMembershipCriterionOperatorSchema = z.enum(['is_member', 'is_not_member']);
export type CohortMembershipCriterionOperator = z.infer<
  typeof CohortMembershipCriterionOperatorSchema
>;
