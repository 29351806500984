import type {ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const TikTokProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  username: z.string(),
  bioDescription: z.string().nullable(),
  followersCount: z.number(),
  followingCount: z.number(),
  likesCount: z.number(),
  videosCount: z.number(),
});

export type TikTokProfileProperties = z.infer<typeof TikTokProfilePropertiesSchema>;

export type TikTokConnectorStruct = ConnectorStruct<
  'tiktok',
  OAuth2AppCredentials,
  OAuth2Credentials,
  TikTokProfileProperties,
  null
>;

export const TikTokConnectorSpec = basicOauth2ConnectorSpec<TikTokConnectorStruct>(
  'tiktok',
  TikTokProfilePropertiesSchema
);
