// i18nOwl-ignore [app-brevo.description, app-brevo.sync.description, app-brevo.sync.title]
import type {App} from '@cohort/merchants/apps';
import BrevoEditConnectionComponent from '@cohort/merchants/apps/brevo/EditConnectionComponent';
import translationEn from '@cohort/merchants/apps/brevo/locales/en.json';
import translationFr from '@cohort/merchants/apps/brevo/locales/fr.json';
import {BrevoSyncIntegration} from '@cohort/merchants/apps/brevo/sync/SyncIntegration';
import type {BrevoAppStruct} from '@cohort/shared/apps/brevo';
import {BrevoAppSpec} from '@cohort/shared/apps/brevo';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const BrevoApp: App<BrevoAppStruct> = {
  spec: BrevoAppSpec,
  ConnectionEditComponent: BrevoEditConnectionComponent,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="brevo" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [],
  userEvents: [],
  sync: BrevoSyncIntegration,
};

export default BrevoApp;
