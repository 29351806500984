import type {CreateConnectionDataADto} from '@cohort/admin-schemas/connection';
import type {ConnectionEditComponentProps} from '@cohort/merchants/apps';
import Button from '@cohort/merchants/components/buttons/Button';
import ConnectionHeader from '@cohort/merchants/components/connections/ConnectionHeader';
import Input from '@cohort/merchants/components/form/input/Input';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {createConnection} from '@cohort/merchants/lib/api/Connections';
import type {ComarchCredentials} from '@cohort/shared/apps/comarch/connector';
import {ComarchCredentialsSchema} from '@cohort/shared/apps/comarch/connector';
import {REDACTED_FIELD} from '@cohort/shared/constants';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const ComarchEditConnectionComponent: React.FC<ConnectionEditComponentProps> = ({
  existingConnection: connectionToEdit,
  onClose,
  showCancelBtn,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {t} = useTranslation('app-comarch', {
    keyPrefix: 'editConnectionComponent',
  });

  const {register, handleSubmit, control} = useForm<ComarchCredentials>({
    resolver: zodResolver(ComarchCredentialsSchema),
    defaultValues: {
      apiUrl: connectionToEdit ? REDACTED_FIELD : undefined,
      username: connectionToEdit ? REDACTED_FIELD : undefined,
      password: connectionToEdit ? REDACTED_FIELD : undefined,
      programId: connectionToEdit ? REDACTED_FIELD : undefined,
    },
  });

  const {isLoading, mutate: createOrFixConnectionMutation} = useCohortMutation({
    mutationFn: async (data: CreateConnectionDataADto) => createConnection(merchantId, data),
    notifySuccessMessage: t('createSuccessNotification'),
    onSuccess: async connection => {
      await queryClient.invalidateQueries(connectionsKeys.list(merchantId));
      onClose?.(connection);
    },
    onError: error => {
      if (
        isCohortError(error, 'connector.credentials-invalid') &&
        error.context.cause === 'app.comarch.invalid-api-url'
      ) {
        notify('error', t('errorInvalidApiUrl'));
        return;
      }
      notify('error', t('errorInvalidCredentials'));
    },
  });

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(async data => {
          createOrFixConnectionMutation({
            connectorId: 'comarch',
            name: null,
            credentials: {
              apiUrl: data.apiUrl,
              username: data.username,
              password: data.password,
              programId: data.programId,
            },
            existingConnectionId: connectionToEdit?.id,
          });
        })(e);
      }}
      className="space-y-6 p-6"
      data-testid="create-connection-form"
      id="create-connection-form"
    >
      <ConnectionHeader appId="comarch" title={t('title')} />
      <UrlInput
        type="text"
        name="apiUrl"
        label={t('labelApiUrl')}
        register={register}
        control={control}
      />
      <Input
        type="text"
        name="programId"
        label={t('labelProgramId')}
        register={register}
        control={control}
      />
      <Input
        type="text"
        name="username"
        label={t('labelUsername')}
        register={register}
        control={control}
      />
      <Input
        type="password"
        name="password"
        label={t('labelPassword')}
        register={register}
        control={control}
      />
      <div className="flex justify-between">
        <Button
          data-testid="create-digital-asset-collection-submit-btn"
          type="submit"
          form="create-connection-form"
          loading={isLoading}
        >
          {connectionToEdit ? t('buttonReconnect') : t('buttonCreate')}
        </Button>

        {showCancelBtn && (
          <Button
            type="button"
            variant="secondary"
            className="flex gap-2"
            onClick={() => onClose?.(null)}
          >
            {t('buttonCancel')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default ComarchEditConnectionComponent;
