import type {ConnectorStruct} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import type {SpotifyProfileProperties} from '@cohort/shared/apps/spotify/schema';
import {SpotifyProfilePropertiesSchema} from '@cohort/shared/apps/spotify/schema';

export type SpotifyMerchantConnectorStruct = ConnectorStruct<
  'spotify-merchant',
  OAuth2AppCredentials,
  OAuth2Credentials,
  SpotifyProfileProperties,
  null
>;

export const SpotifyMerchantConnectorSpec =
  basicOauth2ConnectorSpec<SpotifyMerchantConnectorStruct>(
    'spotify-merchant',
    SpotifyProfilePropertiesSchema
  );
