import Button from '@cohort/merchants/components/buttons/Button';
import type {PreConnectionConfigComponentProps} from '@cohort/merchants/components/connections/OAuthEditConnectionComponent';
import Input from '@cohort/merchants/components/form/input/Input';
import {ShopifyShopIdSchema} from '@cohort/shared/apps/shopify/merchantConnector';
import {zodResolver} from '@hookform/resolvers/zod';
import {CirclesThreePlus} from '@phosphor-icons/react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const SHOPIFY_SHOP_DOMAIN = '.myshopify.com';

const ShopifyConnectionSchema = z.object({
  shopId: z.preprocess(arg => `${arg}${SHOPIFY_SHOP_DOMAIN}`, ShopifyShopIdSchema),
});
type ShopifyConnectionType = z.infer<typeof ShopifyConnectionSchema>;

const ShopifyShopPicker: React.FC<PreConnectionConfigComponentProps> = ({
  onConfigFinished,
  showCancelBtn,
  onClose,
}) => {
  const {t} = useTranslation('app-shopify', {
    keyPrefix: 'editConnection',
  });
  const {register, handleSubmit, control, watch} = useForm<ShopifyConnectionType>({
    resolver: zodResolver(ShopifyConnectionSchema),
    defaultValues: {
      shopId: '',
    },
  });

  const shopId = watch('shopId');

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(onConfigFinished)(e);
      }}
      className="w-full space-y-6"
      data-testid="start-oauth2-form"
      id="start-oauth2-form"
    >
      <Input
        insetSuffix={SHOPIFY_SHOP_DOMAIN}
        type="text"
        name="shopId"
        label={t('labelShopId')}
        register={register}
        control={control}
        description={t('descriptionShopId')}
      />

      <div className="flex justify-between">
        <Button
          data-testid="start-oauth2-submit-btn"
          type="submit"
          form="start-oauth2-form"
          className="gap-2"
          disabled={shopId.length < 1}
        >
          <CirclesThreePlus className="h-5 w-5" />
          {t('buttonSubmit')}
        </Button>
        {showCancelBtn && (
          <Button
            type="button"
            variant="secondary"
            className="flex gap-2"
            onClick={() => onClose?.(null)}
          >
            {t('buttonCancel')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default ShopifyShopPicker;
