import type {PerkAnalyticsADto} from '@cohort/admin-schemas/perk';
import NotFoundSection from '@cohort/merchants/components/error-pages/NotFoundSection';
import {usePerk} from '@cohort/merchants/hooks/api/Perks';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import SkeletonLoader from '@cohort/merchants/layouts/SkeletonLoader';
import {createContext} from 'react';
import {useParams} from 'react-router-dom';

type CurrentPerkProviderProps = {
  children: React.ReactNode;
};

export const CurrentPerkContext = createContext<PerkAnalyticsADto | null>(null);

export const CurrentPerkProvider: React.FC<CurrentPerkProviderProps> = ({children}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const {perkId} = useParams();

  const {data: perk, isFetched} = usePerk(
    merchantId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    perkId!,
    {enabled: Boolean(perkId), refetchOnWindowFocus: false}
  );

  if (perkId === undefined) {
    return <NotFoundSection />;
  }

  if (isFetched && !perk) {
    return <NotFoundSection />;
  }

  if (perk === undefined) {
    return <SkeletonLoader />;
  }

  return <CurrentPerkContext.Provider value={perk}>{children}</CurrentPerkContext.Provider>;
};
