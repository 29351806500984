import {z} from 'zod';

export const BaseResourcePropertiesSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const ResourcePropertiesSchema = BaseResourcePropertiesSchema.passthrough();
export type ResourceProperties = z.infer<typeof ResourcePropertiesSchema>;

export type ResourceStruct<Id extends string = string, Properties = ResourceProperties> = {
  Id: Id;
  Properties: Properties;
};

export type ResourceSpec<R extends ResourceStruct = ResourceStruct> = {
  id: R['Id'];
  schema: z.ZodSchema<R['Properties']>;
};
