import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import type {CohortUsernameChangedEventStruct} from '@cohort/shared/apps/cohort/userEvents/usernameChanged';
import {CohortUsernameChangedEventSpec} from '@cohort/shared/apps/cohort/userEvents/usernameChanged';
import {Trans} from 'react-i18next';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const memberHandle = (userEvent.properties as CohortUsernameChangedEventStruct['Properties'])
    .memberHandle;
  return (
    <Trans
      i18nKey="userEvents.user.username-changed.detail"
      ns="app-cohort"
      values={{
        memberHandle,
      }}
      components={{
        bold: <span className="cursor-pointer font-semibold" />,
      }}
    />
  );
};

export const CohortUsernameChangedUserEvent: UserEvent<CohortUsernameChangedEventStruct> = {
  spec: CohortUsernameChangedEventSpec,
  detailComponent: DetailComponent,
};
