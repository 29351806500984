import {createContext} from 'react';

export type SelectMediaContextType = {
  selectedMediaId: string | null;
  setSelectedMediaId: (mediaId: string | null) => void;
  selectMediaSheetOpened: boolean;
  setSelectMediaSheetOpened: (selectMediaSheetOpened: boolean) => void;
};

const SelectMediaContext = createContext<SelectMediaContextType | undefined>(undefined);

export default SelectMediaContext;
