import type {WrappedField} from '@cohort/merchants/components/form/FieldWrapper';
import FieldWrapper from '@cohort/merchants/components/form/FieldWrapper';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {forwardRef} from 'react';

type BaseTextareaProps = JSX.IntrinsicElements['textarea'] &
  WrappedField & {
    error: string | undefined;
    name: string;
  };

const BaseTextarea = forwardRef<HTMLTextAreaElement, BaseTextareaProps>(
  ({label, description, error, name, optional, className, ...props}, ref) => {
    return (
      <FieldWrapper
        label={label}
        name={name}
        description={description}
        error={error}
        optional={optional}
      >
        <textarea
          className={cn(
            'resize-none rounded-md border border-border p-2 text-sm focus:border-primary focus:ring-primary',
            className
          )}
          data-testid={name}
          name={name}
          {...props}
          ref={ref}
        />
      </FieldWrapper>
    );
  }
);
export default BaseTextarea;
