import MerchantLogoPlaceholder from '@cohort/components-xps/components/MerchantLogoPlaceholder';
import {useOrganizations} from '@cohort/merchants/hooks/api/Organizations';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {trackChangeOrganizationButtonClicked} from '@cohort/merchants/lib/Tracking';
import {getOrganizationSigninUrl} from '@cohort/merchants/lib/Utils';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {Popover, PopoverContent, PopoverTrigger} from '@cohort/shared-frontend/components/Popover';
import {CaretDown, Check} from '@phosphor-icons/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {shallow} from 'zustand/shallow';

const NavbarOrganizationsPopUp: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'navBar.navbarOrganizationsPopUp'});

  const {data: organizations} = useOrganizations();

  const {organization} = useUserSessionStore(
    store => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organizationMember: store.organizationMember!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organization: store.organization!,
    }),
    shallow
  );

  async function switchToOrganization(slug: string): Promise<void> {
    if (slug === organization.slug) {
      return;
    }
    const url = getOrganizationSigninUrl(slug);
    window.location.assign(url);
  }

  return (
    <Popover>
      <PopoverTrigger className="flex items-center space-x-2">
        {organization.merchantLogoFileKey !== null ? (
          <img
            src={getImageUrl(import.meta.env.COHORT_ENV, organization.merchantLogoFileKey, {
              w: Sizes.XS,
              h: Sizes.XS,
            })}
            className="h-6 w-6 rounded-full border"
          />
        ) : (
          <MerchantLogoPlaceholder size={24} merchantName={organization.name} />
        )}
        <p
          className="text-sm font-medium text-slate-500 group-hover:text-slate-700"
          data-testid="active-org-name"
        >
          {organization.name}
        </p>
        <CaretDown className="h-4 w-4 text-slate-400 group-hover:text-slate-600" />
      </PopoverTrigger>
      <PopoverContent className="w-60">
        <div className="border-b border-border p-4">
          <p className="text-sm">{t('labelOrganizations')}</p>
        </div>
        <div className="py-2">
          {organizations !== undefined &&
            organizations.map(org => (
              <button
                key={org.id}
                onClick={async () => {
                  trackChangeOrganizationButtonClicked(org);
                  return switchToOrganization(org.slug);
                }}
                className="group flex w-full cursor-pointer items-center justify-between px-4 py-2 text-sm text-slate-700 hover:bg-slate-50"
              >
                <div className="flex items-center gap-3">
                  {org.merchantLogoFileKey !== null ? (
                    <img
                      src={getImageUrl(import.meta.env.COHORT_ENV, org.merchantLogoFileKey, {
                        w: Sizes.XS,
                        h: Sizes.XS,
                      })}
                      className="h-6 w-6 rounded-full border"
                      alt={org.name}
                    />
                  ) : (
                    <MerchantLogoPlaceholder size={24} merchantName={org.name} />
                  )}
                  <span>{org.name}</span>
                </div>
                <div>
                  {organization.id === org.id && <Check className="h-5 w-5 text-primary" />}
                </div>
              </button>
            ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default NavbarOrganizationsPopUp;
