import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import {BaseUserEventExportConfigSchema} from '@cohort/shared/apps/sync';
import type {z} from 'zod';

export const KlaviyoUserEventExportConfigSchema = BaseUserEventExportConfigSchema.extend({});
export type KlaviyoUserEventExportConfig = z.infer<typeof KlaviyoUserEventExportConfigSchema>;

export type KlaviyoSyncStruct = SyncIntegrationStruct<
  never,
  never,
  never,
  KlaviyoUserEventExportConfig,
  never,
  never
>;

export const KlaviyoSyncSpec: SyncIntegrationSpec<KlaviyoSyncStruct> = {
  contentSyncConfigSchema: null,
  userSyncsConfigSchema: null,
  userSyncsStateSchema: null,
  userEventExportConfigSchema: KlaviyoUserEventExportConfigSchema,
  userExportConfigSchema: null,
  userExportStateSchema: null,
};
