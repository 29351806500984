import StepCompletionCard from '@cohort/merchants/apps/StepCompletionCard';
import type {GarminConnectCompleteActivityVerificationAttemptData} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';

const GarminConnectCompleteActivityTriggerIntegrationStepCompletionTitleComponent: React.FC<{
  data: GarminConnectCompleteActivityVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => (
  <StepCompletionCard
    translationConfig={{
      // i18nOwl-ignore [app-garmin-connect.triggerIntegrations.complete-activity.stepCompletionTitleComponent.success]
      // i18nOwl-ignore [app-garmin-connect.triggerIntegrations.complete-activity.stepCompletionTitleComponent.error]
      key: `triggerIntegrations.complete-activity.stepCompletionTitleComponent.${
        completionSuccess ? 'success' : 'error'
      }`,
      ns: 'app-garmin-connect',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: data === null ? 'N/A' : data.garminConnectUsername!,
    }}
  />
);

export default GarminConnectCompleteActivityTriggerIntegrationStepCompletionTitleComponent;
