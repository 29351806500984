/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {ListUserEventsParamsADto} from '@cohort/admin-schemas/userEvents';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getUserEvents} from '@cohort/merchants/lib/api/UserEvents';

export const userEventsKeys = {
  userEvents: ['userEvents'] as const,
  list: (merchantId: string, params: ListUserEventsParamsADto) =>
    [...userEventsKeys.userEvents, merchantId, 'list', params] as const,
};

export const useUserEvents = (
  merchantId: string,
  params: ListUserEventsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: userEventsKeys.list(merchantId, params),
    queryFn: async () => getUserEvents(merchantId, params),
    ...options,
  });
