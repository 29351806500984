import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortEngagementScoreUserPropertyStruct = UserPropertyStruct<
  'cohort.engagement-score',
  number
>;

export const CohortEngagementScoreUserPropertySpec: UserPropertySpec<CohortEngagementScoreUserPropertyStruct> =
  {
    id: 'cohort.engagement-score',
    name: 'Cohort Engagement Score',
    dataType: 'number',
    scope: 'global',
    refreshInterval: 24 * 60 * 60, // 24 hours
  };
