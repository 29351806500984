import type {ResourceSpec, ResourceStruct} from '@cohort/shared/apps/resource';
import {BaseResourcePropertiesSchema} from '@cohort/shared/apps/resource';
import {z} from 'zod';

export const CohortContentResourceSchema = BaseResourcePropertiesSchema.extend({
  id: z.string().uuid(),
});

export type CohortContentResourceType = z.infer<typeof CohortContentResourceSchema>;

export type CohortContentResourceStruct = ResourceStruct<
  'cohort.content',
  CohortContentResourceType
>;

export const CohortContentResourceSpec: ResourceSpec<CohortContentResourceStruct> = {
  id: 'cohort.content',
  schema: CohortContentResourceSchema,
};
