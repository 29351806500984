// i18nOwl-ignore [app-comarch.description]
import type {App} from '@cohort/merchants/apps';
import ComarchEditConnectionComponent from '@cohort/merchants/apps/comarch/EditConnectionComponent';
import translationEn from '@cohort/merchants/apps/comarch/locales/en.json';
import translationFr from '@cohort/merchants/apps/comarch/locales/fr.json';
import type {ComarchAppStruct} from '@cohort/shared/apps/comarch';
import {ComarchAppSpec} from '@cohort/shared/apps/comarch';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const ComarchApp: App<ComarchAppStruct> = {
  spec: ComarchAppSpec,
  ConnectionEditComponent: ComarchEditConnectionComponent,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="comarch" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [],
  userEvents: [],
  sync: undefined,
};

export default ComarchApp;
