import type {TriggerIntegration} from '@cohort/merchants/apps';
import TikTokFollowTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/follow/ConfigComponent';
import TikTokFollowTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/follow/StepCompletionTitleComponent';
import type {TikTokFollowTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/follow';
import {TikTokFollowTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/follow';

export const TikTokFollowTriggerIntegration: TriggerIntegration<TikTokFollowTriggerStruct> = {
  spec: TikTokFollowTriggerSpec,
  configComponent: TikTokFollowTriggerConfigComponent,
  stepCompletionTitleComponent: TikTokFollowTriggerStepCompletionTitleComponent,
  getTitle: t => t('triggers.follow.title', {ns: 'app-tiktok'}),
};
