// i18nOwl-ignore [app-shopify.perkIntegrations.discount.title, app-shopify.perkIntegrations.discount.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import ShopifyDiscountPerkIntegrationConfigComponent from '@cohort/merchants/apps/shopify/perkIntegrations/discount/ConfigComponent';
import ShopifyDiscountPerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/shopify/perkIntegrations/discount/UsageViewComponent';
import type {ShopifyDiscountPerkIntegrationStruct} from '@cohort/shared/apps/shopify/perks/discount';
import {ShopifyDiscountPerkIntegrationSpec} from '@cohort/shared/apps/shopify/perks/discount';

export const ShopifyDiscountPerkIntegration: PerkIntegration<ShopifyDiscountPerkIntegrationStruct> =
  {
    spec: ShopifyDiscountPerkIntegrationSpec,
    configComponent: ShopifyDiscountPerkIntegrationConfigComponent,
    usageViewComponent: ShopifyDiscountPerkIntegrationsUsageViewComponent,
    iconColor: 'text-green-500',
    backgroundColor: 'bg-green-100',
  };
