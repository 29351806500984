import type {ContentADto} from '@cohort/admin-schemas/content';
import {CurrentContentContext} from '@cohort/merchants/pages/contents/CurrentContentContext';
import {useContext} from 'react';

export const useCurrentContent = (): ContentADto => {
  const context = useContext(CurrentContentContext);

  if (context === null) {
    throw new Error('useCurrentContent must be used within a CurrentContentProvider');
  }
  return context;
};
