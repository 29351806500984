import {cn} from '@cohort/shared-frontend/utils/classNames';

type EmptyStateProps = {
  className?: string;
  children: React.ReactNode;
};

type EmptyStateTitleProps = {
  children: React.ReactNode;
};

type EmptyStateDescriptionProps = {
  children: React.ReactNode;
};

type EmptyStateContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const EmptyStateTitle: React.FC<EmptyStateTitleProps> = ({children}) => (
  <span className="font-medium">{children}</span>
);

const EmptyStateDescription: React.FC<EmptyStateDescriptionProps> = ({children}) => (
  <span className="text-sm text-slate-500">{children}</span>
);

const EmptyState: React.FC<EmptyStateProps> = ({className, children}) => (
  <div className={cn('flex h-full w-full flex-col items-center justify-center gap-4', className)}>
    {children}
  </div>
);

const EmptyStateContainer: React.FC<EmptyStateContainerProps> = ({className, children}) => (
  <div
    className={cn(
      'flex h-80 flex-col items-center justify-center rounded-md bg-slate-50',
      className
    )}
  >
    {children}
  </div>
);

export {EmptyState, EmptyStateTitle, EmptyStateDescription, EmptyStateContainer};
