import type {ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import {typeformAppKeys} from '@cohort/merchants/apps/typeform/api';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {executeAction} from '@cohort/merchants/lib/api/Apps';
import type {GetUserObjectsActionStruct} from '@cohort/shared/apps/salesforce/actions/getUserObjects';
import type {UseQueryResult} from '@tanstack/react-query';

export const salesforceUserKeys = {
  salesforceApp: ['salesforceApp'] as const,
  getUserObjects: (merchantId: string, merchantConnectionId: string) =>
    [...typeformAppKeys.typeformApp, merchantId, merchantConnectionId, 'get-user-objects'] as const,
};

export const useSalesforceUserObjects = (
  merchantId: string,
  merchantConnectionId: string,
  options?: QueryOptions
): UseQueryResult<ExecuteActionResponseADto<GetUserObjectsActionStruct>, unknown> => {
  return useCohortQuery({
    queryKey: salesforceUserKeys.getUserObjects(merchantId, merchantConnectionId),
    queryFn: async () => {
      return executeAction<GetUserObjectsActionStruct>(merchantId, {
        appId: 'salesforce',
        actionId: 'get-user-objects',
        merchantConnectionId,
        input: {},
      });
    },
    ...options,
  });
};
