/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {GetBadgesParamsADto} from '@cohort/admin-schemas/badge';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {getBadge, getBadges} from '@cohort/merchants/lib/api/Badges';

export const badgesKeys = {
  badges: ['badges'] as const,
  list: (merchantId: string) => [...badgesKeys.badges, merchantId, 'list'] as const,
  listWithFilters: (merchantId: string, params?: GetBadgesParamsADto) =>
    [...badgesKeys.list(merchantId), params] as const,
  getById: (merchantId: string, badgeId: string) =>
    [...badgesKeys.badges, merchantId, badgeId] as const,
};

export const useBadges = (
  merchantId: string,
  params: GetBadgesParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: badgesKeys.listWithFilters(merchantId, params),
    queryFn: async () => getBadges(merchantId, params),
    ...options,
  });

export const useBadge = (merchantId: string, badgeId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: badgesKeys.getById(merchantId, badgeId),
    queryFn: async () => getBadge(merchantId, badgeId),
    ...options,
  });
