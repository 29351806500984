import {z} from 'zod';

export function ensureMetricsIsSetRefinment(
  data: Record<string, unknown>,
  ctx: z.RefinementCtx
): void {
  if (data.hasDistance && !data.minDistanceKilometers) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['minDistanceKilometers'],
    });
  }

  if (data.hasDuration && !data.minDurationMinutes) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['minDurationMinutes'],
    });
  }

  if (data.hasAverageElevationGain && !data.minAverageElevationGainMeters) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['minAverageElevationGainMeters'],
    });
  }

  if (data.hasAverageSpeed && !data.minAverageSpeedKilometersPerHour) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['minAverageSpeedKilometersPerHour'],
    });
  }

  if (data.hasActivityTypes && !data.activityGroups) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['activityGroups'],
    });
  }

  if (data.hasNumberOfSteps && !data.minNumberOfSteps) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'errorRequired',
      path: ['minNumberOfSteps'],
    });
  }
}
