import type {
  ExportPerkAccessesParamsADto,
  GetPerkAccessesParamsADto,
  PerkAccessADto,
} from '@cohort/admin-schemas/perkAccesses';
import {PerkAccessASchema} from '@cohort/admin-schemas/perkAccesses';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ExportDto} from '@cohort/shared/schema/common/export';
import {ExportSchema} from '@cohort/shared/schema/common/export';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';

export async function getPerkAccess(
  merchantId: string,
  perkAccessId: string
): Promise<PerkAccessADto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/perk-accesses/${perkAccessId}`, {
    expect: HttpCodes.SUCCESS,
    parser: PerkAccessASchema.parse,
  });
}

export async function getPerkAccesses(
  merchantId: string,
  params: GetPerkAccessesParamsADto
): Promise<[PaginationDto, PerkAccessADto[]]> {
  return apiCall('GET', `/v1/merchants/${merchantId}/perk-accesses`, {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(PerkAccessASchema),
    params,
  });
}

export async function exportPerkAccesses(
  merchantId: string,
  params: ExportPerkAccessesParamsADto
): Promise<ExportDto> {
  return apiCall('POST', `/v1/merchants/${merchantId}/perk-accesses/export`, {
    expect: HttpCodes.CREATED,
    parser: ExportSchema.parse,
    params,
  });
}
