import type {ExecuteActionDataADto, ExecuteActionResponseADto} from '@cohort/admin-schemas/apps';
import {ExecuteActionResponseASchema} from '@cohort/admin-schemas/apps';
import {apiCall, HttpCodes} from '@cohort/merchants/lib/Api';
import type {ActionStruct} from '@cohort/shared/apps/app';

export async function executeAction<T extends ActionStruct = ActionStruct>(
  merchantId: string,
  data: ExecuteActionDataADto<T>
): Promise<ExecuteActionResponseADto<T>> {
  return apiCall('POST', `/v1/merchants/${merchantId}/apps/execute-action`, {
    expect: HttpCodes.CREATED,
    parser: ExecuteActionResponseASchema.parse,
    body: data,
  }) as Promise<ExecuteActionResponseADto<T>>;
}
