import {z} from 'zod';

export const ContentStatusSchema = z.enum(['draft', 'published']);
export type ContentStatus = z.infer<typeof ContentStatusSchema>;

export const ContentAudienceSchema = z.enum(['everyone', 'cohort', 'notVisible']);
export type ContentAudience = z.infer<typeof ContentAudienceSchema>;

export const ContentsOrderBySchema = z.enum([
  'createdAt',
  '-createdAt',
  'viewsCount',
  '-viewsCount',
]);
export type ContentsOrderByParams = z.infer<typeof ContentsOrderBySchema>;
