import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';
import type {ResourceListDataType} from '@cohort/shared/schema/common/userProperty';

export type DiscordRolesUserPropertyStruct = UserPropertyStruct<
  'discord.roles',
  ResourceListDataType
>;

export const DiscordRolesUserPropertySpec: UserPropertySpec<DiscordRolesUserPropertyStruct> = {
  id: 'discord.roles',
  name: 'Roles on server',
  dataType: 'resource_list',
  scope: 'merchant_connection',
  resource: 'discord.role',
  refreshInterval: 24 * 60 * 60, // 24 hours
};
