import type {UserPropertySpec, UserPropertyStruct} from '@cohort/shared/apps/userProperty';

export type CohortLanguageUserPropertyStruct = UserPropertyStruct<'cohort.language', string>;

export const CohortLanguageUserPropertySpec: UserPropertySpec<CohortLanguageUserPropertyStruct> = {
  id: 'cohort.language',
  name: 'Language',
  dataType: 'string',
  scope: 'global',
};
