import AsidePageMenu from '@cohort/merchants/components/AsidePageMenu';
import {useCurrentDigitalAssetCollection} from '@cohort/merchants/hooks/contexts/currentDigitalAssetCollection';
import {
  getDigitalAssetCampaignsRoute,
  getDigitalAssetNotificationsRoute,
  getDigitalAssetOwnersRoute,
  getDigitalAssetSettingsRoute,
} from '@cohort/merchants/lib/Pages';
import {Bell, Gear, ListMagnifyingGlass} from '@phosphor-icons/react';
import {Megaphone} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

const DigitalAssetCollectionPageMenu = (): JSX.Element => {
  const digitalAssetCollection = useCurrentDigitalAssetCollection();
  const {t} = useTranslation('pages', {
    keyPrefix: 'digitalAssetCollections.digitalAssetCollection.digitalAssetCollectionPageMenu',
  });

  return (
    <AsidePageMenu
      entries={[
        {
          name: t('labelOwners'),
          path: getDigitalAssetOwnersRoute(digitalAssetCollection.id).path,
          icon: <ListMagnifyingGlass size={20} />,
          testId: 'digital-asset-collection-owners-link',
        },
        {
          name: t('labelCampaigns'),
          path: getDigitalAssetCampaignsRoute(digitalAssetCollection.id).path,
          icon: <Megaphone size={20} />,
          testId: 'digital-asset-collection-campaigns-link',
        },
        {
          name: t('labelNotifications'),
          path: getDigitalAssetNotificationsRoute(digitalAssetCollection.id).path,
          icon: <Bell size={20} />,
          testId: 'digital-asset-collection--notifications-link',
        },
        {
          name: t('labelSettings'),
          path: getDigitalAssetSettingsRoute(digitalAssetCollection.id).path,
          icon: <Gear size={20} />,
          testId: 'digital-asset-collection-details-link',
        },
      ]}
    />
  );
};

export default DigitalAssetCollectionPageMenu;
