import type {ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials, OAuth2Credentials} from '@cohort/shared/apps/oauth';
import {basicOauth2ConnectorSpec} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const StravaProfilePropertiesSchema = BaseProfilePropertiesSchema.extend({
  username: z.string().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  gender: z.enum(['M', 'F']).nullable(),
  memberSince: z.date(),
  isPremium: z.boolean(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  country: z.string().nullable(),
  followersCount: z.number().nullable(),
  friendsCount: z.number().nullable(),
  totalRidesCount: z.number(),
  totalRidesDistance: z.number(),
  totalRunsCount: z.number(),
  totalRunsDistance: z.number(),
  totalSwimsCount: z.number(),
  totalSwimsDistance: z.number(),
  ytdRidesCount: z.number(),
  ytdRidesDistance: z.number(),
  ytdRunsCount: z.number(),
  ytdRunsDistance: z.number(),
  ytdSwimsCount: z.number(),
  ytdSwimsDistance: z.number(),
  recentRideCount: z.number(),
  recentRideDistance: z.number(),
  recentRunCount: z.number(),
  recentRunDistance: z.number(),
  recentSwimCount: z.number(),
  recentSwimDistance: z.number(),
});
export type StravaProfileProperties = z.infer<typeof StravaProfilePropertiesSchema>;

export type StravaConnectorStruct = ConnectorStruct<
  'strava',
  OAuth2AppCredentials,
  OAuth2Credentials,
  StravaProfileProperties,
  null
>;

export const StravaConnectorSpec = basicOauth2ConnectorSpec<StravaConnectorStruct>(
  'strava',
  StravaProfilePropertiesSchema
);
