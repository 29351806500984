import type {TriggerIntegration} from '@cohort/merchants/apps';
import TwitterFollowTriggerIntegrationConfigComponent from '@cohort/merchants/apps/twitter/triggerIntegrations/follow/ConfigComponent';
import TwitterFollowTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/twitter/triggerIntegrations/follow/StepCompletionTitleComponent';
import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import {TwitterFollowTriggerIntegrationSpec} from '@cohort/shared/apps/twitter/triggers/follow';

export const TwitterFollowTriggerIntegration: TriggerIntegration<TwitterFollowTriggerStruct> = {
  spec: TwitterFollowTriggerIntegrationSpec,
  configComponent: TwitterFollowTriggerIntegrationConfigComponent,
  stepCompletionTitleComponent: TwitterFollowTriggerIntegrationStepCompletionTitleComponent,
  getTitle: t => t('triggerIntegrations.follow.title', {ns: 'app-twitter'}),
};
