import type {CohortFormQuestionType} from '@cohort/shared/schema/common/cohortForm';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {At, Calendar, CheckSquare, Hash, ListDashes, TextAa} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type CohortFormConfigProps = {
  type: CohortFormQuestionType;
  textClassName?: string;
};

// i18nOwl-ignore [checkbox, date, email, number, select, text]
const CohortFormQuestionTypeLabel: React.FC<CohortFormConfigProps> = ({type, textClassName}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'form.cohortForm.cohortFormQuestionTypeLabel',
  });

  const Icon = match(type)
    .with('checkbox', () => CheckSquare)
    .with('date', () => Calendar)
    .with('email', () => At)
    .with('number', () => Hash)
    .with('select', () => ListDashes)
    .with('text', () => TextAa)
    .exhaustive();

  return (
    <div className="flex items-center gap-2">
      <Icon className="h-4 w-4 text-slate-400" />
      <p className={cn('font-normal text-slate-500', textClassName)}>{t(type)}</p>
    </div>
  );
};

export default CohortFormQuestionTypeLabel;
