import {EmailSchema} from '@cohort/shared/schema/common';
import {SsoProviderSchema} from '@cohort/shared/schema/common/ssoProvider';
import {z} from 'zod';

export const SsoProviderASchema = z.object({
  name: z.string(),
  type: SsoProviderSchema,
  providerId: z.string(),
  logoFileKey: z.string().nullable(),
});
export type SsoProviderADto = z.infer<typeof SsoProviderASchema>;

export const OrganizationASchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  adminAuthTenantId: z.string(),
  adminEmailSigninEnabled: z.boolean(),
  adminSsoProvider: SsoProviderASchema.nullable(),
  slug: z.string(),
  merchantId: z.string().uuid(),
  merchantSlug: z.string(),
  merchantLogoFileKey: z.string().nullable(),
  testMerchantId: z.string().uuid(),
  testMerchantSlug: z.string(),
});
export type OrganizationADto = z.infer<typeof OrganizationASchema>;

export const InviteOrganizationMembersASchema = z.object({
  emails: z.array(EmailSchema),
});
export type InviteOrganizationMembersADto = z.infer<typeof InviteOrganizationMembersASchema>;

export const GetOrganizationApiSettingsResponseASchema = z.object({
  webhooksPortalLink: z.string().url(),
});
export type GetOrganizationApiSettingsResponseADto = z.infer<
  typeof GetOrganizationApiSettingsResponseASchema
>;
