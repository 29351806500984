import type {CurrentAppContextValue} from '@cohort/merchants/pages/apps/app/CurrentAppContext';
import {CurrentAppContext} from '@cohort/merchants/pages/apps/app/CurrentAppContext';
import {useContext} from 'react';

export const useCurrentApp = (): CurrentAppContextValue => {
  const context = useContext(CurrentAppContext);

  if (context === null) {
    throw new Error('useCurrentApp must be used within a CurrentAppProvider');
  }
  return context;
};
