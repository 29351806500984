import type {OrganizationADto} from '@cohort/admin-schemas/organization';
import type {OrganizationMemberADto} from '@cohort/admin-schemas/organizationMember';
import type {DataMode} from '@cohort/shared/schema/common/dataMode';
import {create} from 'zustand';

export type UserSessionStore = {
  isLoggedIn: boolean;
  isAuthenticating: boolean;
  organizationMember?: OrganizationMemberADto;
  organization?: OrganizationADto;
  merchantId?: string;
  merchantSlug?: string;
  setIsAuthenticating: (isAuthenticating: boolean) => void;
  login: (organizationMember: OrganizationMemberADto) => void;
  partialLogin: () => void;
  logout: () => void;
  updateOrganizationMerchantLogo: (logoUrl: string | null) => void;
  toggleMerchantId: (mode: DataMode) => void;
};

export const useUserSessionStore = create<UserSessionStore>(set => ({
  isLoggedIn: false,
  isAuthenticating: true,
  setIsAuthenticating: isAuthenticating => set({isAuthenticating}),
  login: organizationMember => {
    set({
      isAuthenticating: false,
      isLoggedIn: true,
      merchantId: undefined,
      organizationMember,
      organization: organizationMember.organization,
    });
  },
  partialLogin: () => {
    set({
      isAuthenticating: false,
      isLoggedIn: true,
    });
  },
  logout: () =>
    set({
      isAuthenticating: false,
      isLoggedIn: false,
      organizationMember: undefined,
      organization: undefined,
      merchantId: undefined,
    }),
  updateOrganizationMerchantLogo: (logoUrl: string | null) =>
    set(state => ({
      organization: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...state.organization!,
        merchantLogoFileKey: logoUrl,
      },
    })),
  toggleMerchantId: mode =>
    set(state => ({
      merchantId:
        mode === 'live' ? state.organization?.merchantId : state.organization?.testMerchantId,
      merchantSlug:
        mode === 'live' ? state.organization?.merchantSlug : state.organization?.testMerchantSlug,
    })),
}));
