interface Props {
  title: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  actions?: React.ReactNode | React.ReactNode[];
  badge?: React.ReactNode;
}

const Header: React.FC<Props> = ({title, subtitle, actions, badge}) => (
  <div className="flex w-full flex-row items-start justify-between">
    <div className="grow">
      <div className="flex items-center gap-4">
        <h1 className="text-2xl font-bold">{title}</h1>
        {badge}
      </div>
      {subtitle && (
        <div className="mt-2 flex-grow text-sm leading-5 text-slate-500">{subtitle}</div>
      )}
    </div>
    {actions !== undefined && (
      <div className="flex flex-shrink-0 items-center gap-4 pl-2">{actions}</div>
    )}
  </div>
);

export default Header;
