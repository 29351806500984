import type {TriggerIntegration} from '@cohort/merchants/apps';
import FacebookLikePostTriggerIntegrationConfigComponent from '@cohort/merchants/apps/facebook/triggers/likePost/ConfigComponent';
import FacebookLikePostTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/likePost/StepCompletionTitleComponent';
import type {FacebookLikePostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePost';
import {FacebookLikePostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePost';

export const FacebookLikePostTriggerIntegration: TriggerIntegration<FacebookLikePostTriggerStruct> =
  {
    spec: FacebookLikePostTriggerSpec,
    configComponent: FacebookLikePostTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: FacebookLikePostTriggerIntegrationStepCompletionTitleComponent,
    getTitle: t => t('triggers.like-post.title', {ns: 'app-facebook'}),
  };
